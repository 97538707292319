import { useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import invariant from 'tiny-invariant'

import {
  UpdateModuleModel,
  UpdateModuleSectionOrderModel,
} from 'api/data-contracts'
import { useApiClient } from './use-clients'

// GET:/api/Module/{moduleId}
const useGetModule = () => {
  const { moduleId } = useParams()
  invariant(moduleId, 'moduleId not available')
  const client = useApiClient()

  const result = useQuery({
    queryKey: ['module', moduleId],
    queryFn: () => client.module.getModule(moduleId),
    enabled: !!moduleId,
    staleTime: Infinity,
  })
  return { ...result, module: result.data?.data ?? null }
}

// PUT:/api/Module/{moduleId}
interface UpdateModuleModelProps {
  moduleId: string
  data: UpdateModuleModel
}
const useUpdateModule = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (props: UpdateModuleModelProps) =>
      client.module.updateModule(props.moduleId, props.data),
    onSuccess: data => {
      queryClient.invalidateQueries(['module'])
      queryClient.setQueryData(['module'], data)
      queryClient.refetchQueries(['module'])
    },
  })
  return mutation
}

// GET:/api/Module/{moduleId}/sections
const useGetSections = (moduleId: string) => {
  const client = useApiClient()

  const result = useQuery({
    queryKey: ['sections', { moduleId }],
    queryFn: () => client.module.getSections(moduleId),
    staleTime: Infinity,
  })
  return { ...result, sections: result.data?.data, moduleId }
}

// POST:/api/Module/{moduleId}/sections
export interface AddSectionToModuleModel {
  Name?: string | undefined
  Description?: string | undefined
  Tags?: string | undefined
  IsInMenu?: boolean
  SectionType?: string | undefined
  SectionTemplate?: string | undefined
  IsRequiredToBeCompleted?: boolean
  Title?: string | undefined
  ContentImage?: File | undefined
  ContentArea1?: string | undefined
  ContentArea2?: string | undefined
  ContentArea3?: string | undefined
  /** @format int32 */
  MiniQuizNumQuestionsToShow?: number
  /** @format int32 */
  AssessmentNumQuestionsToShow?: number
  AssessmentMarkProcess?: string | undefined
  AssessmentQuestionOrder?: string | undefined
  /** @format int32 */
  AssessmentPassRate?: number
  AssessmentIsTimed?: boolean
  /** @format int32 */
  AssessmentTimePerQuestionSeconds?: number
  /** @format int32 */
  AssessmentNumberOfAttempts?: number
}
interface AddNewSectionToModuleProps {
  data: AddSectionToModuleModel
}
const useAddNewSectionToModule = () => {
  const { moduleId } = useParams()
  invariant(moduleId, 'moduleId not available')
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (props: AddNewSectionToModuleProps) => {
      return client.module.addNewSectionToModule(moduleId, props.data)
    },
    onSuccess: data => {
      queryClient.invalidateQueries(['section'])
      queryClient.setQueryData(['section'], data) //set query (oldData,newData)
      queryClient.refetchQueries(['sections']) // refetch queries that depend on the user data
    },
  })
  return mutation
}

// PUT:/api/Module/{moduleId}/sections/order
interface UpdateModuleSectionOrderProps {
  moduleId?: string | undefined
  data: UpdateModuleSectionOrderModel
}
const useUpdateModuleSectionOrder = (moduleId: string) => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (props: UpdateModuleSectionOrderProps) =>
      client.module.updateModuleSectionOrder(moduleId, props.data),
    onSuccess: data => {
      queryClient.setQueryData(['sections', { moduleId: moduleId }], data)
      // queryClient.refetchQueries(['sections'])
    },
  })
  return mutation
}

// PUT:/api/Module/{moduleId}/resources/{resourceId}
interface AddResourceToModuleParams {
  moduleId: string
  resourceId: string
}
const useAddResourceToModule = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation(
    async ({ moduleId, resourceId }: AddResourceToModuleParams) => {
      const data = await client.module.addResourceToModule(moduleId, resourceId)
      return data
    },
    {
      onSuccess: data => {
        queryClient.invalidateQueries(['resources'])
        queryClient.invalidateQueries(['moduleResources'])
        queryClient.setQueryData(['moduleResources'], data)
      },
    },
  )
  return mutation
}

// GET:/api/Module/{moduleId}/resources
const useGetModuleResources = () => {
  const { moduleId } = useParams()
  invariant(moduleId, 'moduleId not available')
  const client = useApiClient()
  const result = useQuery({
    queryKey: ['moduleResources', { moduleId }],
    queryFn: () => client.module.getModuleResources(moduleId),
    staleTime: Infinity,
  })
  return { ...result, resources: result.data?.data, moduleId }
}

// DELETE:/api/Module/{moduleId}/resources/{resourceId}
interface useRemoveResourceFromModuleProps {
  firstParam: string
  secondParam: string
}
const useRemoveResourceFromModule = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: async ({
      firstParam,
      secondParam,
    }: useRemoveResourceFromModuleProps) => {
      await client.module.removeResourceFromModule(firstParam, secondParam)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['moduleResources'])
      queryClient.refetchQueries(['moduleResources'])
    },
  })
  return mutation
}

// DELETE:/api/Module/{moduleId}
const useDeleteModule = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: async (moduleId: string) => {
      await client.module.deleteModule(moduleId)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['modules'])
      queryClient.refetchQueries(['modules'])
    },
  })
  return mutation
}

interface useGiveFeedbackProps {
  moduleId: string
  data: {
    userCourseInstanceId: string
    moduleFeedback: string
  }
}

const useGiveFeedback = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: (props: useGiveFeedbackProps) =>
      client.module.feedbackUsersModule(props.moduleId, props.data),
    onSuccess: async () => {
      await queryClient.refetchQueries(['userCourseInstance'])
    },
  })
  return mutation
}

interface useCompleteModuleProps {
  moduleId: string
  data: {
    userCourseInstanceId: string
  }
}

const useCompleteModule = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: (props: useCompleteModuleProps) =>
      client.module.completeUsersModule(props.moduleId, props.data),
    onSuccess: async () => {
      await queryClient.refetchQueries(['userCourseInstance'])
    },
  })
  return mutation
}

interface useUpdateModuleCompletionImageProps {
  moduleId: string
  data: {
    moduleCompletionImage?: File
  }
}
const useUpdateModuleCompletionImage = () => {
  const client = useApiClient()
  const mutation = useMutation({
    mutationFn: (props: useUpdateModuleCompletionImageProps) =>
      client.module.updateModuleCompletionImage(props.moduleId, props.data),
  })
  return mutation
}
interface useDeleteModuleCompletionImageProps {
  moduleId: string
}
const useDeleteModuleCompletionImage = () => {
  const client = useApiClient()
  const mutation = useMutation({
    mutationFn: (props: useDeleteModuleCompletionImageProps) =>
      client.module.deleteModuleCompletionImage(props.moduleId),
  })
  return mutation
}
export {
  useUpdateModule,
  useGetModule,
  useGetSections,
  useAddNewSectionToModule,
  useUpdateModuleSectionOrder,
  useGetModuleResources,
  useAddResourceToModule,
  useRemoveResourceFromModule,
  useDeleteModule,
  useGiveFeedback,
  useCompleteModule,
  useUpdateModuleCompletionImage,
  useDeleteModuleCompletionImage,
}
