import { useEffect, useMemo, useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'

import { FormTextField } from 'components/FormTextField'
import { useBranding } from 'utils/useBranding'
import { ComapnySettingModel } from 'utils/models/Company'
import { InitialValues } from './InitialValues'
import { ValidationSchema } from './ValidationSchema'
import { OrganisationSettingModel } from 'api/data-contracts'
import ImageDragAndDrop from './ImageDragAndDrop'
import { Fileprops } from 'utils/models/File'

// API
import {
  useUpdateBannerImage,
  useDeleteBannerImage,
  useGetSettings,
  useUpdateOrganisationSettings,
  useGetOrganisation,
  useGetOrganisationID,
} from 'utils/organisation'
import { ActionButton } from 'components/ActionButton'

const PlatformSetting = () => {
  const { branding } = useBranding()
  const { organisationId } = useGetOrganisationID()

  const { mutateAsync: updateBannerImage } = useUpdateBannerImage()
  const { mutateAsync: deleteBannerImage } = useDeleteBannerImage()
  const { settingData } = useGetSettings()
  const { mutateAsync: updateOrgSettings } = useUpdateOrganisationSettings()
  const { getOrganisationData } = useGetOrganisation()

  const initalValues = useMemo(() => {
    return InitialValues
  }, [])

  const [platformSetting, setPlatformSetting] =
    useState<ComapnySettingModel>(initalValues)
  const [bannerImage, setBannerImage] = useState<Fileprops | null>(null)
  const [isBannerImageDeleted, setIsBannerImageDeleted] = useState(false)
  const [saveSettingLoader, setSaveSettingLoader] = useState(false)

  useEffect(() => {
    setPlatformSetting({
      ...settingData,
      ...getOrganisationData,
    })
  }, [settingData, getOrganisationData])

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<ComapnySettingModel>({
    resolver: yupResolver(ValidationSchema),
    defaultValues: useMemo(() => {
      return platformSetting
    }, [platformSetting]),
  })

  useEffect(() => {
    reset(platformSetting)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platformSetting])

  const onSubmit: SubmitHandler<ComapnySettingModel> = async data => {
    const updateOrgSettingData: OrganisationSettingModel = {
      bannerTitle: data.bannerTitle,
      bannerImage: data.bannerImage,
      bannerSubTitle: data.bannerSubTitle,
      bannerCallToActionText: data.bannerCallToActionText,
      bannerCallToActionUrl: data.bannerCallToActionUrl,
      introTitle: data.introTitle,
      introText: data.introText,
      howItWorksTitle: data.howItWorksTitle,
      howItWorks: data.howItWorks,
      howItWorksTitle2: data.howItWorksTitle2,
      howItWorks2: data.howItWorks2,
      howItWorksTitle3: data.howItWorksTitle3,
      howItWorks3: data.howItWorks3,
      howItWorksTitle4: data.howItWorksTitle4,
      howItWorks4: data.howItWorks4,
      moreInfoTitle: data.moreInfoTitle,
      moreInfoDescription: data.moreInfoDescription,
      moreInfoCallToActionText: data.moreInfoCallToActionText,
      moreInfoCallToActionUrl: data.moreInfoCallToActionUrl,
      focusTag: data.focusTag,
      companyInfoTitle: data.companyInfoTitle,
      companyInfo: data.companyInfo,
    }

    setSaveSettingLoader(true)
    try {
      if (organisationId !== '') {
        // Delete images
        if (isBannerImageDeleted) {
          await deleteBannerImage({
            organisationId,
          })
        }
        // Update images
        if (bannerImage !== null) {
          await updateBannerImage({
            organisationId,
            data: {
              bannerImage: bannerImage as File,
            },
          })
        }
        await updateOrgSettings({
          organisationId,
          data: updateOrgSettingData,
        })
      }
      toast.success('Platform setting saved')
    } catch {
      toast.error('Error')
    }
    setSaveSettingLoader(false)
  }

  return (
    <form className="mb-50">
      <div className="flex flex-wrap justify-between">
        <FormTextField
          control={control}
          errors={errors?.introTitle}
          name="introTitle"
          label="Intro title"
          multilineValue={2}
        />
        <FormTextField
          control={control}
          errors={errors?.introText}
          name="introText"
          label="Intro text"
          multilineValue={4}
        />
        <FormTextField
          control={control}
          errors={errors?.companyInfoTitle}
          name="companyInfoTitle"
          label="Company info title"
        />
        <FormTextField
          control={control}
          errors={errors?.companyInfo}
          name="companyInfo"
          label="Company info"
        />
        <FormTextField
          control={control}
          errors={errors?.bannerTitle}
          name="bannerTitle"
          label="Banner title"
        />
        <FormTextField
          control={control}
          errors={errors?.bannerSubTitle}
          name="bannerSubTitle"
          label="Banner sub title"
          multilineValue={3}
        />
        <FormTextField
          control={control}
          errors={errors?.bannerCallToActionText}
          name="bannerCallToActionText"
          label="Banner call to action text"
        />
        <FormTextField
          control={control}
          errors={errors?.bannerCallToActionUrl}
          name="bannerCallToActionUrl"
          label="Banner call to action url"
        />

        <FormTextField
          control={control}
          errors={errors?.howItWorksTitle}
          name="howItWorksTitle"
          label="How it works title"
          multilineValue={2}
        />
        <FormTextField
          control={control}
          errors={errors?.howItWorks}
          name="howItWorks"
          label="How it works"
          multilineValue={4}
        />
        <FormTextField
          control={control}
          errors={errors?.howItWorksTitle2}
          name="howItWorksTitle2"
          label="How it works title 2"
          multilineValue={2}
        />
        <FormTextField
          control={control}
          errors={errors?.howItWorks2}
          name="howItWorks2"
          label="How it works 2"
          multilineValue={4}
        />
        <FormTextField
          control={control}
          errors={errors?.howItWorksTitle3}
          name="howItWorksTitle3"
          label="How it works title 3"
          multilineValue={2}
        />
        <FormTextField
          control={control}
          errors={errors?.howItWorks3}
          name="howItWorks3"
          label="How it works 3"
          multilineValue={4}
        />
        <FormTextField
          control={control}
          errors={errors?.howItWorksTitle4}
          name="howItWorksTitle4"
          label="How it works title 4"
          multilineValue={2}
        />
        <FormTextField
          control={control}
          errors={errors?.howItWorks4}
          name="howItWorks4"
          label="How it works 4"
          multilineValue={4}
        />
        <FormTextField
          control={control}
          errors={errors?.moreInfoTitle}
          name="moreInfoTitle"
          label="More info title"
          multilineValue={2}
        />
        <FormTextField
          control={control}
          errors={errors?.moreInfoDescription}
          name="moreInfoDescription"
          label="More info description"
          multilineValue={4}
        />
        <FormTextField
          control={control}
          errors={errors?.moreInfoCallToActionText}
          name="moreInfoCallToActionText"
          label="More info call to action text"
        />
        <FormTextField
          control={control}
          errors={errors?.moreInfoCallToActionUrl}
          name="moreInfoCallToActionUrl"
          label="More info call to action url"
        />
        <FormTextField
          control={control}
          errors={errors?.focusTag}
          name="focusTag"
          label="Focus tag"
        />
      </div>
      <div className="flex flex-wrap justify-between">
        <div className="w-full mb-25 desktop-w-50 min-w-500px">
          <p className="text-label">Banner image</p>
          <ImageDragAndDrop
            imageFile={bannerImage}
            setImageFile={setBannerImage}
            imageExist={platformSetting?.bannerImage}
            deleteStatus={isBannerImageDeleted}
            setDeleteImageStatus={setIsBannerImageDeleted}
            fileTypes={['JPG', 'PNG', 'JPEG']}
          />
        </div>
      </div>

      <ActionButton
        name="Save"
        background={branding.stylingPrimary ?? 'blue'}
        setAction={handleSubmit(onSubmit)}
        className={`flex justify-center
          ${saveSettingLoader && 'pointer-event-none opacity-50'}`}
        spinner={saveSettingLoader}
      />
    </form>
  )
}

export default PlatformSetting
