import { PageTitle } from 'utils/constants/pageTitles'
import TitleHelmet from 'components/TitleHelmet'
import { useGetUserCourseInstanceById } from 'utils/userCourseInstance'
import { useParams } from 'react-router'
import { useCompleteModule, useGetSections } from 'utils/module'
import AssessmentAccordion from '../../components/dashboard/AssessmentAccordion'
import React, { useState } from 'react'
import { ASSESSMENT } from 'utils/constants/sections'
import ModuleFeedback from 'components/dashboard/ModuleFeedback'
import AnchorLink from 'components/AnchorLink'
import { toast } from 'react-toastify'
import ButtonLoaderSpinner from 'components/ButtonLoaderSpinner'

const ParticipantsModule = () => {
  const { participantId, moduleId } = useParams()
  const { userCourseInstance } = useGetUserCourseInstanceById(participantId!)
  const { sections } = useGetSections(moduleId!)

  const [completeModuleDisable, setCompleteModuleDisable] = useState(false)

  const firstname = userCourseInstance?.user?.firstname
  const lastname = userCourseInstance?.user?.lastname
  const fullname = `${firstname !== undefined ? firstname : ''} ${
    lastname !== undefined ? lastname : ''
  }`

  const currentModuleData = userCourseInstance?.userModuleDatas?.find(
    module => module?.module?.id === moduleId,
  )

  const { mutateAsync: completeModule } = useCompleteModule()

  const onSubmit = () => {
    setCompleteModuleDisable(true)
    userCourseInstance &&
      completeModule({
        moduleId: moduleId!,
        data: {
          userCourseInstanceId: userCourseInstance.id!,
        },
      })
        .then(() => {
          toast.success('Module marked as complete!')
          setCompleteModuleDisable(false)
        })
        .catch(() => {
          toast.error('Error marking module')
          setCompleteModuleDisable(false)
        })
  }

  let assessmentCounter = 1

  return (
    <>
      <TitleHelmet
        title={PageTitle.COURSE_SETUP_COURSE_INSTANCE_PARTICIPANTS_MODULE}
      />
      <h1>{fullname}</h1>
      Assessments which need your attention
      <section className="px-9 py-20 lg:py-32 lg:px-24 font-white bg-branding-primary">
        <div className="container mx-auto lg:px-12 flex gap-6 justify-center lg:justify-start">
          <div className="w-100 lg:w-[65%] z-10">
            <div className="w-100">
              {sections &&
                sections.map(section =>
                  section.sectionType === ASSESSMENT ? (
                    <React.Fragment key={section.id}>
                      <AssessmentAccordion
                        currentModuleData={currentModuleData!}
                        section={section}
                        index={assessmentCounter++}
                      />
                    </React.Fragment>
                  ) : null,
                )}
            </div>
            <div className="module-feedback">
              <h2>Feedback on module (optional)</h2>
              {userCourseInstance && (
                <ModuleFeedback
                  currentModuleData={currentModuleData!}
                  moduleId={moduleId!}
                  userCourseInstanceId={userCourseInstance.id!}
                />
              )}
              {userCourseInstance &&
                currentModuleData?.userModuleStatus === 'FEEDBACKREQUIRED' && (
                  <AnchorLink
                    className={`standard-button flex align-center  ${
                      completeModuleDisable && 'opacity-50 pointer-event-none'
                    }`}
                    onClick={onSubmit}
                  >
                    Complete module
                    <ButtonLoaderSpinner
                      spinnerColor="#fff"
                      spinnerSize={15}
                      loadingState={completeModuleDisable}
                    />
                  </AnchorLink>
                )}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default ParticipantsModule
