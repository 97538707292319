import { useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import invariant from 'tiny-invariant'

import {
  AddContentSectionToSectionModel,
  UpdateContentSectionOrderModel,
  UpdateSectionModel,
} from 'api/data-contracts'
import { useApiClient } from './use-clients'

// GET:/api/Module/{moduleId}
const useGetSection = () => {
  const { sectionId } = useParams()
  invariant(sectionId, 'sectionId not available')
  const client = useApiClient()

  const result = useQuery({
    queryKey: ['sections', sectionId],
    queryFn: () => client.module.getModule(sectionId),
  })
  return { ...result, module: result.data?.data ?? null }
}

// GET:/api/Section/{sectionId}
interface useGetSectionByIdProps {
  sectionId: string
}
const useGetSectionById = () => {
  const client = useApiClient()
  const mutation = useMutation({
    mutationFn: (props: useGetSectionByIdProps) =>
      client.section.getSection(props.sectionId),
  })
  return mutation
}

// DELETE:/api/Section/{sectionId}
const useDeleteSection = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (sectionId: string) => client.section.deleteSection(sectionId),
    onSuccess: () => {
      queryClient.invalidateQueries(['sections'])
    },
  })
  return mutation
}

// PUT:/api/Section/{sectionId}
interface UpdateSectionModelProps {
  sectionId: string
  data: UpdateSectionModel
}
const useUpdateSection = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (props: UpdateSectionModelProps) =>
      client.section.updateSection(props.sectionId, props.data),
    onSuccess: data => {
      queryClient.invalidateQueries(['sections'])
      queryClient.setQueryData(['sections'], data)
      queryClient.refetchQueries(['sections'])
    },
  })
  return mutation
}

// PUT:/api/Section/{sectionId}/images/contentimage
interface useUpdateContentImageSectionProps {
  sectionId: string
  data: { contentImage?: File }
}
const useUpdateContentImageSection = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (props: useUpdateContentImageSectionProps) =>
      client.section.updateSectionContentImage(props.sectionId, props.data),
    onSuccess: data => {
      queryClient.invalidateQueries(['sections'])
      queryClient.setQueryData(['sections'], data)
      queryClient.refetchQueries(['sections'])
    },
  })
  return mutation
}

// DELETE:/api/Section/{sectionId}/images/contentimage
interface useDeleteContentImageSectionProps {
  sectionId: string
}
const useDeleteContentImageSection = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (props: useDeleteContentImageSectionProps) =>
      client.section.deleteSectionContentImage(props.sectionId),
    onSuccess: () => {
      queryClient.invalidateQueries(['sections'])
      queryClient.refetchQueries(['sections'])
    },
  })
  return mutation
}

// GET:/api/Section/{sectionId}/questions
const useGetSectionQuestions = (sectionId: string) => {
  const client = useApiClient()

  const result = useQuery({
    queryKey: ['sectionsQuestions', { sectionId }],
    queryFn: () => client.section.getSectionQuestions(sectionId),
  })
  return { ...result, getSectionQuestion: result.data?.data, sectionId }
}

// DELETE:/api/Section/{sectionId}/questions/{questionId}
interface useQuestionFromSectionProps {
  sectionId: string
  questionId: string
}
const useDeleteQuestionFromSection = () => {
  const { moduleId } = useParams()
  invariant(moduleId, 'moduleId not available')
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (props: useQuestionFromSectionProps) =>
      client.section.deleteQuestionFromSection(
        props.sectionId,
        props.questionId,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(['sectionsQuestions'])
      queryClient.refetchQueries(['sectionsQuestions'])
    },
  })
  return mutation
}

// PUT:/api/Section/{sectionId}/questions/{questionId}
const useAddQuestionToSection = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (props: useQuestionFromSectionProps) =>
      client.section.addQuestionToSection(props.sectionId, props.questionId),
    onSuccess: () => {
      queryClient.invalidateQueries(['sectionsQuestions'])
      queryClient.refetchQueries(['sectionsQuestions'])
    },
  })
  return mutation
}

// PUT:/api/Section/{sectionId}/questions/order
interface useUpdateSectionQuestionOrderProps {
  sectionId: string
  data: {
    orderedQuestionIds: string[] | null
  }
}
const useUpdateSectionQuestionOrder = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (props: useUpdateSectionQuestionOrderProps) =>
      client.section.updateSectionQuestionOrder(props.sectionId, props.data),
    onSuccess: () => {
      queryClient.invalidateQueries(['sectionsQuestions'])
      queryClient.refetchQueries(['sectionsQuestions'])
    },
  })
  return mutation
}

// POST:/api/Section/{sectionId}/contentsections
interface useAddContentSectionToSectionProps {
  sectionId: string
  data: AddContentSectionToSectionModel
}
const useAddContentSectionToSection = () => {
  const client = useApiClient()
  const mutation = useMutation({
    mutationFn: (props: useAddContentSectionToSectionProps) => {
      return client.section.addContentSectionToSection(
        props.sectionId,
        props.data,
      )
    },
  })
  return mutation
}

// DELETE:/api/Section/{sectionId}/contentsections/{contentSectionId}
interface useDeleteContentSectionFromSectionProps {
  sectionId: string
  contentSectionId: string
}
const useDeleteContentSectionFromSection = () => {
  const client = useApiClient()
  const mutation = useMutation({
    mutationFn: (props: useDeleteContentSectionFromSectionProps) =>
      client.section.deleteContentSectionFromSection(
        props.sectionId,
        props.contentSectionId,
      ),
  })
  return mutation
}

// PUT:/api/Section/{sectionId}/contentsections/order
interface useUpdateContentSectionOrderProps {
  sectionId: string
  data: UpdateContentSectionOrderModel
}
const useUpdateContentSectionOrder = () => {
  const client = useApiClient()
  const mutation = useMutation({
    mutationFn: (props: useUpdateContentSectionOrderProps) =>
      client.section.updateContentSectionOrder(props.sectionId, props.data),
  })
  return mutation
}

export {
  useGetSection,
  useGetSectionById,
  useUpdateSection,
  useDeleteSection,
  useUpdateContentImageSection,
  useDeleteContentImageSection,
  useGetSectionQuestions,
  useDeleteQuestionFromSection,
  useAddQuestionToSection,
  useUpdateSectionQuestionOrder,
  useAddContentSectionToSection,
  useDeleteContentSectionFromSection,
  useUpdateContentSectionOrder,
}
