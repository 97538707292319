import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { CoursesTable } from 'components/tables/CoursesTable'
import { RowSelection } from 'utils/models/Resource'
import { useDeleteCourse } from 'utils/course'
import { effectSelectedMuiTableRows } from 'utils/materialTable/effectRow'
import { ActionButton } from 'components/ActionButton'
import { useBranding } from 'utils/useBranding'
import { PageTitle } from 'utils/constants/pageTitles'
import TitleHelmet from 'components/TitleHelmet'
import * as ROUTE from '../../utils/constants/clientRoutes'
import {
  useCopyCourse,
  useGetOrganisationID,
  useGetStatsDashboard,
} from 'utils/organisation'
import { COURSES } from 'utils/constants/statsPanel'
import OverviewPanel from 'components/OverviewPanel'

const Courses = () => {
  const { branding } = useBranding()
  const { organisationId } = useGetOrganisationID()
  const navigate = useNavigate()

  const { mutateAsync: deleteCourse } = useDeleteCourse()
  const { mutateAsync: copyCourse } = useCopyCourse()
  const { stats } = useGetStatsDashboard(COURSES)

  const [courseRowSelection, setCourseRowSelection] = useState<RowSelection>({})

  const courseId = Object.keys(courseRowSelection)[0]

  const onDeleteCourse = () => {
    effectSelectedMuiTableRows(
      courseRowSelection,
      setCourseRowSelection,
      deleteCourse,
      'Course deleted!',
      'Error deleting course - make sure the course is not in use',
    )
  }

  const onCopyCourse = () => {
    effectSelectedMuiTableRows(
      courseRowSelection,
      setCourseRowSelection,
      copyCourse,
      'Course copied!',
      'Error copying course - make sure the course is not in use',
      organisationId,
    )
  }

  return (
    <>
      <TitleHelmet title={PageTitle.COURSES} />
      <h1>{PageTitle.COURSES}</h1>
      <OverviewPanel statsData={stats} />

      <div className="flex justify-between mt-40">
        <div>
          <Link to="/settings/courses/new-course">
            <ActionButton
              name="Create course"
              background={branding.stylingPrimary ?? 'blue'}
            />
          </Link>
          {Object.keys(courseRowSelection).length > 0 && (
            <ActionButton
              name="Delete course"
              background={branding.stylingPrimary ?? 'blue'}
              setAction={onDeleteCourse}
            />
          )}
          {Object.keys(courseRowSelection).length > 0 && (
            <ActionButton
              name="Copy course"
              background={branding.stylingPrimary ?? 'blue'}
              setAction={onCopyCourse}
            />
          )}
        </div>
        {Object.keys(courseRowSelection).length > 0 && (
          <ActionButton
            name="Edit course description"
            background={branding.stylingPrimary ?? 'blue'}
            setAction={() =>
              navigate(
                ROUTE.COURSE_CONFIGURATION.replace(':courseId', `${courseId}`),
              )
            }
            className="mr-0"
          />
        )}
      </div>
      <CoursesTable
        courseRowSelection={courseRowSelection!}
        setCourseRowSelection={setCourseRowSelection!}
      />
    </>
  )
}
export default Courses
