import { useEffect } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'

import { FormTextField } from 'components/FormTextField'
import { useBranding } from 'utils/useBranding'
import { ValidationSchema } from './ValidationSchema'
import { ActionButton } from 'components/ActionButton'

// API
import {
  useGetOrganisationID,
  useUpdateStripeData,
  useGetOrganisationById,
} from 'utils/organisation'
import { UpdateStripeDataForOrganisationModel } from 'api/data-contracts'

const PaymentDetails = () => {
  const { organisationId } = useGetOrganisationID()
  const { branding } = useBranding()
  const { organisation } = useGetOrganisationById()
  const { mutateAsync: updateStripeData } = useUpdateStripeData()

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<UpdateStripeDataForOrganisationModel>({
    resolver: yupResolver(ValidationSchema),
  })

  useEffect(() => {
    reset({
      stripeSecret: organisation?.paymentSecret,
      stripePublicKey: organisation?.paymentKey,
    })
  }, [organisation, reset])

  const onSubmit: SubmitHandler<
    UpdateStripeDataForOrganisationModel
  > = async data => {
    try {
      await updateStripeData({
        organisationId: organisationId!,
        data: {
          stripePublicKey: data.stripePublicKey,
          stripeSecret: data.stripeSecret,
        },
      })
      toast.success('Payment details Updated!')
    } catch {
      toast.error('Update Failed')
    }
  }

  return (
    <form className="mb-50">
      <div className="flex flex-wrap justify-between">
        <FormTextField
          control={control}
          errors={errors?.stripeSecret}
          name="stripeSecret"
          label="Stripe secretkey"
          type="password"
        />
        <FormTextField
          control={control}
          errors={errors?.stripePublicKey}
          name="stripePublicKey"
          label="Stripe publickey"
        />
      </div>
      <ActionButton
        name="Save"
        background={branding.stylingPrimary ?? 'blue'}
        setAction={handleSubmit(onSubmit)}
      />
    </form>
  )
}

export default PaymentDetails
