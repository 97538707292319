import { ActionButton } from 'components/ActionButton'
import TitleHelmet from 'components/TitleHelmet'
import { useState } from 'react'
import { PageTitle } from 'utils/constants/pageTitles'
import { useDeleteTopic } from 'utils/topics'
import { effectSelectedMuiTableRows } from 'utils/materialTable/effectRow'
import { RowSelection } from 'utils/models/Resource'
import { useBranding } from 'utils/useBranding'
import { TopicsTable } from 'components/tables/courseInstance/TopicsTable'
import { AddTopicPopout } from 'components/topics/AddTopicPopout'
import { EditTopicPopout } from 'components/topics/EditTopicPopout'

const Topics = () => {
  const { branding } = useBranding()
  const { mutateAsync: deleteTopic } = useDeleteTopic()
  const [editTopicDrawerVisibility, setEditTopicDrawerVisibility] =
    useState(false)

  const onClickEditTopicDrawerClose = () => {
    setEditTopicDrawerVisibility(false)
  }

  const [addTopicDrawerVisibility, setAddTopicDrawerVisibility] =
    useState(false)

  const onAddTopicDrawerClose = () => {
    setAddTopicDrawerVisibility(false)
  }

  const [topicRowSelection, setTopicRowSelection] = useState<RowSelection>({})

  const onDeleteTopic = () => {
    effectSelectedMuiTableRows(
      topicRowSelection,
      setTopicRowSelection,
      deleteTopic,
      'Topic removed from organisation!',
      'Error deleting topic - make sure the topic is not in use',
    )
  }

  return (
    <>
      <TitleHelmet title={PageTitle.TOPICS} />
      <h1>{PageTitle.TOPICS}</h1>
      <ActionButton
        name="Create a topic"
        background={branding.stylingPrimary ?? 'blue'}
        setAction={() => setAddTopicDrawerVisibility(true)}
      />
      {Object.keys(topicRowSelection).length > 0 && (
        <ActionButton
          name="Edit topic"
          background={branding.stylingPrimary ?? 'blue'}
          setAction={() => setEditTopicDrawerVisibility(true)}
        />
      )}
      <EditTopicPopout
        drawerVisibility={editTopicDrawerVisibility}
        drawerClose={onClickEditTopicDrawerClose}
        topicRowSelection={topicRowSelection!}
        setTopicRowSelection={setTopicRowSelection!}
      />

      {Object.keys(topicRowSelection).length > 0 && (
        <ActionButton
          name="Remove topic"
          background={branding.stylingSecondary ?? 'orange'}
          setAction={onDeleteTopic}
        />
      )}
      <TopicsTable
        topicRowSelection={topicRowSelection!}
        setTopicRowSelection={setTopicRowSelection!}
      />
      <AddTopicPopout
        drawerVisibility={addTopicDrawerVisibility}
        drawerClose={onAddTopicDrawerClose}
      />
    </>
  )
}

export default Topics
