/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ContentItemModel,
  ContentSectionModel,
  NotFoundExceptionModel,
  UpdateContentSectionModel,
  ValidationExceptionModel,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class ContentSection<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags ContentSection
   * @name UpdateContentSection
   * @request PUT:/api/ContentSection/{contentSectionId}
   * @secure
   */
  updateContentSection = (
    contentSectionId: string,
    data: UpdateContentSectionModel,
    params: RequestParams = {},
  ) =>
    this.request<
      ContentSectionModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/ContentSection/${contentSectionId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags ContentSection
   * @name GetContentSectionContentItems
   * @request GET:/api/ContentSection/{contentSectionId}/contentitems
   * @secure
   */
  getContentSectionContentItems = (
    contentSectionId: string,
    params: RequestParams = {},
  ) =>
    this.request<ContentItemModel[], NotFoundExceptionModel>({
      path: `/api/ContentSection/${contentSectionId}/contentitems`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags ContentSection
   * @name AddContentItemToContentSection
   * @request POST:/api/ContentSection/{contentSectionId}/contentitems
   * @secure
   */
  addContentItemToContentSection = (
    contentSectionId: string,
    data: {
      ContentItemType?: string
      ContentValue?: string
      /** @format int32 */
      Order?: number
      /** @format binary */
      ContentValueImage?: File
    },
    params: RequestParams = {},
  ) =>
    this.request<
      ContentItemModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/ContentSection/${contentSectionId}/contentitems`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
}
