import { Container, CssBaseline, styled } from '@mui/material'
import { Outlet, useLocation } from 'react-router-dom'

import Appbar from './Appbar'
import Sidebar from './Sidebar'
import Sidenav from './Sidenav'
import Header from './Header'
import Coursenav from './Coursenav'
import Modulenav from './Modulenav'
import TitleHelmet from 'components/TitleHelmet'
import SlideContentLossMessage from 'components/SlideContentLossMessage'

// Contexts
import { QuestionProvider } from 'components/courseSetup/Questions/QuestionContext'
import { CurrentSectionProvider } from 'components/moduleSlides/CurrentSectionContext'
import { NewSectionProvider } from 'components/moduleSlides/NewSectionContext'
import { NewContentSectionProvider } from 'components/moduleSlides/NewContentSectionContext'

// Constants
import { PageTitle } from 'utils/constants/pageTitles'
import { LockdownCourseProvider } from 'components/moduleSlides/LockdownCourseContext'

// ==============================|| MAIN LAYOUT ||============================== //
let Main = styled('main')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  padding: '88px 28px',
  flexGrow: 1,
  p: {
    xs: '88px 0px',
    md: '88px',
  },
}))

interface LayoutProps {
  title?: string
}

const MainLayout = ({ title }: LayoutProps) => {
  const location = useLocation()

  let navBar = null
  switch (true) {
    case location.pathname.startsWith('/settings/module-setup/slides'):
      navBar = <Modulenav type={'minimal'} />
      Main = styled('main')(({ theme }) => ({
        width: '100%',
        display: 'flex',
        height: '88vh',
        padding: '88px 0px',
      }))
      break
    case location.pathname.startsWith('/settings/course-setup'):
      navBar = <Coursenav />
      break
    case location.pathname.startsWith('/settings/module-setup'):
      navBar = <Modulenav type={'full'} />
      break
    case location.pathname.startsWith('/settings'):
      navBar = <Sidenav />
      break
    default:
      navBar = <Sidebar />
      break
  }

  return (
    <>
      <TitleHelmet title={PageTitle.SETTINGS} />
      <LockdownCourseProvider>
        <QuestionProvider>
          <CurrentSectionProvider>
            <NewSectionProvider>
              <NewContentSectionProvider>
                <div className="flex min-h-100vh">
                  <CssBaseline />
                  <Appbar />
                  {navBar}
                  <Main>
                    <Container>
                      <Header title={title || ''} />
                      <Outlet />
                    </Container>
                  </Main>
                </div>
                <SlideContentLossMessage />
              </NewContentSectionProvider>
            </NewSectionProvider>
          </CurrentSectionProvider>
        </QuestionProvider>
      </LockdownCourseProvider>
    </>
  )
}

export default MainLayout
