import { TextField } from '@mui/material'

interface CustomInputTextWithLabelProps {
  value: string
  title: string
  setAction: (value: string) => void
  multiline?: boolean
}

const CustomInputTextWithLabel = ({
  value,
  title,
  setAction,
  multiline,
}: CustomInputTextWithLabelProps) => {
  return (
    <>
      <p className="text-label">{title}</p>
      <TextField
        variant="outlined"
        autoComplete="off"
        value={value === undefined || value === null ? '' : value}
        multiline={multiline}
        rows={multiline ? 2 : 1}
        placeholder={title}
        sx={{
          width: '100%',
          background: '#fff',
          borderRadius: 2,
          marginBottom: '20px',
          '& .MuiOutlinedInput-root': {
            '& > fieldset': {
              border: 'none',
            },
          },
        }}
        onChange={e => setAction(e.target.value)}
      />
    </>
  )
}

export default CustomInputTextWithLabel
