import { useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import invariant from 'tiny-invariant'

import {
  UpdateCourseModel,
  AddQuestionToCourseModel,
  AddCourseInstanceToCourseModel,
  UpdateCourseModuleOrderModel,
} from 'api/data-contracts'
import { ResourceModel } from 'utils/models/Resource'
import { useApiClient } from './use-clients'

// GET:/api/Course/{courseId}
const useGetCourse = () => {
  const { courseId } = useParams()
  invariant(courseId, 'courseId not available')
  const client = useApiClient()

  const result = useQuery({
    queryKey: ['course', courseId],
    queryFn: () => client.course.getCourseById(courseId),
    staleTime: Infinity,
  })
  return { ...result, course: result.data?.data ?? null }
}

// PUT:/api/Course/{courseId}
interface UpdateCourseModelProps {
  courseId: string
  data: UpdateCourseModel
}
const useUpdateCourse = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (props: UpdateCourseModelProps) =>
      client.course.updateCourse(props.courseId, props.data),
    onSuccess: data => {
      queryClient.setQueryData(['course'], data)
      queryClient.refetchQueries(['course'])
    },
  })
  return mutation
}

// DELETE:/api/Course/{courseId}
const useDeleteCourse = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: async (courseId: string) => {
      await client.course.deleteCourse(courseId)
    },
    onSuccess: () => {
      queryClient.refetchQueries(['courses'])
    },
  })
  return mutation
}

// GET:/api/Course/{courseId}/modules
const useGetModules = () => {
  const { courseId } = useParams()
  invariant(courseId, 'courseId not available')
  const client = useApiClient()

  const result = useQuery({
    queryKey: ['modules', { courseId }],
    queryFn: () => client.course.getModules(courseId),
    staleTime: Infinity,
  })
  return { ...result, modules: result.data?.data, courseId }
}

//  GET:/api/Course/{courseId}/resources
const useGetResources = (courseId: string) => {
  const client = useApiClient()

  const result = useQuery({
    queryKey: ['resources', courseId],
    queryFn: () => client.course.getResources(courseId),
    enabled: !!courseId,
    staleTime: Infinity,
  })
  return { ...result, resources: result.data?.data }
}

// POST:/api/Course/{courseId}/modules
export interface AddModuleToCourseModel {
  Name?: string | undefined
  Description?: string | undefined
  Tags?: string | undefined
  ModuleImage?: File | undefined
  ModuleCompletionTitle?: string | undefined
  ModuleCompletionDescription?: string | undefined
  ModuleCompletionImage?: File | undefined
  ModuleMovement?: string | undefined
  ModuleProgression?: string | undefined
  PreInfo?: string | undefined
  ModuleCompleteEmailOptional?: string | undefined
  SendCompleteEmail?: boolean | undefined
}
const useAddNewModuleToCourse = () => {
  const { courseId } = useParams()
  invariant(courseId, 'courseId not available')
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (data: AddModuleToCourseModel) =>
      client.course.addNewModuleToCourse(courseId, data),
    onSuccess: async data => {
      queryClient.setQueryData(['module'], data) //set query (oldData,newData)
      queryClient.refetchQueries(['module']) // refetch queries that depend on the user data
      queryClient.refetchQueries(['modules', { courseId }]) // refetch queries that depend on the user data
    },
  })
  return mutation
}

// POST:/api/Course/{courseId}/resources
const useAddResource = () => {
  const { courseId } = useParams()
  invariant(courseId, 'courseId not available')
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (data: ResourceModel) =>
      client.course.addResource(courseId, data),
    onSuccess: data => {
      queryClient.setQueryData(['resource'], data) //set query (oldData,newData)
      queryClient.refetchQueries(['resource']) // refetch queries that depend on the user data
      queryClient.setQueryData(['resources'], data) //set query (oldData,newData)
      queryClient.refetchQueries(['resources']) // refetch queries that depend on the user data
    },
  })
  return mutation
}

// GET:/api/Course/{courseId}/questions
const useGetQuestions = () => {
  const { courseId } = useParams()
  invariant(courseId, 'courseId not available')
  const client = useApiClient()

  const result = useQuery({
    queryKey: ['questions', { courseId }],
    queryFn: () => client.course.getQuestions(courseId),
    staleTime: Infinity,
  })
  return { ...result, questions: result.data?.data, courseId }
}

// POST:/api/Course/{courseId}/questions
const useAddQuestion = () => {
  const { courseId } = useParams()
  invariant(courseId, 'courseId not available')
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (data: AddQuestionToCourseModel) =>
      client.course.addQuestion(courseId, data),
    onSuccess: data => {
      queryClient.setQueryData(['questions'], data) //set query (oldData,newData)
      queryClient.refetchQueries(['questions']) // refetch queries that depend on the user data
    },
  })
  return mutation
}

// POST:/api/Course/{courseId}/instances
interface useAddCourseInstanceToCourseProps {
  courseId: string
  data: AddCourseInstanceToCourseModel
}
const useAddCourseInstanceToCourse = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (props: useAddCourseInstanceToCourseProps) =>
      client.course.addCourseInstanceToCourse(props.courseId, props.data),
    onSuccess: data => {
      queryClient.setQueryData(['courses-instances'], data)
      queryClient.refetchQueries(['courses-instances'])
      queryClient.setQueryData(['courseInstances'], data)
      queryClient.refetchQueries(['courseInstances'])
    },
  })
  return mutation
}

// GET:/api/Course/{courseId}/instances
const useGetCourseInstancesByCourse = () => {
  const { courseId } = useParams()
  invariant(courseId, 'courseId not available')
  const client = useApiClient()

  const result = useQuery({
    queryKey: ['courseInstances', { courseId }],
    queryFn: () => client.course.getCourseInstances(courseId),
    enabled: !!courseId,
    staleTime: Infinity,
  })
  return { ...result, courseInstances: result.data?.data ?? [] }
}

// PUT:/api/Course/{courseId}/modules/order
interface UpdateCourseModuleOrderProps {
  courseId: string
  data: UpdateCourseModuleOrderModel
}
const useUpdateCourseModuleOrder = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (props: UpdateCourseModuleOrderProps) =>
      client.course.updateCourseModuleOrder(props.courseId, props.data),
    onSuccess: data => {
      queryClient.setQueryData(['modules'], data)
      queryClient.refetchQueries(['modules'])
    },
  })
  return mutation
}

// PUT:/api/Course/{courseId}/images/coursecompletionimage
interface useUpdateCourseCompletionImageProps {
  courseId: string
  data: {
    courseCompletionImage?: File
  }
}
const useUpdateCourseCompletionImage = () => {
  const client = useApiClient()
  const mutation = useMutation({
    mutationFn: (props: useUpdateCourseCompletionImageProps) =>
      client.course.updateCourseCompletionImage(props.courseId, props.data),
  })
  return mutation
}

// DELETE:/api/Course/{courseId}/images/coursecompletionimage
interface useDeleteCourseCompletionImageProps {
  courseId: string
}
const useDeleteCourseCompletionImage = () => {
  const client = useApiClient()
  const mutation = useMutation({
    mutationFn: (props: useDeleteCourseCompletionImageProps) =>
      client.course.deleteCourseCompletionImage(props.courseId),
  })
  return mutation
}

// PUT:/api/Course/{courseId}/images/courseimage
interface useUpdateCourseImageProps {
  courseId: string
  data: {
    courseImage?: File
  }
}
const useUpdateCourseImage = () => {
  const client = useApiClient()
  const mutation = useMutation({
    mutationFn: (props: useUpdateCourseImageProps) =>
      client.course.updateCourseImage(props.courseId, props.data),
  })
  return mutation
}

// DELETE:/api/Course/{courseId}/images/courseimage
interface useDeleteCourseImageProps {
  courseId: string
}
const useDeleteCourseImage = () => {
  const client = useApiClient()
  const mutation = useMutation({
    mutationFn: (props: useDeleteCourseImageProps) =>
      client.course.deleteCourseImage(props.courseId),
  })
  return mutation
}

export {
  useAddResource,
  useGetCourse,
  useGetModules,
  useGetResources,
  useUpdateCourse,
  useAddNewModuleToCourse,
  useDeleteCourse,
  useGetQuestions,
  useAddQuestion,
  useAddCourseInstanceToCourse,
  useGetCourseInstancesByCourse,
  useUpdateCourseModuleOrder,
  useUpdateCourseCompletionImage,
  useDeleteCourseCompletionImage,
  useUpdateCourseImage,
  useDeleteCourseImage,
}
