import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Container from '@mui/material/Container'
import { Link, styled } from '@mui/material'
import { useGetProfile, useSignOut } from 'utils/auth'
import { useBranding } from 'utils/useBranding'
import './appbar.css'
import * as ROUTE from '../../../utils/constants/clientRoutes'
import { generateMediaPath } from 'utils/generateMediaPath'
import { NewSectionContext } from 'components/moduleSlides/NewSectionContext'

function ResponsiveAppBar() {
  const navigate = useNavigate()
  const { branding } = useBranding()
  const { signOut } = useSignOut()
  const { profile } = useGetProfile()

  const {
    isNewSlideEdited,
    setSlideContentLossMessageVisible,
    setNavigateAction,
  } = useContext(NewSectionContext)

  const handleSignOut = () => {
    signOut()
  }

  const Img = styled('img')<
    React.DetailedHTMLProps<
      React.ImgHTMLAttributes<HTMLImageElement>,
      HTMLImageElement
    >
  >({ margin: 0, padding: 0 })

  return (
    <AppBar
      position="fixed"
      color="primary"
      sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
    >
      <Container maxWidth={false}>
        <Toolbar
          disableGutters
          sx={{
            display: 'flex',
            height: '100%',
            justifyContent: 'space-between',
          }}
        >
          <span style={{ display: 'block' }}>
            <Link
              onClick={() => {
                if (isNewSlideEdited) {
                  setSlideContentLossMessageVisible(true)
                  setNavigateAction({
                    actionFunc: () => {
                      navigate(ROUTE.DASHBOARD)
                    },
                  })
                } else {
                  navigate(ROUTE.DASHBOARD)
                }
              }}
              sx={{ color: '#fff' }}
            >
              {branding.companyLogo ? (
                <Img
                  src={
                    branding.companyLogo.includes('https')
                      ? branding.companyLogo
                      : generateMediaPath(branding.companyLogo)
                  }
                  className="companylogo"
                  alt="company logo"
                />
              ) : null}
            </Link>
          </span>

          <div className="flex-grow-0">
            <Box sx={{ display: 'inline-block' }}>
              <Link
                sx={{
                  fontSize: 16,
                  pr: 2,
                  color: '#fff',
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
                onClick={handleSignOut}
              >
                Log out
              </Link>
            </Box>
            <IconButton>
              <div className="navbar-profile">
                <p className="text-uppercase">
                  {profile?.firstname ? profile?.firstname.substr(0, 1) : ''}
                  {profile?.lastname ? profile?.lastname.substr(0, 1) : ''}
                </p>
              </div>
            </IconButton>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default ResponsiveAppBar
