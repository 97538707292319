import { useContext } from 'react'
import { LockdownCourseContext } from './moduleSlides/LockdownCourseContext'

interface WarningAlertProps {
  title: string
}
const WarningAlert = ({ title }: WarningAlertProps) => {
  const { isWarningAlertVisible, setIsWarningAlertVisible } = useContext(
    LockdownCourseContext,
  )

  return (
    <>
      {isWarningAlertVisible && (
        <div className="warningAlert">
          {title}
          <span
            className="close"
            aria-hidden="true"
            onClick={() => setIsWarningAlertVisible(false)}
          >
            &times;
          </span>
        </div>
      )}
    </>
  )
}

export default WarningAlert
