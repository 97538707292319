import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useApiClient } from './use-clients'

// DELETE:/api/ContentItem/{contentItemId}
interface useDeleteContentItemProps {
  contentItemId: string
}
const useDeleteContentItem = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (props: useDeleteContentItemProps) => {
      return client.contentItem.deleteContentItem(props.contentItemId)
    },
    onSuccess: data => {
      queryClient.invalidateQueries(['section'])
      queryClient.setQueryData(['section'], data)
      queryClient.refetchQueries(['sections'])
    },
  })
  return mutation
}

// PUT:/api/ContentItem/{contentItemId}
interface useupdateContentItemProps {
  contentItemId: string
  data: {
    ContentValue?: string
    ContentValueImage?: File
  }
}
const useUpdateContentItem = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (props: useupdateContentItemProps) => {
      return client.contentItem.updateContentItem(
        props.contentItemId,
        props.data,
      )
    },
    onSuccess: data => {
      queryClient.invalidateQueries(['section'])
      queryClient.setQueryData(['section'], data)
      queryClient.refetchQueries(['sections'])
    },
  })
  return mutation
}

export { useDeleteContentItem, useUpdateContentItem }
