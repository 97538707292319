import { toast } from 'react-toastify'
import { GenericMutationFunction } from 'utils/models/Generics'
import { RowSelection } from 'utils/models/Resource'

const effectSelectedMuiTableRows = (
  rowSelection: RowSelection,
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelection>>,
  mutationFunction: GenericMutationFunction,
  successMsg: string,
  errorMsg: string,
  optionalFuncParam?: string,
) => {
  Promise.all(
    Object.keys(rowSelection)
      .filter(key => rowSelection[key])
      .map(async rowId => {
        try {
          if (optionalFuncParam) {
            await mutationFunction({
              firstParam: optionalFuncParam,
              secondParam: rowId,
            })
          } else {
            await mutationFunction(rowId)
          }
          toast.success(successMsg)
        } catch (error) {
          toast.error(errorMsg)
        }
      }),
  )
  setRowSelection({})
}

export { effectSelectedMuiTableRows }
