import SaveIcon from '../../assets/icon/Save.svg'

interface ModuleSectionButtonProps {
  onClickIconHandler: () => void
  name: string
  className?: string
}

const ModuleSectionButton = ({
  onClickIconHandler,
  name,
  className,
}: ModuleSectionButtonProps) => {
  return (
    <div
      className={`flex align-center flex-column text-center cursor-pointer ${className}`}
    >
      <img
        src={SaveIcon}
        alt="actionIcon"
        onClick={() => onClickIconHandler()}
      />
      <p className="m-0">{name}</p>
    </div>
  )
}

export default ModuleSectionButton
