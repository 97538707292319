import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import '../../styles/table.css'
import { Tooltip, IconButton, darken, Link } from '@mui/material'
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table'
import { toast } from 'react-toastify'

import { useGetPolicyDocuments } from 'utils/organisation'
import { PolicyDocumentModel } from 'api/data-contracts'
import { useDeletePolicyDocument } from 'utils/policyDocuments'

import DeleteIcon from '@mui/icons-material/Delete'

const PolicyDocumentTable = () => {
  const navigate = useNavigate()
  const { policyDocuments, isError } = useGetPolicyDocuments()
  const { mutateAsync: deletePolicyDocument } = useDeletePolicyDocument()
  const [rowSelection, setRowSelection] = useState({})

  const onClickDeleteQuestionHandler = useCallback(
    async (id: string) => {
      try {
        await deletePolicyDocument({
          policyId: id,
        })
        toast.success('Policy deleted')
      } catch {
        toast.error('Error')
      }
    },
    [deletePolicyDocument],
  )

  const columns = useMemo<MRT_ColumnDef<PolicyDocumentModel>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        Cell: ({ cell }) => (
          <Link
            sx={{
              textDecoration: 'none',
              cursor: 'pointer',
            }}
            onClick={() =>
              navigate(
                `/settings/company-settings/policy-documents/${cell?.row?.id}/edit`,
                { state: cell?.row?.original },
              )
            }
          >
            {cell?.row?.original?.name}
          </Link>
        ),
      },
      {
        accessorKey: 'content',
        header: 'Content',
      },
      {
        accessorKey: 'id',
        header: 'Action',
        Cell: ({ cell }) => (
          <Tooltip title="Delete">
            <IconButton
              onClick={() => onClickDeleteQuestionHandler(cell?.row?.id)}
            >
              <DeleteIcon sx={{ width: '20px', height: '20px' }} />
            </IconButton>
          </Tooltip>
        ),
      },
    ],
    [navigate, onClickDeleteQuestionHandler],
  )

  return (
    <MaterialReactTable
      columns={columns}
      data={policyDocuments}
      initialState={{
        showGlobalFilter: true,
      }}
      enableGlobalFilterModes
      muiSearchTextFieldProps={{
        placeholder: `Search`,
        sx: { maxWidth: '300px', marginBottom: '20px' },
        variant: 'outlined',
      }}
      muiTablePaperProps={{
        elevation: 0,
        sx: {
          '& tr > th > div': {
            display: 'flex',
            fontSize: '18px',
          },
          '& tr > th > div > div > span': {
            display: 'none',
          },
          borderRadius: '0',
          border: 'none',
        },
      }}
      muiTableBodyProps={{
        sx: theme => ({
          '& th > div': {
            display: 'flex',
          },
          '& tr:nth-of-type(odd)': {
            backgroundColor: darken(theme.palette.background.default, 0.1),
          },
        }),
      }}
      muiTableBodyCellProps={{
        sx: {
          lineHeight: '1.6',
        },
      }}
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: 'error',
              children: 'Error loading data',
            }
          : undefined
      }
      enableSelectAll={false}
      enableMultiRowSelection={false}
      getRowId={originalRow => originalRow.id || ''}
      onRowSelectionChange={setRowSelection}
      state={{
        rowSelection,
        showAlertBanner: isError,
        showProgressBars: false,
      }}
      positionToolbarAlertBanner={'top'}
      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={true}
      enableSorting={true}
      enableBottomToolbar={true}
      enableTopToolbar={true}
      enableToolbarInternalActions={false}
      muiTableBodyRowProps={{ hover: false }}
    />
  )
}

export { PolicyDocumentTable }
