import { LockdownCourseContext } from 'components/moduleSlides/LockdownCourseContext'
import { useContext, useState, useEffect, useCallback } from 'react'
import { toast } from 'react-toastify'
import { useGetCourseInstancesByCourse } from 'utils/course'
import { useGetParticipantsByCourseInstanceIdUsingMutate } from 'utils/courseInstance'

export const useCourseLockdown = () => {
  const { setIsCurrentCourseLocked } = useContext(LockdownCourseContext)

  const { courseInstances } = useGetCourseInstancesByCourse()
  const [courseInstancesId, setCourseInstancesId] = useState<string[]>([])

  useEffect(() => {
    if (courseInstances) {
      const getCourseInstancesId = courseInstances.map(value => value.id)
      setCourseInstancesId(getCourseInstancesId as string[])
    }
  }, [courseInstances])

  const { mutateAsync: getParticipantsByCourseInstanceIdUsingMutate } =
    useGetParticipantsByCourseInstanceIdUsingMutate()

  const checkedCourseLocked = useCallback(async () => {
    setIsCurrentCourseLocked(false)

    try {
      if (courseInstancesId.length > 0) {
        for (let i = 0; i < courseInstancesId.length; i++) {
          const { data } = await getParticipantsByCourseInstanceIdUsingMutate({
            courseInstanceId: courseInstancesId[i],
          })
          if (data.length > 0) {
            setIsCurrentCourseLocked(true)
            break
          } else {
            setIsCurrentCourseLocked(false)
          }
        }
      }
      courseInstancesId.length === 0 && setIsCurrentCourseLocked(false)
    } catch {
      toast.error('Unable to get participants data')
    }
  }, [
    courseInstancesId,
    getParticipantsByCourseInstanceIdUsingMutate,
    setIsCurrentCourseLocked,
  ])

  useEffect(() => {
    checkedCourseLocked()
  }, [courseInstancesId, checkedCourseLocked])
}
