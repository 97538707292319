import { useMutation, useQuery } from '@tanstack/react-query'
import invariant from 'tiny-invariant'

import {
  UserModel,
  UpdateUserModel,
  UpdateUserPasswordModel,
  NotFoundExceptionModel,
} from 'api/data-contracts'
import { useApiClient } from './use-clients'
import { HttpResponse } from 'api/http-client'

// GET:/api/User/{userid}
const useGetUserById = (userid: string) => {
  invariant(userid, 'UserId not available')
  const client = useApiClient()

  const result = useQuery<HttpResponse<UserModel, NotFoundExceptionModel>>({
    queryKey: ['course', userid],
    queryFn: () => client.user.getUserById(userid),
    staleTime: Infinity,
  })
  return { ...result.data }
}

// PUT:/api/User/{userid}
interface useUpdateUserProps {
  userid: string
  data: UpdateUserModel
}
const useUpdateUser = () => {
  const client = useApiClient()
  const mutation = useMutation({
    mutationFn: (props: useUpdateUserProps) =>
      client.user.updateUser(props.userid, props.data),
  })
  return mutation
}

// PUT:/api/User/{userid}/lockedstate
interface useUpdateLockedStateProps {
  userid: string
  query?: {
    isLocked?: boolean
  }
}
const useUpdateLockedState = () => {
  const client = useApiClient()
  const mutation = useMutation({
    mutationFn: (props: useUpdateLockedStateProps) =>
      client.user.updateLockedState(props.userid, props.query),
  })
  return mutation
}

// GET:/api/User/{emailaddress}
const useGetUserByEmailAddress = (emailaddress: string) => {
  invariant(emailaddress, 'emailaddress not available')
  const client = useApiClient()

  const result = useQuery<HttpResponse<UserModel, NotFoundExceptionModel>>({
    queryKey: ['emailaddress', emailaddress],
    queryFn: () => client.user.getUserByEmailAddress(emailaddress),
    staleTime: Infinity,
  })
  return { ...result.data }
}

//PUT:/api/User/{userid}/changepassword
interface useChangePasswordProps {
  userid: string
  data: UpdateUserPasswordModel
}
const useChangePassword = () => {
  const client = useApiClient()
  const mutation = useMutation({
    mutationFn: (props: useChangePasswordProps) =>
      client.user.changePassword(props.userid, props.data),
  })
  return mutation
}

export {
  useGetUserById,
  useGetUserByEmailAddress,
  useUpdateLockedState,
  useUpdateUser,
  useChangePassword,
}
