import { toast } from 'react-toastify'
import { useContext, useState } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'

import ModulePopout from './ModulePopout'
import { CurrentSectionContext } from './CurrentSectionContext'
import { useBranding } from 'utils/useBranding'
import {
  useDeleteQuestionFromSection,
  useGetSectionQuestions,
} from 'utils/section'
import { QuestionModel } from 'api/data-contracts'

import { setQuestionList, setQuestionType } from 'utils/question'
import { QuestionContext } from 'components/courseSetup/Questions/QuestionContext'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd'
import { useUpdateSectionQuestionOrder } from 'utils/section'
import { ActionButton } from 'components/ActionButton'
import { MULTISELECT } from 'utils/constants/questions'
import { CreateQuestionPopout } from 'components/CreateQuestionPopout'
import { LockdownCourseContext } from './LockdownCourseContext'

const QuestionsList = () => {
  const { courseId } = useParams()
  const { currentSection } = useContext(CurrentSectionContext)
  const { setList, setQueType, setUpdateQuestionId } =
    useContext(QuestionContext)
  const navigate = useNavigate()
  const { branding } = useBranding()
  const { isCurrentCourseLocked } = useContext(LockdownCourseContext)

  // Drawer Functionality
  const [drawerVisibility, setDrawerVisibility] = useState(false)

  const [createQuestionDrawerVisibility, setCreateQuestionDrawerVisibility] =
    useState(false)

  const onCreateQuestionDrawerClose = () => {
    setCreateQuestionDrawerVisibility(false)
  }

  const onDrawerClose = () => {
    setDrawerVisibility(false)
  }

  const { getSectionQuestion } = useGetSectionQuestions(
    currentSection?.id as string,
  )
  const { mutateAsync: deleteSectionQuestion } = useDeleteQuestionFromSection()

  const onClickEditQuestionHandler = (
    questionType: string,
    questionId: string,
  ) => {
    navigate(`/settings/course-setup/questions/${courseId}`)
    setQuestionList('false')
    setList('false')
    setQuestionType(questionType)
    setQueType(questionType)
    setUpdateQuestionId(questionId)
  }

  const onClickDeleteSectionQuestionHandler = async (questionId: string) => {
    try {
      await deleteSectionQuestion({
        sectionId: currentSection?.id as string,
        questionId: questionId,
      })
      toast.success('Question deleted')
    } catch {
      toast.error('Error while deleting section question')
    }
  }

  const updateSectionQuestionOrder = useUpdateSectionQuestionOrder()

  const reorder = (
    list: QuestionModel[],
    startIndex: number,
    endIndex: number,
  ): QuestionModel[] => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const newItems = reorder(
      getSectionQuestion as QuestionModel[],
      result.source.index,
      result.destination.index,
    )

    const getOnlyIdsFromArray: string[] = newItems.map(
      value => value.id as string,
    )

    if (getSectionQuestion !== undefined) {
      const data = {
        orderedQuestionIds: getOnlyIdsFromArray as string[],
      }
      try {
        await updateSectionQuestionOrder.mutateAsync({
          sectionId: currentSection?.id as string,
          data,
        })
      } catch {
        toast.error('Error while reordering questions')
      }
    }
  }

  return (
    <div>
      <p className="text-label mb-20 mt-20">Questions</p>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              className="mb-15"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {getSectionQuestion &&
                getSectionQuestion.map((question, index) => (
                  <Draggable
                    key={question.id}
                    draggableId={question.id as string}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        className="flex justify-between align-center"
                        key={question.id}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <p className="text-label mt-0">{question?.name}</p>
                        <div>
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={() =>
                                onClickEditQuestionHandler(
                                  question?.questionType as string,
                                  question?.id as string,
                                )
                              }
                            >
                              <EditIcon
                                sx={{ width: '20px', height: '20px' }}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              className={`${
                                isCurrentCourseLocked &&
                                'pointer-event-none opacity-50'
                              }`}
                              onClick={() =>
                                onClickDeleteSectionQuestionHandler(
                                  question?.id as string,
                                )
                              }
                            >
                              <DeleteIcon
                                sx={{ width: '20px', height: '20px' }}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <ActionButton
        name="+ Add question"
        background={branding.stylingPrimary ?? 'blue'}
        setAction={() => setDrawerVisibility(true)}
        className={`${
          isCurrentCourseLocked && 'pointer-event-none opacity-50'
        }`}
      />

      <ActionButton
        name="Create Question"
        background={branding.stylingPrimary ?? 'blue'}
        setAction={() => {
          setCreateQuestionDrawerVisibility(true)
          setQueType(MULTISELECT)
          setQuestionType(MULTISELECT)
          setUpdateQuestionId('')
        }}
        className={`${
          isCurrentCourseLocked && 'pointer-event-none opacity-50'
        }`}
      />

      <ModulePopout
        drawerVisibility={drawerVisibility}
        drawerClose={onDrawerClose}
      />

      <CreateQuestionPopout
        drawerVisibility={createQuestionDrawerVisibility}
        drawerClose={onCreateQuestionDrawerClose}
      />
    </div>
  )
}

export default QuestionsList
