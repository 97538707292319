import { useEffect, useState } from 'react'
import { OrganisationRoleModel } from 'api/data-contracts'
import {
  useGetOrganisationRoles,
  useOrganisationUserRoles,
  useUpdateOrganisationUserRoles,
} from 'utils/organisation'
import { ActionButton } from 'components/ActionButton'
import { toast } from 'react-toastify'
import { useBranding } from 'utils/useBranding'

interface AccesslevelProps {
  userId: string
}
const Accesslevel = ({ userId }: AccesslevelProps) => {
  const { orgRoles } = useGetOrganisationRoles()
  const { orgUserRoles } = useOrganisationUserRoles(userId!)
  const { mutateAsync: updateOrganisationUserRoles } =
    useUpdateOrganisationUserRoles()
  const { branding } = useBranding()
  const [updateBtnVisibility, setUpdateBtnVisibility] = useState(false)

  const [selectedRoles, setSelectedRoles] = useState<string[]>([])

  useEffect(() => {
    // Check if there are initially selected roles (first roles in orgUserRoles)
    const initialRoles =
      orgUserRoles.length > 0 ? orgUserRoles.map(role => role.id!) : []
    setSelectedRoles(initialRoles)
  }, [orgUserRoles])

  const handleCheckboxChange = (roleId: string) => {
    setSelectedRoles(prevSelectedRoles => {
      if (prevSelectedRoles.includes(roleId)) {
        // If roleId is already selected, remove it from the array
        return prevSelectedRoles.filter(id => id !== roleId)
      } else {
        // If roleId is not selected, add it to the array
        return [...prevSelectedRoles, roleId]
      }
    })
    setUpdateBtnVisibility(true)
  }

  const updateRoleAPIHandler = async () => {
    try {
      // Filter the orgRoles based on the selected roles
      const selectedOrgRoles = orgRoles.filter(role =>
        selectedRoles.includes(role.id!),
      )
      await updateOrganisationUserRoles({
        userId: userId!,
        data: {
          organisationRoleModels: selectedOrgRoles, // Send the selected roles
        },
      })
      toast.success('User roles updated')
      setUpdateBtnVisibility(false)
    } catch {
      toast.error('Unable to update user roles')
    }
  }

  return (
    <>
      <p className="pt-10 fw-700 mb-20 fs-20">
        You can change the access level of a user here
      </p>
      <div className="mb-30">
        {orgRoles &&
          orgRoles.map((rolesvalue: OrganisationRoleModel) => (
            <div className="mt-10 flex align-center" key={rolesvalue.id}>
              <input
                type="checkbox"
                className="chechbox-roles"
                name={rolesvalue.name as string}
                checked={selectedRoles.includes(rolesvalue.id!)}
                onChange={() => handleCheckboxChange(rolesvalue.id!)}
              />
              <label htmlFor={rolesvalue.name as string}>
                {rolesvalue.name}
              </label>
            </div>
          ))}
      </div>

      {updateBtnVisibility && (
        <ActionButton
          name="Update"
          background={branding.stylingPrimary ?? 'blue'}
          setAction={updateRoleAPIHandler}
        />
      )}
    </>
  )
}

export default Accesslevel
