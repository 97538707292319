import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from './use-clients'

// DELETE:/api/Testimonial/{testimonialId}
const useDeleteTestimonial = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (testimonialId: string) =>
      client.testimonial.deleteTestimonial(testimonialId),
    onSuccess: () => {
      queryClient.refetchQueries(['testimonial'])
    },
  })
  return mutation
}

// DELETE:/api/Testimonial/{testimonialId}/images/testimonialimage
const useDeleteTestimonialImage = () => {
  const client = useApiClient()

  const mutation = useMutation({
    mutationFn: (testimonialId: string) =>
      client.testimonial.deleteTestimonialImage(testimonialId),
  })
  return mutation
}

export { useDeleteTestimonial, useDeleteTestimonialImage }
