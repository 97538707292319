import { useContext, useState } from 'react'
import { TextField } from '@mui/material'
import { toast } from 'react-toastify'

import { useUpdateSection } from 'utils/section'
import { CurrentSectionContext } from 'components/moduleSlides/CurrentSectionContext'
import { NewSectionContext } from 'components/moduleSlides/NewSectionContext'
import { KeyValueStringObject, emptyObjectKey } from 'utils/emptyObjectKey'
import ModuleSectionButton from 'components/moduleSlides/ModuleSectionButton'
import QuestionsList from 'components/moduleSlides/QuestionsList'

const UpdateMiniQuizSection = () => {
  const { currentSection, setCurrentSection } = useContext(
    CurrentSectionContext,
  )
  const { setStatus, setNewSection, title, setTitle, setIsNewSlideEdited } =
    useContext(NewSectionContext)

  const { mutateAsync: updateSection } = useUpdateSection()

  const [introduction, setIntroduction] = useState(
    currentSection?.contentArea1 as string,
  )
  const [numberOfQuestionsToShow, setNumberOfQuestionsToShow] = useState(
    currentSection?.miniQuizNumQuestionsToShow,
  )

  const contentArea1: KeyValueStringObject = {
    contentArea1: introduction,
  }
  const filteredData = emptyObjectKey(contentArea1)

  const data = {
    ...currentSection,
    ...filteredData,
    Name: title?.length ? title : currentSection?.name,
    MiniQuizNumQuestionsToShow: numberOfQuestionsToShow,
  }

  const onClickupdateModuleHandler = async () => {
    try {
      await updateSection({
        sectionId: currentSection?.id as string,
        data,
      })

      setNewSection({})
      setStatus(undefined)
      setCurrentSection(undefined)
      setTitle(undefined)
      setIsNewSlideEdited(false)
      toast.success('Slide updated')
    } catch {
      toast.error('Unable to update slide')
    }
  }

  return (
    <div className="p-25 bg-white-opacity-6 border-20 width-full-40 max-height-full-40 mt-20 mb-20 overflow-auto">
      <div className="w-full mt-10 mb-30">
        <p className="text-label">Introduction</p>
        <TextField
          multiline
          autoComplete="off"
          rows={3}
          value={introduction === null ? '' : introduction}
          placeholder="Introduction"
          fullWidth
          className="mb-20 bg-white border-8"
          onChange={e => {
            setIntroduction(e.target.value)
            setIsNewSlideEdited(true)
          }}
        />

        <p className="text-label">Questions to show</p>
        <TextField
          variant="outlined"
          autoComplete="off"
          placeholder="Questions to show"
          fullWidth
          value={
            numberOfQuestionsToShow === null ? '' : numberOfQuestionsToShow
          }
          type="number"
          className="bg-white border-8 mb-20"
          onChange={e => {
            setNumberOfQuestionsToShow(+e.target.value)
            setIsNewSlideEdited(true)
          }}
        />
      </div>

      <QuestionsList />

      <ModuleSectionButton
        onClickIconHandler={onClickupdateModuleHandler}
        name="Update"
      />
    </div>
  )
}

export default UpdateMiniQuizSection
