import TitleHelmet from 'components/TitleHelmet'
import PlatformSetting from 'components/companySettings/PlatformSettings'
import Testimonials from 'components/companySettings/Testimonials'
import { PageTitle } from 'utils/constants/pageTitles'

const PlatformSettings = () => {
  return (
    <>
      <h1>{PageTitle.PLATFORM_SETTINGS}</h1>
      <TitleHelmet title={PageTitle.PLATFORM_SETTINGS} />
      <PlatformSetting />

      <h1>{PageTitle.TESTIMONIALS}</h1>
      <Testimonials />
    </>
  )
}

export default PlatformSettings
