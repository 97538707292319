import { useMemo } from 'react'
import '../../../styles/table.css'
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table'
import { useGetResources } from 'utils/course'
import { ResourceModel } from 'api/data-contracts'
import { darken } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useGetModuleResources } from 'utils/module'
import { RowSelection } from 'utils/models/Resource'

type Props = {
  setModuleCourseRowSelection: React.Dispatch<
    React.SetStateAction<RowSelection>
  >
  moduleCourseRowSelection: RowSelection
}

const ModuleCourseResourcesTable = ({
  moduleCourseRowSelection: rowSelection,
  setModuleCourseRowSelection: setRowSelection,
}: Props) => {
  //defines the whole return of useQuery
  const { courseId } = useParams()
  const { resources: courseResources } = useGetResources(courseId!)
  const { resources: moduleResources, isError: moduleResourcesError } =
    useGetModuleResources()
  const resources =
    courseResources?.filter(
      courseResource =>
        !moduleResources?.some(
          moduleResource => moduleResource.id === courseResource.id,
        ),
    ) ?? []

  const columns = useMemo<MRT_ColumnDef<ResourceModel>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        // Cell: ({ cell }) => (
        //   <Link
        //     to={`/settings/course-setup/resources/${courseId}/new-resource/${cell.id
        //       .split('_', 1)
        //       .toLocaleString()}`}
        //   >
        //     {cell.getValue<number>().toLocaleString()}
        //   </Link>
        // ),
      },
    ],
    [],
  )

  if (moduleResourcesError) {
    return <span>Error loading resources</span>
  }

  if (typeof resources === 'undefined') {
    return <div>No resources found</div>
  }

  return (
    <MaterialReactTable
      columns={columns}
      data={resources}
      initialState={{
        showGlobalFilter: true,
      }}
      enableGlobalFilterModes
      muiSearchTextFieldProps={{
        placeholder: `Search`,
        sx: { maxWidth: '200px' },
        variant: 'outlined',
      }}
      muiTablePaperProps={{
        elevation: 0,
        sx: {
          borderRadius: '0',
          border: 'none',
        },
      }}
      muiTableBodyProps={{
        sx: theme => ({
          '& tr': {
            width: '500px',
          },
          '& tr:nth-of-type(odd)': {
            backgroundColor: darken(theme.palette.background.default, 0.1),
          },
          '& td': {
            lineHeight: '0.8',
            width: '100%',
            whiteSpace: 'nowrap',
          },
        }),
      }}
      enableRowSelection
      enableSelectAll={false}
      enableMultiRowSelection={true}
      getRowId={originalRow => originalRow.id || ''}
      onRowSelectionChange={setRowSelection}
      state={{
        rowSelection,
        showAlertBanner: moduleResourcesError,
        showProgressBars: false,
      }} //pass our managed row selection state to the table to use
      positionToolbarAlertBanner={'none'}
      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={true}
      enableSorting={true}
      enableBottomToolbar={true}
      enableTopToolbar={true}
      enableToolbarInternalActions={false}
      muiTableBodyRowProps={{ hover: false }}
    />
  )
}

export { ModuleCourseResourcesTable }
