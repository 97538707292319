import { useCallback, useMemo, useState } from 'react'
import '../../styles/table.css'
import { IconButton, Tooltip, darken } from '@mui/material'
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table'

import { OrganisationTestimonialModel } from 'api/data-contracts'

import { IMAGE_BASEURL } from 'utils/constants/sections'
import { useGetTestimonials } from 'utils/organisation'
import {
  useDeleteTestimonial,
  useDeleteTestimonialImage,
} from 'utils/testimonial'

import DeleteIcon from '@mui/icons-material/Delete'
import { toast } from 'react-toastify'

const TestimonialsTable = () => {
  const { testimonials, isError } = useGetTestimonials()
  const { mutateAsync: deleteTestimonial } = useDeleteTestimonial()
  const { mutateAsync: deleteTestimonialImage } = useDeleteTestimonialImage()

  const [rowSelection, setRowSelection] = useState({})

  const onClickDeleteTestimonialHandler = useCallback(
    async (testimonialId: string) => {
      try {
        await deleteTestimonialImage(testimonialId)
        await deleteTestimonial(testimonialId)
        toast.success('Testimonial deleted')
      } catch {
        toast.error('Unable to delete testimonial')
      }
    },
    [deleteTestimonialImage, deleteTestimonial],
  )

  const columns = useMemo<MRT_ColumnDef<OrganisationTestimonialModel>[]>(
    () => [
      {
        accessorKey: 'author',
        header: 'Author',
      },
      {
        accessorKey: 'testimonial',
        header: 'Testimonial',
      },
      {
        accessorKey: 'image',
        header: 'Image',
        Cell: ({ cell }) => (
          <>
            {cell?.row?.original?.image !== null ? (
              <div className="flex justify-center">
                <div className="bg-white p-20 border-10 max-w-300px">
                  <img
                    style={{ maxHeight: '145px' }}
                    src={IMAGE_BASEURL + cell?.row?.original?.image}
                    alt="upload file"
                  />
                </div>
              </div>
            ) : (
              '-'
            )}
          </>
        ),
      },
      {
        accessorKey: 'id',
        header: 'Action',
        Cell: ({ cell }) => (
          <Tooltip title="Delete">
            <IconButton
              onClick={() => onClickDeleteTestimonialHandler(cell?.row?.id)}
            >
              <DeleteIcon sx={{ width: '20px', height: '20px' }} />
            </IconButton>
          </Tooltip>
        ),
      },
    ],
    [onClickDeleteTestimonialHandler],
  )

  if (isError) {
    return <span>Error loading courses</span>
  }

  if (typeof testimonials === 'undefined') {
    return <div>No testimonials found</div>
  }

  return (
    <MaterialReactTable
      columns={columns}
      data={testimonials}
      initialState={{
        showGlobalFilter: true,
      }}
      enableGlobalFilterModes
      muiSearchTextFieldProps={{
        placeholder: `Search`,
        sx: { maxWidth: '300px', marginBottom: '20px' },
        variant: 'outlined',
      }}
      muiTablePaperProps={{
        elevation: 0,
        sx: {
          '& tr > th > div': {
            display: 'flex',
            fontSize: '18px',
          },
          '& tr > th > div > div > span': {
            display: 'none',
          },
          borderRadius: '0',
          border: 'none',
        },
      }}
      muiTableBodyProps={{
        sx: theme => ({
          '& th > div': {
            display: 'flex',
          },
          '& tr:nth-of-type(odd)': {
            backgroundColor: darken(theme.palette.background.default, 0.1),
          },
        }),
      }}
      muiTableBodyCellProps={{
        sx: {
          lineHeight: '1.6',
        },
      }}
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: 'error',
              children: 'Error loading data',
            }
          : undefined
      }
      enableSelectAll={false}
      enableMultiRowSelection={false}
      getRowId={originalRow => originalRow.id || ''}
      onRowSelectionChange={setRowSelection}
      state={{
        rowSelection,
        showAlertBanner: isError,
        showProgressBars: false,
      }}
      positionToolbarAlertBanner={'top'}
      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={true}
      enableSorting={true}
      enableBottomToolbar={true}
      enableTopToolbar={true}
      enableToolbarInternalActions={false}
      muiTableBodyRowProps={{ hover: false }}
    />
  )
}

export { TestimonialsTable }
