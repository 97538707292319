import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import jwt_decode, { JwtPayload } from 'jwt-decode'
import invariant from 'tiny-invariant'

import {
  LoginUserModel,
  LoginResponseModel,
  RefreshTokenCommand,
} from 'api/data-contracts'
import { HttpResponse } from 'api/http-client'
import { useAuthClient } from './use-clients'

// Constants
import * as ROUTE from '../utils/constants/clientRoutes'
import { TOKEN_KEY, REFRESH_TOKEN_KEY } from './constants/auth'
import { isValidToken } from './useValidateToken'
import { toast } from 'react-toastify'

export function getToken() {
  return localStorage.getItem(TOKEN_KEY)
}

export function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN_KEY)
}

export function setToken(token: string) {
  localStorage.setItem(TOKEN_KEY, token)
}

export function setRefreshToken(token: string) {
  localStorage.setItem(REFRESH_TOKEN_KEY, token)
}

export function removeToken() {
  localStorage.removeItem(TOKEN_KEY)
  localStorage.removeItem(REFRESH_TOKEN_KEY)
}

export function isLoggedIn() {
  const token = getToken()
  return !!token && !isTokenExpired()
}

export function decodeJwtToken(): JwtPayload | null {
  const token = getToken()
  if (token) {
    return jwt_decode<JwtPayload>(token)
  }
  return null
}

export function isTokenExpired() {
  const decoded = decodeJwtToken()
  invariant(decoded, 'decode token must be present.')
  invariant(decoded.exp, 'decode token expiry must be present.')

  if (decoded.exp * 1000 < new Date().getTime()) {
    return true
  } else {
    return false
  }
}

// POST:/api/Auth/login
const useSignIn = () => {
  const authClient = useAuthClient()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const mutation = useMutation({
    mutationFn: (userLoginModel: LoginUserModel) =>
      authClient.auth.loginUser(userLoginModel, { secure: false }),
    async onSuccess(res: HttpResponse<LoginResponseModel>) {
      const token = res.data?.accessToken
      const refreshToken = res.data?.refreshToken
      setToken(token!)
      setRefreshToken(refreshToken!)
      const decoded = decodeJwtToken()
      const isValidUser = isValidToken(token!)
      if (isValidUser) {
        queryClient.setQueryData(['user'], () => JSON.stringify(decoded))
        navigate(ROUTE.DASHBOARD)
      } else {
        toast.error('You do not have access to this platform.')
      }
    },
  })

  return mutation
}

// if the auth header as bearer token is set
// The api will return a user json object, which will then be stored in useQuery
// GET:/api/Auth/profile
const useGetProfile = () => {
  const client = useAuthClient()
  const result = useQuery({
    queryKey: ['profile'],
    queryFn: () => client.auth.profile(),
    staleTime: Infinity,
  })
  return { ...result, profile: result.data?.data }
}

// # refetch user from localstorage if it does not exist in react query state (usually used after page refresh)
const useUser = () => {
  const result = useQuery<JwtPayload | null, Error>({
    queryKey: ['user'], //get user from reactQuery
    queryFn: () => decodeJwtToken(), //decode the localstorage token and store in cache
  })

  return { ...result, user: result?.data ?? null }
}

//removes token from localStorage and token from useQuery
const useSignOut = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const signOut = () => {
    queryClient.setQueryData(['user'], () => null) //removes user from cache
    queryClient.refetchQueries(['user']) // refetch queries that depend on the user data
    removeToken() //remove auth token
    navigate('/login')
    queryClient.removeQueries()
  }
  return { signOut }
}

// POST:/api/Auth/token/refresh
const useAuthTokenRefreshCreate = () => {
  const client = useAuthClient()
  const navigate = useNavigate()

  const mutation = useMutation({
    mutationFn: (data: RefreshTokenCommand) =>
      client.auth.authTokenRefreshCreate(data),
    onSuccess(res: HttpResponse<LoginResponseModel>) {
      const token = res.data?.accessToken
      const refreshToken = res.data?.refreshToken
      setToken(token!)
      setRefreshToken(refreshToken!)
    },
    onError: () => {
      navigate('/login')
    },
  })
  return mutation
}

export {
  useSignIn,
  useGetProfile,
  useUser,
  useSignOut,
  useAuthTokenRefreshCreate,
}
