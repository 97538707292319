import { useState } from 'react'
import Drawer from '@mui/material/Drawer'
import { Header } from 'components/lib'
import { Box, Tabs, Tab } from '@mui/material'

import { useBranding } from 'utils/useBranding'
import Accesslevel from './Accesslevel'
import Accountaccess from './Accountaccess'
import Courses from './Courses'
import Details from './Details'
import { UserModel } from 'api/data-contracts'
import ContactInfromation from './ContactInfromation'
import { generateMediaPath } from 'utils/generateMediaPath'

interface UserDetailPopoutProps {
  drawerVisibility: boolean
  drawerClose: () => void
  userDetailData: UserModel
}

const UserDetailPopout = ({
  drawerVisibility,
  drawerClose,
  userDetailData,
}: UserDetailPopoutProps) => {
  const { branding } = useBranding()
  const [userDetailTab, setUserDetailTab] = useState(0)
  const [contanctInfo, setContactInfo] = useState(false)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setUserDetailTab(newValue)
  }
  const date =
    userDetailData && userDetailData?.lastLoggedIn !== null
      ? new Date(userDetailData?.lastLoggedIn as string)
      : '-'

  return (
    <Drawer
      anchor={'right'}
      open={drawerVisibility}
      onClose={() => drawerClose()}
    >
      <Box
        sx={{
          margin: '3rem',
          marginTop: '6rem',
          maxWidth: '650px',
          minWidth: '650px',
        }}
      >
        {contanctInfo ? (
          <ContactInfromation
            userDetails={userDetailData}
            setUserDetailTab={setUserDetailTab}
            setContactInfo={setContactInfo}
          />
        ) : (
          <>
            {/***** Header *****/}
            {userDetailTab === 0 && (
              <div className="flex mb-20">
                <div className="w-100px">
                  <Box
                    sx={{
                      width: '100px',
                      height: '100px',
                      background: branding.stylingSecondary ?? 'orange',
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {userDetailData.profileImage !== null ? (
                      <img
                        className="previewImage"
                        src={generateMediaPath(
                          userDetailData.profileImage as string,
                        )}
                        alt="previewImage"
                      />
                    ) : (
                      <h1 style={{ color: '#fff', textTransform: 'uppercase' }}>
                        {userDetailData?.firstname
                          ? userDetailData?.firstname.substr(0, 1)
                          : ''}
                        {userDetailData?.lastname
                          ? userDetailData?.lastname.substr(0, 1)
                          : ''}
                      </h1>
                    )}
                  </Box>
                </div>
                <div className="width-full-100 pl-20">
                  <h3
                    style={{
                      marginTop: '0',
                      marginBottom: '0px',
                      textTransform: 'capitalize',
                    }}
                  >
                    {userDetailData?.firstname ?? 'Firstname'}{' '}
                    {userDetailData?.lastname ?? 'Lastname'}
                  </h3>

                  <p className="text-label">
                    Last logged in {date.toLocaleString('en-GB').substr(0, 10)}
                  </p>
                  <div className="flex">
                    <p
                      className="text-label cursor-pointer"
                      onClick={() => setUserDetailTab(2)}
                    >
                      Reset password
                    </p>
                  </div>
                </div>
              </div>
            )}
            {userDetailTab === 1 && (
              <Header variant="signin">Access level</Header>
            )}
            {userDetailTab === 2 && (
              <Header variant="signin">Account access</Header>
            )}
            {userDetailTab === 3 && <Header variant="signin">Courses</Header>}

            {/***** Tabs *****/}
            <Tabs
              onChange={handleChange}
              value={userDetailTab}
              selectionFollowsFocus
              sx={{ marginBottom: '20px' }}
            >
              <Tab
                label="Details"
                sx={{
                  background: '#fff',
                  borderRadius: '10px 10px 0 0',
                  marginRight: '10px',
                  fontWeight: '700',
                  minWidth: '150px',
                  minHeight: '60px',
                  textTransform: 'capitalize',
                }}
              />

              <Tab
                label="Access level"
                sx={{
                  background: '#fff',
                  borderRadius: '10px 10px 0 0',
                  marginRight: '10px',
                  fontWeight: '700',
                  minWidth: '150px',
                  minHeight: '60px',
                  textTransform: 'capitalize',
                }}
              />
              <Tab
                label="Account access"
                sx={{
                  background: '#fff',
                  borderRadius: '10px 10px 0 0',
                  marginRight: '10px',
                  fontWeight: '700',
                  minWidth: '150px',
                  minHeight: '60px',
                  textTransform: 'capitalize',
                }}
              />
              <Tab
                label="Courses"
                sx={{
                  background: '#fff',
                  borderRadius: '10px 10px 0 0',
                  marginRight: '10px',
                  fontWeight: '700',
                  minWidth: '150px',
                  minHeight: '60px',
                  textTransform: 'capitalize',
                }}
              />
            </Tabs>

            {/***** Tab details which is below the tabs *****/}
            {userDetailTab === 0 && (
              <Details
                setTabAction={setUserDetailTab}
                userDetailData={userDetailData}
                setContactInfo={setContactInfo}
              />
            )}
            {userDetailTab === 1 && <Accesslevel userId={userDetailData.id!} />}
            {userDetailTab === 2 && (
              <Accountaccess userId={userDetailData.id!} />
            )}
            {userDetailTab === 3 && <Courses userId={userDetailData.id!} />}
          </>
        )}
      </Box>
    </Drawer>
  )
}

export { UserDetailPopout }
