import { useState } from 'react'

import { useBranding } from 'utils/useBranding'
import TitleHelmet from 'components/TitleHelmet'
import { CoursesInstancesTable } from 'components/tables/courseInstance/CoursesInstancesTable'
import { PageTitle } from 'utils/constants/pageTitles'
import { ActionButton } from 'components/ActionButton'
import CourseInstancePopout from 'components/dashboard/CourseInstancePopout'
import { RowSelection } from 'utils/models/Resource'

const CourseInstances = () => {
  const { branding } = useBranding()

  const [courseInstanceRowSelection, setCourseInstanceRowSelection] =
    useState<RowSelection>({})
  const [
    newCourseInstanceDrawerVisibility,
    setNewCourseInstanceDrawerVisibility,
  ] = useState(false)
  const [
    editCourseInstanceDrawerVisibility,
    setEditCourseInstanceDrawerVisibility,
  ] = useState(false)

  const onCreateCourseInstanceDrawerClose = () => {
    setNewCourseInstanceDrawerVisibility(false)
  }

  const onClickEditCourseInstanceDrawerClose = () => {
    setEditCourseInstanceDrawerVisibility(false)
  }

  const settingCourseInstanceId = Object.keys(courseInstanceRowSelection)[0]

  return (
    <>
      <TitleHelmet title={PageTitle.COURSE_SETUP_COURSE_INSTANCES} />
      <h1>{PageTitle.COURSE_SETUP_COURSE_INSTANCES}</h1>
      <ActionButton
        name="Add course instance"
        background={branding.stylingPrimary ?? 'blue'}
        setAction={() => setNewCourseInstanceDrawerVisibility(true)}
      />
      {Object.keys(courseInstanceRowSelection).length > 0 && (
        <ActionButton
          name="Edit course instance"
          background={branding.stylingPrimary ?? 'blue'}
          setAction={() => setEditCourseInstanceDrawerVisibility(true)}
        />
      )}
      <br />

      <CourseInstancePopout
        drawerVisibility={editCourseInstanceDrawerVisibility}
        drawerClose={onClickEditCourseInstanceDrawerClose}
        id="EDIT"
        settingCourseInstanceId={settingCourseInstanceId}
      />

      <CourseInstancePopout
        drawerVisibility={newCourseInstanceDrawerVisibility}
        drawerClose={onCreateCourseInstanceDrawerClose}
      />

      <CoursesInstancesTable
        courseInstanceRowSelection={courseInstanceRowSelection!}
        setCourseInstanceRowSelection={setCourseInstanceRowSelection!}
      />
    </>
  )
}

export default CourseInstances
