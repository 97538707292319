import { useMemo } from 'react'
import 'styles/table.css'
import { darken } from '@mui/material'
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table'
import { UserCourseInstanceModel } from 'api/data-contracts'
import { RowSelection } from 'utils/models/Resource'
import { useGetTopics } from 'utils/organisation'

type Props = {
  setTopicRowSelection: React.Dispatch<React.SetStateAction<RowSelection>>
  topicRowSelection: RowSelection
}

const TopicsTable = ({
  topicRowSelection: rowSelection,
  setTopicRowSelection: setRowSelection,
}: Props) => {
  const { topics, isError } = useGetTopics()

  const columns = useMemo<MRT_ColumnDef<UserCourseInstanceModel>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'description',
        header: 'Description',
      },
    ],
    [],
  )

  if (isError) {
    return <span>Error loading topics</span>
  }

  if (typeof topics === 'undefined') {
    return <div>No topics found</div>
  }

  return (
    <MaterialReactTable
      columns={columns}
      data={topics}
      initialState={{
        showGlobalFilter: true,
      }}
      enableGlobalFilterModes
      muiSearchTextFieldProps={{
        placeholder: `Search`,
        sx: { maxWidth: '200px' },
        variant: 'outlined',
      }}
      muiTablePaperProps={{
        elevation: 0,
        sx: {
          '& tr > th > div': {
            display: 'flex',
            fontSize: '18px',
          },
          '& tr > th > div > div > span': {
            display: 'none',
          },
          borderRadius: '0',
          border: 'none',
        },
      }}
      muiTableBodyProps={{
        sx: theme => ({
          '& th > div': {
            display: 'flex',
          },
          '& tr:nth-of-type(odd)': {
            backgroundColor: darken(theme.palette.background.default, 0.1),
          },
        }),
      }}
      muiTableBodyCellProps={{
        sx: {
          lineHeight: '1.6',
        },
      }}
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: 'error',
              children: 'Error loading data',
            }
          : undefined
      }
      enableRowSelection
      enableSelectAll={false}
      enableMultiRowSelection={false}
      getRowId={originalRow => originalRow.id || ''}
      onRowSelectionChange={setRowSelection}
      state={{
        rowSelection,
        showAlertBanner: isError,
        showProgressBars: false,
      }}
      positionToolbarAlertBanner={'none'}
      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={true}
      enableSorting={true}
      enableBottomToolbar={true}
      enableTopToolbar={true}
      enableToolbarInternalActions={false}
      muiTableBodyRowProps={{ hover: false }}
    />
  )
}

export { TopicsTable }
