import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useApiClient } from './use-clients'
import { UpdateContentSectionModel } from 'api/data-contracts'

// PUT:/api/ContentSection/{contentSectionId}
interface useUpdateContentSectionProps {
  contentSectionId: string
  data: UpdateContentSectionModel
}
const useUpdateContentSection = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (props: useUpdateContentSectionProps) => {
      return client.contentSection.updateContentSection(
        props.contentSectionId,
        props.data,
      )
    },
    onSuccess: data => {
      queryClient.setQueryData(['section'], data)
      queryClient.refetchQueries(['sections'])
    },
  })
  return mutation
}

// POST:/api/ContentSection/{contentSectionId}/contentitems
interface useAddContentItemToContentSectionProps {
  contentSectionId: string
  data: {
    ContentItemType?: string
    ContentValue?: string
    Order?: number
    ContentValueImage?: File
  }
}
const useAddContentItemToContentSection = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (props: useAddContentItemToContentSectionProps) => {
      return client.contentSection.addContentItemToContentSection(
        props.contentSectionId,
        props.data,
      )
    },
    onSuccess: data => {
      queryClient.setQueryData(['section'], data)
      queryClient.refetchQueries(['sections'])
    },
  })
  return mutation
}

export { useUpdateContentSection, useAddContentItemToContentSection }
