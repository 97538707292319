import { useContext, useState } from 'react'
import { Drawer } from '@mui/material'
import { ModuleCourseQuestionTable } from 'components/tables/module/ModuleCourseQuestionTable'

import { useAddQuestionToSection } from 'utils/section'
import { CurrentSectionContext } from './CurrentSectionContext'
import { toast } from 'react-toastify'
import { ActionButton } from 'components/ActionButton'
import { useBranding } from 'utils/useBranding'
import { QuestionModel } from 'api/data-contracts'

interface ModulePopoutProps {
  drawerVisibility: boolean
  drawerClose: () => void
  name?: string
  addMiniQuizQuestions?: QuestionModel[] | undefined
  setAddMiniQuizQuestions?: React.Dispatch<
    React.SetStateAction<QuestionModel[]>
  >
}

const ModulePopout = ({
  drawerVisibility,
  drawerClose,
  name,
  addMiniQuizQuestions,
  setAddMiniQuizQuestions,
}: ModulePopoutProps) => {
  const { currentSection } = useContext(CurrentSectionContext)
  const { branding } = useBranding()

  const { mutateAsync: addQuestionToSection } = useAddQuestionToSection()

  const [selectedCourseQuestionRows, setSelectedCourseQuestionRows] = useState<
    Record<string, boolean>
  >({})

  const [questionValue, setQuestionValue] = useState<QuestionModel[]>()

  const handleCourseResourceRowSelection = (
    selectedCourseQuestionRows: Record<string, boolean>,
  ) => {
    setSelectedCourseQuestionRows(selectedCourseQuestionRows)
  }

  const handleQuestionSelectionChange = (value: QuestionModel[]) => {
    setQuestionValue(value)
  }

  const onClickSaveQuestion = async () => {
    if (name === 'saveMiniQuiz') {
      setAddMiniQuizQuestions &&
        setAddMiniQuizQuestions((addMiniQuizQuestions: QuestionModel[]) => {
          return [
            ...addMiniQuizQuestions,
            ...(questionValue as QuestionModel[]),
          ]
        })
    } else {
      currentSection?.id &&
        (await Promise.all(
          Object.keys(selectedCourseQuestionRows)
            .filter(key => selectedCourseQuestionRows[key])
            .map(async questionId => {
              try {
                await addQuestionToSection({
                  sectionId: currentSection.id as string,
                  questionId: questionId,
                })
              } catch (error) {
                toast.error('Error while adding question')
              }
            }),
        ))
      toast.success('Question added!')
    }
    await drawerClose()
  }

  return (
    <Drawer
      anchor={'right'}
      open={drawerVisibility}
      onClose={() => drawerClose()}
    >
      <div className="m-3rem mt-5rem">
        <h2>Questions</h2>

        <ModuleCourseQuestionTable
          onRowSelectionChange={handleCourseResourceRowSelection}
          onQuestionSelectionChange={handleQuestionSelectionChange}
          addMiniQuizQuestions={addMiniQuizQuestions}
        />

        <div className="mt-30">
          <ActionButton
            name="Add"
            background={branding.stylingPrimary ?? 'blue'}
            setAction={onClickSaveQuestion}
          />
          <ActionButton
            name="Cancel"
            background={branding.stylingPrimary ?? 'blue'}
            setAction={() => drawerClose()}
          />
        </div>
      </div>
    </Drawer>
  )
}

export default ModulePopout
