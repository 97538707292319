import React, { createContext, useState, useEffect } from 'react'

type ResourceFileContextType = {
  resourceFile: File
  setResourceFile: React.Dispatch<React.SetStateAction<File>>
}

export const ResourceFileContext = createContext<ResourceFileContextType>({
  resourceFile: {} as File,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setResourceFile: () => {},
})

type Props = {
  children: React.ReactNode
}

export const ResourceFileProvider = ({ children }: Props) => {
  const [resourceFile, setResourceFile] = useState<File>({} as File)

  const contextValue: ResourceFileContextType = {
    resourceFile,
    setResourceFile,
  }

  useEffect(() => {
    if (!resourceFile) {
      setResourceFile({} as File)
    }
  }, [resourceFile, setResourceFile])

  return (
    <ResourceFileContext.Provider value={contextValue}>
      {children}
    </ResourceFileContext.Provider>
  )
}
