import { useMemo } from 'react'
import 'styles/table.css'
import { darken, Link } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import MaterialReactTable, {
  MRT_Cell,
  MRT_ColumnDef,
} from 'material-react-table'
import { useGetParticipantsByCourseInstanceId } from 'utils/courseInstance'
import { UserCourseInstanceModel } from 'api/data-contracts'
import * as ROUTE from 'utils/constants/clientRoutes'
import { RowSelection } from 'utils/models/Resource'

type Props = {
  setParticipantRowSelection: React.Dispatch<React.SetStateAction<RowSelection>>
  participantRowSelection: RowSelection
}

const ParticipantsTable = ({
  participantRowSelection: rowSelection,
  setParticipantRowSelection: setRowSelection,
}: Props) => {
  const { courseInstanceId } = useParams()
  const { participants, isError } = useGetParticipantsByCourseInstanceId(
    courseInstanceId!,
  )

  const navigate = useNavigate()
  const useOptional1 =
    participants?.length > 0
      ? participants[0]?.courseInstance?.useOptional1
      : false

  const useOptional2 =
    participants?.length > 0
      ? participants[0]?.courseInstance?.useOptional2
      : false

  const dynamicHeaderForOptional1 =
    participants?.length > 0
      ? `${participants[0]?.courseInstance?.optional1}`
      : 'Optional 1'

  const dynamicHeaderForOptional2 =
    participants?.length > 0
      ? `${participants[0]?.courseInstance?.optional2}`
      : 'Optional 2'

  const columns = useMemo<MRT_ColumnDef<UserCourseInstanceModel>[]>(() => {
    const columnsToShow: MRT_ColumnDef<UserCourseInstanceModel>[] = [
      {
        id: 'name',
        accessorFn: row => `${row?.user?.firstname} ${row?.user?.lastname}`,
        header: 'Name',
        Cell: ({ cell }: { cell: MRT_Cell<UserCourseInstanceModel> }) => (
          <Link
            sx={{ textDecoration: 'none', cursor: 'pointer' }}
            onClick={() =>
              navigate(
                ROUTE.PARTICIPANT.replace(
                  ':courseInstanceId',
                  `${courseInstanceId}`,
                ).replace(':participantId', `${cell?.row?.original?.id}`),
              )
            }
          >
            {cell?.row?.original?.user?.firstname}{' '}
            {cell?.row?.original?.user?.lastname}
          </Link>
        ),
      },
      {
        id: 'email',
        accessorKey: 'user.email',
        header: 'Email',
      },
      {
        id: 'progress',
        accessorKey: 'currentModule.name',
        header: 'Progress',
      },
    ]
    if (useOptional1) {
      columnsToShow.push({
        accessorKey: 'optional1',
        header: dynamicHeaderForOptional1,
        Cell: ({ cell }: { cell: MRT_Cell<UserCourseInstanceModel> }) =>
          cell?.row?.original?.optional1,
      })
    }
    if (useOptional2) {
      columnsToShow.push({
        accessorKey: 'optional2',
        header: dynamicHeaderForOptional2,
        Cell: ({ cell }: { cell: MRT_Cell<UserCourseInstanceModel> }) =>
          cell?.row?.original?.optional2,
      })
    }

    return columnsToShow
  }, [
    courseInstanceId,
    navigate,
    dynamicHeaderForOptional1,
    dynamicHeaderForOptional2,
    useOptional1,
    useOptional2,
  ])

  if (isError) {
    return <span>Error loading resources</span>
  }

  if (typeof participants === 'undefined') {
    return <div>No resources found</div>
  }

  return (
    <MaterialReactTable
      columns={columns}
      data={participants}
      initialState={{
        showGlobalFilter: true,
        columnOrder: ['name', 'email', 'optional1', 'optional2', 'progress'],
      }}
      enableGlobalFilterModes
      muiSearchTextFieldProps={{
        placeholder: `Search`,
        sx: { maxWidth: '300px', marginBottom: '20px' },
        variant: 'outlined',
      }}
      muiTablePaperProps={{
        elevation: 0,
        sx: {
          '& tr > th > div': {
            display: 'flex',
            fontSize: '18px',
          },
          '& tr > th > div > div > span': {
            display: 'none',
          },
          borderRadius: '0',
          border: 'none',
        },
      }}
      muiTableBodyProps={{
        sx: theme => ({
          '& th > div': {
            display: 'flex',
          },
          '& tr:nth-of-type(odd)': {
            backgroundColor: darken(theme.palette.background.default, 0.1),
          },
        }),
      }}
      muiTableBodyCellProps={{
        sx: {
          lineHeight: '1.6',
        },
      }}
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: 'error',
              children: 'Error loading data',
            }
          : undefined
      }
      enableRowSelection
      enableSelectAll={false}
      enableMultiRowSelection={true}
      getRowId={originalRow => originalRow.id || ''}
      onRowSelectionChange={setRowSelection}
      state={{
        rowSelection,
        showAlertBanner: isError,
        showProgressBars: false,
      }}
      positionToolbarAlertBanner={'none'}
      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={true}
      enableSorting={true}
      enableBottomToolbar={true}
      enableTopToolbar={true}
      enableToolbarInternalActions={false}
      muiTableBodyRowProps={{ hover: false }}
    />
  )
}

export { ParticipantsTable }
