import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { ModuleResourcesTable } from 'components/tables/module/ModuleResourcesTable'
import { AddResourceToModulePopout } from 'components/resources/AddResourceToModulePopout'
import { useBranding } from 'utils/useBranding'
import { CreateResourcePopout } from 'components/resources/CreateResourcePopout'
import { effectSelectedMuiTableRows } from 'utils/materialTable/effectRow'
import { ActionButton } from 'components/ActionButton'
import { PageTitle } from 'utils/constants/pageTitles'
import TitleHelmet from 'components/TitleHelmet'

// API
import { useRemoveResourceFromModule } from 'utils/module'

const ModuleResources = () => {
  const { branding } = useBranding()
  const { moduleId } = useParams()

  const { mutateAsync: removeResourceToModule } = useRemoveResourceFromModule()

  const [moduleRowSelection, setModuleRowSelection] = useState({})
  const [moduleCourseRowSelection, setModuleCourseRowSelection] = useState({})

  const [
    addResourceToModuleDrawerVisibility,
    setAddResourceToModuleDrawerVisibility,
  ] = useState(false)

  const [createResourceDrawerVisibility, setCreateResourceDrawerVisibility] =
    useState(false)

  const onAddResourceToModuleDrawerClose = () => {
    setAddResourceToModuleDrawerVisibility(false)
  }

  const onCreateResourceDrawerClose = () => {
    setCreateResourceDrawerVisibility(false)
  }

  const onRemoveResourceFromModule = () => {
    moduleId &&
      effectSelectedMuiTableRows(
        moduleRowSelection,
        setModuleRowSelection,
        removeResourceToModule,
        'Resource removed from module!',
        'Error removing resource from module',
        moduleId,
      )
  }

  return (
    <>
      <TitleHelmet title={PageTitle.COURSE_SETUP_RESOURCES} />
      <h1>{PageTitle.COURSE_SETUP_RESOURCES}</h1>
      <ActionButton
        name="Add an existing resource"
        background={branding.stylingPrimary ?? 'blue'}
        setAction={() => setAddResourceToModuleDrawerVisibility(true)}
      />
      <ActionButton
        name="Create a new resource"
        background={branding.stylingPrimary ?? 'blue'}
        setAction={() => setCreateResourceDrawerVisibility(true)}
      />
      {Object.keys(moduleRowSelection).length > 0 && (
        <ActionButton
          name="Remove resource"
          background={branding.stylingSecondary ?? 'orange'}
          setAction={() => onRemoveResourceFromModule}
        />
      )}

      <ModuleResourcesTable
        moduleRowSelection={moduleRowSelection!}
        setModuleRowSelection={setModuleRowSelection!}
      />

      <AddResourceToModulePopout
        moduleCourseRowSelection={moduleCourseRowSelection!}
        setModuleCourseRowSelection={setModuleCourseRowSelection!}
        drawerVisibility={addResourceToModuleDrawerVisibility}
        drawerClose={onAddResourceToModuleDrawerClose}
      />

      <CreateResourcePopout
        drawerVisibility={createResourceDrawerVisibility}
        drawerClose={onCreateResourceDrawerClose}
      />
    </>
  )
}
export default ModuleResources
