import TitleHelmet from 'components/TitleHelmet'
import { RolesTable } from 'components/tables/RolesTable'
import { PageTitle } from 'utils/constants/pageTitles'

const Roles = () => {
  return (
    <>
      <TitleHelmet title={PageTitle.ROLES} />
      <h1>{PageTitle.ROLES}</h1>
      <RolesTable />
    </>
  )
}
export default Roles
