import { useEffect, useMemo, useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'

import { useBranding } from 'utils/useBranding'
import { FormTextField } from 'components/FormTextField'
import { ComapnySettingModel } from 'utils/models/Company'
import { InitialValues } from './InitialValues'
import { ValidationSchema } from './ValidationSchema'
import { OrganisationModel } from 'api/data-contracts'
import ImageDragAndDrop from './ImageDragAndDrop'
import { Fileprops } from 'utils/models/File'
import { ActionButton } from 'components/ActionButton'

// API
import {
  useUpdateCompanyLogo,
  useDeleteCompanyLogo,
  useUpdateEmailLogo,
  useDeleteEmailLogo,
  useGetSettings,
  useGetOrganisation,
  useUpdateOrganisation,
  useGetOrganisationID,
} from 'utils/organisation'

const CompanyInformation = () => {
  const { branding } = useBranding()
  const { organisationId } = useGetOrganisationID()

  const { mutateAsync: updateCompanyLogo } = useUpdateCompanyLogo()
  const { mutateAsync: deleteCompanyLogo } = useDeleteCompanyLogo()
  const { mutateAsync: updateEmailLogo } = useUpdateEmailLogo()
  const { mutateAsync: deleteEmailLogo } = useDeleteEmailLogo()
  const { settingData } = useGetSettings()
  const { getOrganisationData } = useGetOrganisation()
  const { mutateAsync: updateOrganisation } = useUpdateOrganisation()

  const initalValues = useMemo(() => {
    return InitialValues
  }, [])

  const [companyDetails, setCompanyDetails] =
    useState<ComapnySettingModel>(initalValues)
  const [companyLogo, setCompanyLogo] = useState<Fileprops | null>(null)
  const [emailLogo, setEmailLogo] = useState<Fileprops | null>(null)
  const [isCompanyLogoDeleted, setIsCompanyLogoDeleted] = useState(false)
  const [isEmailLogoDeleted, setIsEmailLogoDeleted] = useState(false)
  const [saveCompanyDetailsLoader, setSaveCompanyDetailsLoader] =
    useState(false)

  useEffect(() => {
    setCompanyDetails({
      ...settingData,
      ...getOrganisationData,
    })
  }, [settingData, getOrganisationData])

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<ComapnySettingModel>({
    resolver: yupResolver(ValidationSchema),
    defaultValues: useMemo(() => {
      return companyDetails
    }, [companyDetails]),
  })

  useEffect(() => {
    reset(companyDetails)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyDetails])

  const onSubmit: SubmitHandler<ComapnySettingModel> = async data => {
    const updateOrganisationData: OrganisationModel = {
      name: data.name,
      description: data.description,
      backgroundImage: data.backgroundImage,
      companyLogo: data.companyLogo,
      emailLogo: data.emailLogo,
      siteBaseUrl: data.siteBaseUrl,
      stylingPrimary: data.stylingPrimary,
      stylingSecondary: data.stylingSecondary,
      stylingInfo: data.stylingInfo,
    }

    setSaveCompanyDetailsLoader(true)
    try {
      if (organisationId !== '') {
        // Delete images
        if (isCompanyLogoDeleted) {
          await deleteCompanyLogo({
            organisationId,
          })
        }
        if (isEmailLogoDeleted) {
          await deleteEmailLogo({
            organisationId,
          })
        }
        // Update images
        if (companyLogo !== null) {
          await updateCompanyLogo({
            organisationId,
            data: {
              companyLogo: companyLogo as File,
            },
          })
        }
        if (emailLogo !== null) {
          await updateEmailLogo({
            organisationId,
            data: {
              emailLogo: emailLogo as File,
            },
          })
        }
        await updateOrganisation({
          organisationId,
          data: updateOrganisationData,
        })
      }
      toast.success('Company details saved')
    } catch {
      toast.error('Error')
    }
    setSaveCompanyDetailsLoader(false)
  }

  return (
    <form>
      <div className="flex flex-wrap justify-between">
        <FormTextField
          control={control}
          errors={errors?.name}
          name="name"
          label="Name"
        />
        <FormTextField
          control={control}
          errors={errors?.description}
          name="description"
          label="Description"
        />
        <FormTextField
          control={control}
          errors={errors?.siteBaseUrl}
          name="siteBaseUrl"
          label="Site baseurl"
        />
        <FormTextField
          control={control}
          errors={errors?.stylingPrimary}
          name="stylingPrimary"
          label="Styling primary"
        />
        <FormTextField
          control={control}
          errors={errors?.stylingSecondary}
          name="stylingSecondary"
          label="Styling secondary"
        />
        <FormTextField
          control={control}
          errors={errors?.stylingInfo}
          name="stylingInfo"
          label="Styling info"
        />

        <div className="w-full mb-25 desktop-w-50 min-w-500px">
          <p className="text-label">Email logo</p>
          <ImageDragAndDrop
            imageFile={emailLogo}
            setImageFile={setEmailLogo}
            imageExist={companyDetails?.emailLogo}
            deleteStatus={isEmailLogoDeleted}
            setDeleteImageStatus={setIsEmailLogoDeleted}
            fileTypes={['JPG', 'PNG', 'JPEG', 'SVG']}
          />
          <p className="text-label">
            Email logo should be 290px * 151px resolution for better display.
          </p>
        </div>
        <div className="w-full mb-25 desktop-w-50 min-w-500px">
          <p className="text-label">Company logo</p>
          <ImageDragAndDrop
            imageFile={companyLogo}
            setImageFile={setCompanyLogo}
            imageExist={companyDetails?.companyLogo}
            deleteStatus={isCompanyLogoDeleted}
            setDeleteImageStatus={setIsCompanyLogoDeleted}
            fileTypes={['JPG', 'PNG', 'JPEG', 'SVG']}
          />
        </div>
      </div>
      <ActionButton
        name="Save"
        background={branding.stylingPrimary ?? 'blue'}
        setAction={handleSubmit(onSubmit)}
        className={`flex justify-center
          ${saveCompanyDetailsLoader && 'pointer-event-none opacity-50'}`}
        spinner={saveCompanyDetailsLoader}
      />
    </form>
  )
}

export default CompanyInformation
