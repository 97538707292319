import { createContext, useState, ReactNode, useEffect } from 'react'

interface LockdownCourseContextType {
  isCurrentCourseLocked: boolean
  setIsCurrentCourseLocked: (value: boolean) => void
  isWarningAlertVisible: boolean
  setIsWarningAlertVisible: (value: boolean) => void
}

export const LockdownCourseContext = createContext<LockdownCourseContextType>({
  isCurrentCourseLocked: false,
  setIsCurrentCourseLocked: () => {
    //initalized function
  },
  isWarningAlertVisible: false,
  setIsWarningAlertVisible: () => {
    //initalized function
  },
})

const LockdownCourseProvider = ({ children }: { children: ReactNode }) => {
  const [isCurrentCourseLocked, setIsCurrentCourseLocked] = useState(false)
  const [isWarningAlertVisible, setIsWarningAlertVisible] = useState(false)

  useEffect(() => {
    isCurrentCourseLocked && setIsWarningAlertVisible(true)
    !isCurrentCourseLocked && setIsWarningAlertVisible(false)
  }, [isCurrentCourseLocked])

  return (
    <LockdownCourseContext.Provider
      value={{
        isCurrentCourseLocked,
        setIsCurrentCourseLocked,
        isWarningAlertVisible,
        setIsWarningAlertVisible,
      }}
    >
      {children}
    </LockdownCourseContext.Provider>
  )
}

export { LockdownCourseProvider }
