import { UserModel } from 'api/data-contracts'
import { useOrganisationUserRoles } from 'utils/organisation'

interface DetailsProps {
  setTabAction: (value: number) => void
  userDetailData: UserModel
  setContactInfo: React.Dispatch<React.SetStateAction<boolean>>
}
const Details = ({
  setTabAction,
  userDetailData,
  setContactInfo,
}: DetailsProps) => {
  const { orgUserRoles } = useOrganisationUserRoles(userDetailData.id!)
  return (
    <>
      <div className="flex pt-10">
        <div className="w-full w-768-60">
          <p className="fw-700 mt-0 mb-5">Email address</p>
          <p className="mt-0"> {userDetailData?.email ?? 'Email'}</p>
          <p className="fw-700 mt-0 mb-5">First name</p>
          <p className="mt-0">{userDetailData?.firstname ?? 'First name'}</p>
          <p className="fw-700 mt-0 mb-5">Last name</p>
          <p className="mt-0"> {userDetailData?.lastname ?? 'Last name'}</p>
          <p className="fw-700 mt-0 mb-5">Department</p>
          <p className="mt-0">{userDetailData?.department ?? 'Department'}</p>

          <p
            color="inherit"
            className="cursor-pointer fw-700 text-decoration-underline"
            onClick={() => setContactInfo(true)}
          >
            Manage contact information
          </p>
        </div>
        <div className="w-full w-768-40">
          <p className="fw-700 mt-0 mb-5">Access level</p>
          <div style={{ display: 'grid' }}>
            {orgUserRoles.length > 0
              ? orgUserRoles.map(role => <li key={role.id}>{role.name}</li>)
              : 'N/A'}
          </div>
          <p
            color="inherit"
            className="cursor-pointer fw-700 text-decoration-underline"
            onClick={() => setTabAction(1)}
          >
            Manage access level
          </p>
        </div>
      </div>

      <div className="mt-40">
        <p className="fw-700 mt-0 mb-5">Current course</p>
        <p className="mt-0">Course 2</p>
        <p
          color="inherit"
          className="cursor-pointer fw-700 text-decoration-underline"
          onClick={() => setTabAction(3)}
        >
          View all courses
        </p>
      </div>
    </>
  )
}

export default Details
