import React, { createContext, useContext, useState } from 'react'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { Option } from 'react-bootstrap-typeahead/types/types'
import 'styles/react-bootstrap-typeahead.scss'

type TypeaheadContextValue = {
  selectedOptions: Option[]
  setSelectedOptions: (options: Option[]) => void
}

const TypeaheadContext = createContext<TypeaheadContextValue>({
  selectedOptions: [],
  setSelectedOptions: (options: Option[]) => {
    // Initialize function
  },
})

type TypeaheadContextProviderProps = {
  children: React.ReactNode
}

const TypeaheadContextProvider = ({
  children,
}: TypeaheadContextProviderProps) => {
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([])

  return (
    <TypeaheadContext.Provider value={{ selectedOptions, setSelectedOptions }}>
      {children}
    </TypeaheadContext.Provider>
  )
}

const useTypeaheadContext = () => useContext(TypeaheadContext)

export { TypeaheadContextProvider, useTypeaheadContext }
