import { ReactNode } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ThemeContext } from './ThemeContext'
import Toast from 'components/Toast'
import { ResourceFileProvider } from 'components/resources/ResourceFileContext'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

type Props = { children: ReactNode }

const AppProviders = ({ children }: Props) => (
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={true} />
    <ThemeContext>
      <ResourceFileProvider>
        <BrowserRouter>{children}</BrowserRouter>
      </ResourceFileProvider>
      <Toast />
    </ThemeContext>
  </QueryClientProvider>
)

export { AppProviders }
