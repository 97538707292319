import { PageTitle } from 'utils/constants/pageTitles'
import OverviewFlexBox from './OverviewFlexBox'
import { StatDataModel } from 'api/data-contracts'

interface OverviewPanelProps {
  statsData?: StatDataModel[]
}
const OverviewPanel = ({ statsData }: OverviewPanelProps) => {
  return (
    <>
      <div className="flex flex-wrap justify-between align-center">
        <h2 className="fw-500 mb-20">{PageTitle.OVERVIEW_PANEL}</h2>
        <p className="m-0">Last updated 03/04/2023</p>
      </div>

      {statsData && (
        <div className="flex flex-wrap">
          {statsData?.map((value, index) => (
            <OverviewFlexBox
              title={value.statName!}
              amount={value.statValue!}
              key={index}
            />
          ))}
        </div>
      )}
    </>
  )
}

export default OverviewPanel
