import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { MenuItem, Select } from '@mui/material'
import { toast } from 'react-toastify'

import { useBranding } from 'utils/useBranding'
import { ResourceModel } from 'utils/models/Resource'
import { Fileprops } from 'utils/models/File'
import ImageUploadDragAndDrop from 'components/ImageUploadDragAndDrop'
import { ConfirmPopup } from 'components/moduleSlides/ConfirmPopup'
import { FormTextField } from 'components/FormTextField'
import { ActionButton } from 'components/ActionButton'

// Constants
import { FILE, URL } from 'utils/constants/resources'

// API - custom hooks
import { useAddResource } from 'utils/course'

const validationSchema = Yup.object<ResourceModel>().shape({
  Name: Yup.string().required('Name is required'),
  Description: Yup.string().required('Description is required'),
  ResourceType: Yup.string().required('Resource type is required'),
  Url: Yup.string().when('ResourceType', (ResourceType, schema) =>
    ResourceType.toString() === URL
      ? schema.required('Url is required')
      : schema.notRequired(),
  ),
})

const NewCourseResource = () => {
  const { branding } = useBranding()
  const navigate = useNavigate()
  const { courseId } = useParams()

  const { mutateAsync: addResource } = useAddResource()

  const [showConfirm, setShowConfirm] = useState(false)
  const [file, setFile] = useState<Fileprops | null>(null)

  const onCancel = () => {
    navigate(`/settings/course-setup/resources/${courseId}`)
    setShowConfirm(false)
  }

  const onCancelHandler = () => {
    if (!watch('Name') && !watch('Description')) {
      navigate(`/settings/course-setup/resources/${courseId}`)
      setShowConfirm(false)
    } else {
      setShowConfirm(true)
    }
  }

  const {
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm<ResourceModel>({
    resolver: yupResolver(validationSchema),
  })
  const watchResourceType = watch('ResourceType', URL)

  const onSubmit: SubmitHandler<ResourceModel> = async data => {
    const resource: {
      Name: string | undefined
      Description: string | undefined
      ResourceType: string | undefined
      Url?: string
      File?: File
    } = {
      Name: data.Name,
      Description: data.Description,
      ResourceType: data.ResourceType,
    }

    if (data.ResourceType === URL) {
      resource.Url = data.Url
    } else {
      resource.File = file as File
    }

    try {
      await addResource(resource)
      reset()
      navigate(`/settings/course-setup/resources/${courseId}`)
      toast.success('Resource added')
    } catch {
      toast.error('Unable to add resource')
    }
  }

  return (
    <>
      <h1>Create Resource</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-wrap justify-between">
          <FormTextField
            control={control}
            errors={errors?.Name}
            name="Name"
            label="Name"
          />
          <FormTextField
            control={control}
            errors={errors?.Description}
            name="Description"
            label="Description"
            multilineValue={2}
          />

          <Controller
            name={'ResourceType'}
            control={control}
            defaultValue={'URL'}
            render={({ field: { onChange, value } }) => (
              <div className="w-full mb-25 desktop-w-50">
                <p className="text-label"> Resource type</p>
                <Select
                  value={value}
                  onChange={onChange}
                  error={errors.ResourceType?.message !== undefined}
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                      {
                        border: 0,
                      },
                    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                      {
                        border: 0,
                      },
                    background: '#fff',
                  }}
                >
                  <MenuItem value={'URL'}>URL</MenuItem>
                  <MenuItem value={'FILE'}>FILE</MenuItem>
                </Select>
              </div>
            )}
          />

          {watchResourceType && watchResourceType === URL && (
            <FormTextField
              control={control}
              errors={errors?.Url}
              name="Url"
              label="URL"
            />
          )}
        </div>

        {watchResourceType === FILE && (
          <div className="w-full mb-25 desktop-w-50">
            <p className="text-label">File</p>
            <ImageUploadDragAndDrop
              file={file}
              setFile={setFile}
              fileTypes={['JPG', 'PNG', 'GIF', 'SVG', 'PDF']}
              label="Upload or drop a file here"
            />
          </div>
        )}

        <ActionButton
          name="Add"
          background={branding.stylingPrimary ?? 'blue'}
          setAction={handleSubmit(onSubmit)}
        />
        <ActionButton
          name="Cancel"
          background={branding.stylingSecondary ?? 'orange'}
          setAction={onCancelHandler}
        />
      </form>

      {showConfirm && (
        <ConfirmPopup
          title="Confirm cancel"
          message="Are you sure you want to stop creating this resource?"
          onConfirm={onCancel}
          open={showConfirm}
          onCancel={() => setShowConfirm(false)}
        />
      )}
    </>
  )
}

export default NewCourseResource
