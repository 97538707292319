import { toast } from 'react-toastify'
import { MenuItem, Select } from '@mui/material'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { FormTextField } from 'components/FormTextField'
import { ActionButton } from 'components/ActionButton'
import {
  AddUserToCourseInstanceModel,
  CourseInstanceModel,
} from 'api/data-contracts'
import { useAddUserToCourse } from 'utils/courseInstance'
import { useBranding } from 'utils/useBranding'
import { useParams } from 'react-router-dom'

interface InviteUserFormProps {
  courseInstanceData: CourseInstanceModel
}
const InviteUserForm = ({ courseInstanceData }: InviteUserFormProps) => {
  const { branding } = useBranding()
  const { courseInstanceId } = useParams()
  const { mutateAsync: addUserToCourse } = useAddUserToCourse()
  const ValidationSchema = Yup.object<AddUserToCourseInstanceModel>().shape({
    email: Yup.string()
      .email('Enter a valid email')
      .required('Email is required'),
    firstname: Yup.string().required('First name is required'),
    lastname: Yup.string().required('Last name is required'),
    department: Yup.string().required('Department is required'),
    // eslint-disable-next-line
    optional1: Yup.string().when((schema: any) => {
      if (courseInstanceData.useOptional1 === true) {
        return Yup.string().required(
          `${`${courseInstanceData.optional1} is required`}`,
        )
      }
      return schema
    }),
    // eslint-disable-next-line
    optional2: Yup.string().when((schema: any) => {
      if (courseInstanceData.useOptional2 === true) {
        return Yup.string().required(
          `${`${courseInstanceData.optional2} is required`}`,
        )
      }
      return schema
    }),
  })

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<AddUserToCourseInstanceModel>({
    resolver: yupResolver(ValidationSchema),
  })

  const onSubmit: SubmitHandler<AddUserToCourseInstanceModel> = async data => {
    try {
      await addUserToCourse({
        courseInstanceId: courseInstanceId as string,
        data: {
          userName: data.email,
          email: data.email,
          firstname: data.firstname,
          lastname: data.lastname,
          department: data.department,
          optional1: data.optional1,
          optional2: data.optional2,
        },
      })

      toast.success('user invited!')
      reset()
    } catch {
      toast.error('Errored')
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '1rem',
      }}
    >
      <form>
        <FormTextField
          control={control}
          errors={errors?.email}
          name="email"
          label="Email"
          length={true}
        />
        <FormTextField
          control={control}
          errors={errors?.firstname}
          name="firstname"
          label="First name"
          length={true}
        />
        <FormTextField
          control={control}
          errors={errors?.lastname}
          name="lastname"
          label="Last name"
          length={true}
        />
        <FormTextField
          control={control}
          errors={errors?.department}
          name="department"
          label="Department"
          length={true}
        />

        {courseInstanceData.useOptional1 === true && (
          <>
            {courseInstanceData.optional1Values !== null ? (
              <Controller
                name="optional1"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <div className="w-full mb-25">
                    <p className="text-label">{`${courseInstanceData.optional1}`}</p>
                    {courseInstanceData.optional1Values?.includes(',') ? (
                      <Select
                        value={
                          value === null || value === undefined ? '' : value
                        }
                        onChange={onChange}
                        error={errors.optional1?.message !== undefined}
                        className="bg-white"
                      >
                        {courseInstanceData.optional1Values
                          .split(',')
                          .map((value, index) => (
                            <MenuItem value={value} key={index}>
                              {value}
                            </MenuItem>
                          ))}
                      </Select>
                    ) : (
                      <Select
                        value={
                          value === null || value === undefined ? '' : value
                        }
                        onChange={onChange}
                        error={errors.optional1?.message !== undefined}
                        className="bg-white"
                      >
                        <MenuItem value={courseInstanceData.optional1Values!}>
                          {courseInstanceData.optional1Values}
                        </MenuItem>
                      </Select>
                    )}

                    {errors?.optional1 !== undefined && (
                      <p className="error-text">{errors?.optional1.message}</p>
                    )}
                  </div>
                )}
              />
            ) : (
              <FormTextField
                control={control}
                errors={errors?.optional1}
                name="optional1"
                label={`${courseInstanceData.optional1}`}
                length={true}
              />
            )}
          </>
        )}

        {courseInstanceData.useOptional2 === true && (
          <>
            {courseInstanceData.optional2Values !== null ? (
              <Controller
                name="optional2"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <div className="w-full mb-25">
                    <p className="text-label">{`${courseInstanceData.optional2}`}</p>
                    {courseInstanceData.optional2Values?.includes(',') ? (
                      <Select
                        value={
                          value === null || value === undefined ? '' : value
                        }
                        onChange={onChange}
                        error={errors.optional2?.message !== undefined}
                        className="bg-white"
                      >
                        {courseInstanceData.optional2Values
                          .split(',')
                          .map((value, index) => (
                            <MenuItem value={value} key={index}>
                              {value}
                            </MenuItem>
                          ))}
                      </Select>
                    ) : (
                      <Select
                        value={
                          value === null || value === undefined ? '' : value
                        }
                        onChange={onChange}
                        error={errors.optional2?.message !== undefined}
                        className="bg-white"
                      >
                        <MenuItem value={courseInstanceData.optional2Values!}>
                          {courseInstanceData.optional2Values}
                        </MenuItem>
                      </Select>
                    )}

                    {errors?.optional2 !== undefined && (
                      <p className="error-text">{errors?.optional2.message}</p>
                    )}
                  </div>
                )}
              />
            ) : (
              <FormTextField
                control={control}
                errors={errors?.optional2}
                name="optional2"
                label={`${courseInstanceData.optional2}`}
                length={true}
              />
            )}
          </>
        )}

        <div className="flex mt-15">
          <ActionButton
            name="Create"
            background={branding.stylingPrimary ?? 'blue'}
            setAction={handleSubmit(onSubmit)}
          />
        </div>
      </form>
    </div>
  )
}

export { InviteUserForm }
