import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import CustomInputTextWithLabel from './CustomInputTextWithLabel'
import { ActionButton } from './ActionButton'
import { useBranding } from 'utils/useBranding'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import { editorConfiguration } from 'utils/ckeditor/EditorConfiguration'

// API
import { useUpdateMessageTemplate } from 'utils/messageTemplate'

const MessageTemplateDetails = () => {
  const { branding } = useBranding()
  const { state: messageTemplateData } = useLocation()
  const { mutateAsync: updateMessageTemplate } = useUpdateMessageTemplate()

  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [subject, setSubject] = useState<string>('')
  const [body, setBody] = useState<string>('')

  useEffect(() => {
    setName(messageTemplateData?.name)
    setDescription(messageTemplateData?.description)
    setSubject(messageTemplateData?.subject)
    setBody(messageTemplateData?.body)
  }, [messageTemplateData])

  const onClickUpdateMessageHandler = async () => {
    const messageData = {
      name,
      description,
      subject,
      body,
    }

    try {
      await updateMessageTemplate({
        messageTemplateId: messageTemplateData?.id,
        data: messageData,
      })
      toast.success('Message updated')
    } catch {
      toast.error('Error')
    }
  }

  return (
    <>
      <CustomInputTextWithLabel
        title="Name"
        value={name}
        setAction={setName}
        multiline={false}
      />
      <CustomInputTextWithLabel
        title="Description"
        value={description}
        setAction={setDescription}
        multiline={false}
      />
      <CustomInputTextWithLabel
        title="Subject"
        value={subject}
        setAction={setSubject}
        multiline={true}
      />

      <p className="text-label">Body</p>
      <div className="mb-30 p-20 bg-BCBFBE border-10">
        <CKEditor
          editor={Editor}
          config={editorConfiguration}
          data={body}
          onChange={(event, editor) => {
            const data = editor.data.get()
            setBody(data)
          }}
        />
      </div>

      <ActionButton
        name="Update message"
        background={branding.stylingPrimary ?? 'blue'}
        setAction={onClickUpdateMessageHandler}
      />
    </>
  )
}

export default MessageTemplateDetails
