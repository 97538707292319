import { ReactElement, useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TuneIcon from '@mui/icons-material/Tune'
import BookIcon from '@mui/icons-material/Book'
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { SectionNav } from 'components/moduleSlides/SectionNav'
import * as ROUTE from '../../../utils/constants/clientRoutes'
import AnchorLink from 'components/AnchorLink'
import { Tooltip } from '@mui/material'
import { PageTitle } from 'utils/constants/pageTitles'
import { NewSectionContext } from 'components/moduleSlides/NewSectionContext'
import { CurrentSectionContext } from 'components/moduleSlides/CurrentSectionContext'

interface ModuleNavItem {
  label: string
  path?: string
  icon: ReactElement
  children?: ModuleNavItem[]
}

interface ModuleNavProps {
  type: 'full' | 'minimal'
}

export default function ModuleNav({ type }: ModuleNavProps) {
  // get moduleId from react query
  //OR
  // get moduleId from URL
  const { courseId } = useParams()
  const { moduleId } = useParams()
  const navigate = useNavigate()
  const { setCurrentSection } = useContext(CurrentSectionContext)
  const {
    isNewSlideEdited,
    setSlideContentLossMessageVisible,
    setNavigateAction,
    setNewSection,
    setTitle,
    setStatus,
  } = useContext(NewSectionContext)

  const navigation: ModuleNavItem[] = [
    {
      label: 'Module Details',
      path: `/settings/module-setup/details/${courseId}/${moduleId}`,
      icon: <TuneIcon />,
    },
    {
      label: 'Resources',
      path: `/settings/module-setup/resources/${courseId}/${moduleId}`,
      icon: <BookIcon />,
    },
    {
      label: 'Module Slides',
      path: `/settings/module-setup/slides/${courseId}/${moduleId}`,
      icon: <ViewCarouselIcon />,
    },
  ]

  const sxHide = {
    display: 'none',
  }
  const sxShow = {
    display: 'block',
    paddingLeft: '15px',
  }

  const [showChildren, setShowChildren] = useState<{ [key: string]: boolean }>(
    {},
  )

  const onClickHandler = (item: ModuleNavItem) => {
    if (isNewSlideEdited) {
      setSlideContentLossMessageVisible(true)
      setNavigateAction({
        actionFunc: () => {
          setShowChildren({ ...showChildren, [item.path || '']: false })
          setNewSection({})
          setStatus(undefined)
          setCurrentSection(undefined)
          setTitle(undefined)
          navigate(item.path as string)
        },
      })
    } else {
      navigate(item.path as string)
      setShowChildren({ ...showChildren, [item.path || '']: false })
      setNewSection({})
      setStatus(undefined)
      setCurrentSection(undefined)
      setTitle(undefined)
    }
  }

  //add CSS for show hide children, relevant icons and dropdown icon
  const renderNavItem = (item: ModuleNavItem) => (
    <ListItem
      sx={{ flexDirection: 'Column', alignItems: 'stretch' }}
      key={item.path}
      disablePadding
    >
      <ListItemButton onClick={() => onClickHandler(item)}>
        {type === 'full' ? (
          <>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.label} />
          </>
        ) : (
          <Tooltip title={item.label}>
            <ListItemIcon sx={{ minWidth: '26px' }}>{item.icon}</ListItemIcon>
          </Tooltip>
        )}
        {!item.children ? '' : <ExpandMoreIcon />}
      </ListItemButton>
      {item.children && (
        <List
          sx={showChildren ? sxShow : sxHide}
          className={showChildren ? 'show-children' : 'hide-children'}
        >
          {item.children.map(child => renderNavItem(child))}
        </List>
      )}
    </ListItem>
  )

  return (
    <div className="flex">
      <Drawer
        variant="permanent"
        sx={{
          width: type === 'minimal' ? `auto` : '255px',
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: 'fit-content',
            boxSizing: 'border-box',
            flexDirection: 'column',
            position: 'relative',
          },
          display: {
            xs: 'none',
            md: 'block',
          },
        }}
      >
        <Toolbar />
        <AnchorLink
          onClick={() => {
            if (isNewSlideEdited) {
              setSlideContentLossMessageVisible(true)
              setNavigateAction({
                actionFunc: () => {
                  navigate(ROUTE.COURSE_MODULES.replace(':courseId', courseId!))
                },
              })
            } else {
              navigate(ROUTE.COURSE_MODULES.replace(':courseId', courseId!))
            }
          }}
          style={{
            display: 'flex',
            flexGrow: '1',
            justifyContent: 'start',
            position: 'relative',
            alignItems: 'center',
            marginTop: '20px',
            padding: '8px 16px',
            color: '#000000DE',
            overflowWrap: 'break-word',
            cursor: 'pointer',
            fontWeight: '400',
            fontSize: '1.1428571428571428rem',
            lineHeight: '1.5',
          }}
        >
          <ArrowBackIcon
            fontSize="medium"
            sx={{
              marginRight: '5px',
              display: 'inline-flex',
              flexShrink: '0',
            }}
          />
          <span
            style={{
              display: 'block',
              flex: '1 1 auto',
              minWidth: '0',
              marginTop: '4px',
              marginBottom: '4px',
            }}
          >
            {type === 'full' && PageTitle.NAV_BACK}
          </span>
        </AnchorLink>
        <div className="flex overflow-auto h-full justify-between flex-column">
          <List>{navigation.map(renderNavItem)}</List>
        </div>
      </Drawer>
      {type === 'minimal' && (
        <Drawer
          variant="permanent"
          sx={{
            width: type === 'minimal' ? `auto` : '255px',
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: 'fit-content',
              boxSizing: 'border-box',
              flexDirection: 'column',
              position: 'relative',
            },
            display: {
              xs: 'none',
              md: 'block',
            },
          }}
        >
          <Toolbar />
          <Box
            sx={{
              overflow: 'auto',
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
              maxHeight: 'calc(100vh - 64px)',
            }}
          >
            <SectionNav />
          </Box>
        </Drawer>
      )}
    </div>
  )
}
