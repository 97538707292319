import {
  Box,
  BoxProps,
  FormControl,
  FormControlProps,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { styled, CSSObject } from '@mui/system'
import { NavLink } from 'react-router-dom'

const Link = styled(NavLink)(({ theme }) => ({
  textDecoration: 'none',
  cursor: 'pointer',
  paddingLeft: 42,
  paddingRight: 42,
  height: 48,
  fontSize: 18,
  fontWeight: 'bold',
  border: `1px solid ${theme.palette.primary.dark}`,
  color: theme.palette.primary.dark,
  borderRadius: 8,
  width: 'fit-content',
  whiteSpace: 'nowrap',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'left 15px center',
  display: 'flex',
  alignItems: 'center',
  marginBottom: 31,
}))

const Tab = styled('div')(({ theme }) => ({
  marginBottom: -1,
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
  height: 44,
  width: 'fit-content',
  padding: '0px 28px',
  background: 'white',
  borderRadius: '8px 8px 0px 0px',
  borderColor: theme.palette.primary.light,
  borderWidth: '1px',
  borderStyle: 'solid',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
}))

type StandardBtnVariant = {
  small: CSSObject
  normal: CSSObject
  outlined: CSSObject
}

const StandardBtnVariants: StandardBtnVariant = {
  small: {
    fontSize: 12,
  },
  normal: {
    fontSize: 16,
  },
  outlined: {
    fontSize: 16,
    background: '#fff',
    color: '#000',
    border: '1px solid #000',
    fontWeight: '600',
    display: 'inline',
    width: 'auto',
  },
}

const StandardButton = styled('button')<{ variant: keyof StandardBtnVariant }>(
  ({ theme }) => ({
    all: 'unset',
    cursor: 'pointer',
    width: 'fit-content',
    height: 32,
    padding: theme.spacing(1, 3),
    fontSize: 12,
    fontWeight: '400',
    fontFamily: 'Muli',    
    borderRadius: '7px',
    background: theme.palette.primary.dark,
    color: theme.palette.primary.light,
    whiteSpace: 'nowrap',
    backgroundRepeat: 'no-repeat',
  }),
  ({ variant = 'small' }) => StandardBtnVariants[variant],
)

const DrawerNavItem = styled('div')(({ theme }) => ({
  fontSize: 12,
}))

/* const StyledH1 = styled('h1')(({ theme }) => ({
  fontSize: 12,
})) */


const CustomFormBox = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'relative',
  maxWidth: 420,
  Width: 100,
  marginTop: 25,
  marginLeft: 25,
  marginRight: 25,
  marginBottom: 25,
}))
const CustomFormControl = styled(FormControl)<FormControlProps>(
  ({ theme }) => ({
    width: '100%',
    height: 50,
    fontSize: 12,
    fontWeight: 'bold',
  }),
)

const CustomFormTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  all: 'unset',
  cursor: 'pointer',
  width: '100%',
  height: 32,
  fontSize: 12,
  fontWeight: 'bold',
  background: theme.palette.primary.light,
  color: theme.palette.primary.dark,
  whiteSpace: 'nowrap',
}))

type HeaderVariant = { main: CSSObject; signin: CSSObject }

const headerVariants: HeaderVariant = {
  main: {
    fontSize: 28,
    marginBottom: 28,
  },
  signin: {
    fontSize: 22,
    marginBottom: 20,
  },
}

const Header = styled('h1')<{ variant: keyof HeaderVariant }>(
  ({ theme }) => ({
    color: theme.palette.primary.dark,
    marginTop: 0,
    padding: 0,
  }),
  ({ variant }) => headerVariants[variant],
)

export {
  Link,
  Tab,
  StandardButton,
  DrawerNavItem,
  Header,
  CustomFormBox,
  CustomFormTextField,
  CustomFormControl,
}
