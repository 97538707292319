import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from './use-clients'
import { UpdatePolicyDocumentModel } from 'api/data-contracts'

// PUT:/api/PolicyDocument/{policyId}
interface useUpdatePolicyDocumentProps {
  policyId: string
  data: UpdatePolicyDocumentModel
}
const useUpdatePolicyDocument = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (props: useUpdatePolicyDocumentProps) =>
      client.policyDocument.updatePolicyDocument(props.policyId, props.data),
    onSuccess: data => {
      queryClient.setQueryData(['organisation'], data)
      queryClient.refetchQueries(['organisation'])
    },
  })
  return mutation
}

// DELETE:/api/PolicyDocument/{policyId}
interface useDeletePolicyDocumentProps {
  policyId: string
}
const useDeletePolicyDocument = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (props: useDeletePolicyDocumentProps) =>
      client.policyDocument.deletePolicyDocument(props.policyId),
    onSuccess: () => {
      queryClient.refetchQueries(['organisation'])
    },
  })
  return mutation
}

export { useUpdatePolicyDocument, useDeletePolicyDocument }
