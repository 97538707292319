import { toast } from 'react-toastify'
import { useContext, useEffect, useState } from 'react'

import { useBranding } from 'utils/useBranding'
import CustomInputTextWithLabel from '../../../CustomInputTextWithLabel'
import CustomCheckedBoxwithLabel from '../../../CustomCheckedBoxwithLabel'
import { ActionButton } from '../../../ActionButton'
import { AddAnswerField } from '../AddAnswerField'
import {
  FREETEXT,
  MULTISELECT,
  SINGLESELECT,
  SPECIFICORDER,
} from 'utils/constants/questions'
import { QuestionContext } from '../QuestionContext'
import {
  AnswerDataModel,
  setQuestionList,
  useUpdateAnswerOptionOrder,
  useUpdateQuestion,
} from 'utils/question'

// API
import { useAddQuestion } from 'utils/course'
import {
  useAddAnswerOption,
  useGetQuestion,
  useGetAnswerOption,
} from 'utils/question'
import {
  useDeleteAnswerOption,
  useUpdateAnswerOption,
} from 'utils/answerOptions'

import MultiSelect from './MultiSelect'
import SingleSelect from './SingleSelect'
import SpecificOrder from './SpecificOrder'
import { UpdateAnswerOptionOrderModel } from 'api/data-contracts'
import { useAddQuestionToSection } from 'utils/section'
import { CurrentSectionContext } from 'components/moduleSlides/CurrentSectionContext'
import { LockdownCourseContext } from 'components/moduleSlides/LockdownCourseContext'
import { MINIQUIZ } from 'utils/constants/sections'

interface QuestionTypesProps {
  drawerClose?: () => void
  drawerVisibility?: boolean
}

const QuestionTypes = ({
  drawerClose,
  drawerVisibility,
}: QuestionTypesProps) => {
  const { queType, setQueType, updateQuestionId } = useContext(QuestionContext)
  const { currentSection } = useContext(CurrentSectionContext)
  const { isCurrentCourseLocked } = useContext(LockdownCourseContext)
  const { branding } = useBranding()

  const { mutateAsync: addQuestion } = useAddQuestion()
  const { mutateAsync: addAnswerOption } = useAddAnswerOption()
  const { questionData } = useGetQuestion(updateQuestionId)
  const { answersData } = useGetAnswerOption(updateQuestionId)
  const { mutateAsync: updateQuestion } = useUpdateQuestion()
  const { mutateAsync: updateAnswerOption } = useUpdateAnswerOption()
  const { mutateAsync: updateAnswerOptionOrder } = useUpdateAnswerOptionOrder()
  const { mutateAsync: deleteAnswerOption } = useDeleteAnswerOption()
  const { mutateAsync: addQuestionToSection } = useAddQuestionToSection()

  const [name, setName] = useState<string>('')
  const [question, setQuestion] = useState<string>('')
  const [useForMiniquiz, setUseForMini] = useState(false)

  const [addAnswer, setAddAnswer] = useState<string>('')
  const [answerData, setAnswerData] = useState<AnswerDataModel[]>([])
  const [submitQuestionLoader, setSubmitQuestionLoader] = useState(false)
  const [submitAndCreateQuestionLoader, setSubmitAndCreateQuestionLoader] =
    useState(false)

  useEffect(() => {
    if (questionData) {
      setName(questionData.name as string)
      setQuestion(questionData.question as string)
      setUseForMini(questionData.canUseForMini as boolean)
    }
  }, [questionData])

  useEffect(() => {
    if (answersData) {
      setAnswerData(answersData)
    }
  }, [answersData])

  const addQuestionTypeData = {
    name: name,
    question: question,
    questionType: queType,
    canUseForMini: useForMiniquiz,
  }
  const updateQuestionTypeData = {
    name: name,
    question: question,
    canUseForMini: useForMiniquiz,
  }

  const anyAnswerSelected = answerData.map(val => val.isCorrect).includes(true)

  // Add Answer Radiobutton/Checkbox
  const onClickAddAnswerHandler = () => {
    const data = {
      answerText: addAnswer,
      isCorrect: false,
    }
    setAnswerData(answerData => [...answerData, data])
    setAddAnswer('')
  }

  const onChangeCheckboxHandler = (index: number) => {
    const updatedObj = {
      ...answerData[index],
      isCorrect: !answerData[index].isCorrect,
    }
    setAnswerData(answerData => [
      ...answerData.slice(0, index),
      updatedObj,
      ...answerData.slice(index + 1),
    ])
  }

  const typesWithRadioButton =
    queType === SINGLESELECT ||
    queType === MULTISELECT ||
    queType === SPECIFICORDER

  // Delete Answer Radiobutton/Checkbox
  const onClickDeleteAnswerHandler = (index: number, id: string) => {
    if (id !== undefined) {
      deleteAnswerOption({ answerOptionId: id })
    }
    index > -1 && answerData.splice(index, 1)
    setAnswerData(answerData => [...answerData])
  }

  const SaveQuestionAPI = async (value: boolean) => {
    if (addQuestionTypeData.name === '') {
      toast.error('Please enter name')
      return <></>
    }

    if (addQuestionTypeData.question === '') {
      toast.error('Please enter question')
      return <></>
    }

    if (typesWithRadioButton && answerData.length === 0) {
      toast.error('Please add a answer')
      return <></>
    }

    if (typesWithRadioButton && !anyAnswerSelected) {
      toast.error('Select at least one answer')
      return <></>
    }

    if (currentSection?.sectionType === MINIQUIZ && !useForMiniquiz) {
      toast.error('Please select miniquiz under question can be used for')
      return <></>
    }

    value === true && setSubmitQuestionLoader(true)
    value === false && setSubmitAndCreateQuestionLoader(true)

    try {
      const addQuestionResponse = await addQuestion(addQuestionTypeData)
      const questionId = addQuestionResponse?.data?.id as string

      for (let i = 0; i < answerData.length; i++) {
        const answerValue = {
          answerText: answerData[i].answerText,
          isCorrect: answerData[i].isCorrect,
        }
        await addAnswerOption({ questionId, data: answerValue })
      }

      setQuestionList(`${value}`)
      setQueType('')
      toast.success('Question added !!')

      if (drawerClose !== undefined && currentSection !== undefined) {
        await addQuestionToSection({
          sectionId: currentSection.id as string,
          questionId: questionId,
        })
        drawerClose()
      }
      setSubmitQuestionLoader(false)
      setSubmitAndCreateQuestionLoader(false)
    } catch (error) {
      toast.error('Unable to save question')
      setSubmitQuestionLoader(false)
      setSubmitAndCreateQuestionLoader(false)
    }
  }

  const updateQuestionAPI = async (value: boolean) => {
    if (updateQuestionTypeData.name === '') {
      toast.error('Please enter name')
      return <></>
    }

    if (updateQuestionTypeData.question === '') {
      toast.error('Please enter question')
      return <></>
    }

    if (typesWithRadioButton && answerData.length === 0) {
      toast.error('Please add a answer')
      return <></>
    }

    if (typesWithRadioButton && !anyAnswerSelected) {
      toast.error('Select at least one answer')
      return <></>
    }

    value === true && setSubmitQuestionLoader(true)
    value === false && setSubmitAndCreateQuestionLoader(true)

    try {
      await updateQuestion({
        questionId: updateQuestionId,
        data: updateQuestionTypeData,
      })

      for (let i = 0; i < answerData.length; i++) {
        const answerValue = {
          answerText: answerData[i].answerText,
          isCorrect: answerData[i].isCorrect,
        }

        if (answerData[i].id === undefined) {
          await addAnswerOption({
            questionId: updateQuestionId,
            data: answerValue,
          })
        } else {
          await updateAnswerOption({
            answerOptionId: answerData[i].id as string,
            data: answerValue,
          })
        }

        if (queType === SPECIFICORDER) {
          const orderedIds = answerData.map(value => value.id)
          const data = {
            orderedAnswerOptionIds: orderedIds,
          }
          await updateAnswerOptionOrder({
            questionId: updateQuestionId,
            data: data as UpdateAnswerOptionOrderModel,
          })
        }
      }
      setQuestionList(`${value}`)
      setQueType('')
      setSubmitQuestionLoader(false)
      setSubmitAndCreateQuestionLoader(false)
      toast.success('Question updated !!')
    } catch (error) {
      setSubmitQuestionLoader(false)
      setSubmitAndCreateQuestionLoader(false)
      toast.error('Error')
    }
  }

  const onClickSaveQuestionHandler = () => {
    SaveQuestionAPI(true)
  }
  const onClickSaveAndCreateQuestionHandler = () => {
    SaveQuestionAPI(false)
  }
  const onClickUpdateQuestionHandler = () => {
    updateQuestionAPI(true)
  }
  const onClickUpdateAndCreateQuestionHandler = () => {
    updateQuestionAPI(false)
  }

  return (
    <>
      <CustomInputTextWithLabel
        title="Name"
        value={name}
        setAction={setName}
        multiline={false}
      />

      <CustomInputTextWithLabel
        title="Question"
        value={question}
        setAction={setQuestion}
        multiline={true}
      />

      {queType !== FREETEXT && <p className="text-label"> Answers</p>}

      {queType === SPECIFICORDER && (
        <SpecificOrder
          answerData={answerData}
          onClickDeleteAnswerHandler={onClickDeleteAnswerHandler}
          setAnswerData={setAnswerData}
        />
      )}

      {queType === MULTISELECT && (
        <MultiSelect
          answerData={answerData}
          onChangeCheckboxHandler={onChangeCheckboxHandler}
          onClickDeleteAnswerHandler={onClickDeleteAnswerHandler}
        />
      )}

      {queType === SINGLESELECT && (
        <SingleSelect
          answerData={answerData}
          onClickDeleteAnswerHandler={onClickDeleteAnswerHandler}
          setAnswerData={setAnswerData}
        />
      )}

      {(queType === MULTISELECT ||
        queType === SPECIFICORDER ||
        queType === SINGLESELECT) && (
        <AddAnswerField
          onClickAddAnswerHandler={onClickAddAnswerHandler}
          addAnswer={addAnswer}
          setAddAnswer={setAddAnswer}
          limit={queType === SINGLESELECT ? 2 : 10}
          answerDataLength={answerData.length}
        />
      )}

      <p className="text-label">Can this question be used for</p>
      <CustomCheckedBoxwithLabel
        label="Miniquiz"
        action={useForMiniquiz}
        setAction={setUseForMini}
      />

      <div className="flex">
        <ActionButton
          name={`${
            updateQuestionId.length ? 'Update question' : 'Save question'
          }`}
          background={branding.stylingPrimary ?? 'blue'}
          setAction={
            updateQuestionId.length
              ? onClickUpdateQuestionHandler
              : onClickSaveQuestionHandler
          }
          className={`flex 
          ${
            (isCurrentCourseLocked || submitQuestionLoader) &&
            'pointer-event-none opacity-50'
          }`}
          spinner={submitQuestionLoader}
        />
        {drawerVisibility && (
          <ActionButton
            name="Cancel"
            background={branding.stylingSecondary ?? 'blue'}
            setAction={drawerClose}
          />
        )}
        {!drawerVisibility && (
          <ActionButton
            name={`${
              updateQuestionId.length
                ? 'Update & create question'
                : 'Save & create question'
            }`}
            background={branding.stylingPrimary ?? 'blue'}
            setAction={
              updateQuestionId.length
                ? onClickUpdateAndCreateQuestionHandler
                : onClickSaveAndCreateQuestionHandler
            }
            className={`flex 
            ${
              (isCurrentCourseLocked || submitAndCreateQuestionLoader) &&
              'pointer-event-none opacity-50'
            }`}
            spinner={submitAndCreateQuestionLoader}
          />
        )}
      </div>
    </>
  )
}

export default QuestionTypes
