// Unauthenticated Routes
export const LOGIN = '/login'

// Authenticated Routes
export const DASHBOARD = '/dashboard'
export const PAGE_NOT_FOUND = '*'
export const SETTINGS = '/settings'
export const COMPANY_DETAILS = '/settings/company-settings/company-details'
export const SECURITY = '/settings/company-settings/security'
export const PAYMENTS = '/settings/company-settings/payments'
export const POLICY_DOCUMENTS = '/settings/company-settings/policy-documents'
export const POLICY_DOCUMENTS_CREATE =
  '/settings/company-settings/policy-documents/create'
export const POLICY_DOCUMENTS_BY_POLICY_ID =
  '/settings/company-settings/policy-documents/:policyId/edit'
export const ACTIVE_USERS = '/settings/users/active-users'
export const DELETE_USERS = '/settings/users/deleted-users'
export const PLATFORM_SETTINGS = '/settings/company-settings/platform-settings'
export const ROLES = '/settings/roles'
export const THEMES = '/settings/themes'
export const MESSAGE_TEMPLATES = '/settings/message-templates'
export const MESSAGE_TEMPLATES_BY_MESSAGE_ID =
  '/settings/message-templates/:messageId/edit'
export const TOPICS = '/settings/topics'
export const COURSES = '/settings/courses'
export const NEW_COURSE = '/settings/courses/new-course'
export const COURSE_CONFIGURATION =
  '/settings/course-setup/configuration/:courseId'
export const COURSES_INSTANCES =
  '/settings/course-setup/course-instances/:courseId'
export const DASHBOARD_COURSE_INSTANCE =
  '/dashboard/course-setup/course-instances/:courseId/course-instance/:courseInstanceId'
export const PARTICIPANT =
  '/settings/course-setup/course-instances/:courseInstanceId/participant/:participantId'
export const PARTICIPANTS_MODULE =
  '/settings/course-setup/course-instances/:courseInstanceId/participant/:participantId/:moduleId'
export const COURSE_ACCESS = '/settings/course-setup/access/:courseId'
export const COURSE_MODULES = '/settings/course-setup/modules/:courseId'
export const COURSE_RESOURCES = '/settings/course-setup/resources/:courseId'
export const COURSE_QUESTION = '/settings/course-setup/questions/:courseId'
export const COURSE_MESSAGE_FLOW =
  '/settings/course-setup/message-flow/:courseId'

export const MODULE_DETAILS =
  '/settings/module-setup/details/:courseId/:moduleId'
export const NEW_MODULE_DETAILS =
  '/settings/course-setup/modules/:courseId/new-module'
export const CREATE_RESOURCE_DETAILS =
  '/settings/course-setup/resources/:courseId/new-resource/:resourceId'

export const EDIT_RESOURCE_DETAILS =
  '/settings/course-setup/resources/:courseId/resource/:resourceId'
export const MODULE_RESOURCES =
  '/settings/module-setup/resources/:courseId/:moduleId'
export const MODULE_SLIDES = '/settings/module-setup/slides/:courseId/:moduleId'
