import { useState, useEffect, useContext } from 'react'
import TitleHelmet from 'components/TitleHelmet'
import { useParams } from 'react-router-dom'

import { ChooseSectionType } from './sectionSetup/ChooseSectionType'
import ChooseContentTypeTemplate from './sectionSetup/content/ChooseContentSection'
import Content from './sectionSetup/content/Content'
import AddMiniQuizSection from './sectionSetup/miniQuiz/AddMiniQuizSection'
import UpdateMiniQuizSection from './sectionSetup/miniQuiz/UpdateMiniQuizSection'
import AddAssessmentSection from './sectionSetup/assessment/AddAssessmentSection'
import UpdateAssessmentSection from './sectionSetup/assessment/UpdateAssessmentSection'
import { PageTitle } from 'utils/constants/pageTitles'
import { useCourseLockdown } from 'pages/courseSetup/CourseLockdown'

// API - custom hooks
import { useUpdateSection } from 'utils/section'

// Contexts
import { CurrentSectionContext } from './CurrentSectionContext'
import { NewSectionContext } from './NewSectionContext'
import { LockdownCourseContext } from './LockdownCourseContext'

// Constants
import { CONTENT, MINIQUIZ, ASSESSMENT } from '../../utils/constants/sections'

// Icons
import PreviewIcon from '../../assets/icon/preview.svg'
import { useGetOrganisationID, useGetSiteDomains } from 'utils/organisation'
import { PLATFORM } from 'utils/constants/statsPanel'

const SectionStage = () => {
  const { moduleId } = useParams()
  const { status, newSection, title, setTitle, setIsNewSlideEdited } =
    useContext(NewSectionContext)
  const { isCurrentCourseLocked } = useContext(LockdownCourseContext)
  const { currentSection } = useContext(CurrentSectionContext)
  const { mutateAsync: updateSection } = useUpdateSection()
  const { mutateAsync: getSiteDomains } = useGetSiteDomains()
  const { organisationId } = useGetOrganisationID()

  const [platformDomain, setPlatformDomain] = useState('')

  useEffect(() => {
    if (organisationId) {
      const getSiteDomainHandler = async () => {
        const { data } = await getSiteDomains({
          organisationId: organisationId,
        })
        const hostname = window.location.hostname
        const result = data.find(
          item =>
            item.domainType === PLATFORM &&
            (hostname.includes('localhost')
              ? item.domain?.includes('localhost')
              : !item.domain?.includes('localhost')),
        )
        setPlatformDomain(result?.domain ?? '')
      }
      getSiteDomainHandler()
    }
  }, [getSiteDomains, organisationId])

  useCourseLockdown()

  const bgImageCondition =
    currentSection === undefined && Object.keys(newSection).length === 0

  const [slideName, setSlideName] = useState<string | null | undefined>('')

  useEffect(() => {
    setSlideName(currentSection?.name)
  }, [currentSection])

  const chooseSection =
    status === 'inprogress' && newSection && !newSection.SectionType

  const chooseContent =
    status === 'inprogress' &&
    newSection &&
    newSection.SectionType === CONTENT &&
    !currentSection

  const addContentSection =
    status === 'inprogress' &&
    newSection &&
    newSection.SectionType === CONTENT &&
    currentSection?.sectionType === CONTENT

  const updateContentSection =
    status === undefined &&
    Object.keys(newSection).length === 0 &&
    currentSection?.sectionType === CONTENT

  useEffect(() => {
    const data = {
      name: title?.length ? title : 'New section',
      title: title?.length ? title : 'New section',
    }
    if (!isCurrentCourseLocked && (addContentSection || updateContentSection)) {
      updateSection({
        sectionId: currentSection?.id as string,
        data: data,
      })
    }
    // other dependencies will un-necessarily re-render component
    //eslint-disable-next-line
  }, [title])

  const addMiniQuizSection =
    status === 'inprogress' &&
    newSection &&
    newSection.SectionType === MINIQUIZ &&
    newSection.SectionTemplate === MINIQUIZ

  const updateMiniQuizSection =
    status === undefined &&
    Object.keys(newSection).length === 0 &&
    currentSection?.sectionType === MINIQUIZ

  const addAssessmentSection =
    status === 'inprogress' &&
    newSection &&
    newSection.SectionType === ASSESSMENT &&
    newSection.SectionTemplate === ASSESSMENT

  const updateAssessmentSection =
    status === undefined &&
    Object.keys(newSection).length === 0 &&
    currentSection?.sectionType === ASSESSMENT

  const addSectionCondition =
    addContentSection || addMiniQuizSection || addAssessmentSection

  const updateSectionCondition =
    updateContentSection || updateMiniQuizSection || updateAssessmentSection

  const slideTitleValueHandler = () => {
    if (addSectionCondition) {
      return title === null || title === undefined ? '' : title
    }
    if (updateSectionCondition) {
      return slideName === null || slideName === undefined ? '' : slideName
    }
  }

  return (
    <>
      <TitleHelmet title={PageTitle.COURSE_SETUP_MODULE_SLIDES} />
      {(addSectionCondition || updateSectionCondition) && (
        <>
          <p className="mb-10">Slide title</p>
          <div className="flex justify-between align-center">
            <input
              autoComplete="off"
              placeholder="Slide title"
              className="slide-title-input input-field mb-20 min-w-300px"
              value={slideTitleValueHandler()}
              onChange={e => {
                updateSectionCondition && setSlideName(e.target.value)
                setTitle(e.target.value);
                (currentSection?.sectionType === MINIQUIZ ||
                  currentSection?.sectionType === ASSESSMENT) &&
                  setIsNewSlideEdited(true)
              }}
            />
            <a
              className="preview-icon"
              target="_blank"
              rel="noreferrer"
              href={`${
                window.location.hostname.includes('localhost')
                  ? 'http'
                  : 'https'
              }://${platformDomain}/preview/${moduleId}/section/${
                currentSection?.id
              }`}
            >
              <img src={PreviewIcon} alt="preview" />
            </a>
          </div>
        </>
      )}

      <div
        className={`module-slide-wrapper ${
          !bgImageCondition && 'bg-image-none'
        }`}
      >
        {chooseSection ? <ChooseSectionType /> : null}
        {chooseContent ? <ChooseContentTypeTemplate /> : null}

        {addContentSection || updateContentSection ? <Content /> : null}

        {addMiniQuizSection ? <AddMiniQuizSection /> : null}
        {updateMiniQuizSection ? <UpdateMiniQuizSection /> : null}

        {addAssessmentSection ? <AddAssessmentSection /> : null}
        {updateAssessmentSection ? <UpdateAssessmentSection /> : null}
      </div>
    </>
  )
}

export default SectionStage
