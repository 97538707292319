import { ReactElement, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ApartmentIcon from '@mui/icons-material/Apartment'
import BusinessIcon from '@mui/icons-material/Business'
import PaymentsIcon from '@mui/icons-material/Payments'
import SecurityIcon from '@mui/icons-material/Security'
import ArticleIcon from '@mui/icons-material/Article'
import SchoolIcon from '@mui/icons-material/School'
import Person2Icon from '@mui/icons-material/Person2'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import GroupRemoveIcon from '@mui/icons-material/GroupRemove'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import EmailIcon from '@mui/icons-material/Email'
import TopicIcon from '@mui/icons-material/Topic'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import * as ROUTE from '../../../utils/constants/clientRoutes'
import AnchorLink from 'components/AnchorLink'
import { PageTitle } from 'utils/constants/pageTitles'

const drawerWidth = 255

export default function ClippedDrawer() {
  type NavItem = {
    label: string
    path?: string
    icon: ReactElement
    children?: NavItem[]
  }
  const navigate = useNavigate()

  const navigation: NavItem[] = [
    {
      label: 'Company settings',
      icon: <ApartmentIcon />,
      children: [
        {
          label: 'Company details',
          path: '/settings/company-settings/company-details',
          icon: <BusinessIcon />,
        },
        {
          label: 'Payments',
          path: '/settings/company-settings/payments',
          icon: <PaymentsIcon />,
        },
        {
          label: 'Security',
          path: '/settings/company-settings/security',
          icon: <SecurityIcon />,
        },
        {
          label: 'Policy documents',
          path: '/settings/company-settings/policy-documents',
          icon: <ArticleIcon />,
        },
        {
          label: 'Platform settings',
          path: '/settings/company-settings/platform-settings',
          icon: <ApartmentIcon />,
        },
      ],
    },
    {
      label: 'Courses',
      path: '/settings/courses',
      icon: <SchoolIcon />,
    },
    {
      label: ' Users',
      icon: <Person2Icon />,
      children: [
        {
          label: 'Active users',
          path: '/settings/users/active-users',
          icon: <GroupAddIcon />,
        },
        {
          label: 'Deleted users',
          path: '/settings/users/deleted-users',
          icon: <GroupRemoveIcon />,
        },
      ],
    },
    {
      label: 'Roles',
      path: '/settings/roles',
      icon: <ManageAccountsIcon />,
    },
    /*   {
      label: 'Themes',
      path: '/settings/themes',
      icon: <PaletteIcon />,
    }, */
    {
      label: 'Topics',
      path: ROUTE.TOPICS,
      icon: <TopicIcon />,
    },
    {
      label: 'Message templates',
      path: '/settings/message-templates',
      icon: <EmailIcon />,
    },
  ]

  const sxHide = {
    display: 'none',
  }
  const sxShow = {
    display: 'block',
    paddingLeft: '15px',
  }

  const [showChildren, setShowChildren] = useState<{ [key: string]: boolean }>(
    {},
  )

  const onSidebarLinkClickHandler = (item: NavItem) => {
    navigate(item.path as string)
    setShowChildren({
      ...showChildren,
      [item.label || '']: !showChildren[item.label || ''],
    })
  }

  //add CSS for show hide children, relevant icons and dropdown icon
  const renderNavItem = (item: NavItem) => (
    <ListItem
      sx={{ flexDirection: 'Column', alignItems: 'stretch' }}
      key={item.label}
      disablePadding
    >
      <ListItemButton onClick={() => onSidebarLinkClickHandler(item)}>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.label} />
        {!item.children ? '' : <ExpandMoreIcon />}
      </ListItemButton>
      {item.children && (
        <List
          sx={showChildren[item.label] ? sxShow : sxHide}
          className={
            showChildren[item.label] ? 'show-children' : 'hide-children'
          }
        >
          {item.children.map(child => renderNavItem(child))}
        </List>
      )}
    </ListItem>
  )

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: 'fit-content',
          boxSizing: 'border-box',
          flexDirection: 'column',
        },
        display: {
          xs: 'none',
          md: 'block',
        },
      }}
    >
      <Toolbar />
      <AnchorLink
        onClick={() => navigate(ROUTE.DASHBOARD)}
        style={{
          display: 'flex',
          flexGrow: '1',
          justifyContent: 'start',
          position: 'relative',
          alignItems: 'center',
          marginTop: '20px',
          padding: '8px 16px',
          color: '#000000DE',
          overflowWrap: 'break-word',
          cursor: 'pointer',
          fontWeight: '400',
          fontSize: '1.1428571428571428rem',
          lineHeight: '1.5',
        }}
      >
        <ArrowBackIcon
          fontSize="medium"
          sx={{
            marginRight: '5px',
            display: 'inline-flex',
            flexShrink: '0',
          }}
        />
        <span
          style={{
            display: 'block',
            flex: '1 1 auto',
            minWidth: '0',
            marginTop: '4px',
            marginBottom: '4px',
          }}
        >
          {PageTitle.NAV_BACK}
        </span>
      </AnchorLink>
      <div className="flex overflow-auto h-full justify-between flex-column">
        <List>{navigation.map(renderNavItem)}</List>
      </div>
    </Drawer>
  )
}
