import { useContext } from 'react'

// Contexts
import { NewSectionContext } from '../NewSectionContext'

// Constants
import { ASSESSMENT, CONTENT, MINIQUIZ } from 'utils/constants/sections'
import { LockdownCourseContext } from '../LockdownCourseContext'

const ChooseSectionType = () => {
  const { newSection, setNewSection } = useContext(NewSectionContext)
  const { isCurrentCourseLocked } = useContext(LockdownCourseContext)

  const contentHandler = () => {
    setNewSection({
      ...newSection,
      SectionType: CONTENT,
    })
  }

  const miniQuizHandler = () => {
    setNewSection({
      ...newSection,
      SectionType: MINIQUIZ,
      SectionTemplate: MINIQUIZ,
    })
  }

  const assessmentHandler = () => {
    setNewSection({
      ...newSection,
      SectionType: ASSESSMENT,
      SectionTemplate: ASSESSMENT,
    })
  }

  return (
    <div className="slides-content-wrapper">
      <button
        className={`slide-heading-button w-150px mb-30
        ${isCurrentCourseLocked && 'pointer-event-none opacity-50'}`}
        onClick={() => contentHandler()}
      >
        Content
      </button>
      <button
        className={`slide-heading-button w-150px mb-30
       ${isCurrentCourseLocked && 'pointer-event-none opacity-50'}`}
        onClick={() => miniQuizHandler()}
      >
        Mini quiz
      </button>
      <button
        className={`slide-heading-button w-150px m-0
        ${isCurrentCourseLocked && 'pointer-event-none opacity-50'}`}
        onClick={() => assessmentHandler()}
      >
        Assessment
      </button>
    </div>
  )
}

export { ChooseSectionType }
