/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  NotFoundExceptionModel,
  OrganisationTestimonialModel,
  UnauthorizedExceptionModel,
  UpdateTestimonialModel,
  ValidationExceptionModel,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Testimonial<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Testimonial
   * @name UpdateTestimonial
   * @request PUT:/api/Testimonial/{testimonialId}
   * @secure
   */
  updateTestimonial = (
    testimonialId: string,
    data: UpdateTestimonialModel,
    params: RequestParams = {},
  ) =>
    this.request<
      OrganisationTestimonialModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Testimonial/${testimonialId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Testimonial
   * @name DeleteTestimonial
   * @request DELETE:/api/Testimonial/{testimonialId}
   * @secure
   */
  deleteTestimonial = (testimonialId: string, params: RequestParams = {}) =>
    this.request<void, UnauthorizedExceptionModel | NotFoundExceptionModel>({
      path: `/api/Testimonial/${testimonialId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    })
  /**
   * No description
   *
   * @tags Testimonial
   * @name UpdateTestimonialImage
   * @request PUT:/api/Testimonial/{testimonialId}/images/testimonialimage
   * @secure
   */
  updateTestimonialImage = (
    testimonialId: string,
    data: {
      /** @format binary */
      image?: File
    },
    params: RequestParams = {},
  ) =>
    this.request<
      OrganisationTestimonialModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Testimonial/${testimonialId}/images/testimonialimage`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Testimonial
   * @name DeleteTestimonialImage
   * @request DELETE:/api/Testimonial/{testimonialId}/images/testimonialimage
   * @secure
   */
  deleteTestimonialImage = (
    testimonialId: string,
    params: RequestParams = {},
  ) =>
    this.request<
      OrganisationTestimonialModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Testimonial/${testimonialId}/images/testimonialimage`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    })
}
