import { useContext } from 'react'
import { Select, MenuItem, Drawer } from '@mui/material'

import {
  FREETEXT,
  MULTISELECT,
  SINGLESELECT,
  SPECIFICORDER,
} from 'utils/constants/questions'

import { QuestionContext } from './courseSetup/Questions/QuestionContext'
import { setQuestionType } from 'utils/question'
import QuestionTypes from './courseSetup/Questions/QuestionType'

interface CreateResourcePopoutProps {
  drawerVisibility: boolean
  drawerClose: () => void
}

const CreateQuestionPopout = ({
  drawerVisibility,
  drawerClose,
}: CreateResourcePopoutProps) => {
  const { queType, setQueType } = useContext(QuestionContext)

  return (
    <Drawer anchor={'right'} open={drawerVisibility}>
      <div className="flex flex-column pt-15 mt-5rem m-5rem min-w-500px">
        <h2>Create Question</h2>

        <div className="w-full mb-25">
          <p className="text-label">Question type</p>
          <Select
            value={queType}
            onChange={e => {
              setQueType(e.target.value)
              setQuestionType(e.target.value)
            }}
            sx={{
              '.MuiOutlinedInput-notchedOutline': { border: 0 },
              '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                {
                  border: 0,
                },
              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                {
                  border: 0,
                },
              background: '#fff',
            }}
          >
            <MenuItem value={MULTISELECT}>MULTISELECT</MenuItem>
            <MenuItem value={SINGLESELECT}>SINGLESELECT</MenuItem>
            <MenuItem value={FREETEXT}>FREETEXT</MenuItem>
            <MenuItem value={SPECIFICORDER}>SPECIFICORDER</MenuItem>
          </Select>
        </div>

        <QuestionTypes
          drawerClose={drawerClose}
          drawerVisibility={drawerVisibility}
        />
      </div>
    </Drawer>
  )
}

export { CreateQuestionPopout }
