import { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useDeleteResource } from 'utils/resource'
import { RowSelection } from 'utils/models/Resource'
import { ResourcesTable } from 'components/tables/ResourcesTable'
import { effectSelectedMuiTableRows } from 'utils/materialTable/effectRow'
import { ActionButton } from 'components/ActionButton'
import { useBranding } from 'utils/useBranding'
import { PageTitle } from 'utils/constants/pageTitles'
import TitleHelmet from 'components/TitleHelmet'

const CourseResources = () => {
  const { branding } = useBranding()
  const navigate = useNavigate()

  const [resourceRowSelection, setResourceRowSelection] =
    useState<RowSelection>({})
  const { courseId } = useParams()
  const { mutateAsync: deleteResource } = useDeleteResource()

  const onDeleteResource = () => {
    effectSelectedMuiTableRows(
      resourceRowSelection,
      setResourceRowSelection,
      deleteResource,
      'Resource removed from module!',
      'Error deleting resource - make sure the resource is not in use',
    )
  }

  return (
    <>
      <TitleHelmet title={PageTitle.COURSE_SETUP_RESOURCES} />
      <h1>{PageTitle.COURSE_SETUP_RESOURCES}</h1>

      <ActionButton
        name="Create Resource"
        background={branding.stylingPrimary ?? 'blue'}
        setAction={() =>
          navigate(
            `/settings/course-setup/resources/${courseId}/new-resource/${courseId}`,
          )
        }
      />

      {Object.keys(resourceRowSelection).length > 0 && (
        <ActionButton
          name="Delete Resource"
          background={branding.stylingPrimary ?? 'blue'}
          setAction={() => onDeleteResource()}
        />
      )}

      <ResourcesTable
        resourceRowSelection={resourceRowSelection!}
        setResourceRowSelection={setResourceRowSelection!}
      />
    </>
  )
}
export default CourseResources
