import { useContext, useState } from 'react'
import { Button, IconButton, Tooltip } from '@mui/material'

import { useBranding } from 'utils/useBranding'
import { QuestionModel } from 'api/data-contracts'
import ModulePopout from 'components/moduleSlides/ModulePopout'
import { useNavigate, useParams } from 'react-router-dom'
import { QuestionContext } from 'components/courseSetup/Questions/QuestionContext'
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd'
import { setQuestionList, setQuestionType } from 'utils/question'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

interface AddQuestionsForCreateMiniQuizProps {
  addMiniQuizQuestions: QuestionModel[] | undefined
  setAddMiniQuizQuestions: React.Dispatch<React.SetStateAction<QuestionModel[]>>
}

const AddQuestionsForCreateMiniQuiz = ({
  addMiniQuizQuestions,
  setAddMiniQuizQuestions,
}: AddQuestionsForCreateMiniQuizProps) => {
  const { courseId } = useParams()
  const { branding } = useBranding()
  const { setList, setQueType, setUpdateQuestionId } =
    useContext(QuestionContext)
  const navigate = useNavigate()

  // Drawer Functionality
  const [drawerVisibility, setDrawerVisibility] = useState(false)
  const onDrawerClose = () => {
    setDrawerVisibility(false)
  }

  const onClickEditQuestionHandler = (
    questionType: string,
    questionId: string,
  ) => {
    navigate(`/settings/course-setup/questions/${courseId}`)
    setQuestionList('false')
    setList('false')
    setQuestionType(questionType)
    setQueType(questionType)
    setUpdateQuestionId(questionId)
  }

  const onClickDeleteSectionQuestionHandler = (index: number) => {
    index > -1 && addMiniQuizQuestions && addMiniQuizQuestions.splice(index, 1)
    setAddMiniQuizQuestions(answerData => [...answerData])
  }

  const reorder = (
    list: QuestionModel[],
    startIndex: number,
    endIndex: number,
  ): QuestionModel[] => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const newItems = reorder(
      addMiniQuizQuestions as QuestionModel[],
      result.source.index,
      result.destination.index,
    )
    setAddMiniQuizQuestions(newItems)
  }

  return (
    <div>
      <p className="text-label mb-20 mt-20">Questions</p>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {addMiniQuizQuestions &&
                addMiniQuizQuestions.map((question, index) => (
                  <Draggable
                    key={question.id}
                    draggableId={question.id as string}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        className="flex justify-between align-center"
                        key={question.id}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <p className="text-label">{question?.name}</p>
                        <div>
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={() =>
                                onClickEditQuestionHandler(
                                  question?.questionType as string,
                                  question?.id as string,
                                )
                              }
                            >
                              <EditIcon
                                sx={{ width: '20px', height: '20px' }}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              onClick={() =>
                                onClickDeleteSectionQuestionHandler(index)
                              }
                            >
                              <DeleteIcon
                                sx={{ width: '20px', height: '20px' }}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Button
        sx={{
          background: branding.stylingPrimary ?? 'blue',
          color: '#fff',
          padding: '10px 20px',
          fontWeight: '700',
          marginTop: '15px',
          '&:hover': {
            background: branding.stylingPrimary ?? 'blue',
          },
        }}
        onClick={() => setDrawerVisibility(true)}
      >
        + Add question
      </Button>

      <ModulePopout
        drawerVisibility={drawerVisibility}
        drawerClose={onDrawerClose}
        name="saveMiniQuiz"
        addMiniQuizQuestions={addMiniQuizQuestions}
        setAddMiniQuizQuestions={setAddMiniQuizQuestions}
      />
    </div>
  )
}

export default AddQuestionsForCreateMiniQuiz
