import { Control, Controller, MultipleFieldErrors } from 'react-hook-form'
import { TextField } from '@mui/material'

interface FormTextFieldProps {
  control?: Control
  errors?: {
    type?: string
    message?: string
    types?: MultipleFieldErrors
  }
  name: string
  label: string
  multilineValue?: number
  length?: boolean
  type?: string
  subLabel?: string
}

export const FormTextField = ({
  control,
  errors,
  name,
  label,
  multilineValue,
  length,
  type,
  subLabel,
}: FormTextFieldProps) => {
  const isPasswordField = type === 'password'

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <div
          className={`w-full mb-25 min-w-300px 
            ${length ? 'w-full' : 'desktop-w-50'}`}
        >
          <p className="text-label">{label}</p>
          {subLabel && <p className="mt-0 fs-14 fw-600">{subLabel}</p>}
          <TextField
            variant="outlined"
            multiline={multilineValue ? true : false}
            autoComplete="off"
            rows={multilineValue || 1}
            placeholder={label}
            value={
              value === null || value === undefined || value === 'undefined'
                ? ''
                : value
            }
            sx={{
              width: '100%',
              background: '#fff',
              borderRadius: 2,
              '& .MuiOutlinedInput-root': {
                '& > fieldset': {
                  border: 'none',
                },
              },
            }}
            onChange={onChange}
            inputProps={{
              style: isPasswordField ? { fontFamily: 'monospace' } : {},
            }}
          />
          {errors?.message !== undefined && (
            <p className="error-text">{errors.message}</p>
          )}
        </div>
      )}
    />
  )
}
