import { useMemo } from 'react'
import '../../styles/table.css'
import { useNavigate } from 'react-router-dom'
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table'
import { useGetOrganisationCourses } from 'utils/organisation'
import { CourseModel } from 'api/data-contracts'
import { darken } from '@mui/material'
import AnchorLink from 'components/AnchorLink'
import { RowSelection } from 'utils/models/Resource'

type Props = {
  setCourseRowSelection: React.Dispatch<React.SetStateAction<RowSelection>>
  courseRowSelection: RowSelection
}

const CoursesTable = ({
  courseRowSelection: rowSelection,
  setCourseRowSelection: setRowSelection,
}: Props) => {
  const navigate = useNavigate()
  const { courses, isError } = useGetOrganisationCourses()

  const columns = useMemo<MRT_ColumnDef<CourseModel>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        Cell: ({ cell }) => (
          <AnchorLink
            className="p-0"
            onClick={() =>
              navigate(`/settings/course-setup/modules/${cell.row.original.id}`)
            }
          >
            {cell.getValue<number>().toLocaleString()}
          </AnchorLink>
        ),
      },
      {
        accessorKey: 'tags',
        header: 'Tags',
      },
      {
        accessorKey: 'courseStatus',
        header: 'Course status',
        Cell: ({ cell }) =>
          cell.getValue<string>().toLocaleString().toLocaleLowerCase(),
      },
      {
        accessorKey: 'createdDate',
        header: 'Created date',
      },
    ],
    [navigate],
  )
  return (
    <MaterialReactTable
      columns={columns}
      data={courses}
      initialState={{
        showGlobalFilter: true,
        sorting: [{ id: 'createdDate', desc: true }],
        columnVisibility: {
          name: true,
          tags: true,
          courseStatus: true,
          createdDate: false,
        },
      }}
      enableGlobalFilterModes
      muiSearchTextFieldProps={{
        placeholder: `Search`,
        sx: { maxWidth: '200px' },
        variant: 'outlined',
      }}
      muiTablePaperProps={{
        elevation: 0,
        sx: {
          '& tr > th > div': {
            display: 'flex',
            fontSize: '18px',
          },
          '& tr > th > div > div > span': {
            display: 'none',
          },
          borderRadius: '0',
          border: 'none',
        },
      }}
      muiTableBodyProps={{
        sx: theme => ({
          '& tr:nth-of-type(odd)': {
            backgroundColor: darken(theme.palette.background.default, 0.1),
          },
        }),
      }}
      muiTableBodyCellProps={{
        sx: theme => ({
          '& tr > td:nth-of-type(5)': {
            display: 'none !important',
          },
          '& td': {
            lineHeight: '0.8',
          },
        }),
      }}
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: 'error',
              children: 'Error loading data',
            }
          : undefined
      }
      enableRowSelection
      enableSelectAll={false}
      enableMultiRowSelection={false}
      getRowId={originalRow => originalRow.id || ''}
      onRowSelectionChange={setRowSelection}
      state={{
        rowSelection,
        showAlertBanner: isError,
        showProgressBars: false,
      }} //pass our managed row selection state to the table to use
      positionToolbarAlertBanner={'none'}
      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={true}
      enableSorting={true}
      enableBottomToolbar={true}
      enableTopToolbar={true}
      enableToolbarInternalActions={false}
      muiTableBodyRowProps={{ hover: false }}
    />
  )
}

export { CoursesTable }
