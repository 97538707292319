import { useQuery, useQueryClient } from '@tanstack/react-query'
import { LoaderSpinner } from 'components/LoaderSpinner'
import { Suspense, useEffect, useState } from 'react'
import { useLocation, useNavigate, useRoutes } from 'react-router-dom'
import {
  getRefreshToken,
  getToken,
  useAuthTokenRefreshCreate,
} from 'utils/auth'
import { setOrganisationId } from 'utils/organisation'
import { useBranding } from 'utils/useBranding'
import AuthenticatedRoutes from './routes/AuthenticatedRoutes'
import UnauthenticatedRoutes from './routes/UnauthenticatedRoutes'
import * as ROUTE from './utils/constants/clientRoutes'

// ==============================|| ROUTING RENDER ||============================== //

const App = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { mutateAsync: authTokenRefreshCreate } = useAuthTokenRefreshCreate()
  const [isReady, setIsReady] = useState(false)
  const { brandingId, isLoading: isBrandingLoading } = useBranding()
  const { data: user } = useQuery(['user'])
  const accessToken = getToken()
  const refreshToken = getRefreshToken()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (user && (pathname === ROUTE.LOGIN || pathname === '/')) {
      navigate(ROUTE.DASHBOARD)
    }
    if (user === undefined && accessToken !== null) {
      queryClient.setQueryData(['user'], () => JSON.stringify(accessToken))
    }
    if (user === undefined && accessToken === null) {
      navigate(ROUTE.LOGIN)
    }
  }, [accessToken, navigate, pathname, queryClient, user])

  useEffect(() => {
    if (!isBrandingLoading) {
      setOrganisationId(brandingId!)
      setIsReady(true)
    }
  }, [isBrandingLoading, brandingId])

  const routing = useRoutes(
    user ? [AuthenticatedRoutes] : [UnauthenticatedRoutes],
  )

  useEffect(() => {
    if (accessToken === null && refreshToken !== null) {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ;(async () => {
        const data = {
          refreshToken: refreshToken,
        }
        await authTokenRefreshCreate(data)
      })()
    }
  }, [authTokenRefreshCreate, accessToken, refreshToken])

  return (
    <>
      {isReady ? (
        <Suspense fallback={<LoaderSpinner />}>{routing}</Suspense>
      ) : (
        <LoaderSpinner />
      )}
    </>
  )
}

export default App
