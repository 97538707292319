import { createContext, useState } from 'react'

type QuestionContextType = {
  list: string
  setList: (section: string) => void
  queType: string
  setQueType: (title: string) => void
  updateQuestionId: string
  setUpdateQuestionId: (title: string) => void
}

export const QuestionContext = createContext<QuestionContextType>({
  list: '',
  setList: () => {
    //initalized function
  },
  queType: '',
  setQueType: () => {
    //initalized function
  },
  updateQuestionId: '',
  setUpdateQuestionId: () => {
    //initalized function
  },
})

const QuestionProvider = ({ children }: { children: React.ReactNode }) => {
  const [list, setList] = useState<string>('')
  const [queType, setQueType] = useState<string>('')
  const [updateQuestionId, setUpdateQuestionId] = useState<string>('')

  return (
    <QuestionContext.Provider
      value={{
        list,
        setList,
        queType,
        setQueType,
        updateQuestionId,
        setUpdateQuestionId,
      }}
    >
      {children}
    </QuestionContext.Provider>
  )
}

export { QuestionProvider }
