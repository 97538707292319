import { AddSectionToModuleModel } from 'utils/module'
import { createContext, useState } from 'react'

type NavigateActionObj = {
  actionFunc: () => void
}

type NewSectionContextType = {
  newSection: AddSectionToModuleModel
  setNewSection: (slide: AddSectionToModuleModel) => void
  status: string | undefined
  setStatus: (section: string | undefined) => void
  title: string | undefined
  setTitle: (title: string | undefined) => void
  isNewSlideEdited: boolean
  setIsNewSlideEdited: (value: boolean) => void
  slideContentLossMessageVisible: boolean
  setSlideContentLossMessageVisible: (value: boolean) => void
  navigateAction: NavigateActionObj
  setNavigateAction: (value: NavigateActionObj) => void
}

export const NewSectionContext = createContext<NewSectionContextType>({
  newSection: {} as AddSectionToModuleModel,
  setNewSection: () => {
    throw new Error('Function called without NewSectionContext provider')
  },
  status: undefined,
  setStatus: () => {
    throw new Error('Function called without NewSectionContext provider')
  },
  title: undefined,
  setTitle: () => {
    throw new Error('Function called without NewSectionContext provider')
  },
  isNewSlideEdited: false,
  setIsNewSlideEdited: () => {
    throw new Error('Function called without NewSectionContext provider')
  },
  slideContentLossMessageVisible: false,
  setSlideContentLossMessageVisible: () => {
    throw new Error('Function called without NewSectionContext provider')
  },
  navigateAction: {
    actionFunc: () => {
      throw new Error('Function called without NewSectionContext provider')
    },
  },
  setNavigateAction: () => {
    throw new Error('Function called without NewSectionContext provider')
  },
})

const NewSectionProvider = ({ children }: { children: React.ReactNode }) => {
  const [newSection, setNewSection] = useState<AddSectionToModuleModel>(
    {} as AddSectionToModuleModel,
  )
  const [status, setStatus] = useState<string>()
  const [title, setTitle] = useState<string>()
  const [isNewSlideEdited, setIsNewSlideEdited] = useState(false)
  const [slideContentLossMessageVisible, setSlideContentLossMessageVisible] =
    useState(false)
  const [navigateAction, setNavigateAction] = useState<NavigateActionObj>({
    actionFunc: () => {
      // Function implementation
    },
  })

  return (
    <NewSectionContext.Provider
      value={{
        newSection,
        setNewSection,
        status,
        setStatus,
        title,
        setTitle,
        isNewSlideEdited,
        setIsNewSlideEdited,
        slideContentLossMessageVisible,
        setSlideContentLossMessageVisible,
        navigateAction,
        setNavigateAction,
      }}
    >
      {children}
    </NewSectionContext.Provider>
  )
}

export { NewSectionProvider }
