import TitleHelmet from 'components/TitleHelmet'
import { PageTitle } from 'utils/constants/pageTitles'

const Themes = () => {
  return (
    <>
      <TitleHelmet title={PageTitle.THEMES} />
      <h1>{PageTitle.THEMES}</h1>
    </>
  )
}
export default Themes
