import { useMemo } from 'react'
import 'styles/table.css'
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table'
import { useGetCourseInstancesByCourse } from 'utils/course'
import { CourseInstanceModel } from 'api/data-contracts'
import { darken } from '@mui/material'
import { FormatDate } from 'utils/FormatDate'
import { RowSelection } from 'utils/models/Resource'

interface CoursesInstancesTableProps {
  courseInstanceRowSelection: RowSelection
  setCourseInstanceRowSelection: React.Dispatch<
    React.SetStateAction<RowSelection>
  >
}

const CoursesInstancesTable = ({
  courseInstanceRowSelection: rowSelection,
  setCourseInstanceRowSelection: setRowSelection,
}: CoursesInstancesTableProps) => {
  const { courseInstances, isError } = useGetCourseInstancesByCourse()

  const columns = useMemo<MRT_ColumnDef<CourseInstanceModel>[]>(
    () => [
      {
        accessorKey: 'title',
        header: 'Title',
      },
      {
        accessorKey: 'startDate',
        header: 'Start date',
        Cell: ({ cell }) =>
          FormatDate(cell?.row?.original?.startDate as string),
      },
      {
        accessorKey: 'endDate',
        header: 'End date',
        Cell: ({ cell }) => FormatDate(cell?.row?.original?.endDate as string),
      },
      {
        accessorKey: 'instanceStatus',
        header: 'Instance status',
        Cell: ({ cell }) =>
          cell.getValue<string>().toLocaleString().toLocaleLowerCase(),
      },
      {
        accessorKey: 'numAttendees',
        header: 'Number of attendees',
      },
    ],
    [],
  )
  return (
    <MaterialReactTable
      columns={columns}
      data={courseInstances}
      initialState={{
        showGlobalFilter: true,
      }}
      enableGlobalFilterModes
      muiSearchTextFieldProps={{
        placeholder: `Search`,
        sx: { maxWidth: '200px' },
        variant: 'outlined',
      }}
      muiTablePaperProps={{
        elevation: 0,
        sx: {
          '& tr > th > div': {
            display: 'flex',
            fontSize: '18px',
          },
          '& tr > th > div > div > span': {
            display: 'none',
          },
          borderRadius: '0',
          border: 'none',
        },
      }}
      muiTableBodyProps={{
        sx: theme => ({
          '& tr:nth-of-type(odd)': {
            backgroundColor: darken(theme.palette.background.default, 0.1),
          },
        }),
      }}
      muiTableBodyCellProps={{
        sx: {
          lineHeight: '1.6', //add a border between columns
        },
      }}
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: 'error',
              children: 'Error loading data',
            }
          : undefined
      }
      enableRowSelection
      enableSelectAll={false}
      enableMultiRowSelection={false}
      getRowId={originalRow => originalRow.id || ''}
      onRowSelectionChange={setRowSelection}
      state={{
        rowSelection,
        showAlertBanner: isError,
        showProgressBars: false,
      }} //pass our managed row selection state to the table to use
      positionToolbarAlertBanner={'none'}
      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={true}
      enableSorting={true}
      enableBottomToolbar={true}
      enableTopToolbar={true}
      enableToolbarInternalActions={false}
      muiTableBodyRowProps={{ hover: false }}
    />
  )
}

export { CoursesInstancesTable }
