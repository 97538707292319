import { Header } from '../lib'
import { Drawer } from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'
import { UpdateTopicModel } from 'api/data-contracts'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useGetOrganisationID } from 'utils/organisation'
import { FormTextField } from 'components/FormTextField'
import { ActionButton } from 'components/ActionButton'
import { useBranding } from 'utils/useBranding'
import { toast } from 'react-toastify'
import { useUpdateTopic } from 'utils/topics'
import { RowSelection } from 'utils/models/Resource'

const validationSchema = Yup.object<UpdateTopicModel>().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
})

interface EditTopicPopoutProps {
  drawerVisibility: boolean
  drawerClose: () => void
  setTopicRowSelection: React.Dispatch<React.SetStateAction<RowSelection>>
  topicRowSelection: RowSelection
}

const EditTopicPopout = ({
  drawerVisibility,
  drawerClose,
  topicRowSelection: rowSelection,
  setTopicRowSelection: setRowSelection,
}: EditTopicPopoutProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UpdateTopicModel>({
    resolver: yupResolver(validationSchema),
  })
  const { branding } = useBranding()
  const { organisationId } = useGetOrganisationID()
  const { mutateAsync: editTopic } = useUpdateTopic()

  const onClickEditTopic: SubmitHandler<UpdateTopicModel> = data => {
    const topicId = Object.keys(rowSelection)[0]
    if (organisationId && topicId) {
      editTopic({
        topicId: topicId,
        data: {
          name: data.name!,
          description: data.description!,
        },
      }).then(() => {
        toast.success('Topic edited.')
      })
    }
    drawerClose()
  }
  return (
    <Drawer
      anchor={'right'}
      open={drawerVisibility}
      onClose={() => drawerClose()}
    >
      <div className="m-3rem mt-5rem">
        <Header variant="signin">Edit a topic</Header>
        <form>
          <FormTextField
            control={control}
            errors={errors?.name}
            name="name"
            label="Name"
            length={true}
          />
          <FormTextField
            control={control}
            errors={errors?.description}
            name="description"
            label="Description"
            length={true}
          />
          <ActionButton
            name="Edit"
            background={branding.stylingPrimary ?? 'blue'}
            setAction={handleSubmit(onClickEditTopic)}
          />
        </form>
      </div>
    </Drawer>
  )
}

export { EditTopicPopout }
