import UsersCourseTable from 'components/tables/UsersCourseTable'

interface CoursesProps {
  userId: string
}
const Courses = ({ userId }: CoursesProps) => {
  return <UsersCourseTable userId={userId} />
}

export default Courses
