import { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import Loadable from 'ui-component/Loadable'

import MainLayout from 'layout/MainLayout'
import CompanyDetails from 'pages/company/CompanyDetails'
import Security from 'pages/company/Security'
import Users from 'pages/settings/users/Users'
import Roles from 'pages/settings/Roles'
import Themes from 'pages/settings/Themes'
import MessageTemplates from 'pages/settings/MessageTemplates'
import MessageTemplateDetails from 'components/MessageTemplateDetails'
import Configuration from 'pages/courseSetup/Configuration'
import CourseInstances from 'pages/courseSetup/courseInstances/CourseInstances'
import ParticipantList from 'pages/Dashboard/ParticipantList'
import Access from 'pages/courseSetup/Access'
import MessageFlow from 'pages/courseSetup/MessageFlow'
import NewConfiguration from 'pages/courseSetup/NewConfiguration'
import Modules from 'pages/courseSetup/Modules'
import CourseQuestions from 'pages/courseSetup/Questions'
import Settings from 'pages/settings/Settings'
import Courses from 'pages/settings/Courses'
import CourseResources from 'pages/courseSetup/Resources'
import ModuleResources from 'pages/moduleSetup/Resources'
import Details from 'pages/moduleSetup/Details'
import Slides from 'pages/moduleSetup/Sections'
import NewDetails from 'pages/moduleSetup/NewDetails'
import PolicyDocuments from 'pages/company/PolicyDocuments'
import PolicyDetails from 'components/companySettings/PolicyDocuments/PolicyDetails'
import DeleteUsers from 'pages/settings/users/DeleteUsers'
import NewCourseResource from 'pages/courseSetup/NewResource'
import Resource from 'pages/courseSetup/Resource'
import PlatformSettings from 'pages/company/PlatformSettings'
import Participant from 'pages/Dashboard/Participant'
import ParticipantsModule from 'pages/Dashboard/ParticipantsModule'

// Constants
import * as ROUTE from '../utils/constants/clientRoutes'
import Topics from 'pages/settings/Topics'
import Payments from 'pages/company/Payments'

const Dashboard = Loadable(lazy(() => import('pages/Dashboard')))

const AuthenticatedApp = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: ROUTE.DASHBOARD,
      element: <Dashboard />,
    },
    {
      path: ROUTE.SETTINGS,
      element: <Settings />,
    },
    {
      path: ROUTE.COMPANY_DETAILS,
      element: <CompanyDetails />,
    },
    {
      path: ROUTE.PAYMENTS,
      element: <Payments />,
    },
    {
      path: ROUTE.SECURITY,
      element: <Security />,
    },
    {
      path: ROUTE.POLICY_DOCUMENTS,
      element: <PolicyDocuments />,
    },
    {
      path: ROUTE.PLATFORM_SETTINGS,
      element: <PlatformSettings />,
    },
    {
      path: ROUTE.POLICY_DOCUMENTS_CREATE,
      element: <PolicyDetails />,
    },
    {
      path: ROUTE.POLICY_DOCUMENTS_BY_POLICY_ID,
      element: <PolicyDetails />,
    },
    {
      path: ROUTE.ACTIVE_USERS,
      element: <Users />,
    },
    {
      path: ROUTE.DELETE_USERS,
      element: <DeleteUsers />,
    },
    {
      path: ROUTE.ROLES,
      element: <Roles />,
    },
    {
      path: ROUTE.THEMES,
      element: <Themes />,
    },
    {
      path: ROUTE.MESSAGE_TEMPLATES,
      element: <MessageTemplates />,
    },
    {
      path: ROUTE.TOPICS,
      element: <Topics />,
    },
    {
      path: ROUTE.MESSAGE_TEMPLATES_BY_MESSAGE_ID,
      element: <MessageTemplateDetails />,
    },
    {
      path: ROUTE.COURSES,
      element: <Courses />,
    },
    {
      path: ROUTE.COURSE_CONFIGURATION,
      element: <Configuration />,
    },
    {
      path: ROUTE.COURSES_INSTANCES,
      element: <CourseInstances />,
    },
    {
      path: ROUTE.DASHBOARD_COURSE_INSTANCE,
      element: <ParticipantList />,
    },
    {
      path: ROUTE.PARTICIPANT,
      element: <Participant />,
    },
    {
      path: ROUTE.PARTICIPANTS_MODULE,
      element: <ParticipantsModule />,
    },
    {
      path: ROUTE.COURSE_ACCESS,
      element: <Access />,
    },
    {
      path: ROUTE.NEW_COURSE,
      element: <NewConfiguration />,
    },
    {
      path: ROUTE.COURSE_MODULES,
      element: <Modules />,
    },
    {
      path: ROUTE.COURSE_QUESTION,
      element: <CourseQuestions />,
    },
    {
      path: ROUTE.COURSE_MESSAGE_FLOW,
      element: <MessageFlow />,
    },
    {
      path: ROUTE.COURSE_RESOURCES,
      element: <CourseResources />,
    },
    {
      path: ROUTE.CREATE_RESOURCE_DETAILS,
      element: <NewCourseResource />,
    },
    {
      path: ROUTE.EDIT_RESOURCE_DETAILS,
      element: <Resource />,
    },
    {
      path: ROUTE.MODULE_DETAILS,
      element: <Details />,
    },
    {
      path: ROUTE.NEW_MODULE_DETAILS,
      element: <NewDetails />,
    },
    {
      path: ROUTE.MODULE_RESOURCES,
      element: <ModuleResources />,
    },
    {
      path: ROUTE.MODULE_SLIDES,
      element: <Slides />,
    },
    {
      path: ROUTE.PAGE_NOT_FOUND,
      element: <Navigate to={ROUTE.DASHBOARD} />,
    },
  ],
}

export default AuthenticatedApp
