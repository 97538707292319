import { toast } from 'react-toastify'
import { Drawer } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useBranding } from 'utils/useBranding'
import { ActionButton } from 'components/ActionButton'
import { TypeAheadInput } from 'components/TypeAheadInput'
import { useGetOrganisationUsers } from 'utils/organisation'
import { Option } from 'react-bootstrap-typeahead/types/types'
import { useTypeaheadContext } from 'context/TypeAheadContext'
import {
  useAddExistingUserToCourse,
  useGetCourseInstanceById,
  useGetParticipantsByCourseInstanceId,
} from 'utils/courseInstance'
import { DataOption } from 'utils/types'
import AnchorLink from 'components/AnchorLink'
import { useEffect, useState } from 'react'
import { InviteUserForm } from './InviteUserForm'
import UserInviteConfirmation from 'components/UserInviteConfirmation'
import { CourseInstanceModel } from 'api/data-contracts'

interface AddParticipantToCourseInstancePopoutProps {
  drawerVisibility: boolean
  drawerClose: () => void
}

const AddParticipantToCourseInstancePopout = ({
  drawerVisibility,
  drawerClose,
}: AddParticipantToCourseInstancePopoutProps) => {
  const { branding } = useBranding()
  const { courseInstanceId } = useParams()
  const { mutateAsync: addExistingUserToCourse } = useAddExistingUserToCourse()
  const { users } = useGetOrganisationUsers()
  const { participants } = useGetParticipantsByCourseInstanceId(
    courseInstanceId!,
  )
  const { selectedOptions, setSelectedOptions } = useTypeaheadContext()

  const [addUserFormVisibility, setAddUserFormVisibility] = useState(false)
  const [optional1Val, setOptional1Val] = useState('')
  const [optional2Val, setOptional2Val] = useState('')
  const [userInviteConfirmationVisible, setUserInviteConfirmationVisible] =
    useState(false)

  const onExistAddUserForm = () => {
    setAddUserFormVisibility(false)
  }
  const { mutateAsync: getCourseInstanceById } = useGetCourseInstanceById()
  const [courseInstanceData, setCourseInstanceData] =
    useState<CourseInstanceModel>({})

  useEffect(() => {
    const getIndividualCourseInstanceHandler = async () => {
      const { data } = await getCourseInstanceById({
        courseInstanceId: courseInstanceId!,
      })
      setCourseInstanceData(data)
    }
    if (drawerVisibility === true) {
      getIndividualCourseInstanceHandler()
      setOptional1Val('')
      setOptional2Val('')
    }
  }, [courseInstanceId, getCourseInstanceById, drawerVisibility])

  const userLabels: Option[] = users
    ? users
        .filter(
          user =>
            !participants.find(
              participant => participant?.user?.id === user?.id,
            ),
        )
        .map(user => ({
          id: user.id,
          email: user.email,
          firstName: user.firstname,
          lastName: user.lastname,
        }))
    : []

  const onClickAddExistingUserToCourse = async () => {
    if (
      courseInstanceData?.useOptional1 === true &&
      (optional1Val === '' || optional1Val === undefined)
    ) {
      toast.error(`${courseInstanceData?.optional1} is required`)
      setUserInviteConfirmationVisible(true)
      return <></>
    }

    if (
      courseInstanceData?.useOptional2 === true &&
      (optional2Val === '' || optional2Val === undefined)
    ) {
      toast.error(`${courseInstanceData?.optional2} is required`)
      setUserInviteConfirmationVisible(true)
      return <></>
    }

    if (courseInstanceId) {
      await Promise.all(
        selectedOptions.map(option => {
          const dataOption = option as DataOption
          return addExistingUserToCourse({
            courseInstanceId,
            user: {
              userId: dataOption.id as string,
              Optional1: optional1Val,
              Optional2: optional2Val,
            },
          })
            .then(() => {
              toast.success('User(s) added.')
            })
            .catch(() => {
              toast.error('Unable to add user')
            })
        }),
      )
    }
    await drawerClose()
    setSelectedOptions([])
    setOptional1Val('')
    setOptional2Val('')
    setUserInviteConfirmationVisible(false)
  }

  const addUserHandler = () => {
    if (selectedOptions.length === 0) {
      toast.error(`Select at least one user`)
      return <></>
    }

    if (
      courseInstanceData.useOptional1 === true ||
      courseInstanceData.useOptional2 === true
    ) {
      setUserInviteConfirmationVisible(true)
    } else {
      onClickAddExistingUserToCourse()
    }
  }

  return (
    <Drawer
      anchor={'right'}
      open={drawerVisibility}
      onClose={() => drawerClose()}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingTop: 15,
          marginTop: '5rem',
          margin: '5rem',
        }}
      >
        <h2>Invite users</h2>
        {!addUserFormVisibility ? (
          <div>
            <TypeAheadInput options={userLabels!} />
            <div className="flex mt-15">
              <ActionButton
                name="Add"
                background={branding.stylingPrimary ?? 'blue'}
                setAction={addUserHandler}
              />
              <ActionButton
                name="Close"
                background={branding.stylingSecondary ?? 'blue'}
                setAction={drawerClose}
              />
              <ActionButton
                name="Add a user"
                background={branding.stylingSecondary ?? 'blue'}
                setAction={() => setAddUserFormVisibility(true)}
              />
            </div>
          </div>
        ) : (
          <div>
            <AnchorLink style={{ padding: 0 }} onClick={onExistAddUserForm}>
              &lt; Back
            </AnchorLink>
            <InviteUserForm courseInstanceData={courseInstanceData} />
          </div>
        )}

        {userInviteConfirmationVisible && (
          <UserInviteConfirmation
            courseInstanceData={courseInstanceData}
            optional1Val={optional1Val}
            setOptional1Val={setOptional1Val}
            optional2Val={optional2Val}
            setOptional2Val={setOptional2Val}
            onCancel={() => setUserInviteConfirmationVisible(false)}
            onAddUser={() => onClickAddExistingUserToCourse()}
          />
        )}
      </div>
    </Drawer>
  )
}

export { AddParticipantToCourseInstancePopout }
