/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  NotFoundExceptionModel,
  TopicModel,
  UnauthorizedExceptionModel,
  UpdateTopicModel,
  ValidationExceptionModel,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Topic<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Topic
   * @name UpdateTopic
   * @request PUT:/api/Topic/{topicId}
   * @secure
   */
  updateTopic = (
    topicId: string,
    data: UpdateTopicModel,
    params: RequestParams = {},
  ) =>
    this.request<TopicModel, ValidationExceptionModel | NotFoundExceptionModel>(
      {
        path: `/api/Topic/${topicId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      },
    )
  /**
   * No description
   *
   * @tags Topic
   * @name DeleteTopic
   * @request DELETE:/api/Topic/{topicId}
   * @secure
   */
  deleteTopic = (topicId: string, params: RequestParams = {}) =>
    this.request<void, UnauthorizedExceptionModel | NotFoundExceptionModel>({
      path: `/api/Topic/${topicId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    })
}
