import { useContext, useState } from 'react'
import { Box, TextField } from '@mui/material'
import { toast } from 'react-toastify'

import { useAddNewSectionToModule } from 'utils/module'
import { useAddQuestionToSection } from 'utils/section'
import { CurrentSectionContext } from 'components/moduleSlides/CurrentSectionContext'
import { NewSectionContext } from 'components/moduleSlides/NewSectionContext'
import ModuleSectionButton from 'components/moduleSlides/ModuleSectionButton'
import { KeyValueStringObject, emptyObjectKey } from 'utils/emptyObjectKey'
import AddQuestionsForCreateMiniQuiz from './AddQuestionsForCreateMiniQuiz'

import { QuestionModel } from 'api/data-contracts'
import { LockdownCourseContext } from 'components/moduleSlides/LockdownCourseContext'

const AddMiniQuizSection = () => {
  const { setCurrentSection } = useContext(CurrentSectionContext)

  const {
    setStatus,
    newSection,
    setNewSection,
    title,
    setTitle,
    setIsNewSlideEdited,
  } = useContext(NewSectionContext)
  const { mutateAsync: addNewSectionToModule } = useAddNewSectionToModule()
  const { mutateAsync: addQuestionToSection } = useAddQuestionToSection()
  const { isCurrentCourseLocked } = useContext(LockdownCourseContext)

  const [introduction, setIntroduction] = useState('')
  const [numberOfQuestionsToShow, setNumberOfQuestionsToShow] = useState('')

  const [addMiniQuizQuestions, setAddMiniQuizQuestions] = useState<
    QuestionModel[]
  >([])

  const contentArea1: KeyValueStringObject = {
    contentArea1: introduction,
  }

  const filteredData = emptyObjectKey(contentArea1)

  const data = {
    ...newSection,
    ...filteredData,
    Name: title?.length ? title : 'New Section',
    MiniQuizNumQuestionsToShow: +numberOfQuestionsToShow as number,
  }

  const onClickAddNewSectionHandler = async () => {
    try {
      const res = await addNewSectionToModule({
        data,
      })
      for (let i = 0; i < addMiniQuizQuestions.length; i++) {
        await addQuestionToSection({
          sectionId: res?.data?.id as string,
          questionId: addMiniQuizQuestions[i].id as string,
        })
      }

      setNewSection({})
      setStatus(undefined)
      setCurrentSection(undefined)
      setTitle(undefined)
      setIsNewSlideEdited(false)
      toast.success('Slide added')
    } catch {
      toast.error('Unable to add slide')
    }
  }

  return (
    <Box
      sx={{
        padding: '25px',
        bgcolor: 'rgba(255,255,255,0.6)',
        borderRadius: 5,
        width: 'calc(100% - 40px)',
        maxHeight: 'calc(100% - 40px)',
        margin: '20px 0',
        overflow: 'auto',
      }}
    >
      <div className="w-full mt-10 mb-30">
        <p className="text-label">Introduction</p>
        <TextField
          multiline
          rows={3}
          value={introduction}
          autoComplete="off"
          placeholder="Introduction"
          fullWidth
          sx={{
            marginBottom: '20px',
            background: '#fff',
            borderRadius: 2,
            '& .MuiOutlinedInput-root': {
              '& > fieldset': {
                border: 'none',
              },
            },
          }}
          onChange={e => {
            setIntroduction(e.target.value)
            setIsNewSlideEdited(true)
          }}
        />

        <p className="text-label">Questions to show</p>
        <TextField
          variant="outlined"
          autoComplete="off"
          placeholder="Questions to show"
          fullWidth
          type="number"
          sx={{
            background: '#fff',
            borderRadius: 2,
            marginBottom: '20px',
            '& .MuiOutlinedInput-root': {
              '& > fieldset': {
                border: 'none',
              },
            },
          }}
          onChange={e => {
            setNumberOfQuestionsToShow(e.target.value)
            setIsNewSlideEdited(true)
          }}
        />
      </div>

      <AddQuestionsForCreateMiniQuiz
        addMiniQuizQuestions={addMiniQuizQuestions}
        setAddMiniQuizQuestions={setAddMiniQuizQuestions}
      />

      <ModuleSectionButton
        onClickIconHandler={onClickAddNewSectionHandler}
        name="Save"
        className={`${
          isCurrentCourseLocked && 'pointer-event-none opacity-50'
        }`}
      />
    </Box>
  )
}

export default AddMiniQuizSection
