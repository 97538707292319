import { useMemo, useState } from 'react'
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table'
import { CourseModel } from 'api/data-contracts'
import { darken } from '@mui/material'
import { useNavigate } from 'react-router-dom'

// API
import { useGetMessageTemplates } from 'utils/organisation'
import AnchorLink from 'components/AnchorLink'

const MessageTemplateTable = () => {
  const { messages, isError } = useGetMessageTemplates()

  const navigate = useNavigate()
  const [rowSelection, setRowSelection] = useState({})

  const columns = useMemo<MRT_ColumnDef<CourseModel>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        Cell: ({ cell }) => (
          <AnchorLink
            className="p-0"
            onClick={() =>
              navigate(
                `/settings/message-templates/${cell?.row?.original?.id}/edit`,
                { state: cell?.row?.original },
              )
            }
          >
            {cell?.row?.original?.name}
          </AnchorLink>
        ),
      },
      {
        accessorKey: 'description',
        header: 'Description',
      },
      {
        accessorKey: 'sysName',
        header: 'System name',
      },
    ],
    [navigate],
  )

  return (
    <MaterialReactTable
      columns={columns}
      data={messages}
      initialState={{
        showGlobalFilter: true,
      }}
      enableGlobalFilterModes
      muiSearchTextFieldProps={{
        placeholder: `Search`,
        sx: { maxWidth: '200px' },
        variant: 'outlined',
      }}
      muiTablePaperProps={{
        elevation: 0,
        sx: {
          '& tr > th > div': {
            display: 'flex',
            fontSize: '18px',
          },
          '& tr > th > div > div > span': {
            display: 'none',
          },
          borderRadius: '0',
          border: 'none',
        },
      }}
      muiTableBodyProps={{
        sx: theme => ({
          '& th > div': {
            display: 'flex',
          },
          '& tr:nth-of-type(odd)': {
            backgroundColor: darken(theme.palette.background.default, 0.1),
          },
        }),
      }}
      muiTableBodyCellProps={{
        sx: {
          lineHeight: '1.6',
        },
      }}
      enableSelectAll={false}
      enableMultiRowSelection={false}
      getRowId={originalRow => originalRow.id || ''}
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: 'error',
              children: 'Error loading data',
            }
          : undefined
      }
      onRowSelectionChange={setRowSelection}
      state={{
        rowSelection,
        showProgressBars: false,
      }}
      positionToolbarAlertBanner={'top'}
      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={true}
      enableSorting={true}
      enableBottomToolbar={true}
      enableTopToolbar={true}
      enableToolbarInternalActions={false}
      muiTableBodyRowProps={{ hover: false }}
    />
  )
}

export { MessageTemplateTable }
