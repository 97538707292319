import { PageTitle } from 'utils/constants/pageTitles'
import styles from './Users.module.css'
import TitleHelmet from 'components/TitleHelmet'

const DeleteUsers = () => {
  return (
    <>
      <TitleHelmet title={PageTitle.DELETE_USERS} />
      <h1 className={styles.heading}>{PageTitle.DELETE_USERS}</h1>
    </>
  )
}

export default DeleteUsers
