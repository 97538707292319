import { RadioGroup, FormControlLabel, Radio, Button } from '@mui/material'
import { AnswerDataModel } from 'utils/question'

import DeleteIcon from '@mui/icons-material/Delete'

interface SingleSelectProps {
  answerData: AnswerDataModel[]
  onClickDeleteAnswerHandler: (value: number, id: string) => void
  setAnswerData: (value: React.SetStateAction<AnswerDataModel[]>) => void
}

const SingleSelect = ({
  answerData,
  onClickDeleteAnswerHandler,
  setAnswerData,
}: SingleSelectProps) => {
  return (
    <>
      <RadioGroup>
        {answerData && answerData.length
          ? answerData.map((key, index) => (
              <div className="questionCheckedboxWrapper" key={index}>
                <FormControlLabel
                  value={key.answerText}
                  control={<Radio checked={key.isCorrect === true} />}
                  label={key.answerText}
                  labelPlacement="start"
                  onClick={() => {
                    setAnswerData(prevTabs =>
                      prevTabs.map(prevTab =>
                        prevTab.answerText === key.answerText
                          ? { ...prevTab, isCorrect: true }
                          : { ...prevTab, isCorrect: false },
                      ),
                    )
                  }}
                />
                <Button
                  sx={{ background: '#fff' }}
                  onClick={() =>
                    onClickDeleteAnswerHandler(index, key.id as string)
                  }
                >
                  <DeleteIcon />
                </Button>
              </div>
            ))
          : ''}
      </RadioGroup>
    </>
  )
}

export default SingleSelect
