import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useApiClient } from './use-clients'
import { UpdateTopicModel } from 'api/data-contracts'

// PUT: /api/Organisation/{organisationId}/settings
interface useUpdateTopicProps {
  topicId: string
  data: UpdateTopicModel
}
const useUpdateTopic = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: async (props: useUpdateTopicProps) => {
      await client.topic.updateTopic(props.topicId, props.data)
    },
    onSuccess: () => {
      queryClient.refetchQueries(['topics'])
    },
  })
  return mutation
}

// DELETE:/api/Course/{courseId}
const useDeleteTopic = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: async (topicId: string) => {
      await client.topic.deleteTopic(topicId)
    },
    onSuccess: () => {
      queryClient.refetchQueries(['topics'])
    },
  })
  return mutation
}

export { useUpdateTopic, useDeleteTopic }
