import { ActionButton } from 'components/ActionButton'
import OverviewPanel from 'components/OverviewPanel'
import TitleHelmet from 'components/TitleHelmet'
import { AddParticipantToCourseInstancePopout } from 'components/courseInstance/AddParticipantToCoursePopout'
import CourseInstancePopout from 'components/dashboard/CourseInstancePopout'
import { ParticipantsTable } from 'components/tables/courseInstance/ParticipantsTable'
import { TypeaheadContextProvider } from 'context/TypeAheadContext'
import { useState } from 'react'
import { PageTitle } from 'utils/constants/pageTitles'
import { useDeleteParticipant, useGetStats } from 'utils/courseInstance'
import { effectSelectedMuiTableRows } from 'utils/materialTable/effectRow'
import { RowSelection } from 'utils/models/Resource'
import { useBranding } from 'utils/useBranding'

const ParticipantList = () => {
  const { branding } = useBranding()
  const { mutateAsync: deleteParticipant } = useDeleteParticipant()

  const { courseInstanceStatData } = useGetStats()

  const [
    editCourseInstanceDrawerVisibility,
    setEditCourseInstanceDrawerVisibility,
  ] = useState(false)

  const onClickEditCourseInstanceDrawerClose = () => {
    setEditCourseInstanceDrawerVisibility(false)
  }

  const [
    addParticipantToCourseInstanceDrawerVisibility,
    setAddParticipantToCourseInstanceDrawerVisibility,
  ] = useState(false)

  const onAddParticipantToCourseInstanceDrawerClose = () => {
    setAddParticipantToCourseInstanceDrawerVisibility(false)
  }

  const [participantRowSelection, setParticipantRowSelection] =
    useState<RowSelection>({})

  const onDeleteParticipant = () => {
    effectSelectedMuiTableRows(
      participantRowSelection,
      setParticipantRowSelection,
      deleteParticipant,
      'User removed from course!',
      'Error deleting user - make sure the user is not in use',
    )
  }

  return (
    <>
      <TitleHelmet title={PageTitle.COURSE_SETUP_COURSE_INSTANCE} />
      <h1>{PageTitle.COURSE_SETUP_COURSE_INSTANCE}</h1>

      <OverviewPanel statsData={courseInstanceStatData} />
      <h2 className="pt-10">{PageTitle.MEMBERS}</h2>

      <ActionButton
        name="Invite a user"
        background={branding.stylingPrimary ?? 'blue'}
        setAction={() =>
          setAddParticipantToCourseInstanceDrawerVisibility(true)
        }
      />
      <ActionButton
        name="Edit course instance"
        background={branding.stylingPrimary ?? 'blue'}
        setAction={() => setEditCourseInstanceDrawerVisibility(true)}
      />

      <CourseInstancePopout
        drawerVisibility={editCourseInstanceDrawerVisibility}
        drawerClose={onClickEditCourseInstanceDrawerClose}
        id="EDIT"
      />

      {Object.keys(participantRowSelection).length > 0 && (
        <ActionButton
          name="Remove Participant"
          background={branding.stylingSecondary ?? 'orange'}
          setAction={onDeleteParticipant}
        />
      )}
      <ParticipantsTable
        participantRowSelection={participantRowSelection!}
        setParticipantRowSelection={setParticipantRowSelection!}
      />
      <TypeaheadContextProvider>
        <AddParticipantToCourseInstancePopout
          drawerVisibility={addParticipantToCourseInstanceDrawerVisibility}
          drawerClose={onAddParticipantToCourseInstanceDrawerClose}
        />
      </TypeaheadContextProvider>
    </>
  )
}

export default ParticipantList
