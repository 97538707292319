import { OrganisationSettingModel } from 'api/data-contracts'
import * as Yup from 'yup'

export const ValidationSchema = Yup.object<OrganisationSettingModel>().shape({
  bannerTitle: Yup.string().nullable(),
  bannerSubTitle: Yup.string().nullable(),
  bannerImage: Yup.string().nullable(),
  bannerCallToActionText: Yup.string().nullable(),
  bannerCallToActionUrl: Yup.string().nullable(),
  introTitle: Yup.string().nullable(),
  introText: Yup.string().nullable(),
  howItWorksTitle: Yup.string().nullable(),
  howItWorks: Yup.string().nullable(),
  howItWorksTitle2: Yup.string().nullable(),
  howItWorks2: Yup.string().nullable(),
  howItWorksTitle3: Yup.string().nullable(),
  howItWorks3: Yup.string().nullable(),
  howItWorksTitle4: Yup.string().nullable(),
  howItWorks4: Yup.string().nullable(),
  moreInfoTitle: Yup.string().nullable(),
  moreInfoDescription: Yup.string().nullable(),
  moreInfoCallToActionText: Yup.string().nullable(),
  moreInfoCallToActionUrl: Yup.string().nullable(),
  focusTag: Yup.string().nullable(),
  companyInfoTitle: Yup.string().nullable(),
  companyInfo: Yup.string().nullable(),
  name: Yup.string().nullable(),
  description: Yup.string().nullable(),
  companyLogo: Yup.string().nullable(),
  emailLogo: Yup.string().nullable(),
  backgroundImage: Yup.string().nullable(),
  siteBaseUrl: Yup.string().nullable(),
  stylingPrimary: Yup.string().nullable(),
  stylingSecondary: Yup.string().nullable(),
  stylingInfo: Yup.string().nullable(),
})
