import { useContext } from 'react'
import { Grid, Divider, Typography, Breadcrumbs } from '@mui/material'
import { useNavigate, useParams, useLocation } from 'react-router-dom'

import { PageTitle } from 'utils/constants/pageTitles'
import * as ROUTE from '../../../utils/constants/clientRoutes'
import WarningAlert from 'components/WarningAlert'
import { NewSectionContext } from 'components/moduleSlides/NewSectionContext'

interface MiddleDividersProps {
  title: string
}
const CapitalizeFirstLetter = (string: string) => {
  return string[0].toUpperCase() + string.slice(1).toLowerCase()
}
export default function MiddleDividers({ title }: MiddleDividersProps) {
  const location = useLocation()
  const navigate = useNavigate()
  const { courseId } = useParams()
  const {
    isNewSlideEdited,
    setSlideContentLossMessageVisible,
    setNavigateAction,
  } = useContext(NewSectionContext)

  const pathnames = location.pathname.split('/').filter(x => x)

  const onClickBreadcrumbRedirect = (value: string) => {
    value === PageTitle.SETTINGS && navigate(ROUTE.SETTINGS)
    value === PageTitle.COURSES && navigate(ROUTE.COURSES)
    value === PageTitle.COURSE_SETUP && navigate(ROUTE.COURSES)
    value === PageTitle.COURSE_SETUP_COURSE_INSTANCES &&
      navigate(`/settings/course-setup/course-instances/${courseId}`)
    value === PageTitle.COURSE_SETUP_RESOURCES &&
      navigate(`/settings/course-setup/resources/${courseId}`)
    value === PageTitle.COURSE_SETUP_MODULES &&
      navigate(`/settings/course-setup/modules/${courseId}`)
    value === PageTitle.MODULE_SETUP &&
      navigate(`/settings/course-setup/modules/${courseId}`)
    value === PageTitle.MESSAGE_TEMPLATES && navigate(ROUTE.MESSAGE_TEMPLATES)
    value === PageTitle.COMPANY_SETTING_POLICY_DOCUMENTS &&
      navigate(ROUTE.POLICY_DOCUMENTS)
  }

  return (
    <div className="w-full">
      <div className="mt-20 mb-20">
        <Typography gutterBottom component="div">
          <Breadcrumbs aria-label="breadcrumb">
            {pathnames
              .filter(pathname => !/\d/.test(pathname))
              .map((value, index) => {
                const last = index === pathnames.length - 1
                const to = `/${pathnames.slice(0, index + 1).join('/')}`

                const modifiedValue = value.replace(/-/g, ' ')

                return last ? (
                  <span key={to} color="text.primary">
                    {CapitalizeFirstLetter(modifiedValue)}
                  </span>
                ) : (
                  <span
                    key={to}
                    color="inherit"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (isNewSlideEdited) {
                        setSlideContentLossMessageVisible(true)
                        setNavigateAction({
                          actionFunc: () => {
                            onClickBreadcrumbRedirect(
                              CapitalizeFirstLetter(modifiedValue),
                            )
                          },
                        })
                      } else {
                        onClickBreadcrumbRedirect(
                          CapitalizeFirstLetter(modifiedValue),
                        )
                      }
                    }}
                  >
                    {CapitalizeFirstLetter(modifiedValue)}
                  </span>
                )
              })}
          </Breadcrumbs>
        </Typography>
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography gutterBottom variant="h4" component="div">
              {title}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        {location.pathname.includes('module-setup/slides') && (
          <WarningAlert title="Course is in progress cannot be modified" />
        )}
        {location.pathname.includes('course-setup/modules') && (
          <WarningAlert title="Course is in progress, modules cannot be added and deleted" />
        )}
      </div>
    </div>
  )
}
