import { toast } from 'react-toastify'
import { Drawer } from '@mui/material'
import { useParams } from 'react-router-dom'
import { RowSelection } from 'utils/models/Resource'
import { useBranding } from 'utils/useBranding'
import { ModuleCourseResourcesTable } from 'components/tables/module/ModuleCourseResourcesTable'
import { useAddResourceToModule } from 'utils/module'
import { ActionButton } from 'components/ActionButton'

interface AddResourceToModulePopoutProps {
  setModuleCourseRowSelection: React.Dispatch<
    React.SetStateAction<RowSelection>
  >
  moduleCourseRowSelection: RowSelection
  drawerVisibility: boolean
  drawerClose: () => void
}

const AddResourceToModulePopout = ({
  setModuleCourseRowSelection,
  moduleCourseRowSelection,
  drawerVisibility,
  drawerClose,
}: AddResourceToModulePopoutProps) => {
  const { branding } = useBranding()
  const { moduleId } = useParams()
  const { mutateAsync: addResourceToModule } = useAddResourceToModule()

  const onClickAddResourceToModule = async () => {
    moduleId &&
      (await Promise.all(
        Object.keys(moduleCourseRowSelection)
          .filter(key => moduleCourseRowSelection[key])
          .map(async rowId => {
            try {
              await addResourceToModule({ moduleId, resourceId: rowId })
              toast.success('Resource added!')
            } catch (error) {
              toast.error('Error adding resource to module')
            }
          }),
      ))
    await drawerClose()
    setModuleCourseRowSelection({})
  }

  return (
    <Drawer
      anchor={'right'}
      open={drawerVisibility}
      onClose={() => drawerClose()}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 15,
        padding: '1000px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingTop: 15,
          marginTop: '5rem',
          margin: '5rem',
        }}
      >
        <h2>Add Resources</h2>

        <ModuleCourseResourcesTable
          moduleCourseRowSelection={moduleCourseRowSelection!}
          setModuleCourseRowSelection={setModuleCourseRowSelection!}
        />

        <div className="flex mt-15">
          <ActionButton
            name="Add"
            background={branding.stylingPrimary ?? 'blue'}
            setAction={onClickAddResourceToModule}
          />
          <ActionButton
            name="Close"
            background={branding.stylingSecondary ?? 'blue'}
            setAction={drawerClose}
          />
        </div>
      </div>
    </Drawer>
  )
}

export { AddResourceToModulePopout }
