import { ParticipantDetailsTable } from 'components/tables/courseInstance/ParticipantDetailsTable'
import { PageTitle } from 'utils/constants/pageTitles'
import TitleHelmet from 'components/TitleHelmet'
import { useGetUserCourseInstanceById } from 'utils/userCourseInstance'
import { useParams } from 'react-router'

const Participant = () => {
  const { participantId } = useParams()
  const { userCourseInstance } = useGetUserCourseInstanceById(participantId!)

  const firstname = userCourseInstance?.user?.firstname
  const lastname = userCourseInstance?.user?.lastname
  const fullname = `${firstname !== undefined ? firstname : ''} ${
    lastname !== undefined ? lastname : ''
  }`

  return (
    <>
      <TitleHelmet
        title={PageTitle.COURSE_SETUP_COURSE_INSTANCE_PARTICIPANT_DETAILS}
      />
      <h1>{fullname}</h1>
      <ParticipantDetailsTable />
    </>
  )
}

export default Participant
