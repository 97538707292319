import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query'

import { UpdateResourceModel } from 'api/data-contracts'
import { useApiClient } from './use-clients'

// PUT:/api/Resource/{resourceId}
interface useUpdateResourceProps {
  resourceId: string
  data: UpdateResourceModel
}
const useUpdateResource = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (props: useUpdateResourceProps) =>
      client.resource.updateResource(props.resourceId, props.data),
    onSuccess: data => {
      queryClient.setQueryData(['resources'], data)
      queryClient.refetchQueries(['resources'])
    },
  })
  return mutation
}

// PUT:/api/Resource/{resourceId}/file
interface useUpdateResourceFileProps {
  resourceId: string
  data: {
    File?: File
  }
}
const useUpdateResourceFile = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (props: useUpdateResourceFileProps) =>
      client.resource.updateResourceFile(props.resourceId, props.data),
    onSuccess: data => {
      queryClient.setQueryData(['resources'], data)
      queryClient.refetchQueries(['resources'])
    },
  })
  return mutation
}

// DELETE:/api/Resource/{resourceId}
const useDeleteResource = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: async (resourceId: string) => {
      await client.resource.deleteResource(resourceId)
    },
    onSuccess: async () => {
      await queryClient.refetchQueries(['resources'])
    },
  })
  return mutation
}

// GET:/api/Resource/{resourceId}
// GET:/api/Resource/{resourceId}/file
const useGetResource = (resourceId: string) => {
  const client = useApiClient()

  const resourceQuery = useQuery({
    queryKey: ['resource'],
    queryFn: () => client.resource.getResource(resourceId),
    enabled: !!resourceId,
  })

  const fileQuery = useQuery({
    queryKey: ['resourceFile'],
    queryFn: () => client.resource.getResourceFile(resourceId),
    enabled: !!resourceId && !!resourceQuery.data?.data?.filePath, // Only enable if resourceId and filepath are truthy
  })

  return {
    resource: resourceQuery.data?.data,
    resourceFile: fileQuery.data?.data,
    isLoading: resourceQuery.isLoading || fileQuery.isLoading,
    isError: resourceQuery.isError || fileQuery.isError,
  }
}

export {
  useUpdateResource,
  useUpdateResourceFile,
  useDeleteResource,
  useGetResource,
}
