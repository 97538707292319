import { useState, useContext, Fragment, useCallback } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import { editorConfiguration } from 'utils/ckeditor/EditorConfiguration'
import { toast } from 'react-toastify'

import ContentImage from './ContentImage'
import ChooseContentSection from './ChooseContentSection'
import ChooseContentType from './ChooseContentType'

// Model
import { ContentItemModel } from 'api/data-contracts'
import { ContentSectionModel, SectionModel } from 'utils/models/Slide'

// Contexts
import { CurrentSectionContext } from 'components/moduleSlides/CurrentSectionContext'
import { NewContentSectionContext } from 'components/moduleSlides/NewContentSectionContext'

// API - custom hooks
import { useDeleteContentItem, useUpdateContentItem } from 'utils/contentItem'
import {
  useDeleteContentSectionFromSection,
  useGetSectionById,
} from 'utils/section'

// Constants
import {
  CONTENT,
  EMBED,
  IMAGE,
  THIRTYTHREE_SIXTYSIX,
  FIFTY_FIFTY,
  SIXTYSIX_THIRTYTHREE,
} from 'utils/constants/sections'

// Images
import AddIcon from '../../../../assets/icon/add-plus-icon.svg'
import DeleteIcon from '../../../../assets/icon/Delete.svg'
import DustbinIcon from '../../../../assets/icon/black-dustbin.svg'
import { useUpdateContentSection } from 'utils/contentSection'

const Content = () => {
  const { currentSection, setCurrentSection } = useContext(
    CurrentSectionContext,
  )

  const { mutateAsync: updateContentItem } = useUpdateContentItem()
  const { mutateAsync: deleteContentItem } = useDeleteContentItem()
  const { mutateAsync: getSectionById } = useGetSectionById()
  const { mutateAsync: deleteContentSectionFromSection } =
    useDeleteContentSectionFromSection()
  const { mutateAsync: updateContentSection } = useUpdateContentSection()

  const {
    isContentSectionSelectionVisible,
    setIsContentSectionSelectionVisible,
    isContentItemTypeSelectionVisible,
    setIsContentItemTypeSelectionVisible,
  } = useContext(NewContentSectionContext)

  const [contentSectionId, setContentSectionId] = useState('')
  const [contentItemId, setContentItemId] = useState('')

  const itemsClassName = (value: ContentSectionModel) => {
    return `slide-add-content mb-30 ${
      value.numberOfCols === 1
        ? 'w-full'
        : value.numberOfCols === 2
        ? `${
            value.colWeight === THIRTYTHREE_SIXTYSIX
              ? 'width-full-33-15px'
              : value.colWeight === SIXTYSIX_THIRTYTHREE
              ? 'width-full-66-15px'
              : 'width-full-50-20px'
          }`
        : value.numberOfCols === 3
        ? 'width-full-33-23px'
        : ''
    }`
  }

  const deleteContentItemFunctionality = async (contentItemId: string) => {
    try {
      await deleteContentItem({ contentItemId: contentItemId! })
      const getSectionByIdData = await getSectionById({
        sectionId: currentSection?.id as string,
      })
      setCurrentSection(getSectionByIdData.data as SectionModel)
      toast.success('Content item deleted')
    } catch {
      toast.error('Unable to delete content item')
    }
  }

  const contentItemsRowDeleteHandler = async (contentSectionId: string) => {
    try {
      await deleteContentSectionFromSection({
        sectionId: currentSection?.id as string,
        contentSectionId: contentSectionId,
      })
      const getSectionByIdData = await getSectionById({
        sectionId: currentSection?.id as string,
      })
      setCurrentSection(getSectionByIdData.data as SectionModel)
      toast.success('Content section deleted')
    } catch {
      toast.error('Unable to delete content section')
    }
  }
  const intialRenderValueHandler = useCallback(
    (contentItems: ContentItemModel[], numberOfCols: number) => {
      let array: ContentItemModel[] = []

      const orders = contentItems.map(value => value.order)
      if (contentItems.length) {
        array = [...contentItems]
      }
      for (let i = 0; i < numberOfCols; i++) {
        if (!orders.includes(i + 1)) {
          array.push({ order: i + 1 })
        }
      }
      return array.sort((a, b) => a.order! - b.order!)
    },
    [],
  )

  const twoContentItemsWidthHandler = async (
    contentSectionId: string,
    widthValue: string,
  ) => {
    await updateContentSection({
      contentSectionId: contentSectionId,
      data: { numberOfCols: 2, colWeight: widthValue },
    })
    const getSectionByIdData = await getSectionById({
      sectionId: currentSection?.id as string,
    })
    setCurrentSection(getSectionByIdData.data as SectionModel)
  }

  return (
    <>
      <div className="slides-content-wrapper p-35 width-full-70 max-height-full-40">
        {currentSection &&
          currentSection.sectionType === CONTENT &&
          currentSection.contentSections?.map(
            (contentSectionData: ContentSectionModel, index: number) => (
              <Fragment key={index}>
                {contentSectionData.numberOfCols === 2 && (
                  <div className="flex justify-end w-full">
                    <div className={`content-select-menu`}>
                      <select
                        onChange={event =>
                          twoContentItemsWidthHandler(
                            contentSectionData.id!,
                            event.target.value,
                          )
                        }
                        defaultValue={contentSectionData.colWeight}
                      >
                        <option value={THIRTYTHREE_SIXTYSIX}>
                          {THIRTYTHREE_SIXTYSIX}
                        </option>
                        <option value={FIFTY_FIFTY}>{FIFTY_FIFTY}</option>
                        <option value={SIXTYSIX_THIRTYTHREE}>
                          {SIXTYSIX_THIRTYTHREE}
                        </option>
                      </select>
                    </div>
                  </div>
                )}

                <div
                  className={`flex min-w-100 align-self-start position-relative singleRowContent justify-between`}
                >
                  {intialRenderValueHandler(
                    contentSectionData.contentItems!,
                    contentSectionData.numberOfCols!,
                  ).map((contentItemData, index) => {
                    if (contentItemData.contentItemType) {
                      return (
                        <Fragment key={index}>
                          {contentItemData.contentItemType === CONTENT && (
                            <div
                              className={`${itemsClassName(
                                contentSectionData,
                              )}`}
                            >
                              <p className="m-0 mb-20 fw-700">Content</p>
                              <CKEditor
                                editor={Editor}
                                config={editorConfiguration}
                                data={
                                  contentItemData.contentValue !== null
                                    ? contentItemData.contentValue
                                    : ''
                                }
                                onBlur={async (event, editor) => {
                                  const data = editor.data.get()
                                  await updateContentItem({
                                    contentItemId: contentItemData.id!,
                                    data: { ContentValue: data },
                                  })
                                }}
                              />
                              <img
                                className={`dustbinIcon`}
                                src={DeleteIcon}
                                alt="deleteIcon"
                                onClick={() =>
                                  deleteContentItemFunctionality(
                                    contentItemData.id!,
                                  )
                                }
                              />
                            </div>
                          )}

                          {contentItemData.contentItemType === IMAGE && (
                            <div
                              className={`${itemsClassName(
                                contentSectionData,
                              )}`}
                            >
                              <p className="m-0 mb-20 fw-700">Image</p>
                              <ContentImage
                                contentUrl={
                                  contentItemData.contentValue || null
                                }
                                itemColumnId={contentItemData.id!}
                              />
                              <img
                                className={`dustbinIcon`}
                                src={DeleteIcon}
                                alt="deleteIcon"
                                onClick={() =>
                                  deleteContentItemFunctionality(
                                    contentItemData.id!,
                                  )
                                }
                              />
                            </div>
                          )}

                          {contentItemData.contentItemType === EMBED && (
                            <div
                              className={`${itemsClassName(
                                contentSectionData,
                              )}`}
                            >
                              <p className="m-0 mb-20 fw-700">Embed</p>
                              <textarea
                                autoComplete="off"
                                rows={15}
                                placeholder="Embed code"
                                className="slide-title-input input-field min-w-300px p-25"
                                value={
                                  contentItemData.contentValue !== null
                                    ? contentItemData.contentValue
                                    : ''
                                }
                                onChange={async event => {
                                  const mutateCurrentSection = {
                                    ...currentSection,
                                  }
                                  const finalContentSections =
                                    mutateCurrentSection?.contentSections?.map(
                                      contentSectionsData => {
                                        if (
                                          contentSectionsData?.id ===
                                          contentSectionData?.id
                                        ) {
                                          const ChangedData =
                                            contentSectionsData?.contentItems?.map(
                                              key =>
                                                key?.id === contentItemData?.id
                                                  ? {
                                                      ...key,
                                                      contentValue:
                                                        event.target.value,
                                                    }
                                                  : { ...key },
                                            )
                                          return {
                                            ...contentSectionsData,
                                            contentItems: ChangedData,
                                          }
                                        }
                                        return contentSectionsData
                                      },
                                    )
                                  mutateCurrentSection.contentSections =
                                    finalContentSections
                                  setCurrentSection({ ...mutateCurrentSection })
                                }}
                                onBlur={async event => {
                                  await updateContentItem({
                                    contentItemId: contentItemData.id!,
                                    data: { ContentValue: event.target.value },
                                  })
                                }}
                              />
                              <img
                                className={`dustbinIcon`}
                                src={DeleteIcon}
                                alt="deleteIcon"
                                onClick={() =>
                                  deleteContentItemFunctionality(
                                    contentItemData.id!,
                                  )
                                }
                              />
                            </div>
                          )}
                        </Fragment>
                      )
                    }
                    return (
                      <div
                        key={index}
                        className={`slide-add-content min-h-500px box-shadow-rgba align-center bg-white cursor-pointer
                      ${itemsClassName(contentSectionData)}`}
                        onClick={() => {
                          setContentSectionId(contentSectionData.id!)
                          setContentItemId(`${index + 1}`!)
                          setIsContentItemTypeSelectionVisible(true)
                        }}
                      >
                        <h2 className="mb-5">Add</h2>
                        <h2 className="mt-5">Content</h2>
                      </div>
                    )
                  })}

                  <button
                    className={`contentDustbin`}
                    onClick={() =>
                      contentItemsRowDeleteHandler(contentSectionData.id!)
                    }
                  >
                    <img src={DustbinIcon} alt="dustbinIcon" />
                  </button>
                </div>
              </Fragment>
            ),
          )}

        <div className="flex">
          <div
            className="flex flex-column align-center cursor-pointer"
            onClick={() => setIsContentSectionSelectionVisible(true)}
          >
            <img src={AddIcon} alt="actionIcon" />
            <p className="m-0 mt-5 fw-700">Add Content</p>
          </div>
        </div>
      </div>
      {isContentItemTypeSelectionVisible && (
        <div className="content-options-wrapper">
          <div
            className="back-Shadow"
            onClick={() => setIsContentItemTypeSelectionVisible(false)}
          ></div>
          <ChooseContentType
            contentSectionId={contentSectionId}
            contentOrderId={contentItemId}
          />
        </div>
      )}
      {isContentSectionSelectionVisible && (
        <div className="content-options-wrapper">
          <div
            className="back-Shadow"
            onClick={() => setIsContentSectionSelectionVisible(false)}
          ></div>
          <ChooseContentSection />
        </div>
      )}
    </>
  )
}

export default Content
