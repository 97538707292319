// import OverviewPanel from 'components/OverviewPanel'

const Settings = () => {
  return (
    <>
      {/* <OverviewPanel /> */}
    </>
  )
}
export default Settings
