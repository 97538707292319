import { Dialog } from '@mui/material'
import { ActionButton } from 'components/ActionButton'
import { useBranding } from 'utils/useBranding'

type ConfirmPopupProps = {
  open: boolean
  title: string
  message: string
  onConfirm: () => void
  onCancel: () => void
}

const ConfirmPopup = ({
  open,
  title,
  message,
  onConfirm,
  onCancel,
}: ConfirmPopupProps) => {
  const { branding } = useBranding()

  return (
    <Dialog className="border-0" open={open} onClose={onCancel}>
      <div className="dailog-popout">
        <h2 className="m-0 mb-10">{title}</h2>
        <p className="m-0 mb-30">{message}</p>
        <div className="flex justify-end">
          <ActionButton
            name="Cancel"
            background={branding.stylingPrimary ?? 'blue'}
            setAction={onCancel}
            className="mr-10"
          />
          <ActionButton
            name="Confirm"
            background={branding.stylingPrimary ?? 'blue'}
            setAction={onConfirm}
            className="mr-0"
          />
        </div>
      </div>
    </Dialog>
  )
}

export { ConfirmPopup }
