import { useMemo, useState } from 'react'
import '../../styles/table.css'
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table'
import {
  useGetOrganisationID,
  useGetUserCoursesByOrganisation,
} from 'utils/organisation'
import { UserCourseInstanceModel } from 'api/data-contracts'
import * as ROUTE from 'utils/constants/clientRoutes'
import { Link } from 'react-router-dom'

interface UsersCourseTableProps {
  userId: string
}
const UsersCourseTable = ({ userId }: UsersCourseTableProps) => {
  const { organisationId } = useGetOrganisationID()

  const { userCoursesByOrganisation, isError } =
    useGetUserCoursesByOrganisation(organisationId, userId)

  const [rowSelection, setRowSelection] = useState({})

  const columns = useMemo<MRT_ColumnDef<UserCourseInstanceModel>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name of course',
        Cell: ({ cell }) => (
          <Link
            to={`${ROUTE.COURSE_MODULES.replace(
              ':courseId',
              `${cell.row.original.courseInstance?.course?.id}`,
            )}`}
            className="text-decoration-underline cursor-pointer"
          >
            {`${cell.row.original.courseInstance?.course?.name}`}
          </Link>
        ),
      },
      {
        accessorKey: 'userInstanceStatus',
        header: 'Progress',
      },
    ],
    [],
  )

  if (isError) {
    return <span>Error loading courses</span>
  }

  if (typeof userCoursesByOrganisation === 'undefined') {
    return <div>No user courses found.</div>
  }
  return (
    <MaterialReactTable
      columns={columns}
      data={userCoursesByOrganisation ?? []}
      initialState={{
        showGlobalFilter: true,
      }}
      enableGlobalFilterModes
      muiSearchTextFieldProps={{
        placeholder: `Search`,
        sx: { maxWidth: '200px' },
        variant: 'outlined',
      }}
      muiTablePaperProps={{
        elevation: 0,
        sx: {
          '& tr > th > div': {
            display: 'flex',
            fontSize: '18px',
          },
          '& tr > th > div > div > span': {
            display: 'none',
          },
          borderRadius: '0',
          border: 'none',
        },
      }}
      enableRowSelection={false}
      enableSelectAll={false}
      enableMultiRowSelection={false}
      getRowId={row => row.id || ''}
      onRowSelectionChange={setRowSelection}
      state={{ rowSelection }}
      positionToolbarAlertBanner={'none'}
      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={true}
      enableSorting={true}
      enableBottomToolbar={true}
      enableTopToolbar={true}
      enableToolbarInternalActions={false}
      muiTableBodyRowProps={{ hover: false }}
    />
  )
}

export default UsersCourseTable
