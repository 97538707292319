import { useContext } from 'react'

import { useBranding } from 'utils/useBranding'
import { ActionButton } from './ActionButton'

// Contexts
import { NewSectionContext } from './moduleSlides/NewSectionContext'

const SlideContentLossMessage = () => {
  const { branding } = useBranding()
  const {
    slideContentLossMessageVisible,
    setSlideContentLossMessageVisible,
    navigateAction,
    setIsNewSlideEdited,
  } = useContext(NewSectionContext)

  return (
    <>
      {slideContentLossMessageVisible && (
        <div className="slideContentLossMessageWrapper position-fixed">
          <div className="flex justify-center align-center h-full relative z-1000">
            <div className="min-w-150px max-w-500px bg-white p-25 border-20">
              <p className="text-center fs-22 fw-700 m-0 mt-20 mb-30">
                Are you sure you want to discard your changes?
              </p>
              <div className="flex justify-between w-full">
                <ActionButton
                  name="No"
                  background={branding.stylingPrimary ?? 'blue'}
                  setAction={() => setSlideContentLossMessageVisible(false)}
                />
                <ActionButton
                  name="Yes"
                  background={branding.stylingSecondary ?? 'blue'}
                  setAction={() => {
                    setSlideContentLossMessageVisible(false)
                    navigateAction.actionFunc()
                    setIsNewSlideEdited(false)
                  }}
                  className="mr-0"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SlideContentLossMessage
