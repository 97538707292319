import { useMutation } from '@tanstack/react-query'

import { useApiClient } from './use-clients'
import { UpdateAnswerOptionModel } from 'api/data-contracts'

// PUT:/api/AnswerOption/{answerOptionId}
interface useUpdateAnswerOptionProps {
  answerOptionId: string
  data: UpdateAnswerOptionModel
}
const useUpdateAnswerOption = () => {
  const client = useApiClient()

  const mutation = useMutation({
    mutationFn: (props: useUpdateAnswerOptionProps) =>
      client.answerOption.updateAnswerOption(props.answerOptionId, props.data),
  })
  return mutation
}

// DELETE:/api/AnswerOption/{answerOptionId}
interface useDeleteAnswerOptionProps {
  answerOptionId: string
}
const useDeleteAnswerOption = () => {
  const client = useApiClient()
  const mutation = useMutation({
    mutationFn: (props: useDeleteAnswerOptionProps) =>
      client.answerOption.deleteAnswerOption(props.answerOptionId),
  })
  return mutation
}

export { useUpdateAnswerOption, useDeleteAnswerOption }
