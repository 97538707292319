import { useTypeaheadContext } from 'context/TypeAheadContext'
import { useRef } from 'react'
import { TypeaheadRef, Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { Option } from 'react-bootstrap-typeahead/types/types'
import 'styles/react-bootstrap-typeahead.scss'
import { DataOption } from 'utils/types'

const TypeAheadInput = ({ options }: { options: Option[] }) => {
  const typeaheadRef = useRef<TypeaheadRef>(null)
  const { selectedOptions: selected, setSelectedOptions: setSelected } =
    useTypeaheadContext()
  const renderMenuItem = (option: Option) => {
    const dataOption = option as DataOption
    return (
      <div key={dataOption.id}>
        <div>
          {dataOption.firstName} {dataOption.lastName}
        </div>
        <div>{dataOption.email}</div>
      </div>
    )
  }

  return (
    <Typeahead
      multiple
      id="keep-menu-open"
      onChange={(selected: Option[]) => {
        setSelected(selected)
        typeaheadRef.current?.toggleMenu()
      }}
      options={options}
      placeholder="Invite a user..."
      ref={typeaheadRef}
      selected={selected}
      labelKey="email"
      renderMenuItemChildren={renderMenuItem}
    />
  )
}

export { TypeAheadInput }
