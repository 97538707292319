import { useContext, useEffect, useMemo, useState } from 'react'
import '../../../styles/table.css'
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table'
import { useGetQuestions } from 'utils/course'
import { QuestionModel, ResourceModel } from 'api/data-contracts'
import { darken } from '@mui/material'

import { useGetSectionQuestions } from 'utils/section'
import { CurrentSectionContext } from 'components/moduleSlides/CurrentSectionContext'
import { MINIQUIZ } from 'utils/constants/sections'

interface ModuleCourseQuestionTableProps {
  onRowSelectionChange: (rowSelection: Record<string, boolean>) => void
  onQuestionSelectionChange?: (value: QuestionModel[]) => void
  addMiniQuizQuestions?: QuestionModel[] | undefined
}

const ModuleCourseQuestionTable = ({
  onRowSelectionChange,
  onQuestionSelectionChange,
  addMiniQuizQuestions,
}: ModuleCourseQuestionTableProps) => {
  const { currentSection } = useContext(CurrentSectionContext)
  const { questions: courseQuestions } = useGetQuestions()

  const { getSectionQuestion } = useGetSectionQuestions(
    currentSection?.id as string,
  )

  const [resources, setResources] = useState<QuestionModel[]>([])

  useEffect(() => {
    if (currentSection === undefined) {
      const abcd = courseQuestions?.filter(courseQue => {
        if (!courseQue.canUseForMini) {
          return false
        }
        return !addMiniQuizQuestions?.some(sectionQue => {
          return sectionQue.id === courseQue.id
        })
      })
      setResources(abcd as QuestionModel[])
    }
  }, [addMiniQuizQuestions, courseQuestions, currentSection])

  useEffect(() => {
    if (currentSection !== undefined) {
      const abcd = courseQuestions?.filter(courseQue => {
        if (
          (currentSection?.sectionType === MINIQUIZ ||
            currentSection?.sectionType === undefined) &&
          !courseQue.canUseForMini
        ) {
          return false
        }

        return !getSectionQuestion?.some(sectionQue => {
          return sectionQue.id === courseQue.id
        })
      })
      setResources(abcd as QuestionModel[])
    }
  }, [courseQuestions, currentSection, getSectionQuestion])

  const [rowSelection, setRowSelection] = useState({})

  useEffect(() => {
    onRowSelectionChange(rowSelection)
  }, [rowSelection, onRowSelectionChange])

  useEffect(() => {
    const selectedRowKeys = Object.keys(rowSelection)
    const filterValue = resources.filter(value =>
      selectedRowKeys.includes(value.id as string),
    )
    onQuestionSelectionChange && onQuestionSelectionChange(filterValue)
    // resources will re-render whole loop again
    // eslint-disable-next-line
  }, [rowSelection])

  const columns = useMemo<MRT_ColumnDef<ResourceModel>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
      },
    ],
    [],
  )

  if (typeof resources === 'undefined') {
    return <div>No resources found</div>
  }

  return (
    <MaterialReactTable
      columns={columns}
      data={resources}
      initialState={{
        showGlobalFilter: true,
      }}
      enableGlobalFilterModes
      muiSearchTextFieldProps={{
        placeholder: `Search`,
        sx: { maxWidth: '300px' },
        variant: 'outlined',
      }}
      muiTablePaperProps={{
        elevation: 0,
        sx: {
          borderRadius: '0',
          border: 'none',
        },
      }}
      muiTableBodyProps={{
        sx: theme => ({
          '& tr > td:nth-of-type(2)': {
            minWidth: '300px',
          },
          '& tr:nth-of-type(odd)': {
            backgroundColor: darken(theme.palette.background.default, 0.1),
          },
          '& td': {
            lineHeight: '0.8',
            width: '100%',
            whiteSpace: 'nowrap',
          },
        }),
      }}
      enableRowSelection
      enableSelectAll={false}
      enableMultiRowSelection={true}
      getRowId={originalRow => originalRow.id || ''}
      onRowSelectionChange={setRowSelection}
      state={{
        rowSelection,
        showProgressBars: false,
      }}
      positionToolbarAlertBanner={'none'}
      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={true}
      enableSorting={true}
      enableBottomToolbar={true}
      enableTopToolbar={true}
      enableToolbarInternalActions={false}
      muiTableBodyRowProps={{ hover: false }}
    />
  )
}

export { ModuleCourseQuestionTable }
