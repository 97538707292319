import { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Select, MenuItem, Drawer } from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

import { useBranding } from 'utils/useBranding'
import { ResourceModel } from 'utils/models/Resource'
import { FormTextField } from 'components/FormTextField'
import { FileUpload } from './FileUpload'
import { ResourceFileContext } from './ResourceFileContext'
import { FILE, URL } from 'utils/constants/resources'

// API
import { useAddResource } from 'utils/course'
import { useAddResourceToModule } from 'utils/module'
import { ActionButton } from 'components/ActionButton'

interface CreateResourcePopoutProps {
  drawerVisibility: boolean
  drawerClose: () => void
}

const CreateResourcePopout = ({
  drawerVisibility,
  drawerClose,
}: CreateResourcePopoutProps) => {
  const { resourceFile } = useContext(ResourceFileContext)
  const { branding } = useBranding()

  const { moduleId } = useParams()

  const { mutateAsync: addResource } = useAddResource()
  const { mutateAsync: addResourceToModule } = useAddResourceToModule()
  const [resourceType, setResourceType] = useState(URL)

  const ValidationSchema = Yup.object<ResourceModel>().shape({
    Name: Yup.string().required('Name is required'),
    Description: Yup.string().required('Description is required'),
    ResourceType: Yup.string(),
    Url:
      resourceType === URL
        ? Yup.string().required('URL is required')
        : Yup.string(),
  })

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<ResourceModel>({
    resolver: yupResolver(ValidationSchema),
  })

  const onSubmit: SubmitHandler<ResourceModel> = async (data, errors) => {
    const resource: {
      Name: string | undefined
      Description: string | undefined
      ResourceType: string | undefined
      Url?: string
      File?: File
    } = {
      Name: data.Name,
      Description: data.Description,
      ResourceType: resourceType,
    }

    resourceType === URL
      ? (resource.Url = data.Url)
      : (resource.File = resourceFile)

    try {
      const response = await addResource(resource)

      await addResourceToModule({
        moduleId: moduleId as string,
        resourceId: response?.data?.id as string,
      })

      toast.success('Resource added!')
      reset()
      await drawerClose()
    } catch {
      toast.error('Error')
    }
  }

  return (
    <Drawer
      anchor={'right'}
      open={drawerVisibility}
      onClose={() => drawerClose()}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 15,
        padding: '1000px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingTop: 15,
          marginTop: '5rem',
          margin: '5rem',
        }}
      >
        <h2>Create Resource</h2>

        <form>
          <FormTextField
            control={control}
            errors={errors?.Name}
            name="Name"
            label="Name"
            length={true}
          />
          <FormTextField
            control={control}
            errors={errors?.Description}
            name="Description"
            label="Description"
            length={true}
          />

          <p className="text-label">Resource type</p>
          <Select
            sx={{
              boxShadow: 'none',
              marginBottom: '25px',
              '.MuiOutlinedInput-notchedOutline': { border: 0 },
              '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                {
                  border: 0,
                },
              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                {
                  border: 0,
                },
              background: '#fff',
            }}
            defaultValue={URL}
            onChange={e => setResourceType(e.target.value)}
          >
            <MenuItem value={URL}>URL</MenuItem>
            <MenuItem value={FILE}>FILE</MenuItem>
          </Select>

          {resourceType === URL && (
            <FormTextField
              control={control}
              errors={errors?.Url}
              name="Url"
              label="URL"
              length={true}
            />
          )}

          {resourceType === FILE && (
            <div className="bg-DADADA p-10 border-10">
              <FileUpload />
            </div>
          )}
        </form>

        <div className="flex mt-15">
          <ActionButton
            name="Create"
            background={branding.stylingPrimary ?? 'blue'}
            setAction={handleSubmit(onSubmit)}
          />
          <ActionButton
            name="Close"
            background={branding.stylingSecondary ?? 'orange'}
            setAction={drawerClose}
          />
        </div>
      </div>
    </Drawer>
  )
}

export { CreateResourcePopout }
