import { useContext } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import { Fileprops } from 'utils/models/File'
import { generateMediaPath } from 'utils/generateMediaPath'

// API - custom hooks
import { useUpdateContentItem } from 'utils/contentItem'
import { useGetSectionById } from 'utils/section'

// Contexts
import { CurrentSectionContext } from 'components/moduleSlides/CurrentSectionContext'
import { SectionModel } from 'utils/models/Slide'

const fileTypes = ['JPG', 'PNG', 'JPEG']

interface ContentImageProps {
  contentUrl: string | null
  itemColumnId: string
}
const ContentImage = ({ contentUrl, itemColumnId }: ContentImageProps) => {
  const { mutateAsync: updateContentItem } = useUpdateContentItem()
  const { mutateAsync: getSectionById } = useGetSectionById()
  const { currentSection, setCurrentSection } = useContext(
    CurrentSectionContext,
  )

  const handleChange = async (file: Fileprops) => {
    await updateContentItem({
      contentItemId: itemColumnId!,
      data: { ContentValueImage: file as File },
    })
    const getSectionByIdData = await getSectionById({
      sectionId: currentSection?.id as string,
    })
    setCurrentSection(getSectionByIdData.data as SectionModel)
  }

  return (
    <>
      {contentUrl ? (
        <div className="width-full">
          <div className="w-full p-20 border-10 flex flex-column align-center bg-white-opacity-6 content-image-visible">
            <img
              className="previewImage"
              src={generateMediaPath(contentUrl as string)}
              alt="previewImage"
            />
          </div>
        </div>
      ) : (
        <FileUploader
          classes="DragAndDropFile contentImage"
          multiple={false}
          handleChange={handleChange}
          name="file"
          types={fileTypes}
          label="Upload or Drop a file here "
        />
      )}
    </>
  )
}

export default ContentImage
