import TitleHelmet from 'components/TitleHelmet'
import CompanyInformation from 'components/companySettings/CompanyDetails'
import { PageTitle } from 'utils/constants/pageTitles'

const CompanyDetails = () => {
  return (
    <>
      <TitleHelmet title={PageTitle.COMPANY_SETTING_COMPANY_DETAILS} />
      <h1>{PageTitle.COMPANY_SETTING_COMPANY_DETAILS}</h1>
      <CompanyInformation />
    </>
  )
}
export default CompanyDetails
