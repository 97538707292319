import { ActionButton } from './ActionButton'
import { useBranding } from 'utils/useBranding'
import { CourseInstanceModel } from 'api/data-contracts'

interface UserInviteConfirmationProps {
  courseInstanceData: CourseInstanceModel
  optional1Val: string
  setOptional1Val: React.Dispatch<React.SetStateAction<string>>
  optional2Val: string
  setOptional2Val: React.Dispatch<React.SetStateAction<string>>
  onCancel: () => void
  onAddUser: () => void
}

const UserInviteConfirmation = ({
  courseInstanceData,
  optional1Val,
  setOptional1Val,
  optional2Val,
  setOptional2Val,
  onCancel,
  onAddUser,
}: UserInviteConfirmationProps) => {
  const { branding } = useBranding()

  return (
    <div className="slideContentLossMessageWrapper position-fixed">
      <div className="flex justify-center align-center h-full relative z-1000">
        <div className="user-invite-confirmation-popup">
          {courseInstanceData.useOptional1 === true && (
            <>
              <p className="font-bold text-xl m-0 mb-10">
                {courseInstanceData.optional1}
              </p>
              {courseInstanceData.optional1Values !== null ? (
                <>
                  <select
                    className="w-full outline-none border-none p-15 bg-text-field fs-18 border-10 mb-15"
                    onChange={e => setOptional1Val(e.target.value)}
                  >
                    <option value={''}>Choose option</option>
                    {courseInstanceData.optional1Values?.includes(',') ? (
                      <>
                        {courseInstanceData.optional1Values
                          .split(',')
                          .map((value, index) => (
                            <option value={value} key={index}>
                              {value}
                            </option>
                          ))}
                      </>
                    ) : (
                      <option value={courseInstanceData.optional1Values!}>
                        {courseInstanceData.optional1Values}
                      </option>
                    )}
                  </select>
                </>
              ) : (
                <input
                  type="text"
                  value={optional1Val}
                  onChange={e => setOptional2Val(e.target.value)}
                  placeholder={`${courseInstanceData.optional1}`}
                  className="w-full bg-text-field border-none p-15 border-10 outline-none fs-18 mb-15"
                />
              )}
            </>
          )}
          {courseInstanceData.useOptional2 === true && (
            <>
              <p className="font-bold text-xl m-0 mb-10">
                {courseInstanceData.optional2}
              </p>
              {courseInstanceData.optional2Values !== null ? (
                <>
                  <select
                    className="w-full outline-none border-none p-15 bg-text-field fs-18 border-10 mb-15"
                    onChange={e => setOptional2Val(e.target.value)}
                  >
                    <option value={''}>Choose option</option>
                    {courseInstanceData.optional2Values?.includes(',') ? (
                      <>
                        {courseInstanceData.optional2Values
                          .split(',')
                          .map((value, index) => (
                            <option value={value} key={index}>
                              {value}
                            </option>
                          ))}
                      </>
                    ) : (
                      <option value={courseInstanceData.optional2Values!}>
                        {courseInstanceData.optional2Values}
                      </option>
                    )}
                  </select>
                </>
              ) : (
                <input
                  type="text"
                  value={optional2Val}
                  onChange={e => setOptional2Val(e.target.value)}
                  placeholder={`${courseInstanceData.optional2}`}
                  className="w-full bg-text-field border-none p-15 border-10 outline-none fs-18 mb-15"
                />
              )}
            </>
          )}
          <div className="flex justify-between w-full mt-20">
            <ActionButton
              name="Cancel"
              background="#000"
              setAction={onCancel}
              className=""
            />
            <ActionButton
              name="Add user"
              background={branding.stylingSecondary ?? 'blue'}
              setAction={onAddUser}
              className="mr-0"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserInviteConfirmation
