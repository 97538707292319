import { Auth } from 'api/Auth'
import { AnswerOption } from 'api/AnswerOption'
import { Course } from 'api/Course'
import { CourseInstance } from 'api/CourseInstance'
import { ContentItem } from 'api/ContentItem'
import { ContentSection } from 'api/ContentSection'
import { Module } from 'api/Module'
import { Organisation } from 'api/Organisation'
import { Section } from 'api/Section'
import { User } from 'api/User'
import { UserCourseInstance } from 'api/UserCourseInstance'
import { Question } from 'api/Question'
import { getToken, isTokenExpired } from './auth'
import { getEnv } from './env'
import { Resource } from 'api/Resource'
import { MessageTemplate } from 'api/MessageTemplate'
import { PolicyDocument } from 'api/PolicyDocument'
import { Testimonial } from 'api/Testimonial'
import { Topic } from 'api/Topic'
import { TOKEN_KEY } from './constants/auth'

type SecurityDataType = object
const securityWorker = () => {
  const token = getToken()
  if (token) {
    //check token is not expired,
    if (isTokenExpired()) {
      //TODO exhange refresh token for new token
      localStorage.removeItem(TOKEN_KEY)
    }
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  }
}

const auth = new Auth<SecurityDataType>({
  baseUrl: getEnv().API_URL,
  securityWorker,
})

const useAuthClient = () => {
  return { auth }
}

const answerOption = new AnswerOption<SecurityDataType>({
  baseUrl: getEnv().API_URL,
  securityWorker,
})

const course = new Course<SecurityDataType>({
  baseUrl: getEnv().API_URL,
  securityWorker,
})

const courseInstance = new CourseInstance<SecurityDataType>({
  baseUrl: getEnv().API_URL,
  securityWorker,
})

const contentItem = new ContentItem<SecurityDataType>({
  baseUrl: getEnv().API_URL,
  securityWorker,
})

const contentSection = new ContentSection<SecurityDataType>({
  baseUrl: getEnv().API_URL,
  securityWorker,
})

const module = new Module<SecurityDataType>({
  baseUrl: getEnv().API_URL,
  securityWorker,
})

const organisation = new Organisation<SecurityDataType>({
  baseUrl: getEnv().API_URL,
  securityWorker,
})

const user = new User<SecurityDataType>({
  baseUrl: getEnv().API_URL,
  securityWorker,
})

const userCourseInstance = new UserCourseInstance<SecurityDataType>({
  baseUrl: getEnv().API_URL,
  securityWorker,
})

const question = new Question<SecurityDataType>({
  baseUrl: getEnv().API_URL,
  securityWorker,
})

const section = new Section<SecurityDataType>({
  baseUrl: getEnv().API_URL,
  securityWorker,
})

const resource = new Resource<SecurityDataType>({
  baseUrl: getEnv().API_URL,
  securityWorker,
})

const messageTemplate = new MessageTemplate<SecurityDataType>({
  baseUrl: getEnv().API_URL,
  securityWorker,
})
const policyDocument = new PolicyDocument<SecurityDataType>({
  baseUrl: getEnv().API_URL,
  securityWorker,
})

const testimonial = new Testimonial<SecurityDataType>({
  baseUrl: getEnv().API_URL,
  securityWorker,
})

const topic = new Topic<SecurityDataType>({
  baseUrl: getEnv().API_URL,
  securityWorker,
})

const useApiClient = () => {
  return {
    answerOption,
    course,
    courseInstance,
    contentItem,
    contentSection,
    module,
    organisation,
    user,
    userCourseInstance,
    question,
    section,
    resource,
    messageTemplate,
    policyDocument,
    testimonial,
    topic,
  }
}

export { useAuthClient, useApiClient }
