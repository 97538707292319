/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MessageTemplateModel,
  NotFoundExceptionModel,
  UnauthorizedExceptionModel,
  UpdateMessageTemplateModel,
  ValidationExceptionModel,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class MessageTemplate<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags MessageTemplate
   * @name UpdateMessageTemplate
   * @request PUT:/api/MessageTemplate/{messageTemplateId}
   * @secure
   */
  updateMessageTemplate = (
    messageTemplateId: string,
    data: UpdateMessageTemplateModel,
    params: RequestParams = {},
  ) =>
    this.request<
      MessageTemplateModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/MessageTemplate/${messageTemplateId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags MessageTemplate
   * @name DeleteMessageTemplate
   * @request DELETE:/api/MessageTemplate/{messageTemplateId}
   * @secure
   */
  deleteMessageTemplate = (
    messageTemplateId: string,
    params: RequestParams = {},
  ) =>
    this.request<void, UnauthorizedExceptionModel | NotFoundExceptionModel>({
      path: `/api/MessageTemplate/${messageTemplateId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    })
}
