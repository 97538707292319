import { Helmet } from 'react-helmet'
import { PageTitle } from 'utils/constants/pageTitles'

interface CustomHelmetProps {
  title: PageTitle
}

const TitleHelmet = ({ title }: CustomHelmetProps) => {
  return (
    <Helmet>
      <title>
        {PageTitle.ELEARNING} - {title}
      </title>
    </Helmet>
  )
}

export default TitleHelmet
