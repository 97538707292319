import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'

import { ModulesTable } from 'components/tables/ModulesTable'
import { RowSelection } from 'utils/models/Resource'
import { useDeleteModule } from 'utils/module'
import { effectSelectedMuiTableRows } from 'utils/materialTable/effectRow'
import { ActionButton } from 'components/ActionButton'
import { useBranding } from 'utils/useBranding'
import { PageTitle } from 'utils/constants/pageTitles'
import TitleHelmet from 'components/TitleHelmet'
import ModulesOrderPopout from 'components/courseSetup/ModulesOrderPopout'
import { useCourseLockdown } from './CourseLockdown'
import { LockdownCourseContext } from 'components/moduleSlides/LockdownCourseContext'

const Modules = () => {
  const { branding } = useBranding()
  const { courseId } = useParams()
  const navigate = useNavigate()
  const { mutateAsync: deleteModule } = useDeleteModule()
  const { isCurrentCourseLocked } = useContext(LockdownCourseContext)

  useCourseLockdown()

  const [modulesOrderDrawerVisibility, setModulesOrderDrawerVisibility] =
    useState(false)
  const [moduleRowSelection, setModuleRowSelection] = useState<RowSelection>({})
  const onChangeModulesOrderDrawerClose = () => {
    setModulesOrderDrawerVisibility(false)
  }

  const onDeleteModule = () => {
    effectSelectedMuiTableRows(
      moduleRowSelection,
      setModuleRowSelection,
      deleteModule,
      'Module deleted from course!',
      'Error deleting module - make sure the module is not in use',
    )
  }

  return (
    <>
      <TitleHelmet title={PageTitle.COURSE_SETUP_MODULES} />
      <h1>{PageTitle.COURSE_SETUP_MODULES}</h1>

      <ActionButton
        name="Add a module"
        background={branding.stylingPrimary ?? 'blue'}
        setAction={() =>
          navigate(`/settings/course-setup/modules/${courseId}/new-module`)
        }
        className={`${
          isCurrentCourseLocked && 'pointer-event-none opacity-50'
        }`}
      />

      <ActionButton
        name="Update order"
        background={branding.stylingPrimary ?? 'blue'}
        setAction={() => setModulesOrderDrawerVisibility(true)}
      />

      {Object.keys(moduleRowSelection).length > 0 && (
        <ActionButton
          name="Delete module"
          background={branding.stylingPrimary ?? 'blue'}
          setAction={() => onDeleteModule()}
          className={`${
            isCurrentCourseLocked && 'pointer-event-none opacity-50'
          }`}
        />
      )}

      <ModulesTable
        moduleRowSelection={moduleRowSelection!}
        setModuleRowSelection={setModuleRowSelection!}
      />

      <ModulesOrderPopout
        drawerVisibility={modulesOrderDrawerVisibility}
        drawerClose={onChangeModulesOrderDrawerClose}
      />
    </>
  )
}
export default Modules
