import { useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import { Box, Button } from '@mui/material'
import { useBranding } from 'utils/useBranding'

import { Fileprops } from 'utils/models/File'

import PreviewIcon from '../assets/icon/Visible.svg'
import CancelIcon from '../assets/icon/Cancel.svg'
import CloseIcon from '../assets/icon/Close.svg'

interface ImageUploadDragAndDropProps {
  file: Fileprops | null
  setFile: React.Dispatch<React.SetStateAction<Fileprops | null>>
  fileTypes: string[]
  label?: string
}

const ImageUploadDragAndDrop = ({
  file,
  setFile,
  fileTypes,
  label,
}: ImageUploadDragAndDropProps) => {
  const { branding } = useBranding()
  const [imagePreview, setImagePreview] = useState(false)
  const onHandleFileChange = (file: Fileprops) => {
    setFile(file)
  }

  return (
    <>
      <FileUploader
        classes="DragAndDropFile"
        multiple={false}
        handleChange={onHandleFileChange}
        name="file"
        types={fileTypes}
        label={label ? label : 'Upload or drop a image here'}
      />
      {file && (
        <>
          <p className="text-center mt-10 mb-10">{`Image: ${file.name}`}</p>
          <div className="flex justify-center">
            <Button
              sx={{
                background: branding.stylingPrimary,
                minWidth: '55px',
                minHeight: '55px',
                borderRadius: '50%',
                marginRight: '10px',
                '&:hover': {
                  background: branding.stylingPrimary,
                },
              }}
              onClick={() => setFile(null)}
            >
              <img src={CloseIcon} alt="closeIcon" />
            </Button>
            {file?.type && file?.type.substring(0, 5) === 'image' && (
              <Button
                onClick={() => {
                  setImagePreview(true)
                }}
                sx={{
                  background: branding.stylingSecondary,
                  minWidth: '55px',
                  minHeight: '55px',
                  borderRadius: '50%',
                  marginLeft: '10px',
                  '&:hover': {
                    background: branding.stylingSecondary,
                  },
                }}
              >
                <img src={PreviewIcon} alt="previewIcon" />
              </Button>
            )}
          </div>
        </>
      )}
      {imagePreview && (
        <Box
          sx={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            background: 'rgba(0, 0, 0, 0.5)',
            zIndex: '10000',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {file !== null && (
            <img
              className="uploadImagePreview"
              src={URL.createObjectURL(file as Blob)}
              alt="upload file"
            />
          )}

          <img
            className="cancelPreview"
            src={CancelIcon}
            alt="cancel Icon"
            onClick={() => setImagePreview(false)}
          />
        </Box>
      )}
    </>
  )
}

export default ImageUploadDragAndDrop
