import { useMemo, useState } from 'react'
import 'styles/table.css'
import { darken } from '@mui/material'
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table'
import { UserModuleDataModel } from 'api/data-contracts'
import { useGetUserCourseInstanceById } from 'utils/userCourseInstance'
import { useNavigate, useParams } from 'react-router-dom'
import AnchorLink from 'components/AnchorLink'
import { TRAINER } from 'utils/constants/modules'

const ParticipantDetailsTable = () => {
  const { courseInstanceId, participantId } = useParams()
  const { userCourseInstance } = useGetUserCourseInstanceById(participantId!)
  const navigate = useNavigate()

  const [rowSelection, setRowSelection] = useState({})

  const columns = useMemo<MRT_ColumnDef<UserModuleDataModel>[]>(() => {
    return [
      {
        accessorKey: 'Module',
        header: 'Module',
        Cell: ({ cell }) =>
          cell?.row?.original?.module?.moduleProgression === TRAINER ? (
            <AnchorLink
              className="p-0"
              onClick={() =>
                navigate(
                  `/settings/course-setup/course-instances/${courseInstanceId}/participant/${participantId}/${cell.row.original.module?.id}`,
                )
              }
            >
              {cell?.row?.original?.module?.name}
            </AnchorLink>
          ) : (
            cell?.row?.original?.module?.name
          ),
      },
      {
        accessorKey: 'status',
        header: 'Status',
        Cell: ({ cell }) => cell?.row?.original?.userModuleStatus,
      },
    ]
  }, [navigate, courseInstanceId, participantId])

  return (
    <MaterialReactTable
      columns={columns}
      data={
        (userCourseInstance?.userModuleDatas as UserModuleDataModel[]) ?? []
      }
      initialState={{
        showGlobalFilter: true,
      }}
      enableGlobalFilterModes
      muiSearchTextFieldProps={{
        placeholder: `Search`,
        sx: { maxWidth: '300px', marginBottom: '20px' },
        variant: 'outlined',
      }}
      muiTablePaperProps={{
        elevation: 0,
        sx: {
          '& tr > th > div': {
            display: 'flex',
            fontSize: '18px',
          },
          '& tr > th > div > div > span': {
            display: 'none',
          },
          borderRadius: '0',
          border: 'none',
        },
      }}
      muiTableBodyProps={{
        sx: theme => ({
          '& th > div': {
            display: 'flex',
          },
          '& tr:nth-of-type(odd)': {
            backgroundColor: darken(theme.palette.background.default, 0.1),
          },
        }),
      }}
      muiTableBodyCellProps={{
        sx: {
          lineHeight: '1.6',
        },
      }}
      enableSelectAll={false}
      enableMultiRowSelection={false}
      getRowId={originalRow => originalRow.id || ''}
      onRowSelectionChange={setRowSelection}
      state={{
        rowSelection,
        showProgressBars: false,
      }}
      positionToolbarAlertBanner={'top'}
      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={true}
      enableSorting={true}
      enableBottomToolbar={true}
      enableTopToolbar={true}
      enableToolbarInternalActions={false}
      muiTableBodyRowProps={{ hover: false }}
    />
  )
}

export { ParticipantDetailsTable }
