export const editorConfiguration = {
  removePlugins: ['Title'],
  placeholder: '',
  toolbar: [
    'heading',
    'style',
    '|',
    'fontSize',
    'fontColor',
    'fontBackgroundColor',
    'highlight',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'subscript',
    'superscript',
    '|',
    'numberedList',
    'bulletedList',
    'outdent',
    'indent',
    '|',
    'blockQuote',
    'undo',
    'redo',
    '|',
    'removeFormat',
  ],
  style: {
    definitions: [
      {
        name: 'Info panel',
        element: 'p',
        classes: ['info-panel'],
      },
      {
        name: 'Info box',
        element: 'p',
        classes: ['info-box'],
      },
      {
        name: 'Marker',
        element: 'p',
        classes: ['marker'],
      },
      {
        name: 'Spoiler',
        element: 'p',
        classes: ['spoiler'],
      },
    ],
  },
  heading: {
    options: [
      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
      {
        model: 'heading1',
        view: 'h1',
        title: 'Heading 1',
        class: 'ck-heading_heading1',
      },
      {
        model: 'heading2',
        view: 'h2',
        title: 'Heading 2',
        class: 'ck-heading_heading2',
      },
      {
        model: 'heading3',
        view: 'h3',
        title: 'Heading 3',
        class: 'ck-heading_heading3',
      },
      {
        model: 'heading4',
        view: 'h4',
        title: 'Heading 4',
        class: 'ck-heading_heading4',
      },
      {
        model: 'heading5',
        view: 'h5',
        title: 'Heading 5',
        class: 'ck-heading_heading5',
      },
      {
        model: 'heading6',
        view: 'h6',
        title: 'Heading 6',
        class: 'ck-heading_heading6',
      },
    ],
  },
  htmlSupport: {
    allow: [
      {
        name: /.*/,
        attributes: true,
        classes: true,
        styles: true,
      },
    ],
  },
}
