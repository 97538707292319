import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { MenuItem, Select, Button } from '@mui/material'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { useBranding } from 'utils/useBranding'
import { UpdateCourseModel } from 'api/data-contracts'
import { FormTextField } from 'components/FormTextField'
import { ActionButton } from 'components/ActionButton'
import { PageTitle } from 'utils/constants/pageTitles'
import TitleHelmet from 'components/TitleHelmet'
import { Fileprops } from 'utils/models/File'
import ImageUploadDragAndDrop from 'components/ImageUploadDragAndDrop'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import { editorConfiguration } from 'utils/ckeditor/EditorConfiguration'

// Constants
import * as ROUTE from '../../utils/constants/clientRoutes'

// API - custom hooks
import {
  AddCourseToOrganisationModel,
  useAddNewCourseToOrganisation,
  useGetTopics,
} from 'utils/organisation'
import { LoaderSpinner } from 'components/LoaderSpinner'

const validationSchema = Yup.object<UpdateCourseModel>().shape({
  Name: Yup.string().required('Name is required'),
  SubTitle: Yup.string().required('Subtitle is required'),
  Description: Yup.string(),
  CourseContent: Yup.string(),
  CourseStatus: Yup.string().required('Course status is required'),
  CourseStructure: Yup.string().required('Course structure is required'),
  TopicId: Yup.string(),
  PreInfo: Yup.string(),
  Duration: Yup.string(),
})

const NewConfiguration = () => {
  const [loading, setLoading] = useState(false)
  const { mutateAsync: addNewCourseToOrganisation } =
    useAddNewCourseToOrganisation()
  const { topics } = useGetTopics()

  const [selectedTopic, setSelectedTopic] = useState('')

  const { branding } = useBranding()
  const navigate = useNavigate()
  const [courseImageFile, setCourseImageFile] = useState<Fileprops | null>(null)
  const [courseCompletionImageFile, setCourseCompletionImageFile] =
    useState<Fileprops | null>(null)

  const topicSelectionFunctionality = (id: string) => {
    selectedTopic !== id ? setSelectedTopic(id) : setSelectedTopic('')
  }

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AddCourseToOrganisationModel>({
    resolver: yupResolver(validationSchema),
  })

  const onSubmit: SubmitHandler<AddCourseToOrganisationModel> = async (
    data,
    e,
  ) => {
    try {
      setLoading(true)
      const response = await addNewCourseToOrganisation({
        Name: data.Name,
        SubTitle: data.SubTitle,
        Description: data.Description,
        CourseContent: data.CourseContent,
        CourseStatus: data.CourseStatus,
        CourseStructure: data.CourseStructure,
        CourseCompletionTitle: data.CourseCompletionTitle,
        CourseCompletionDescription: data.CourseCompletionDescription,
        CourseCompletionImage: courseCompletionImageFile as File,
        CourseImage: courseImageFile as File,
        Duration: data.Duration,
        TopicId: selectedTopic,
        PreInfo: data.PreInfo,
        WelcomeEmailOptional: data.WelcomeEmailOptional,
      })
      if (e?.target.id === 'saveInfoAndAddModules') {
        navigate(
          ROUTE.COURSE_MODULES.replace(':courseId', `${response?.data?.id}`),
        )
      }
      if (e?.target.id === 'saveInfo') {
        navigate(
          ROUTE.COURSE_CONFIGURATION.replace(
            ':courseId',
            `${response?.data?.id}`,
          ),
        )
      }
      toast.success('Course added')
    } catch {
      toast.error('Unable to add course')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <TitleHelmet title={PageTitle.COURSE_SETUP} />
      <h1>{PageTitle.COURSE_SETUP}</h1>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <form>
          <div className="flex mb-20 flex-wrap justify-between">
            <div className="w-full desktop-w-50">
              <FormTextField
                control={control}
                errors={errors?.Name}
                name="Name"
                label="Course title"
                length={true}
              />
              <FormTextField
                control={control}
                errors={errors?.SubTitle}
                name="SubTitle"
                label="Course sub title"
                length={true}
              />
              <FormTextField
                control={control}
                errors={errors?.Description}
                name="Description"
                label="Description"
                multilineValue={2}
                length={true}
              />
              <FormTextField
                control={control}
                errors={errors?.CourseContent}
                name="CourseContent"
                label="What you will learn"
                multilineValue={2}
                length={true}
              />

              <FormTextField
                control={control}
                errors={errors?.PreInfo}
                name="PreInfo"
                label="Pre course information"
                multilineValue={4}
                length={true}
              />
              <Controller
                name={'CourseStatus'}
                control={control}
                defaultValue={'INACTIVE'}
                render={({ field: { onChange, value } }) => (
                  <div className="w-full mb-30">
                    <p className="text-label">Course status</p>
                    <Select
                      value={value}
                      onChange={onChange}
                      error={errors.CourseStatus?.message !== undefined}
                      className="bg-white"
                    >
                      <MenuItem value={'INACTIVE'}>Inactive</MenuItem>
                      <MenuItem value={'ACTIVE'}>Active</MenuItem>
                    </Select>
                  </div>
                )}
              />
            </div>
            <div className="w-full desktop-w-50">
              <div className="w-full mb-30">
                <p className="text-label">Image</p>
                <ImageUploadDragAndDrop
                  file={courseImageFile}
                  setFile={setCourseImageFile}
                  fileTypes={['JPG', 'PNG', 'JPEG']}
                />
              </div>
              <Controller
                name={'CourseStructure'}
                control={control}
                defaultValue={'ONLINE'}
                render={({ field: { onChange, value } }) => (
                  <div className="w-full mb-30">
                    <p className="text-label">Type of course</p>
                    <Select
                      value={value}
                      onChange={onChange}
                      error={errors.CourseStructure?.message !== undefined}
                      className="bg-white"
                    >
                      <MenuItem value={'ONLINE'}>Online</MenuItem>
                      <MenuItem value={'ONLINEINPERSON'}>
                        Online / In person
                      </MenuItem>
                    </Select>
                  </div>
                )}
              />

              <FormTextField
                control={control}
                errors={errors?.Duration}
                name="Duration"
                label="Duration"
                length={true}
              />

              <div className="w-full mb-30">
                <p className="text-label">Topics</p>
                <div className="flex flex-wrap align-center">
                  {topics &&
                    topics.map(value => (
                      <Button
                        key={value.id}
                        className="color-white fw-700 pt-10 pb-10 pl-20 pr-20 mr-10"
                        style={{
                          background: `${
                            value.id === selectedTopic
                              ? branding.stylingSecondary
                              : branding.stylingPrimary
                          }`,
                        }}
                        onClick={() =>
                          topicSelectionFunctionality(value.id as string)
                        }
                      >
                        {value.name}
                      </Button>
                    ))}
                </div>
              </div>
            </div>
            <div className="w-full mb-30">
              <p className="text-label">Welcome Email</p>
              <Controller
                name="WelcomeEmailOptional"
                control={control}
                render={({ field }) => (
                  <CKEditor
                    editor={Editor}
                    config={editorConfiguration}
                    onChange={(event, editor) => {
                      const data = editor.data.get()
                      field.onChange(data)
                    }}
                    data={field.value}
                  />
                )}
              />
            </div>
          </div>
          <hr className="text-gray opacity-30"></hr>
          <div className="flex mb-20 flex-wrap justify-between">
            <div className="w-full desktop-w-50">
              <h2>Course Completion setup</h2>
              <FormTextField
                control={control}
                errors={errors?.CourseCompletionTitle}
                name="CourseCompletionTitle"
                label="Completion title"
                length={true}
              />
              <FormTextField
                control={control}
                errors={errors?.CourseCompletionDescription}
                name="CourseCompletionDescription"
                label="Completion description"
                length={true}
              />

              <div className="w-full mb-30">
                <p className="text-label">Image</p>
                <ImageUploadDragAndDrop
                  file={courseCompletionImageFile}
                  setFile={setCourseCompletionImageFile}
                  fileTypes={['JPG', 'PNG', 'JPEG']}
                />
              </div>
            </div>
          </div>
          <ActionButton
            name="Save information"
            background={branding.stylingPrimary ?? 'blue'}
            setAction={handleSubmit(onSubmit)}
            id="saveInfo"
          />
          <ActionButton
            name="Save and add modules"
            background={branding.stylingPrimary ?? 'blue'}
            setAction={handleSubmit(onSubmit)}
            id="saveInfoAndAddModules"
          />
        </form>
      )}
    </div>
  )
}
export default NewConfiguration
