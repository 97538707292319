import { Link } from 'react-router-dom'
import {
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
} from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded'
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded'
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded'

const drawerWidth = '87px'

export default function ClippedDrawer() {
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        display: {
          xs: 'none',
          md: 'block',
        },
      }}
    >
      <Toolbar />

      <div className="flex overflow-auto h-full justify-between flex-column">
        <List sx={{ mt: 7 }}>
          {['Dashboard', 'Resources'].map((text, index) => (
            <ListItem
              key={text}
              disablePadding
              sx={{
                display: 'block',
                mb: 1,
                width: '4px',
                backgroundColor: 'black',
                borderTopRightRadius: '6px',
                borderBottomRightRadius: '6px',
              }}
            >
              <ListItemButton>
                <ListItemIcon sx={{ justifyContent: 'center' }}>
                  {index % 2 === 0 ? (
                    <GridViewRoundedIcon />
                  ) : (
                    <RadioButtonUncheckedRoundedIcon />
                  )}
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <List>
          {['Settings', 'Help'].map((text, index) => (
            <ListItem
              key={text}
              disablePadding
              sx={{ display: 'block', mb: 1 }}
            >
              <Link to="/settings">
                <ListItemButton>
                  <ListItemIcon sx={{ justifyContent: 'center' }}>
                    {index % 2 === 0 ? (
                      <span style={{ display: 'block' }}>
                        <SettingsIcon />
                      </span>
                    ) : (
                      <HelpOutlineRoundedIcon />
                    )}
                  </ListItemIcon>
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
        </List>
      </div>
    </Drawer>
  )
}
