export interface KeyValueStringObject {
  [key: string]: string
}

export const emptyObjectKey = (fullObj: KeyValueStringObject) => {
  const entries = Object.entries(fullObj).filter(
    ([_, v]) => v !== null && v !== undefined,
  )

  return Object.fromEntries(entries)
}
