import TitleHelmet from 'components/TitleHelmet'
import { PageTitle } from 'utils/constants/pageTitles'

const Access = () => {
  return (
    <>
      <TitleHelmet title={PageTitle.COURSE_SETUP_ACCESS} />
      <h1>{PageTitle.COURSE_SETUP_ACCESS}</h1>
    </>
  )
}

export default Access
