import { useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { toast } from 'react-toastify'
import { FormTextField } from 'components/FormTextField'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

import { useBranding } from 'utils/useBranding'
import { TestimonialsTable } from 'components/tables/TestimonialsTable'
import { ActionButton } from 'components/ActionButton'
import { Fileprops } from 'utils/models/File'
import ImageUploadDragAndDrop from 'components/ImageUploadDragAndDrop'

// API - custom hooks
import {
  useAddTestimonialToOrganisation,
  useGetOrganisationID,
} from 'utils/organisation'
import { OrganisationTestimonialModel } from 'api/data-contracts'

const ValidationSchema = Yup.object<OrganisationTestimonialModel>().shape({
  testimonial: Yup.string().nullable().required('Testimonial is required'),
  author: Yup.string().nullable().required('Author is required'),
})

const Testimonials = () => {
  const { branding } = useBranding()
  const { organisationId } = useGetOrganisationID()
  const { mutateAsync: addTestimonialToOrganisation } =
    useAddTestimonialToOrganisation()

  const [file, setFile] = useState<Fileprops | null>(null)
  const [addTestimonialLoader, setAddTestimonialLoader] = useState(false)

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<OrganisationTestimonialModel>({
    resolver: yupResolver(ValidationSchema),
  })

  const onSubmit: SubmitHandler<OrganisationTestimonialModel> = async data => {
    const testimonialData = {
      Testimonial: data.testimonial as string | undefined,
      Author: data.author as string | undefined,
      Image: file as File | undefined,
    }

    setAddTestimonialLoader(true)
    try {
      if (organisationId !== undefined) {
        await addTestimonialToOrganisation({
          organisationId,
          data: testimonialData,
        })
      }
      toast.success('Testimonial added')
      reset()
      setFile(null)
    } catch {
      toast.error('Unable to add testimonial')
    }
    setAddTestimonialLoader(false)
  }

  return (
    <>
      <form>
        <div className="flex flex-wrap justify-between">
          <FormTextField
            control={control}
            errors={errors?.testimonial}
            name="testimonial"
            label="Testimonial"
            multilineValue={4}
          />
          <FormTextField
            control={control}
            errors={errors?.author}
            name="author"
            label="Author"
          />

          <div className="w-full mb-25 desktop-w-50 min-w-500px">
            <p className="text-label">Image</p>
            <ImageUploadDragAndDrop
              file={file}
              setFile={setFile}
              fileTypes={['JPG', 'PNG', 'JPEG']}
            />
          </div>
        </div>

        <ActionButton
          name="Add"
          background={branding.stylingPrimary ?? 'blue'}
          setAction={handleSubmit(onSubmit)}
          className={`flex justify-center
          ${addTestimonialLoader && 'pointer-event-none opacity-50'}`}
          spinner={addTestimonialLoader}
        />
      </form>

      <TestimonialsTable />
    </>
  )
}

export default Testimonials
