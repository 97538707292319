import { useState, useEffect } from 'react'
import { Box, Button } from '@mui/material'

import { FileUploader } from 'react-drag-drop-files'
import { useBranding } from 'utils/useBranding'
import { IMAGE_BASEURL } from 'utils/constants/sections'

import PreviewIcon from '../../assets/icon/Visible.svg'
import CancelIcon from '../../assets/icon/Cancel.svg'
import EditIcon from '../../assets/icon/Edit.svg'
import CloseIcon from '../../assets/icon/Close.svg'
import DustbinIcon from '../../assets/icon/Dustbin.svg'
import { Fileprops } from 'utils/models/File'

interface ImageDragAndDropProps {
  imageFile: Fileprops | null
  setImageFile: React.Dispatch<React.SetStateAction<Fileprops | null>>
  imageExist: string | null | undefined
  deleteStatus?: boolean
  setDeleteImageStatus?: React.Dispatch<React.SetStateAction<boolean>>
  deleteButton?: boolean
  fileTypes?: string[]
  label?: string
}

const ImageDragAndDrop = ({
  imageFile,
  setImageFile,
  imageExist,
  deleteStatus,
  setDeleteImageStatus,
  deleteButton,
  fileTypes,
  label,
}: ImageDragAndDropProps) => {
  const { branding } = useBranding()

  const [imagePreview, setImagePreview] = useState(false)
  const [dragAndDropVisible, setdragAndDropVisible] = useState<boolean>()
  const [imageWithActionButtons, setImageWithActionButtons] =
    useState<boolean>()

  const handleChange = (file: Fileprops) => {
    setImageFile(file)
  }

  const brandingImageExist = imageExist !== '' || imageExist !== null
  const brandingImageNotExist = imageExist === '' || imageExist === null

  useEffect(() => {
    if (brandingImageExist && (!imageFile || imageFile)) {
      setdragAndDropVisible(false)
      setImageWithActionButtons(true)
    }
    if (brandingImageNotExist && !imageFile) {
      setdragAndDropVisible(true)
      setImageWithActionButtons(false)
    }
    if (brandingImageNotExist && imageFile) {
      setdragAndDropVisible(false)
      setImageWithActionButtons(true)
    }
    if (deleteStatus && !imageFile) {
      setdragAndDropVisible(true)
      setImageWithActionButtons(false)
    }
  }, [brandingImageExist, brandingImageNotExist, imageFile, deleteStatus])

  return (
    <>
      {dragAndDropVisible && (
        <>
          <FileUploader
            classes="DragAndDropFile"
            multiple={false}
            handleChange={handleChange}
            name="file"
            types={fileTypes}
            label={label ? label : 'Upload or Drop an image here'}
          />
        </>
      )}

      {imageWithActionButtons && (
        <div className="flex align-center">
          <div className="bg-white text-center p-20 border-10">
            {imageFile === null && (
              <img
                style={{ maxHeight: '145px' }}
                src={IMAGE_BASEURL + imageExist}
                alt="upload file"
              />
            )}
            {imageFile !== null && (
              <img
                style={{ maxHeight: '145px' }}
                src={URL.createObjectURL(imageFile as Blob)}
                alt="upload file"
              />
            )}
          </div>
          <div className="ml-18">
            <Button
              onClick={() => {
                setImagePreview(true)
              }}
              sx={{
                background: branding.stylingSecondary,
                minWidth: '55px',
                minHeight: '55px',
                borderRadius: '50%',
                marginBottom: '10px',
                '&:hover': {
                  background: branding.stylingSecondary,
                },
              }}
            >
              <img src={PreviewIcon} alt="previewIcon" />
            </Button>

            {imageFile === null && (
              <>
                <div>
                  <Button
                    sx={{
                      background: branding.stylingPrimary,
                      minWidth: '55px',
                      minHeight: '55px',
                      borderRadius: '50%',
                      marginBottom: '10px',
                      '&:hover': {
                        background: branding.stylingPrimary,
                      },
                    }}
                    onClick={() => {
                      setdragAndDropVisible(true)
                      setImageWithActionButtons(false)
                    }}
                  >
                    <img src={EditIcon} alt="editIcon" />
                  </Button>
                </div>
                {!deleteButton && (
                  <div>
                    <Button
                      sx={{
                        background: branding.stylingSecondary,
                        minWidth: '55px',
                        minHeight: '55px',
                        borderRadius: '50%',
                        '&:hover': {
                          background: branding.stylingSecondary,
                        },
                      }}
                      onClick={() => {
                        setDeleteImageStatus && setDeleteImageStatus(true)
                        setdragAndDropVisible(true)
                        setImageWithActionButtons(false)
                      }}
                    >
                      <img src={DustbinIcon} alt="DustbinIcon" />
                    </Button>
                  </div>
                )}
              </>
            )}

            {imageFile !== null && (
              <div>
                <Button
                  sx={{
                    background: branding.stylingPrimary,
                    minWidth: '55px',
                    minHeight: '55px',
                    borderRadius: '50%',
                    '&:hover': {
                      background: branding.stylingPrimary,
                    },
                  }}
                  onClick={() => {
                    setImageFile(null)
                    setdragAndDropVisible(true)
                    setImageWithActionButtons(false)
                  }}
                >
                  <img src={CloseIcon} alt="closeIcon" />
                </Button>
              </div>
            )}
          </div>
        </div>
      )}

      {imagePreview && (
        <Box
          sx={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            background: 'rgba(0, 0, 0, 0.5)',
            zIndex: '10000',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {imageFile === null && (
            <img
              className="uploadImagePreview"
              src={IMAGE_BASEURL + imageExist}
              alt="upload file"
            />
          )}
          {imageFile !== null && (
            <img
              className="uploadImagePreview"
              src={URL.createObjectURL(imageFile as Blob)}
              alt="upload file"
            />
          )}

          <img
            className="cancelPreview"
            src={CancelIcon}
            alt="cancel Icon"
            onClick={() => setImagePreview(false)}
          />
        </Box>
      )}
    </>
  )
}

export default ImageDragAndDrop
