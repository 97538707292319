import TitleHelmet from 'components/TitleHelmet'
import { PageTitle } from 'utils/constants/pageTitles'

const Security = () => {
  return (
    <>
      <TitleHelmet title={PageTitle.COMPANY_SETTING_SECURITY} />
      <h1>{PageTitle.COMPANY_SETTING_SECURITY}</h1>
    </>
  )
}
export default Security
