import { useCallback, useContext, useMemo, useState } from 'react'
import '../../styles/table.css'
import { toast } from 'react-toastify'
import { IconButton, Tooltip } from '@mui/material'
import { darken } from '@mui/material'
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table'

import { QuestionModel } from 'api/data-contracts'
import { useGetQuestions } from 'utils/course'

// API
import {
  setQuestionList,
  setQuestionType,
  useDeleteQuestion,
} from 'utils/question'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { QuestionContext } from 'components/courseSetup/Questions/QuestionContext'

const QuestionsTable = () => {
  const { setList, setQueType, setUpdateQuestionId } =
    useContext(QuestionContext)

  const { questions, isError } = useGetQuestions()
  const [rowSelection, setRowSelection] = useState({})

  const deleteQuestion = useDeleteQuestion()

  const onClickEditQuestionHandler = useCallback(
    (questionType: string, questionId: string) => {
      setQuestionList('false')
      setList('false')
      setQuestionType(questionType)
      setQueType(questionType)
      setUpdateQuestionId(questionId)
    },
    [setList, setQueType, setUpdateQuestionId],
  )

  const onClickDeleteQuestionHandler = useCallback(
    (questionId: string) => {
      deleteQuestion
        .mutateAsync(questionId)
        .then(res => toast.success('Question deleted !!'))
        .catch(err => toast.error('Question deletion was unsuccessful'))
    },
    [deleteQuestion],
  )

  const columns = useMemo<MRT_ColumnDef<QuestionModel>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'question',
        header: 'Question',
      },
      {
        accessorKey: 'questionType',
        header: 'Question Type',
      },
      {
        accessorKey: 'id',
        header: 'Action',
        Cell: ({ cell }) => (
          <>
            <Tooltip title="Edit">
              <IconButton
                onClick={() =>
                  onClickEditQuestionHandler(
                    cell?.row?.original?.questionType as string,
                    cell?.row?.id,
                  )
                }
              >
                <EditIcon sx={{ width: '20px', height: '20px' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                onClick={() => onClickDeleteQuestionHandler(cell?.row?.id)}
              >
                <DeleteIcon sx={{ width: '20px', height: '20px' }} />
              </IconButton>
            </Tooltip>
          </>
        ),
      },
    ],
    [onClickEditQuestionHandler, onClickDeleteQuestionHandler],
  )

  if (isError) {
    return <span>Error loading courses</span>
  }

  if (typeof questions === 'undefined') {
    return <div>No courses found</div>
  }

  return (
    <MaterialReactTable
      columns={columns}
      data={questions}
      initialState={{
        showGlobalFilter: true,
      }}
      enableGlobalFilterModes
      muiSearchTextFieldProps={{
        placeholder: `Search`,
        sx: { maxWidth: '200px' },
        variant: 'outlined',
      }}
      muiTablePaperProps={{
        elevation: 0,
        sx: {
          '& tr > th > div': {
            display: 'flex',
            fontSize: '18px',
          },
          '& tr > th > div > div > span': {
            display: 'none',
          },
          borderRadius: '0',
          border: 'none',
        },
      }}
      muiTableBodyProps={{
        sx: theme => ({
          '& th > div': {
            display: 'flex',
          },
          '& tr:nth-of-type(odd)': {
            backgroundColor: darken(theme.palette.background.default, 0.1),
          },
        }),
      }}
      muiTableBodyCellProps={{
        sx: {
          lineHeight: '1.6',
        },
      }}
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: 'error',
              children: 'Error loading data',
            }
          : undefined
      }
      enableSelectAll={false}
      enableMultiRowSelection={false}
      getRowId={originalRow => originalRow.id || ''}
      onRowSelectionChange={setRowSelection}
      state={{
        rowSelection,
        showAlertBanner: isError,
        showProgressBars: false,
      }}
      positionToolbarAlertBanner={'top'}
      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={true}
      enableSorting={true}
      enableBottomToolbar={true}
      enableTopToolbar={true}
      enableToolbarInternalActions={false}
      muiTableBodyRowProps={{ hover: false }}
    />
  )
}

export { QuestionsTable }
