import { Checkbox } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'

interface CustomCheckedBoxwithTextProps {
  label: string
  action: boolean
  setAction: (value: boolean) => void
}

const CustomCheckedBoxwithLabel = ({
  label,
  action,
  setAction,
}: CustomCheckedBoxwithTextProps) => {
  return (
    <FormControlLabel
      sx={{ marginBottom: '20px' }}
      control={
        <Checkbox
          checked={action}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setAction(event.target.checked)
          }
        />
      }
      label={label}
    />
  )
}

export default CustomCheckedBoxwithLabel
