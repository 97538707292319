import { useState, createContext, ReactNode } from 'react'

interface NewContentSectionContextType {
  isContentItemTypeSelectionVisible: boolean
  setIsContentItemTypeSelectionVisible: (value: boolean) => void
  isContentSectionSelectionVisible: boolean
  setIsContentSectionSelectionVisible: (value: boolean) => void
}

export const NewContentSectionContext =
  createContext<NewContentSectionContextType>({
    isContentSectionSelectionVisible: false,
    setIsContentSectionSelectionVisible: () => {
      //initalized function
    },
    isContentItemTypeSelectionVisible: false,
    setIsContentItemTypeSelectionVisible: () => {
      //initalized function
    },
  })

const NewContentSectionProvider = ({ children }: { children: ReactNode }) => {
  const [
    isContentSectionSelectionVisible,
    setIsContentSectionSelectionVisible,
  ] = useState(false)

  const [
    isContentItemTypeSelectionVisible,
    setIsContentItemTypeSelectionVisible,
  ] = useState(false)

  return (
    <NewContentSectionContext.Provider
      value={{
        isContentSectionSelectionVisible,
        setIsContentSectionSelectionVisible,
        isContentItemTypeSelectionVisible,
        setIsContentItemTypeSelectionVisible,
      }}
    >
      {children}
    </NewContentSectionContext.Provider>
  )
}

export { NewContentSectionProvider }
