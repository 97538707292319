import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import invariant from 'tiny-invariant'
import { useParams } from 'react-router-dom'
import { useApiClient } from './use-clients'
import {
  AddUserToCourseInstanceModel,
  UpdateCourseInstanceModel,
} from 'api/data-contracts'

// GET:/api/CourseInstance/{courseInstanceId}/participants
const useGetParticipantsByCourseInstanceId = (courseInstanceId: string) => {
  invariant(courseInstanceId, 'courseInstanceId not available')
  const client = useApiClient()

  const result = useQuery({
    queryKey: ['participants', { courseInstanceId }],
    queryFn: () =>
      client.courseInstance.getParticipantsByCourseInstanceId(courseInstanceId),
    enabled: !!courseInstanceId,
    staleTime: Infinity,
  })
  return { ...result, participants: result.data?.data ?? [] }
}

// GET:/api/CourseInstance/{courseInstanceId}/participants
export interface useGetParticipantsByCourseInstanceIdUsingMutateProps {
  courseInstanceId: string
}
const useGetParticipantsByCourseInstanceIdUsingMutate = () => {
  const client = useApiClient()
  const mutation = useMutation({
    mutationFn: (props: useGetParticipantsByCourseInstanceIdUsingMutateProps) =>
      client.courseInstance.getParticipantsByCourseInstanceId(
        props.courseInstanceId,
      ),
  })
  return mutation
}

// DELETE:/api/Course/{courseId}
const useDeleteParticipant = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: async (usercourseinstanceid: string) => {
      await client.course.deleteCourse(usercourseinstanceid)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['participants'])
      queryClient.refetchQueries(['participants'])
    },
  })
  return mutation
}

// POST:/api/CourseInstance/{courseInstanceId}/participants
interface useAddUserToCourseProps {
  courseInstanceId: string
  data: AddUserToCourseInstanceModel
}
const useAddUserToCourse = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (props: useAddUserToCourseProps) =>
      client.courseInstance.addNewParticipantToCourse(
        props.courseInstanceId,
        props.data,
      ),
    onSuccess: data => {
      queryClient.invalidateQueries(['participants'])
      queryClient.setQueryData(['participants'], data) //set query (oldData,newData)
      queryClient.refetchQueries(['participants']) // refetch queries that depend on the user data
    },
  })
  return mutation
}

// PUT:/api/CourseInstance/{courseInstanceId}/participants
interface useAddExistingUserToCourseProps {
  courseInstanceId: string
  user: { userId: string | undefined; Optional1?: string; Optional2?: string }
}
const useAddExistingUserToCourse = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (props: useAddExistingUserToCourseProps) =>
      client.courseInstance.assignExistingUserToCourse(
        props.courseInstanceId,
        props.user,
      ),
    onSuccess: async data => {
      queryClient.invalidateQueries(['participants']) // refetch queries that depend on the user data
      queryClient.setQueryData(['participants'], data) //set query (oldData,newData)
    },
  })
  return mutation
}

// GET:/api/CourseInstance/{courseInstanceId}
export interface useGetCourseInstanceByIdProps {
  courseInstanceId: string
}
const useGetCourseInstanceById = () => {
  const client = useApiClient()
  const mutation = useMutation({
    mutationFn: (props: useGetCourseInstanceByIdProps) =>
      client.courseInstance.getCourseInstanceById(props.courseInstanceId),
  })
  return mutation
}

// PUT:/api/CourseInstance/{courseInstanceId}
interface useUpdateCourseInstanceProps {
  courseInstanceId: string
  data: UpdateCourseInstanceModel
}
const useUpdateCourseInstance = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (props: useUpdateCourseInstanceProps) =>
      client.courseInstance.updateCourseInstance(
        props.courseInstanceId,
        props.data,
      ),
    onSuccess: data => {
      queryClient.invalidateQueries(['courseInstances'])
      queryClient.setQueryData(['courseInstances'], data)
      queryClient.refetchQueries(['courseInstances'])
      queryClient.invalidateQueries(['courses-instances'])
      queryClient.setQueryData(['courses-instances'], data)
      queryClient.refetchQueries(['courses-instances'])
    },
  })
  return mutation
}

// GET:/api/CourseInstance/{courseInstanceId}/stats
const useGetStats = () => {
  const { courseInstanceId } = useParams()
  invariant(courseInstanceId, 'courseInstanceId not available')
  const client = useApiClient()

  const result = useQuery({
    queryKey: ['course-instance-stats', { courseInstanceId }],
    queryFn: () => client.courseInstance.getStats(courseInstanceId),
    enabled: !!courseInstanceId,
  })
  return { ...result, courseInstanceStatData: result.data?.data ?? [] }
}

export {
  useGetParticipantsByCourseInstanceId,
  useGetParticipantsByCourseInstanceIdUsingMutate,
  useAddUserToCourse,
  useAddExistingUserToCourse,
  useDeleteParticipant,
  useGetCourseInstanceById,
  useUpdateCourseInstance,
  useGetStats,
}
