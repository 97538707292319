import React, { useMemo } from 'react'
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table'
import { CourseModel } from 'api/data-contracts'
import { useGetModules } from 'utils/course'
import { useParams } from 'react-router-dom'
import '../../styles/table.css'
import { RowSelection } from 'utils/models/Resource'
import AnchorLink from 'components/AnchorLink'

type Props = {
  setModuleRowSelection: React.Dispatch<React.SetStateAction<RowSelection>>
  moduleRowSelection: RowSelection
}

const ModulesTable = ({
  moduleRowSelection: rowSelection,
  setModuleRowSelection: setRowSelection,
}: Props) => {
  const { modules, isError } = useGetModules()
  const { courseId } = useParams()

  const columns = useMemo<MRT_ColumnDef<CourseModel>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        Cell: ({ cell }) => (
          <AnchorLink
            className="p-0"
            to={`/settings/module-setup/details/${courseId}/${cell.id
              .split('_', 1)
              .toLocaleString()}`}
          >
            {cell.getValue<number>().toLocaleString()}
          </AnchorLink>
        ),
      },
      {
        accessorKey: 'description',
        header: 'Description',
      },
      {
        accessorKey: 'order',
        header: 'Order',
      },
    ],
    [courseId],
  )

  return (
    <MaterialReactTable
      columns={columns}
      data={modules ?? []}
      initialState={{
        showGlobalFilter: true,
        sorting: [{ id: 'order', desc: false }],
        columnVisibility: {
          name: true,
          description: true,
          order: false,
        },
      }}
      enableGlobalFilterModes
      muiSearchTextFieldProps={{
        placeholder: `Search`,
        sx: { maxWidth: '200px' },
        variant: 'outlined',
      }}
      muiTablePaperProps={{
        elevation: 0,
        sx: {
          '& tr > th > div': {
            display: 'flex',
            fontSize: '18px',
          },
          '& tr > th > div > div > span': {
            display: 'none',
          },
          borderRadius: '0',
          border: 'none',
        },
      }}
      enableRowSelection
      enableSelectAll={false}
      enableMultiRowSelection={false}
      getRowId={originalRow => originalRow.id || ''}
      onRowSelectionChange={setRowSelection}
      state={{
        rowSelection,
        showAlertBanner: isError,
        showProgressBars: false,
      }} //pass our managed row selection state to the table to use
      positionToolbarAlertBanner={'none'}
      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={true}
      enableSorting={true}
      enableBottomToolbar={true}
      enableTopToolbar={true}
      enableToolbarInternalActions={false}
      muiTableBodyRowProps={{ hover: false }}
    />
  )
}

export { ModulesTable }
