export const HoursAndMinutesToSecondsConverter = (
  hours: number,
  minutes: number,
) => {
  return hours * 3600 + minutes * 60
}

export const SecondsToHoursAndMinutesConverter = (seconds: number) => {
  const result = new Date(seconds * 1000).toISOString().slice(11, 19)
  const hours = result.slice(0, 2)
  const minutes = result.slice(3, 5)
  return { hours: parseInt(hours), minutes: parseInt(minutes) }
}
