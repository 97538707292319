import TitleHelmet from 'components/TitleHelmet'
import { PageTitle } from 'utils/constants/pageTitles'
import { MessageTemplateTable } from 'components/tables/MessageTemplateTable'

const MessageTemplates = () => {
  return (
    <>
      <TitleHelmet title={PageTitle.MESSAGE_TEMPLATES} />
      <h1>{PageTitle.MESSAGE_TEMPLATES}</h1>
      <MessageTemplateTable />
    </>
  )
}

export default MessageTemplates
