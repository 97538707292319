import { useMemo } from 'react'
import '../../styles/table.css'
import { useParams } from 'react-router-dom'
import { darken } from '@mui/material'
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table'

import { ResourceModel } from 'api/data-contracts'
import { RowSelection } from 'utils/models/Resource'
import AnchorLink from 'components/AnchorLink'

// API - custom hooks
import { useGetResources } from 'utils/course'

interface ResourcesTableProps {
  setResourceRowSelection: React.Dispatch<React.SetStateAction<RowSelection>>
  resourceRowSelection: RowSelection
}

const ResourcesTable = ({
  resourceRowSelection: rowSelection,
  setResourceRowSelection: setRowSelection,
}: ResourcesTableProps) => {
  const { courseId } = useParams()
  const { resources, isError } = useGetResources(courseId!)

  const columns = useMemo<MRT_ColumnDef<ResourceModel>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        Cell: ({ cell }) => (
          <AnchorLink
            to={`/settings/course-setup/resources/${courseId}/resource/${cell?.row?.original?.id}`}
          >
            {cell.getValue<number>().toLocaleString()}
          </AnchorLink>
        ),
      },
      {
        accessorKey: 'description',
        header: 'Description',
      },
      {
        accessorKey: 'resourceType',
        header: 'ResourceType',
      },
    ],
    [courseId],
  )

  if (isError) {
    return <span>Error loading resources</span>
  }

  if (typeof resources === 'undefined') {
    return <div>No resources found</div>
  }

  return (
    <MaterialReactTable
      columns={columns}
      data={resources}
      initialState={{
        showGlobalFilter: true,
      }}
      enableGlobalFilterModes
      muiSearchTextFieldProps={{
        placeholder: `Search`,
        sx: { maxWidth: '200px' },
        variant: 'outlined',
      }}
      muiTablePaperProps={{
        elevation: 0,
        sx: {
          '& tr > th > div': {
            display: 'flex',
            fontSize: '18px',
          },
          '& tr > th > div > div > span': {
            display: 'none',
          },
          borderRadius: '0',
          border: 'none',
        },
      }}
      muiTableBodyProps={{
        sx: theme => ({
          '& tr:nth-of-type(odd)': {
            backgroundColor: darken(theme.palette.background.default, 0.1),
          },
        }),
      }}
      muiTableBodyCellProps={{
        sx: {
          lineHeight: '1.6', //add a border between columns
        },
      }}
      enableRowSelection
      enableSelectAll={false}
      enableMultiRowSelection={true}
      getRowId={originalRow => originalRow.id || ''}
      onRowSelectionChange={setRowSelection}
      state={{
        rowSelection,
        showAlertBanner: isError,
        showProgressBars: false,
      }} //pass our managed row selection state to the table to use
      positionToolbarAlertBanner={'none'}
      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={true}
      enableSorting={true}
      enableBottomToolbar={true}
      enableTopToolbar={true}
      enableToolbarInternalActions={false}
      muiTableBodyRowProps={{ hover: false }}
    />
  )
}

export { ResourcesTable }
