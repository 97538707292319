import { RadioGroup, FormControlLabel, Radio, Button } from '@mui/material'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd'
import { AnswerDataModel } from 'utils/question'

import DeleteIcon from '@mui/icons-material/Delete'

interface SpecificOrderProps {
  answerData: AnswerDataModel[]
  onClickDeleteAnswerHandler: (value: number, id: string) => void
  setAnswerData: (value: React.SetStateAction<AnswerDataModel[]>) => void
}

const SpecificOrder = ({
  answerData,
  onClickDeleteAnswerHandler,
  setAnswerData,
}: SpecificOrderProps) => {
  // Drag and drop
  const reorder = (
    list: AnswerDataModel[],
    startIndex: number,
    endIndex: number,
  ): AnswerDataModel[] => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const newItems = reorder(
      answerData as AnswerDataModel[],
      result.source.index,
      result.destination.index,
    )
    setAnswerData(newItems)
  }

  return (
    <RadioGroup>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {answerData && answerData.length
                ? answerData.map((key, index) => (
                    <Draggable
                      key={key.answerText}
                      draggableId={key.answerText as string}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          className="questionCheckedboxWrapper"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <FormControlLabel
                            value={key.answerText}
                            control={<Radio checked={key.isCorrect === true} />}
                            label={key.answerText}
                            labelPlacement="start"
                            onClick={() => {
                              setAnswerData(prevTabs =>
                                prevTabs.map(prevTab =>
                                  prevTab.answerText === key.answerText
                                    ? { ...prevTab, isCorrect: true }
                                    : { ...prevTab, isCorrect: false },
                                ),
                              )
                            }}
                          />
                          <Button
                            sx={{ background: '#fff' }}
                            onClick={() =>
                              onClickDeleteAnswerHandler(
                                index,
                                key.id as string,
                              )
                            }
                          >
                            <DeleteIcon />
                          </Button>
                        </div>
                      )}
                    </Draggable>
                  ))
                : ''}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </RadioGroup>
  )
}

export default SpecificOrder
