import { useContext } from 'react'
import { toast } from 'react-toastify'

// API - custom hooks
import { useAddContentItemToContentSection } from 'utils/contentSection'
import { useGetSectionById } from 'utils/section'

// Contexts
import { NewContentSectionContext } from 'components/moduleSlides/NewContentSectionContext'
import { CurrentSectionContext } from 'components/moduleSlides/CurrentSectionContext'

// Constants
import { CONTENT, EMBED, IMAGE } from 'utils/constants/sections'
import { SectionModel } from 'utils/models/Slide'

interface ContentChoiceProps {
  contentSectionId: string
  contentOrderId: string
}
const ChooseContentType = ({
  contentSectionId,
  contentOrderId,
}: ContentChoiceProps) => {
  const { setIsContentItemTypeSelectionVisible } = useContext(
    NewContentSectionContext,
  )
  const { currentSection, setCurrentSection } = useContext(
    CurrentSectionContext,
  )
  const { mutateAsync: addContentItemToContentSection } =
    useAddContentItemToContentSection()
  const { mutateAsync: getSectionById } = useGetSectionById()

  const contentItemTypeHandler = async (itemColumnType: string) => {
    setIsContentItemTypeSelectionVisible(false)

    const data = {
      ContentItemType: itemColumnType,
      Order: +contentOrderId,
    }

    try {
      await addContentItemToContentSection({
        contentSectionId: contentSectionId,
        data: data,
      })
      const getSectionByIdData = await getSectionById({
        sectionId: currentSection?.id as string,
      })

      setCurrentSection(getSectionByIdData.data as SectionModel)
      toast.success('Content item added')
    } catch {
      toast.error('Unable to add content item')
    }
  }

  return (
    <div className="options-choice">
      <button
        className="slide-heading-button w-200px m-0"
        onClick={() => contentItemTypeHandler(CONTENT)}
      >
        Text editor
      </button>
      <button
        className="slide-heading-button w-200px m-0"
        onClick={() => contentItemTypeHandler(IMAGE)}
      >
        Image
      </button>
      <button
        className="slide-heading-button w-200px m-0"
        onClick={() => contentItemTypeHandler(EMBED)}
      >
        Embed video
      </button>
    </div>
  )
}

export default ChooseContentType
