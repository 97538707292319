import ButtonLoaderSpinner from './ButtonLoaderSpinner'

interface CustomButtonProps {
  name: string
  background?: string
  setAction?: () => void
  id?: string
  className?: string
  spinner?: boolean
}

export const ActionButton = ({
  name,
  background,
  setAction,
  id,
  className,
  spinner,
}: CustomButtonProps) => {
  return (
    <button
      id={id}
      className={`${className} action-btn`}
      style={{ background: background }}
      onClick={setAction}
    >
      {name}
      {spinner && (
        <ButtonLoaderSpinner
          spinnerColor="#fff"
          spinnerSize={17}
          loadingState={spinner}
        />
      )}
    </button>
  )
}
