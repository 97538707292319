import type { ReactNode } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { useBranding } from 'utils/useBranding'

type ThemeProps = { children: ReactNode }

const ThemeContext = ({ children }: ThemeProps) => {
  const { branding } = useBranding()

  const theme = createTheme({
    palette: {
      primary: {
        light: '#fff',
        main: `${branding.stylingPrimary}`,
        dark: `${branding.stylingPrimary}`,
        contrastText: '#fff',
      },

      background: {
        default: '#F7F8FA',
      },
    },
    typography: {
      fontFamily: ['Muli', ' sans-serif'].join(','),
      fontSize: 16,
      fontWeightMedium: 400,
    },
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            background: '#F6F6F6',
            width: 'fit-content',
            borderRight: 'solid 1px #ccc',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          sizeSmall: {
            padding: '10px',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'initial',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            width: '100%',
          },
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export { ThemeContext }
