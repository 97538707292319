import { useContext } from 'react'
import { toast } from 'react-toastify'

// API - custom hooks
import { useAddNewSectionToModule } from 'utils/module'
import { useAddContentSectionToSection, useGetSectionById } from 'utils/section'

// Contexts
import { CurrentSectionContext } from 'components/moduleSlides/CurrentSectionContext'
import { NewSectionContext } from 'components/moduleSlides/NewSectionContext'
import { NewContentSectionContext } from 'components/moduleSlides/NewContentSectionContext'

// Constants
import {
  CONTENT,
  CONTENT_ONE_COLUMN,
  HUNDRED,
  FIFTY_FIFTY,
  THIRTYTHREE_THIRTYTHREE_THIRTYTHREE,
} from 'utils/constants/sections'
import { SectionModel } from 'utils/models/Slide'

const ChooseContentSection = () => {
  const { setIsContentSectionSelectionVisible } = useContext(
    NewContentSectionContext,
  )
  const { title, setTitle } = useContext(NewSectionContext)
  const { currentSection, setCurrentSection } = useContext(
    CurrentSectionContext,
  )

  const { mutateAsync: addNewSectionToModule } = useAddNewSectionToModule()
  const { mutateAsync: addContentSectionToSection } =
    useAddContentSectionToSection()
  const { mutateAsync: getSectionById } = useGetSectionById()

  const handleButtonClick = async (numberOfColumn: number) => {
    const newSectionRequiredData = {
      Name: title?.length ? title : 'New Section',
      SectionTemplate: CONTENT_ONE_COLUMN,
      SectionType: CONTENT,
      Title: title?.length ? title : 'New Section',
    }

    try {
      if (currentSection === undefined) {
        const { data } = await addNewSectionToModule({
          data: newSectionRequiredData,
        })

        const sectionId = data.id

        await addContentSectionToSection({
          sectionId: sectionId as string,
          data: {
            numberOfCols: numberOfColumn,
            colWeight:
              numberOfColumn === 1
                ? HUNDRED
                : numberOfColumn === 2
                ? FIFTY_FIFTY
                : numberOfColumn === 3
                ? THIRTYTHREE_THIRTYTHREE_THIRTYTHREE
                : '',
          },
        })

        const getSectionByIdData = await getSectionById({
          sectionId: sectionId as string,
        })

        setCurrentSection(getSectionByIdData.data as SectionModel)
        setTitle(getSectionByIdData.data.name as string)
      }

      if (currentSection) {
        await addContentSectionToSection({
          sectionId: currentSection.id as string,
          data: {
            numberOfCols: numberOfColumn,
            colWeight:
              numberOfColumn === 1
                ? HUNDRED
                : numberOfColumn === 2
                ? FIFTY_FIFTY
                : numberOfColumn === 3
                ? THIRTYTHREE_THIRTYTHREE_THIRTYTHREE
                : '',
          },
        })
        const getSectionByIdData = await getSectionById({
          sectionId: currentSection.id as string,
        })

        setCurrentSection(getSectionByIdData.data as SectionModel)
        setTitle(getSectionByIdData.data.name as string)
      }

      toast.success('Content section added')
      setIsContentSectionSelectionVisible(false)
    } catch {
      toast.error('Unable to add slide')
    }
  }

  return (
    <div className="options-choice">
      <button
        className="slide-heading-button w-190px"
        onClick={() => handleButtonClick(1)}
      >
        Single column
      </button>
      <button
        className="slide-heading-button w-190px"
        onClick={() => handleButtonClick(2)}
      >
        Two column
      </button>
      <button
        className="slide-heading-button w-190px m-0"
        onClick={() => handleButtonClick(3)}
      >
        Three column
      </button>
    </div>
  )
}

export default ChooseContentSection
