import { Suspense, ElementType } from 'react'

// project imports
import Loader from './Loader'

// ==============================|| LOADABLE - LAZY LOADING ||============================== //
interface Props<T> {
  props?: T
}

const Loadable =
  <T extends Record<string, unknown>>(Component: ElementType) =>
  (props: Props<T>) =>
    (
      <Suspense fallback={<Loader />}>
        <Component {...props} />
      </Suspense>
    )

export default Loadable
