import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Paper } from '@mui/material'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd'

import { UpdateModuleSectionOrderModel } from 'api/data-contracts'
import { SectionNavItem } from './SectionNavItem'
import { ContentSectionModel, SectionModel } from 'utils/models/Slide'

// API - custom hooks
import { useGetSections, useUpdateModuleSectionOrder } from 'utils/module'

// Contexts
import { CurrentSectionContext } from './CurrentSectionContext'
import { NewSectionContext } from './NewSectionContext'

const SectionNav = () => {
  const { moduleId } = useParams()
  const { sections } = useGetSections(moduleId!)
  const { currentSection, setCurrentSection } = useContext(
    CurrentSectionContext,
  )
  const {
    setStatus,
    setNewSection,
    title,
    setTitle,
    setIsNewSlideEdited,
    isNewSlideEdited,
    setSlideContentLossMessageVisible,
    setNavigateAction,
  } = useContext(NewSectionContext)

  const handleSetCurrentSection = (section: SectionModel) => {
    setCurrentSection(section as SectionModel)
  }

  const { mutateAsync } = useUpdateModuleSectionOrder(moduleId!)

  const reorder = (
    list: SectionModel[],
    startIndex: number,
    endIndex: number,
  ): SectionModel[] => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const newItems = reorder(
      sections as SectionModel[],
      result.source.index,
      result.destination.index,
    )

    const getOnlyIdsFromArray: string[] = newItems.map(
      value => value.id as string,
    )

    if (sections !== undefined) {
      const data: UpdateModuleSectionOrderModel = {
        orderedSectionsIds: getOnlyIdsFromArray,
      }
      mutateAsync({
        data,
      })
    }
  }

  const addSectionHandler = () => {
    setStatus('inprogress')
    setCurrentSection(undefined)
    setTitle(undefined)
    setNewSection({
      Name: title,
      Title: 'Section',
      AssessmentMarkProcess: 'AUTO',
      AssessmentQuestionOrder: 'RANDOM',
    })
  }

  return (
    <div>
      <div className="text-center">
        <Paper
          onClick={e => {
            if (isNewSlideEdited) {
              setSlideContentLossMessageVisible(true)
              setNavigateAction({
                actionFunc: () => {
                  addSectionHandler()
                  setIsNewSlideEdited(false)
                },
              })
            } else {
              addSectionHandler()
              setIsNewSlideEdited(false)
            }
          }}
          sx={{
            border: '1.5px solid darkgray',
            margin: '8px',
            width: '150px',
            height: '100px',
            fontSize: '13px',
            padding: '5px',
            borderRadius: '0px!important',
            display: 'flex',
            flexDirection: 'column',
            cursor: 'pointer',
          }}
        >
          <div>Add section</div>
        </Paper>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <Box
              sx={{
                display: 'flex',
                '& > :not(style)': {
                  m: 1,
                  width: 128,
                  height: 128,
                },
                flexDirection: 'column',
              }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {sections?.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id as string}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      className="h-full"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <SectionNavItem
                        key={item.id}
                        section={item as ContentSectionModel}
                        selected={item.id === currentSection?.id}
                        onSelect={handleSetCurrentSection}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

export { SectionNav }
