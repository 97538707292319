import {
  Button,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { useState, useEffect, useCallback } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'

import { useBranding } from 'utils/useBranding'
import { AddCourseInstanceToCourseModel as CourseInstanceModel } from 'api/data-contracts'
import { FormTextField } from 'components/FormTextField'
import { ActionButton } from 'components/ActionButton'
import { PageTitle } from 'utils/constants/pageTitles'
import dayjs from 'dayjs'

// API - custom hooks
import { useAddCourseInstanceToCourse } from 'utils/course'
import {
  useGetCourseInstanceById,
  useUpdateCourseInstance,
} from 'utils/courseInstance'
import {
  useGetOrganisationID,
  useGetOrganisationUsersWithRoles,
} from 'utils/organisation'

const validationSchema = Yup.object<CourseInstanceModel>().shape({
  title: Yup.string().required('Title is required'),
  subTitle: Yup.string().required('Sub title is required'),
  description: Yup.string().required('Description is required'),
  startDate: Yup.string().nullable().required('Start date is required'),
  endDate: Yup.string().nullable(),
  // trainerId: Yup.string(),
  location: Yup.string().required('Location is required'),
  instanceStatus: Yup.string().required('Instance status is required'),
  instanceDisplayType: Yup.string().required(
    'Instance display type is required',
  ),
  numAttendees: Yup.string().required('Number of attendees is required'),
  cost: Yup.string().required('Cost is required'),
  bookingInfo: Yup.string().required('Booking info is required'),
  useOptional1: Yup.boolean(),
  optional1: Yup.string().when('useOptional1', ([useOptional1], schema) => {
    if (useOptional1 === true) {
      return Yup.string().required('Optional 1 is required')
    }
    return schema
  }),
  optional1Values: Yup.string(),
  useOptional2: Yup.boolean(),
  optional2: Yup.string().when('useOptional2', ([useOptional2], schema) => {
    if (useOptional2 === true) {
      return Yup.string().required('Optional 2 is required')
    }
    return schema
  }),
  optional2Values: Yup.string(),
})

interface CourseInstanceDetailsProps {
  courseID: string
  setCourseID: React.Dispatch<React.SetStateAction<string>>
  drawerClose: () => void
  id?: string
  settingCourseInstanceId?: string
}

const CourseInstanceDetails = ({
  courseID,
  setCourseID,
  drawerClose,
  id,
  settingCourseInstanceId,
}: CourseInstanceDetailsProps) => {
  const { branding } = useBranding()
  const { courseInstanceId } = useParams()
  const { mutateAsync: addCourseInstanceToCourse } =
    useAddCourseInstanceToCourse()
  const { organisationId } = useGetOrganisationID()
  const { mutateAsync: getCourseInstanceById } = useGetCourseInstanceById()
  const { mutateAsync: updateCourseInstance } = useUpdateCourseInstance()
  const { usersWithRoles } = useGetOrganisationUsersWithRoles({
    organisationId: organisationId,
  })

  const [singleParticipant, setSingleParticipant] =
    useState<CourseInstanceModel>({})

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm<CourseInstanceModel>({
    resolver: yupResolver(validationSchema),
  })

  const isUseOptional1Checked = watch('useOptional1')
  const isUseOptional2Checked = watch('useOptional2')

  useEffect(() => {
    setValue('useOptional1', false)
    setValue('useOptional2', false)
  }, [setValue])

  const addDay = (date: string) => {
    const result = new Date(date)
    id === 'EDIT'
      ? result.setDate(result.getDate())
      : result.setDate(result.getDate() + 1)
    return result.toISOString()
  }

  const getCourseInstance = useCallback(async () => {
    try {
      if (settingCourseInstanceId) {
        const { data } = await getCourseInstanceById({
          courseInstanceId: settingCourseInstanceId as string,
        })
        setSingleParticipant(data as CourseInstanceModel)
      }
      if (courseInstanceId) {
        const { data } = await getCourseInstanceById({
          courseInstanceId: courseInstanceId as string,
        })
        setSingleParticipant(data as CourseInstanceModel)
      }
    } catch {
      toast.error('Unable to get course instance data')
    }
  }, [settingCourseInstanceId, courseInstanceId, getCourseInstanceById])

  useEffect(() => {
    id === 'EDIT' && getCourseInstance()
  }, [getCourseInstance, id])

  useEffect(() => {
    if (id === 'EDIT') {
      console.log(singleParticipant)
      reset({
        ...singleParticipant,
        optional1:
          singleParticipant?.optional1 !== null
            ? singleParticipant?.optional1
            : '',
        optional1Values:
          singleParticipant?.optional1Values !== null
            ? singleParticipant?.optional1Values
            : '',
        optional2:
          singleParticipant?.optional2 !== null
            ? singleParticipant?.optional2
            : '',
        optional2Values:
          singleParticipant?.optional2Values !== null
            ? singleParticipant?.optional2Values
            : '',
      })
    }
  }, [singleParticipant, setValue, id, reset])

  const dateFunc = (value: string | null | undefined) => {
    return value === null || value === undefined
      ? ''
      : id === 'EDIT'
      ? dayjs(value)
      : value
  }

  const onSubmit: SubmitHandler<CourseInstanceModel> = async data => {
    drawerClose()

    const courseInstanceData = {
      ...data,
      startDate: addDay(data.startDate as string),
      endDate: data.endDate ? addDay(data.endDate) : null,
      trainerId: data.trainerId ?? null,
      optional1:
        data.useOptional1 === false || data.optional1 === ''
          ? null
          : data.optional1,
      optional1Values:
        data.useOptional1 === false || data.optional1Values === ''
          ? null
          : data.optional1Values,
      optional2:
        data.useOptional2 === false || data.optional2 === ''
          ? null
          : data.optional2,
      optional2Values:
        data.useOptional2 === false || data.optional2Values === ''
          ? null
          : data.optional2Values,
    }

    try {
      if (id === 'EDIT') {
        if (settingCourseInstanceId) {
          await updateCourseInstance({
            courseInstanceId: settingCourseInstanceId as string,
            data: courseInstanceData as CourseInstanceModel,
          })
        }
        if (courseInstanceId) {
          await updateCourseInstance({
            courseInstanceId: courseInstanceId as string,
            data: courseInstanceData as CourseInstanceModel,
          })
        }
        toast.success('Course instance updated')
      } else {
        await addCourseInstanceToCourse({
          courseId: courseID,
          data: courseInstanceData as CourseInstanceModel,
        })
        toast.success('New course instance created')
      }
    } catch {
      toast.error(
        id === 'EDIT'
          ? 'Unable to update course instance'
          : 'Unable to create course instance',
      )
    }
  }

  return (
    <div className="mt-20">
      {id !== 'EDIT' && (
        <Button
          className="mb-20 p-0 mt--10 fw-700 bg-transparent"
          style={{ color: `${branding.stylingSecondary}` }}
          onClick={() => setCourseID('')}
        >
          {'<'} {PageTitle.NAV_BACK}
        </Button>
      )}
      <form>
        <FormTextField
          control={control}
          errors={errors?.title}
          name="title"
          label="Title"
          length={true}
        />

        <FormTextField
          control={control}
          errors={errors?.subTitle}
          name="subTitle"
          label="Sub title"
          length={true}
        />

        <FormTextField
          control={control}
          errors={errors?.description}
          name="description"
          label="Description"
          multilineValue={2}
          length={true}
        />

        <Controller
          name={'startDate'}
          control={control}
          render={({ field: { onChange, value } }) => (
            <div className="w-full mb-25">
              <p className="text-label">Start date</p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="DD-MM-YYYY"
                  value={dateFunc(value)}
                  onChange={onChange}
                  className="w-full bg-white border-none outline-none"
                />
              </LocalizationProvider>
              {errors?.startDate !== undefined && (
                <p className="error-text">{errors?.startDate.message}</p>
              )}
            </div>
          )}
        />

        <Controller
          name={'endDate'}
          control={control}
          render={({ field: { onChange, value } }) => (
            <div className="w-full mb-25">
              <p className="text-label">End date</p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="DD-MM-YYYY"
                  value={dateFunc(value)}
                  onChange={onChange}
                  className="w-full bg-white border-none outline-none"
                />
              </LocalizationProvider>
              {errors?.endDate !== undefined && (
                <p className="error-text"> {errors?.endDate.message}</p>
              )}
            </div>
          )}
        />
        <FormTextField
          control={control}
          errors={errors?.location}
          name="location"
          label="Location"
          length={true}
        />
        <Controller
          name={'instanceStatus'}
          control={control}
          render={({ field: { onChange, value } }) => (
            <div className="w-full mb-25">
              <p className="text-label">Instance status</p>
              <Select
                value={value === null || value === undefined ? '' : value}
                onChange={onChange}
                error={errors.instanceStatus?.message !== undefined}
                className="bg-white"
              >
                <MenuItem value={'INACTIVE'}>Inactive</MenuItem>
                <MenuItem value={'ACTIVE'}>Active</MenuItem>
              </Select>
              {errors?.instanceStatus !== undefined && (
                <p className="error-text">{errors?.instanceStatus.message}</p>
              )}
            </div>
          )}
        />

        <Controller
          name={'instanceDisplayType'}
          control={control}
          render={({ field: { onChange, value } }) => (
            <div className="w-full mb-25">
              <p className="text-label">Instance display type</p>
              <Select
                value={value === null || value === undefined ? '' : value}
                onChange={onChange}
                error={errors.instanceDisplayType?.message !== undefined}
                className="bg-white"
              >
                <MenuItem value={'PUBLIC'}>Public</MenuItem>
                <MenuItem value={'PRIVATE'}>Private</MenuItem>
              </Select>
              {errors?.instanceDisplayType !== undefined && (
                <p className="error-text">
                  {errors?.instanceDisplayType.message}
                </p>
              )}
            </div>
          )}
        />

        <Controller
          name="trainerId"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div className="w-full mb-25">
              <p className="text-label">Trainer</p>
              <Select
                value={value === null || value === undefined ? '' : value}
                onChange={onChange}
                error={errors.trainerId?.message !== undefined}
                className="bg-white"
              >
                <MenuItem style={{ height: '70px' }} value={undefined}>
                  N/A
                </MenuItem>
                {usersWithRoles &&
                  usersWithRoles.map((user, index) => (
                    <MenuItem key={user.id} value={user.id}>
                      <p>
                        {user.firstname} {user.lastname}{' '}
                        {user.userOrgRoles &&
                          user.userOrgRoles.map((orgRole, index) => (
                            <span className="trainerRole" key={index}>
                              {orgRole.organisationRole?.name}
                            </span>
                          ))}{' '}
                      </p>
                    </MenuItem>
                  ))}
              </Select>
              {errors?.trainerId !== undefined && (
                <p className="error-text">{errors?.trainerId.message}</p>
              )}
            </div>
          )}
        />

        <Controller
          name="numAttendees"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div className="w-full mb-25">
              <p className="text-label">Number of attendees</p>
              <TextField
                variant="outlined"
                type="number"
                autoComplete="off"
                value={value === null || value === undefined ? '' : value}
                className="w-full bg-white border-8"
                onChange={onChange}
              />
              {errors?.numAttendees !== undefined && (
                <p className="error-text">{errors?.numAttendees.message}</p>
              )}
            </div>
          )}
        />

        <Controller
          name={'cost'}
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <p className={`text-label`}>Cost</p>
              <div className="mb-20">
                <OutlinedInput
                  type="number"
                  value={value === null || value === undefined ? '' : value}
                  onChange={onChange}
                  className="border-8 bg-white"
                  startAdornment={
                    <InputAdornment position="start">£</InputAdornment>
                  }
                />
                {errors?.cost !== undefined && (
                  <p className="error-text">{errors?.cost.message}</p>
                )}
              </div>
            </>
          )}
        />
        <FormTextField
          control={control}
          errors={errors?.bookingInfo}
          name="bookingInfo"
          label="Booking info"
          length={true}
        />

        <p className="text-label">User Demographic Data</p>
        <p className="mt-0 fs-14 fw-600">
          These optional fields can be used to capture additional user data. For
          example, Organisation, Country, Department
        </p>

        <Controller
          name="useOptional1"
          control={control}
          render={({ field: { onChange } }) => (
            <div className="mt-10 flex align-center">
              <input
                type="checkbox"
                className="chechbox-roles"
                name="useOptional1"
                checked={
                  isUseOptional1Checked !== undefined
                    ? isUseOptional1Checked
                    : false
                }
                onChange={onChange}
              />
              <label htmlFor="useOptional1">Optional 1</label>
            </div>
          )}
        />

        {isUseOptional1Checked && (
          <>
            <FormTextField
              control={control}
              errors={errors?.optional1}
              name="optional1"
              label="Optional 1"
              length={true}
            />

            <FormTextField
              control={control}
              errors={errors?.optional1Values}
              name="optional1Values"
              label="Optional 1 values"
              length={true}
              subLabel="Leave empty for a free text answer, or add a list separated by commas for a prepopulated dropdown, e.g. England, Wales, Scotland, etc. "
            />
          </>
        )}

        <Controller
          name="useOptional2"
          control={control}
          render={({ field: { onChange } }) => (
            <div className="mt-10 flex align-center">
              <input
                type="checkbox"
                className="chechbox-roles"
                name="useOptional2"
                checked={
                  isUseOptional2Checked !== undefined
                    ? isUseOptional2Checked
                    : false
                }
                onChange={onChange}
              />
              <label htmlFor="useOptional2">Optional 2</label>
            </div>
          )}
        />

        {isUseOptional2Checked && (
          <>
            <FormTextField
              control={control}
              errors={errors?.optional2}
              name="optional2"
              label="Optional 2"
              length={true}
            />
            <FormTextField
              control={control}
              errors={errors?.optional2Values}
              name="optional2Values"
              label="Optional 2 values"
              length={true}
              subLabel="Leave empty for a free text answer, or add a list separated by commas for a prepopulated dropdown, e.g. England, Wales, Scotland, etc. "
            />
          </>
        )}
        <br />

        <ActionButton
          name={id === 'EDIT' ? 'Update' : 'Create'}
          background={branding.stylingPrimary ?? 'blue'}
          setAction={handleSubmit(onSubmit)}
        />
      </form>
    </div>
  )
}

export default CourseInstanceDetails
