import { useContext, useEffect } from 'react'
import { Box } from '@mui/material'
import { StandardButton } from 'components/lib'

import {
  SINGLESELECT,
  MULTISELECT,
  SPECIFICORDER,
  FREETEXT,
} from 'utils/constants/questions'
import {
  getQuestionList,
  getQuestionType,
  setQuestionType,
} from 'utils/question'
import { QuestionContext } from './QuestionContext'
import AllQuestionType from './QuestionType'

const Questions = () => {
  const { queType, setQueType } = useContext(QuestionContext)
  const questionListValue = getQuestionList()
  const questionTypeValue = getQuestionType()

  useEffect(() => {
    questionTypeValue === null && setQueType('')
    questionTypeValue !== null && setQueType(questionTypeValue)
  }, [questionTypeValue, setQueType])

  return (
    <>
      {questionListValue === 'false' && queType === '' && (
        <Box
          sx={{
            width: '100%',
            height: 'auto',
            overflow: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 'calc(100% - 120px)',
            bgcolor: '#BCBFBE',
            borderRadius: 0,
            backgroundColor: '#BCBFBE',
          }}
        >
          <Box
            sx={{
              padding: '35px',
              bgcolor: 'rgba(255,255,255,0.6)',
              borderRadius: 5,
              minWidth: '80%',
              minHeight: '200px',
              maxHeight: 'calc(100% - 40px)',
              overflow: 'auto',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
            }}
          >
            <StandardButton
              sx={{
                width: '100%',
                margin: '0 20px',
                textAlign: 'center',
              }}
              variant="outlined"
              onClick={() => {
                setQuestionType(MULTISELECT)
                setQueType(MULTISELECT)
              }}
            >
              Multiple choice
            </StandardButton>
            <StandardButton
              sx={{
                width: '100%',
                margin: '0 20px',
                textAlign: 'center',
              }}
              variant="outlined"
              onClick={() => {
                setQuestionType(SPECIFICORDER)
                setQueType(SPECIFICORDER)
              }}
            >
              Specific order
            </StandardButton>
            <StandardButton
              sx={{
                width: '100%',
                margin: '0 20px',
                textAlign: 'center',
              }}
              variant="outlined"
              onClick={() => {
                setQuestionType(SINGLESELECT)
                setQueType(SINGLESELECT)
              }}
            >
              Single choice
            </StandardButton>
            <StandardButton
              sx={{
                width: '100%',
                margin: '0 20px',
                textAlign: 'center',
              }}
              variant="outlined"
              onClick={() => {
                setQuestionType(FREETEXT)
                setQueType(FREETEXT)
              }}
            >
              Free text
            </StandardButton>
          </Box>
        </Box>
      )}

      {questionListValue === 'false' && queType !== '' && <AllQuestionType />}
    </>
  )
}

export default Questions
