import { Header } from '../lib'
import { Drawer } from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { toast } from 'react-toastify'

import {
  useAddTopicToOrganisation,
  useGetOrganisationID,
} from 'utils/organisation'
import { AddTopicOrganisationModel } from 'api/data-contracts'
import { FormTextField } from 'components/FormTextField'
import { ActionButton } from 'components/ActionButton'
import { useBranding } from 'utils/useBranding'

const validationSchema = Yup.object<AddTopicOrganisationModel>().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
})

interface AddTopicPopoutProps {
  drawerVisibility: boolean
  drawerClose: () => void
}

const AddTopicPopout = ({
  drawerVisibility,
  drawerClose,
}: AddTopicPopoutProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AddTopicOrganisationModel>({
    resolver: yupResolver(validationSchema),
  })
  const { branding } = useBranding()
  const { organisationId } = useGetOrganisationID()
  const { mutateAsync: addTopic } = useAddTopicToOrganisation()

  const onClickAddTopic: SubmitHandler<
    AddTopicOrganisationModel
  > = async data => {
    try {
      if (organisationId) {
        await addTopic({
          organisationId: organisationId,
          data: {
            name: data.name!,
            description: data.description!,
          },
        })
      }
      toast.success('Topic added.')
    } catch {
      toast.error('Unable to add Topic')
    }
    drawerClose()
  }

  return (
    <Drawer
      anchor={'right'}
      open={drawerVisibility}
      onClose={() => drawerClose()}
    >
      <div className="m-3rem mt-5rem">
        <Header variant="signin">Create a topic</Header>
        <form>
          <FormTextField
            control={control}
            errors={errors?.name}
            name="name"
            label="Name"
            length={true}
          />
          <FormTextField
            control={control}
            errors={errors?.description}
            name="description"
            label="Description"
            length={true}
          />
          <ActionButton
            name="Create"
            background={branding.stylingPrimary ?? 'blue'}
            setAction={handleSubmit(onClickAddTopic)}
          />
        </form>
      </div>
    </Drawer>
  )
}

export { AddTopicPopout }
