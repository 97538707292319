interface OverviewFlexBoxProps {
  title: string
  amount: string
  description?: string
}

const OverviewFlexBox = ({
  title,
  amount,
  description,
}: OverviewFlexBoxProps) => {
  return (
    <div className="flex-box">
      <h2 className="mt-5 title">{title}</h2>
      <h1
        className={`pl-20 fw-700 ${
          isNaN(+amount) ? 'amount-description' : 'amount'
        }`}
      >
        {amount}
      </h1>
      {/* <hr className="width-full-60" />
      <p className="text-center mb-5 fw-600">{description ?? 'View'}</p> */}
    </div>
  )
}

export default OverviewFlexBox
