import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'

import { ActionButton } from 'components/ActionButton'
import { useBranding } from 'utils/useBranding'
import { useOrganisationUserRoles } from 'utils/organisation'
import { useGetProfile } from 'utils/auth'

// API - custom hooks
import { useChangePassword, useUpdateLockedState } from 'utils/user'

// Icons
import InvisibleIcon from '../../../assets/icon/Invisible.svg'
import VisibleIcon from '../../../assets/icon/Visible.svg'
import { passwordValidation } from 'utils/validation/passwords'

interface AccountaccessModel {
  password?: string
  confirmpassword?: string
}

const validationSchema = Yup.object<AccountaccessModel>().shape({
  password: passwordValidation,
  confirmpassword: Yup.string()
    .nullable()
    .oneOf([Yup.ref('password'), null], 'Both passwords should match')
    .required('Confirm password is required'),
})
interface AccountaccessProps {
  userId: string
}
const Accountaccess = ({ userId }: AccountaccessProps) => {
  const { branding } = useBranding()
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
  const { mutateAsync: changePassword } = useChangePassword()
  const { mutateAsync: updateLockedState } = useUpdateLockedState()
  const { orgUserRoles } = useOrganisationUserRoles(userId!)
  const { profile } = useGetProfile()

  const [isUserIncludesAdminRole, setIsUserIncludesAdminRole] = useState(false)
  const [passwordVisible, setPasswordVisible] = useState(false)

  // const [isUserDeleteConfirmationPopout, setIsUserDeleteConfirmationPopout] =
  //   useState(false)

  useEffect(() => {
    const getUserRolesHandler = async () => {
      await setIsUserIncludesAdminRole(
        orgUserRoles.some(userRole => userRole.name === 'Admin'),
      )
    }
    getUserRolesHandler()
  }, [orgUserRoles])

  // Reset passowrd functiionality
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AccountaccessModel>({
    resolver: yupResolver(validationSchema),
  })
  const onSubmit: SubmitHandler<AccountaccessModel> = async data => {
    try {
      await changePassword({
        userid: userId!,
        data: {
          password: data.password,
        },
      })
      toast.success('Password updated')
    } catch {
      toast.error('Unable to update password')
    }
  }

  // Suspend account functionality
  const suspendAccountHandler = async (lockValue: boolean) => {
    try {
      await updateLockedState({
        userid: userId!,
        query: {
          isLocked: lockValue,
        },
      })
      toast.success(`User's account suspended`)
    } catch {
      toast.error(`Unable to suspend user's account`)
    }
  }

  // Delete user functionality
  // const userDeleteHandler = () => {
  //   // API call integration
  // }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-20">
        <p className="pt-10 fw-700 mb-5 fs-20">Reset password</p>
        <Controller
          name="password"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div className="flex flex-column pt-10">
              <label className="fw-600 font-family-muli">Password</label>
              <div className="flex position-relative">
                <input
                  className="login-input-field font-family-muli w-full bg-white p-20"
                  value={value === null || value === undefined ? '' : value}
                  onChange={onChange}
                  placeholder="Password"
                  type={passwordVisible ? 'text' : 'password'}
                />
                <img
                  className="position-absolute passwordFieldEyeIcon"
                  src={passwordVisible ? InvisibleIcon : VisibleIcon}
                  alt="EyeIcon"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                />
              </div>
              {errors?.password !== undefined && (
                <p className="login-text-error font-family-muli fw-600">
                  {errors?.password.message}
                </p>
              )}
            </div>
          )}
        />
        <Controller
          name="confirmpassword"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div className="flex flex-column pt-10">
              <label className=" fw-600 font-family-muli">
                Confirm password
              </label>
              <div className="flex position-relative">
                <input
                  className="login-input-field font-family-muli w-full bg-white p-20"
                  value={value === null || value === undefined ? '' : value}
                  onChange={onChange}
                  placeholder="Confirm password"
                  type={confirmPasswordVisible ? 'text' : 'password'}
                />
                <img
                  className="position-absolute passwordFieldEyeIcon"
                  src={confirmPasswordVisible ? InvisibleIcon : VisibleIcon}
                  alt="EyeIcon"
                  onClick={() =>
                    setConfirmPasswordVisible(!confirmPasswordVisible)
                  }
                />
              </div>
              {errors?.confirmpassword !== undefined && (
                <p className="login-text-error font-family-muli fw-600">
                  {errors?.confirmpassword.message}
                </p>
              )}
            </div>
          )}
        />
      </div>
      <ActionButton
        name="Update password"
        background={branding.stylingPrimary ?? 'blue'}
        setAction={handleSubmit(onSubmit)}
      />

      <p className="pt-10 fw-700  mb-5 fs-20">Suspend account</p>
      <p>This will suspend user access. You can restore access at any time.</p>
      <div
        className={
          profile?.id === userId || isUserIncludesAdminRole
            ? 'pointer-event-none opacity-50'
            : ''
        }
      >
        <ActionButton
          name="Suspend access"
          background={branding.stylingPrimary ?? 'blue'}
          setAction={() => suspendAccountHandler(true)}
        />
        {/* <ActionButton
          name="Un-suspend access"
          background={branding.stylingPrimary ?? 'blue'}
          setAction={() => suspendAccountHandler(false)}
        /> */}
      </div>
      {/* <hr className="mt-30 mb-30" /> */}

      {/* <p className="fw-700  mt-0 mb-10 fs-20">Delete user</p>

      <ActionButton
        name="Delete account"
        background={branding.stylingPrimary ?? 'blue'}
        setAction={() => setIsUserDeleteConfirmationPopout(true)}
        className={
          profile?.id === userId || isUserIncludesAdminRole
            ? 'pointer-event-none opacity-50'
            : ''
        }
      />

      {isUserDeleteConfirmationPopout && (
        <ConfirmPopup
          title="Confirm deletion"
          message="Are you sure you want to delete this user?"
          onConfirm={userDeleteHandler}
          open={isUserDeleteConfirmationPopout}
          onCancel={() => setIsUserDeleteConfirmationPopout(false)}
        />
      )} */}
    </form>
  )
}

export default Accountaccess
