import { useContext, useEffect } from 'react'
import { Button } from '@mui/material'

import { QuestionsTable } from 'components/tables/QuestionsTable'
import { useBranding } from 'utils/useBranding'
import Questions from 'components/courseSetup/Questions'
import {
  getQuestionList,
  setQuestionList,
  setQuestionType,
} from 'utils/question'
import { QuestionContext } from 'components/courseSetup/Questions/QuestionContext'
import { ActionButton } from 'components/ActionButton'
import { PageTitle } from 'utils/constants/pageTitles'
import TitleHelmet from 'components/TitleHelmet'
import { LockdownCourseContext } from 'components/moduleSlides/LockdownCourseContext'

const CourseQuestions = () => {
  const { branding } = useBranding()
  const { isCurrentCourseLocked } = useContext(LockdownCourseContext)
  const { list, setList, setUpdateQuestionId } = useContext(QuestionContext)
  const questionListValue = getQuestionList()

  useEffect(() => {
    questionListValue === null && setList('true')
    questionListValue !== null && setList(questionListValue)
  }, [questionListValue, setList])

  return (
    <>
      <TitleHelmet title={PageTitle.COURSE_SETUP_QUESTIONS} />
      <h1>{PageTitle.COURSE_SETUP_QUESTIONS}</h1>

      {list === 'true' && (
        <>
          <ActionButton
            name="Add question"
            background={branding.stylingPrimary ?? 'blue'}
            setAction={() => {
              setQuestionList('false')
              setList('false')
              setQuestionType('')
              setUpdateQuestionId('')
            }}
            className={`${
              isCurrentCourseLocked && 'pointer-event-none opacity-50'
            }`}
          />

          <QuestionsTable />
        </>
      )}

      {list === 'false' && (
        <>
          <Button
            sx={{
              color: branding.stylingSecondary,
              marginTop: '-20px',
              marginBottom: '10px',
              fontWeight: '700',
              '&:hover': {
                background: 'transparent',
              },
            }}
            onClick={() => {
              setQuestionList('true')
              setList('true')
              setUpdateQuestionId('')
            }}
          >
            {'<'} {PageTitle.NAV_BACK}
          </Button>
          <Questions />
        </>
      )}
    </>
  )
}

export default CourseQuestions
