import { useNavigate } from 'react-router-dom'
import TitleHelmet from 'components/TitleHelmet'
import { PolicyDocumentTable } from 'components/tables/PolicyDocumentTable'
import { PageTitle } from 'utils/constants/pageTitles'
import { useBranding } from 'utils/useBranding'
import { ActionButton } from 'components/ActionButton'
import * as ROUTE from '../../utils/constants/clientRoutes'

const PolicyDocuments = () => {
  const { branding } = useBranding()
  const navigate = useNavigate()

  return (
    <>
      <TitleHelmet title={PageTitle.COMPANY_SETTING_POLICY_DOCUMENTS} />
      <h1>{PageTitle.COMPANY_SETTING_POLICY_DOCUMENTS}</h1>

      <ActionButton
        name="Create a policy"
        background={branding.stylingPrimary ?? 'blue'}
        setAction={() => navigate(ROUTE.POLICY_DOCUMENTS_CREATE)}
      />
      <PolicyDocumentTable />
    </>
  )
}
export default PolicyDocuments
