import { Button, Box, TextField } from '@mui/material'
import { useBranding } from 'utils/useBranding'

interface AddAnswerFieldProps {
  onClickAddAnswerHandler?: () => void
  addAnswer: string
  setAddAnswer: (value: string) => void
  limit: number
  answerDataLength: number
}

export const AddAnswerField = ({
  onClickAddAnswerHandler,
  addAnswer,
  setAddAnswer,
  limit,
  answerDataLength,
}: AddAnswerFieldProps) => {
  const { branding } = useBranding()

  return (
    <>
      <Box
        sx={{
          display: answerDataLength > limit - 1 ? 'none' : 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          background: '#fff',
          padding: '10px',
          borderRadius: '10px',
          marginBottom: '20px',
        }}
      >
        <TextField
          variant="outlined"
          autoComplete='off'
          placeholder="+ Add answer choice"
          value={addAnswer}
          sx={{
            width: '100%',
            background: '#fff',
            borderRadius: 2,
            '& .MuiOutlinedInput-root': {
              '& > fieldset': {
                border: 'none',
              },
            },
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setAddAnswer(e.target.value as string)
          }
        />
        <Button
          sx={{
            background: branding.stylingSecondary ?? 'orange',
            color: '#fff',
            padding: '10px 20px',
            fontWeight: '700',
            marginRight: '10px',
            opacity: !addAnswer.length ? '0.5' : '1',
            pointerEvents: !addAnswer.length ? 'none' : '',
            cursor: !addAnswer.length ? 'not-allowed' : '',
            '&:hover': {
              background: branding.stylingSecondary ?? 'orange',
            },
          }}
          onClick={onClickAddAnswerHandler}
        >
          Add
        </Button>
      </Box>
    </>
  )
}
