import { Header } from '../lib'
import { Drawer } from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'
import { UserModel } from 'api/data-contracts'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import {
  AddUserToOrganisationModel,
  useAddNewUserToOrganisation,
  useGetOrganisationID,
} from 'utils/organisation'
import { FormTextField } from 'components/FormTextField'
import { ActionButton } from 'components/ActionButton'
import { useBranding } from 'utils/useBranding'

const validationSchema = Yup.object<UserModel>().shape({
  Email: Yup.string()
    .required('Email is required')
    .email('Email entered is invalid'),
  Firstname: Yup.string().required('First name is required'),
  Lastname: Yup.string().required('Last name is required'),
  Department: Yup.string().required('Department is required'),
})

interface AddUserToOrgFormProps {
  drawerVisibility: boolean
  drawerClose: () => void
}

const AddUserToOrgFormPopout = ({
  drawerVisibility,
  drawerClose,
}: AddUserToOrgFormProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AddUserToOrganisationModel>({
    resolver: yupResolver(validationSchema),
  })
  const { branding } = useBranding()
  const { organisationId } = useGetOrganisationID()
  const { mutateAsync } = useAddNewUserToOrganisation()

  const onSubmit: SubmitHandler<AddUserToOrganisationModel> = data => {
    drawerClose()

    mutateAsync({
      data: {
        UserName: data.Email,
        Email: data.Email,
        Firstname: data.Firstname,
        Lastname: data.Lastname,
        Department: data.Department,
      },
      organisationId: organisationId,
    })
  }

  return (
    <Drawer
      anchor={'right'}
      open={drawerVisibility}
      onClose={() => drawerClose()}
    >
      <div className="m-3rem mt-5rem">
        <Header variant="signin">Create a user</Header>
        <form>
          <FormTextField
            control={control}
            errors={errors?.Email}
            name="Email"
            label="Email"
            length={true}
          />
          <FormTextField
            control={control}
            errors={errors?.Firstname}
            name="Firstname"
            label="Firstname"
            length={true}
          />

          <FormTextField
            control={control}
            errors={errors?.Lastname}
            name="Lastname"
            label="Lastname"
            length={true}
          />
          <FormTextField
            control={control}
            errors={errors?.Department}
            name="Department"
            label="Department"
            length={true}
          />
          <ActionButton
            name="Create"
            background={branding.stylingPrimary ?? 'blue'}
            setAction={handleSubmit(onSubmit)}
          />
        </form>
      </div>
    </Drawer>
  )
}

export { AddUserToOrgFormPopout }
