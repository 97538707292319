import { useEffect, useState } from 'react'
import { Drawer } from '@mui/material'
import { ActionButton } from 'components/ActionButton'
import { useBranding } from 'utils/useBranding'
import { useParams } from 'react-router-dom'
import { CourseModel, UpdateCourseModuleOrderModel } from 'api/data-contracts'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd'
import { toast } from 'react-toastify'

// API - custom hooks
import { useGetModules, useUpdateCourseModuleOrder } from 'utils/course'

interface ModulesOrderPopoutProps {
  drawerVisibility: boolean
  drawerClose: () => void
}

const ModulesOrderPopout = ({
  drawerVisibility,
  drawerClose,
}: ModulesOrderPopoutProps) => {
  const { branding } = useBranding()
  const { courseId } = useParams()

  const { modules } = useGetModules()
  const { mutateAsync: updateCourseModuleOrder } = useUpdateCourseModuleOrder()

  const [ModulesData, setModulesData] = useState<CourseModel[]>([])

  useEffect(() => {
    if (modules !== undefined) {
      const sortedModules = modules.sort((module1, module2) =>
        module1.order! < module2.order!
          ? -1
          : module1.order! > module2.order!
          ? 1
          : 0,
      )

      drawerVisibility === true && setModulesData(sortedModules)
    }
  }, [drawerVisibility, modules])

  const reorder = (
    list: CourseModel[],
    startIndex: number,
    endIndex: number,
  ): CourseModel[] => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const newItems = reorder(
      ModulesData as CourseModel[],
      result.source.index,
      result.destination.index,
    )

    setModulesData(newItems)
  }

  const onClickUpdateModulesOrderHandler = async () => {
    const getOnlyIdsFromArray: string[] = ModulesData.map(
      value => value.id as string,
    )
    const data = {
      orderedModuleIds: getOnlyIdsFromArray,
    }
    try {
      await updateCourseModuleOrder({
        courseId: courseId as string,
        data: data as UpdateCourseModuleOrderModel,
      })
      await drawerClose()
      toast.success('Modules order updated')
    } catch {
      toast.error('Unable to update order')
    }
  }

  return (
    <Drawer
      anchor={'right'}
      open={drawerVisibility}
      onClose={() => drawerClose()}
    >
      <div className="m-3rem mt-5rem min-w-100 min-w-500px">
        <h2>Update module's order</h2>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {ModulesData &&
                  ModulesData?.map((value, index) => (
                    <Draggable
                      key={value.id}
                      draggableId={value.id as string}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          key={value.id}
                          className="bg-white mt-20 mb-20 fw-600 p-20 cursor-pointer border-8"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {value.name}
                        </div>
                      )}
                    </Draggable>
                  ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <ActionButton
          name="Update order"
          background={branding.stylingPrimary ?? 'blue'}
          setAction={onClickUpdateModulesOrderHandler}
        />
      </div>
    </Drawer>
  )
}

export default ModulesOrderPopout
