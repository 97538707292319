import { InputLabel, MenuItem, Select } from '@mui/material'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { ModuleModel, UpdateModuleModel } from 'api/data-contracts'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DevTool } from '@hookform/devtools'
import { FormTextField } from 'components/FormTextField'
import { ActionButton } from 'components/ActionButton'
import { useBranding } from 'utils/useBranding'
import {
  useDeleteModuleCompletionImage,
  useGetModule,
  useUpdateModule,
  useUpdateModuleCompletionImage,
} from 'utils/module'
import { PageTitle } from 'utils/constants/pageTitles'
import { LoaderSpinner } from 'components/LoaderSpinner'
import TitleHelmet from 'components/TitleHelmet'
import { useState } from 'react'
import { AUTO, TRAINER } from 'utils/constants/modules'
import ImageDragAndDrop from 'components/companySettings/ImageDragAndDrop'
import { Fileprops } from 'utils/models/File'
import CustomCheckedBoxwithLabel from 'components/CustomCheckedBoxwithLabel'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import { editorConfiguration } from 'utils/ckeditor/EditorConfiguration'

const validationSchema = Yup.object<UpdateModuleModel>().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
})

const Details = () => {
  const { module, isLoading, isError, error } = useGetModule()

  return isLoading ? (
    <LoaderSpinner />
  ) : isError && error instanceof Error ? (
    <p>{error.message}</p>
  ) : module ? (
    <DetailsForm module={module} />
  ) : (
    <p>Module does not exist.</p>
  )
}

const DetailsForm = ({ module }: { module: ModuleModel }) => {
  const { moduleId } = useParams()
  const { mutateAsync: updateModule } = useUpdateModule()
  const { branding } = useBranding()
  const { mutateAsync: updateModuleCompletionImage } =
    useUpdateModuleCompletionImage()
  const { mutateAsync: deleteModuleCompletionImage } =
    useDeleteModuleCompletionImage()
  const [moduleCompletionImageFile, setModuleCompletionImageFile] =
    useState<Fileprops | null>(null)

  const [isModuleCompletionImageDeleted, setIsModuleCompletionImageDeleted] =
    useState(false)

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UpdateModuleModel>({
    defaultValues: {
      ...module,
    },

    resolver: yupResolver(validationSchema),
  })

  const onSubmit: SubmitHandler<UpdateModuleModel> = async data => {
    const updateModuleData: UpdateModuleModel = { ...data }

    try {
      if (moduleId) {
        if (isModuleCompletionImageDeleted) {
          await deleteModuleCompletionImage({
            moduleId,
          })
          toast.success('Module completion image was deleted successfully.')
        }

        if (moduleCompletionImageFile !== null) {
          try {
            await updateModuleCompletionImage({
              moduleId,
              data: {
                moduleCompletionImage: moduleCompletionImageFile as File,
              },
            })
            toast.success('Module completion image was updated successfully.')
          } catch (error) {
            toast.error(
              'Error occurred while updating module completion image.',
            )
          }
        }

        try {
          await updateModule({
            data: updateModuleData,
            moduleId: moduleId!,
          })
          toast.success('Module was updated successfully!')
        } catch (error) {
          toast.error('Error occurred while updating the module.')
        }
      }
    } catch (error) {
      toast.error('An unexpected error occurred.')
    }
  }

  return (
    <div className="mb-20">
      <TitleHelmet title={PageTitle.COURSE_SETUP_MODULE_DETAILS} />
      <h1>{PageTitle.COURSE_SETUP_MODULE_DETAILS}</h1>
      <form>
        <div className="flex mb-20 flex-wrap justify-between">
          <div className="w-full desktop-w-50">
            <FormTextField
              control={control}
              errors={errors?.name}
              name="name"
              label="Name"
              length={true}
            />
            <FormTextField
              control={control}
              errors={errors?.description}
              name="description"
              label="Description"
              multilineValue={2}
              length={true}
            />
            <Controller
              name={'moduleProgression'}
              control={control}
              defaultValue={AUTO}
              render={({ field: { onChange, value } }) => (
                <div className="mb-30">
                  <InputLabel className="mb-5 fw-700">
                    Module Progression
                  </InputLabel>
                  <Select
                    value={value}
                    onChange={onChange}
                    error={errors.moduleProgression?.message !== undefined}
                    className="bg-white"
                  >
                    <MenuItem value={AUTO}>Auto</MenuItem>
                    <MenuItem value={TRAINER}>Trainer</MenuItem>
                  </Select>
                </div>
              )}
            />
            <FormTextField
              control={control}
              errors={errors?.preInfo}
              name="preInfo"
              label="Pre Module Information"
              multilineValue={4}
              length={true}
            />
          </div>
        </div>
        <hr className="text-gray opacity-30"></hr>
        <div className="flex mb-20 flex-wrap justify-between">
          <div className="w-full desktop-w-50">
            <h2>Module Completion setup</h2>
            <FormTextField
              control={control}
              errors={errors?.moduleCompletionTitle}
              name="moduleCompletionTitle"
              label="Completion title"
              length={true}
            />
            <FormTextField
              control={control}
              errors={errors?.moduleCompletionDescription}
              name="moduleCompletionDescription"
              label="Completion description"
              length={true}
            />
            <div className="w-full mb-30">
              <Controller
                name="sendCompleteEmail"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <CustomCheckedBoxwithLabel
                    label="Send Completion Email"
                    action={field.value || false}
                    setAction={(value: boolean) => {
                      field.onChange(value)
                    }}
                  />
                )}
              />
              <p className="text-label">Completion Email</p>
              <Controller
                name="moduleCompleteEmailOptional"
                control={control}
                render={({ field }) => (
                  <CKEditor
                    editor={Editor}
                    config={editorConfiguration}
                    onChange={(event, editor) => {
                      const data = editor.data.get()
                      field.onChange(data)
                    }}
                    data={field.value}
                  />
                )}
              />
            </div>
            <div className="w-full mb-30">
              <InputLabel className="mb-5 fw-700">Completion Image</InputLabel>
              <ImageDragAndDrop
                imageFile={moduleCompletionImageFile}
                setImageFile={setModuleCompletionImageFile}
                imageExist={module?.moduleCompletionImage}
                deleteStatus={isModuleCompletionImageDeleted}
                setDeleteImageStatus={setIsModuleCompletionImageDeleted}
                fileTypes={['JPG', 'PNG', 'JPEG']}
              />
            </div>
          </div>
        </div>
        <ActionButton
          name="Update"
          background={branding.stylingPrimary ?? 'blue'}
          setAction={handleSubmit(onSubmit)}
        />
      </form>
      <DevTool control={control} />
    </div>
  )
}
export default Details
