import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, MenuItem, Select } from '@mui/material'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'

import { ActionButton } from 'components/ActionButton'
import { FormTextField } from 'components/FormTextField'
import { CourseModel, UpdateCourseModel } from 'api/data-contracts'
import { useBranding } from 'utils/useBranding'
import { Fileprops } from 'utils/models/File'
import ImageDragAndDrop from 'components/companySettings/ImageDragAndDrop'
import { LoaderSpinner } from 'components/LoaderSpinner'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import { editorConfiguration } from 'utils/ckeditor/EditorConfiguration'

// API - custom hooks
import {
  useDeleteCourseCompletionImage,
  useDeleteCourseImage,
  useGetCourse,
  useUpdateCourse,
  useUpdateCourseCompletionImage,
  useUpdateCourseImage,
} from 'utils/course'
import { PageTitle } from 'utils/constants/pageTitles'
import TitleHelmet from 'components/TitleHelmet'
import { useGetTopics } from 'utils/organisation'

const validationSchema = Yup.object<UpdateCourseModel>().shape({
  name: Yup.string().required('Course name is required'),
  subTitle: Yup.string().required('Sub title is required'),
  description: Yup.string(),
  courseContent: Yup.string(),
  courseStatus: Yup.string().required('Course status is required'),
  courseStructure: Yup.string().required('Course structure is required'),
  preInfo: Yup.string(),
})
const Configuration = () => {
  const { course, isLoading, isError, error } = useGetCourse()
  return isLoading ? (
    <LoaderSpinner />
  ) : isError && error instanceof Error ? (
    <p>{error.message}</p>
  ) : course ? (
    <ConfigurationForm course={course} />
  ) : (
    <p>Course does not exist.</p>
  )
}
type ConfigurationFormProps = {
  course: CourseModel
}
const ConfigurationForm = (props: ConfigurationFormProps) => {
  const { courseId } = useParams()
  const { branding } = useBranding()
  const { topics } = useGetTopics()

  const [selectedTopic, setSelectedTopic] = useState(props.course.topicId || '')
  const topicSelectionFunctionality = (id: string) => {
    selectedTopic !== id ? setSelectedTopic(id) : setSelectedTopic('')
  }
  const { mutateAsync: updateCourse } = useUpdateCourse()
  const { mutateAsync: updateCourseCompletionImage } =
    useUpdateCourseCompletionImage()
  const { mutateAsync: deleteCourseCompletionImage } =
    useDeleteCourseCompletionImage()
  const { mutateAsync: updateCourseImage } = useUpdateCourseImage()
  const { mutateAsync: deleteCourseImage } = useDeleteCourseImage()

  const [courseImageFile, setCourseImageFile] = useState<Fileprops | null>(null)
  const [courseCompletionImageFile, setCourseCompletionImageFile] =
    useState<Fileprops | null>(null)

  const [isCourseImageDeleted, setIsCourseImageDeleted] = useState(false)
  const [isCourseCompletionImageDeleted, setIsCourseCompletionImageDeleted] =
    useState(false)

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UpdateCourseModel>({
    defaultValues: {
      ...props.course,
    },
    resolver: yupResolver(validationSchema),
  })
  const onSubmit: SubmitHandler<UpdateCourseModel> = async data => {
    const updateCourseData: UpdateCourseModel = { ...data }

    if (selectedTopic) {
      updateCourseData.topicId = selectedTopic
    }

    try {
      if (courseId) {
        // Delete images
        if (isCourseImageDeleted) {
          await deleteCourseImage({
            courseId,
          })
        }
        if (isCourseCompletionImageDeleted) {
          await deleteCourseCompletionImage({
            courseId,
          })
        }
        // Update images
        if (courseImageFile !== null) {
          await updateCourseImage({
            courseId,
            data: {
              courseImage: courseImageFile as File,
            },
          })
        }
        if (courseCompletionImageFile !== null) {
          await updateCourseCompletionImage({
            courseId,
            data: {
              courseCompletionImage: courseCompletionImageFile as File,
            },
          })
        }
        await updateCourse({
          data: updateCourseData,
          courseId: courseId,
        })
      }
      toast.success('Course configuration saved')
    } catch {
      toast.error('Error')
    }
  }

  return (
    <div>
      <TitleHelmet title={PageTitle.COURSE_SETUP_CONFIGURATION} />
      <h1>{PageTitle.COURSE_SETUP_CONFIGURATION}</h1>
      <form>
        <div className="flex mb-20 flex-wrap justify-between">
          <div className="w-full desktop-w-50">
            <FormTextField
              control={control}
              errors={errors?.name}
              name="name"
              label="Course title"
              length={true}
            />
            <FormTextField
              control={control}
              errors={errors?.subTitle}
              name="subTitle"
              multilineValue={2}
              label="Course sub title"
              length={true}
            />
            <FormTextField
              control={control}
              errors={errors?.description}
              name="description"
              multilineValue={2}
              label="Description"
              length={true}
            />
            <FormTextField
              control={control}
              errors={errors?.courseContent}
              name="courseContent"
              label="What you will learn"
              multilineValue={2}
              length={true}
            />
            <FormTextField
              control={control}
              errors={errors?.preInfo}
              name="preInfo"
              label="Pre course material"
              multilineValue={4}
              length={true}
            />
            <Controller
              name={'courseStatus'}
              control={control}
              defaultValue={'INACTIVE'}
              render={({ field: { onChange, value } }) => (
                <div className="w-full mb-30">
                  <p className="text-label">Course status</p>
                  <Select
                    value={value}
                    onChange={onChange}
                    error={errors.courseStatus?.message !== undefined}
                    className="bg-white"
                  >
                    <MenuItem value={'INACTIVE'}>Inactive</MenuItem>
                    <MenuItem value={'ACTIVE'}>Active</MenuItem>
                  </Select>
                </div>
              )}
            />
          </div>
          <div className="w-full desktop-w-50">
            <div className="w-full mb-30">
              <p className="text-label">Image</p>
              <ImageDragAndDrop
                imageFile={courseImageFile}
                setImageFile={setCourseImageFile}
                imageExist={props.course?.courseImage}
                deleteStatus={isCourseImageDeleted}
                setDeleteImageStatus={setIsCourseImageDeleted}
                fileTypes={['JPG', 'PNG', 'JPEG']}
              />
            </div>
            <Controller
              name={'courseStructure'}
              control={control}
              defaultValue={'ONLINE'}
              render={({ field: { onChange, value } }) => (
                <div className="w-full mb-30">
                  <p className="text-label">Type of course</p>
                  <Select
                    value={value}
                    onChange={onChange}
                    error={errors.courseStructure?.message !== undefined}
                    className="bg-white"
                  >
                    <MenuItem value={'ONLINE'}>Online</MenuItem>
                    <MenuItem value={'ONLINEINPERSON'}>
                      Online / In person
                    </MenuItem>
                  </Select>
                </div>
              )}
            />
            <FormTextField
              control={control}
              errors={errors?.duration}
              name="duration"
              label="Duration"
              length={true}
            />
            <div className="w-full mb-30">
              <p className="text-label">Topics</p>
              <div className="flex flex-wrap align-center">
                {topics &&
                  topics.map(value => (
                    <Button
                      key={value.id}
                      className="color-white fw-700 pt-10 pb-10 pl-20 pr-20 mr-10"
                      style={{
                        background: `${
                          value.id === selectedTopic
                            ? branding.stylingSecondary
                            : branding.stylingPrimary
                        }`,
                      }}
                      onClick={() =>
                        topicSelectionFunctionality(value.id as string)
                      }
                    >
                      {value.name}
                    </Button>
                  ))}
              </div>
            </div>
            <div className="w-full mb-30">
              <p className="text-label">Welcome Email</p>
              <Controller
                name="welcomeEmailOptional"
                control={control}
                render={({ field }) => (
                  <CKEditor
                    editor={Editor}
                    config={editorConfiguration}
                    onChange={(event, editor) => {
                      const data = editor.data.get()
                      field.onChange(data)
                    }}
                    data={field.value}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <hr className="text-gray opacity-30"></hr>
        <div className="flex mb-20 flex-wrap justify-between">
          <div className="w-full desktop-w-50">
            <h2>Course Completion setup</h2>
            <FormTextField
              control={control}
              errors={errors?.courseCompletionTitle}
              name="courseCompletionTitle"
              label="Completion title"
              length={true}
            />
            <FormTextField
              control={control}
              errors={errors?.courseCompletionDescription}
              name="courseCompletionDescription"
              label="Completion description"
              length={true}
            />

            <div className="w-full mb-30">
              <p className="text-label">Completion Image</p>
              <ImageDragAndDrop
                imageFile={courseCompletionImageFile}
                setImageFile={setCourseCompletionImageFile}
                imageExist={props.course?.courseCompletionImage}
                deleteStatus={isCourseCompletionImageDeleted}
                setDeleteImageStatus={setIsCourseCompletionImageDeleted}
                fileTypes={['JPG', 'PNG', 'JPEG']}
              />
            </div>
          </div>
        </div>
        <ActionButton
          name="Update"
          background={branding.stylingPrimary ?? 'blue'}
          setAction={handleSubmit(onSubmit)}
        />
      </form>
    </div>
  )
}
export default Configuration
