import { useContext, useState } from 'react'
import { Paper } from '@mui/material'
import { toast } from 'react-toastify'
import DeleteIcon from '@mui/icons-material/Delete'

import { SectionModel } from 'utils/models/Slide'
import { useDeleteSection } from 'utils/section'
import { ConfirmPopup } from './ConfirmPopup'
import { NewSectionContext } from './NewSectionContext'
import { CurrentSectionContext } from './CurrentSectionContext'
import { useBranding } from 'utils/useBranding'
import { LockdownCourseContext } from './LockdownCourseContext'

import PlaceholderIcon from '../../assets/image/Placeholder.svg'

type SlideNavItemProps = {
  section: SectionModel
  selected: boolean
  onSelect: (section: SectionModel) => void
}

const SectionNavItem = ({ section, selected, onSelect }: SlideNavItemProps) => {
  const { branding } = useBranding()
  const { isCurrentCourseLocked } = useContext(LockdownCourseContext)
  const { mutateAsync: deleteSection } = useDeleteSection()
  const [showConfirm, setShowConfirm] = useState(false)
  const { setNewSection, setStatus } = useContext(NewSectionContext)
  const { setCurrentSection } = useContext(CurrentSectionContext)

  const {
    isNewSlideEdited,
    setSlideContentLossMessageVisible,
    setNavigateAction,
  } = useContext(NewSectionContext)

  const handleDeleteSection = async () => {
    try {
      await deleteSection(section.id!)
      toast.success('Deleted successfully')
    } catch {
      toast.error(
        'Error deleting section - make sure the section is not in use',
      )
    }
    setShowConfirm(false)
    setNewSection({})
    setStatus(undefined)
    setCurrentSection(undefined)
  }

  const onClickSlideHandler = () => {
    onSelect(section)
    setStatus(undefined)
    setNewSection({})
  }

  return (
    <Paper
      variant="outlined"
      square
      key={section.id}
      sx={{
        '&.selected': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
          border: `2px solid ${branding.stylingPrimary}`,
        },
        border: '1.5px solid darkgray',
        width: '150px!important',
        height: '100px!important',
        fontSize: '13px',
        padding: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundImage: `url(${PlaceholderIcon})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#BCBFBE',
        backgroundPosition: 'center',
        cursor: 'pointer',
      }}
      className={selected ? 'selected' : ''}
      onClick={() => {
        if (isNewSlideEdited) {
          setSlideContentLossMessageVisible(true)
          setNavigateAction({
            actionFunc: () => {
              onClickSlideHandler()
            },
          })
        } else {
          onClickSlideHandler()
        }
      }}
    >
      <div>{section.name}</div>
      <DeleteIcon
        className={`cursor-pointer w-20px
        ${isCurrentCourseLocked && 'pointer-event-none opacity-50'}`}
        onClick={() => setShowConfirm(true)}
      />
      {showConfirm && (
        <ConfirmPopup
          title="Confirm deletion"
          message="Are you sure you want to delete this section?"
          onConfirm={handleDeleteSection}
          open={showConfirm}
          onCancel={() => setShowConfirm(false)}
        />
      )}
    </Paper>
  )
}

export { SectionNavItem }
