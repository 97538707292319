import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from './use-clients'
import {
  AddAnswerOptionModel,
  UpdateAnswerOptionOrderModel,
} from 'api/data-contracts'
import { UpdateQuestionModel } from 'api/data-contracts'

// Constants
import { QUESTIONLIST, QUESTIONTYPE } from './constants/auth'

export function getQuestionList() {
  return localStorage.getItem(QUESTIONLIST)
}

export function setQuestionList(token: string) {
  localStorage.setItem(QUESTIONLIST, token)
}

export function getQuestionType() {
  return localStorage.getItem(QUESTIONTYPE)
}

export function setQuestionType(token: string) {
  localStorage.setItem(QUESTIONTYPE, token)
}

export interface setQueInterface {
  setQueType: (val: string) => void
}

export interface AnswerDataModel {
  answerText?: string | null
  isCorrect?: boolean
  answerOrder?: number
  id?: string
}

// POST:/api/Question/{questionId}/answersoptions
interface useAddAnswerOptionProps {
  questionId: string
  data: AddAnswerOptionModel
}
const useAddAnswerOption = () => {
  const client = useApiClient()

  const mutation = useMutation({
    mutationFn: (props: useAddAnswerOptionProps) => {
      return client.question.addAnswerOption(props.questionId, props.data)
    },
  })
  return mutation
}

// PUT:/api/Question/{questionId}/answersoptions/order
interface updateAnswerOptionOrderProps {
  questionId: string
  data: UpdateAnswerOptionOrderModel
}
const useUpdateAnswerOptionOrder = () => {
  const client = useApiClient()

  const mutation = useMutation({
    mutationFn: (props: updateAnswerOptionOrderProps) =>
      client.question.updateAnswerOptionOrder(props.questionId, props.data),
  })
  return mutation
}

// GET:/api/Question/{questionId}/answersoptions
const useGetAnswerOption = (questionId: string) => {
  const client = useApiClient()

  const result = useQuery({
    queryKey: ['question-answers', questionId],
    queryFn: () => client.question.getAnswerOptions(questionId as string),
    enabled: !!questionId,
    staleTime: Infinity,
  })
  return {
    ...result,
    answersData: result.data?.data,
  }
}

// GET:/api/Question/{questionId}
const useGetQuestion = (questionId: string) => {
  const client = useApiClient()

  const result = useQuery({
    queryKey: ['question', questionId],
    queryFn: () => client.question.getQuestion(questionId as string),
    enabled: !!questionId,
    staleTime: Infinity,
  })
  return {
    ...result,
    questionData: result.data?.data,
  }
}

// DELETE:/api/Question/{questionId}
const useDeleteQuestion = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (questionId: string) =>
      client.question.deleteQuestion(questionId),
    onSuccess: async data => {
      queryClient.invalidateQueries(['questions'])
    },
  })
  return mutation
}

// PUT:/api/Question/{questionId}
interface useUpdateQuestionProps {
  questionId: string
  data: UpdateQuestionModel
}
const useUpdateQuestion = () => {
  const client = useApiClient()

  const mutation = useMutation({
    mutationFn: (props: useUpdateQuestionProps) =>
      client.question.updateQuestion(props.questionId, props.data),
  })
  return mutation
}

export {
  useAddAnswerOption,
  useUpdateAnswerOptionOrder,
  useGetAnswerOption,
  useGetQuestion,
  useDeleteQuestion,
  useUpdateQuestion,
}
