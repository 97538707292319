import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from './use-clients'
import { UpdateMessageTemplateModel } from 'api/data-contracts'

// PUT:/api/MessageTemplate/{messageTemplateId}
interface useUpdateMessageTemplateProps {
  messageTemplateId: string
  data: UpdateMessageTemplateModel
}
const useUpdateMessageTemplate = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (props: useUpdateMessageTemplateProps) =>
      client.messageTemplate.updateMessageTemplate(
        props.messageTemplateId,
        props.data,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(['messages'])
    },
  })
  return mutation
}

export { useUpdateMessageTemplate }
