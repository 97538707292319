export const InitialValues = {
  bannerTitle: '',
  bannerSubTitle: '',
  bannerImage: '',
  bannerCallToActionText: '',
  bannerCallToActionUrl: '',
  introTitle: '',
  introText: '',
  howItWorksTitle: '',
  howItWorks: '',
  howItWorksTitle2: '',
  howItWorks2: '',
  howItWorksTitle3: '',
  howItWorks3: '',
  howItWorksTitle4: '',
  howItWorks4: '',
  moreInfoTitle: '',
  moreInfoDescription: '',
  moreInfoCallToActionText: '',
  moreInfoCallToActionUrl: '',
  focusTag: '',
  companyInfoTitle: '',
  companyInfo: '',
  name: '',
  description: '',
  companyLogo: '',
  emailLogo: '',
  backgroundImage: '',
  siteBaseUrl: '',
  stylingPrimary: '',
  stylingSecondary: '',
  stylingInfo: '',
}
