import { Disclosure } from '@headlessui/react'
import {
  SectionModel,
  UserModuleDataModel,
  UserModuleSectionAssessmentModel,
} from 'api/data-contracts'
import DoneIcon from 'assets/icon/correct-answer.svg'
import CancelIcon from 'assets/icon/incorrect-answer.svg'
import { FREETEXT } from 'utils/constants/questions'
import { ASSESSOR } from 'utils/constants/sections'

type Props = {
  section: SectionModel
  index: number
  currentModuleData: UserModuleDataModel
}

export default function AssessmentAccordion(props: Props) {
  const currentSectionData =
    props.currentModuleData?.userModuleSectionDatas?.find(
      section => section?.section?.id === props.section.id,
    )

  const currentAssesmentData =
    currentSectionData?.section?.assessmentMarkProcess === ASSESSOR
      ? currentSectionData?.assessments?.sort(
          (
            a: UserModuleSectionAssessmentModel,
            b: UserModuleSectionAssessmentModel,
          ) =>
            new Date(b.assessmentStartDate ?? '').getTime() -
            new Date(a.assessmentStartDate ?? '').getTime(),
        )[0]
      : currentSectionData?.assessments?.sort(
          (
            a: UserModuleSectionAssessmentModel,
            b: UserModuleSectionAssessmentModel,
          ) => (b.assessmentScore ?? 0) - (a.assessmentScore ?? 0),
        )[0]

  console.log(currentSectionData, 'currentSectionData')

  return (
    <div className="accordion-container">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="accordion-button">
              <span>
                <h2 className="font-medium">
                  {props.section.name}: Assessment {props.index}
                </h2>
              </span>
              {!open ? (
                <span className="accordion accordion-open"></span>
              ) : (
                <span className="accordion accordion-close"></span>
              )}
            </Disclosure.Button>
            <Disclosure.Panel className="accordion-panel">
              <div className="accordion-panel-content">
                <span className="accordion-info-box">
                  <span>Assessments attempts</span>
                  <span className="text-4xl font-bold">
                    {currentSectionData?.assessments?.length}
                  </span>
                </span>
                <div className="accordion-panel-content">
                  {currentAssesmentData?.assessmentQuestionSets?.map(
                    (question, index) => (
                      <>
                        <h3 style={{ margin: 0 }}>Question {index + 1}</h3>
                        <span>
                          <div>{question?.question?.question}</div>
                        </span>
                        <span className="assessment-answer flex space-between">
                          {question.question?.questionType !== FREETEXT ? (
                            question?.isCorrect ? (
                              <span className="ml-4 text-correct-500 answer-icon">
                                <img
                                  src={DoneIcon}
                                  alt="DoneIcon"
                                  className="w-100"
                                />
                              </span>
                            ) : (
                              <span className="ml-4 text-error-500 answer-icon">
                                <img
                                  src={CancelIcon}
                                  alt="CancelIcon"
                                  className="w-100"
                                />
                              </span>
                            )
                          ) : null}
                          <span
                            className={
                              question.question?.questionType === FREETEXT
                                ? 'free-text-answer'
                                : ''
                            }
                          >
                            {
                              question?.userAssessmentAnswerItems?.[0]
                                .answerText
                            }
                          </span>
                        </span>
                      </>
                    ),
                  )}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  )
}
