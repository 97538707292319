import { useState } from 'react'
import styles from './Users.module.css'

import UsersTable from 'components/tables/UsersTable'
import { AddUserToOrgFormPopout } from 'components/users/AddUserToOrgFormPopout'
import { UserDetailPopout } from 'components/users/UserDetail/UserDetailPopout'
import { UserModel } from 'api/data-contracts'
import { ActionButton } from 'components/ActionButton'
import { useBranding } from 'utils/useBranding'
import { PageTitle } from 'utils/constants/pageTitles'
import TitleHelmet from 'components/TitleHelmet'

const Users = () => {
  const { branding } = useBranding()
  const [addUserToOrgDrawerVisibility, setAddUserToOrgDrawerVisibility] =
    useState(false)

  const [userDetaildrawerVisibility, setUserDetaildrawerVisibility] =
    useState(false)

  const onAddUserToOrgFormDrawerClose = () => {
    setAddUserToOrgDrawerVisibility(false)
  }

  const onUserDetailDrawerClose = () => {
    setUserDetaildrawerVisibility(false)
  }

  const [userDetailData, setUserDetailData] = useState<UserModel>({})

  return (
    <>
      <TitleHelmet title={PageTitle.ACTIVE_USERS} />
      <h1 className={styles.heading}>{PageTitle.ACTIVE_USERS}</h1>
      <ActionButton
        name="Add new user"
        background={branding.stylingPrimary ?? 'blue'}
        setAction={() => setAddUserToOrgDrawerVisibility(true)}
      />

      <AddUserToOrgFormPopout
        drawerVisibility={addUserToOrgDrawerVisibility}
        drawerClose={onAddUserToOrgFormDrawerClose}
      />
      <UserDetailPopout
        drawerVisibility={userDetaildrawerVisibility}
        drawerClose={onUserDetailDrawerClose}
        userDetailData={userDetailData}
      />

      <UsersTable
        setDrawerAction={setUserDetaildrawerVisibility}
        setUserDetailData={setUserDetailData}
      />
    </>
  )
}

export default Users
