import { useCallback } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import invariant from 'tiny-invariant'
import { useApiClient } from './use-clients'
import {
  AddPolicyDocumentToOrganisationModel,
  UpdateOrganisationModel,
  UpdateOrganisationSettingsModel,
  UpdateStripeDataForOrganisationModel,
  UpdateUserOrganisationRolesModel,
} from 'api/data-contracts'
// Constants
import { ORGANISATION_ID } from './constants/auth'
export function setOrganisationId(organisationId: string) {
  localStorage.setItem(ORGANISATION_ID, organisationId)
}
export function getOrganisationId() {
  return localStorage.getItem(ORGANISATION_ID)
}
const useGetOrganisationID = () => {
  const result = useQuery({
    queryKey: ['organisationId'],
    queryFn: () => getOrganisationId(),
  })
  return { ...result, organisationId: result?.data ? result.data : '' }
}
// GET:/api/Organisation/OrganisationsByUser
const useGetUserFirstOrganisationId = () => {
  const apiClient = useApiClient()
  const getUserFirstOrganisationId = useCallback(async () => {
    const organisationResponse =
      await apiClient.organisation.organisationsByUser()
    const organisationId = organisationResponse.data[0]?.id
    invariant(organisationId, 'Invalid organisation data')
    return organisationId
  }, [apiClient])
  return getUserFirstOrganisationId
}
// GET:/api/Organisation/{domain}/branding
const useBranding = () => {
  const domain = window.location.hostname
  const client = useApiClient()
  const result = useQuery({
    queryKey: ['organisation'],
    queryFn: () => client.organisation.branding(domain),
  })
  return { ...result }
}
// GET:/api/Organisation/{organisationId}
const useGetOrganisation = () => {
  const { organisationId, isLoading } = useGetOrganisationID()
  const client = useApiClient()

  const result = useQuery({
    queryKey: ['getOrganisation'],
    queryFn: () =>
      client.organisation.getOrganisationById(organisationId as string),
    enabled: !isLoading && !!organisationId,
  })
  return {
    ...result,
    getOrganisationData: result.data?.data,
  }
}

// GET:/api/Organisation/{organisationId}/users
const useGetOrganisationById = () => {
  const { organisationId, isLoading } = useGetOrganisationID()
  const client = useApiClient()
  const result = useQuery({
    queryKey: ['organisation'],
    queryFn: () => client.organisation.getOrganisationById(organisationId),
    enabled: !isLoading && !!organisationId, // Only enable query if organisationId is truthy and isLoading is false
  })
  return { ...result, organisation: result.data?.data }
}

// PUT:/api/Organisation/{organisationId}
interface useUpdateOrganisationProps {
  organisationId: string
  data: UpdateOrganisationModel
}
const useUpdateOrganisation = () => {
  const client = useApiClient()
  const mutation = useMutation({
    mutationFn: (props: useUpdateOrganisationProps) =>
      client.organisation.updateOrganisation(props.organisationId, props.data),
  })
  return mutation
}

interface useUpdateStripeDataProps {
  organisationId: string
  data: UpdateStripeDataForOrganisationModel
}

const useUpdateStripeData = () => {
  const client = useApiClient()
  const mutation = useMutation({
    mutationFn: (props: useUpdateStripeDataProps) =>
      client.organisation.updateStripeData(props.organisationId, props.data),
  })
  return mutation
}
// GET:/api/Organisation/OrganisationsByUser
const useOrganisationsByUser = () => {
  const client = useApiClient()
  const result = useQuery({
    queryKey: ['organisation'],
    queryFn: () => client.organisation.organisationsByUser(),
  })
  return { ...result, organisation: result.data?.data ?? null }
}

// GET:/api/Organisation/{organisationId}/users
const useGetOrganisationUsers = () => {
  const { organisationId, isLoading } = useGetOrganisationID()
  const client = useApiClient()
  const result = useQuery({
    queryKey: ['users'],
    queryFn: () => client.organisation.organisationUsers(organisationId),
    enabled: !isLoading && !!organisationId, // Only enable query if organisationId is truthy and isLoading is false
    staleTime: Infinity,
  })
  return { ...result, users: result.data?.data }
}

// GET:/api/Organisation/{organisationId}/users/withroles
interface useGetOrganisationUsersWithRolesProps {
  organisationId: string
  query?: {
    specificrole?: string
  }
}
const useGetOrganisationUsersWithRoles = (
  props: useGetOrganisationUsersWithRolesProps,
) => {
  const { organisationId, isLoading } = useGetOrganisationID()
  const client = useApiClient()
  const result = useQuery({
    queryKey: ['usersWithRoles'],
    queryFn: () =>
      client.organisation.organisationUsersWithRole(
        props.organisationId,
        props.query,
      ),
    enabled: !isLoading && !!organisationId, // Only enable query if organisationId is truthy and isLoading is false
    staleTime: Infinity,
  })
  return { ...result, usersWithRoles: result.data?.data }
}

// POST:/api/Organisation/{organisationId}/users
export interface AddUserToOrganisationModel {
  UserName?: string | undefined
  Email?: string | undefined
  Firstname?: string | undefined
  Lastname?: string | undefined
  Department?: string | undefined
  Bio?: string | undefined
  ProfileImage?: File | undefined
}
interface AddNewUserToOrganisationProps {
  organisationId: string
  data: AddUserToOrganisationModel
}
const useAddNewUserToOrganisation = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: (props: AddNewUserToOrganisationProps) =>
      client.organisation.addNewUserToOrganisation(
        props.organisationId,
        props.data,
      ),
    onSuccess: data => {
      queryClient.refetchQueries(['organisation']) // refetch queries that depend on the user data
      queryClient.setQueryData(['users'], data)
      queryClient.refetchQueries(['users'])
    },
  })
  return mutation
}
// GET:/api/Organisation/{organisationId}/courses
const useGetOrganisationCourses = () => {
  const { organisationId, isLoading } = useGetOrganisationID()
  const client = useApiClient()
  const result = useQuery({
    queryKey: ['courses', { organisationId }],
    queryFn: () => client.organisation.organisationCourses(organisationId),
    enabled: !isLoading && !!organisationId, // Only enable query if organisationId is truthy and isLoading is false
  })
  return { ...result, courses: result.data?.data ?? [] }
}
// POST:/api/Organisation/{organisationId}/courses
export interface AddCourseToOrganisationModel {
  Name?: string | undefined
  SubTitle?: string | undefined
  Description?: string | undefined
  CourseContent?: string | undefined
  Duration?: string | undefined
  RunsOver?: string | undefined
  /** @format uuid */
  TopicId?: string | undefined
  Tags?: string | undefined
  CourseImage?: File | undefined
  CourseStatus?: string | undefined
  CourseStructure?: string | undefined
  CourseCompletionTitle?: string | undefined
  CourseCompletionDescription?: string | undefined
  CourseCompletionImage?: File | undefined
  WelcomeEmailOptional?: string | undefined
  PreInfo?: string | undefined
}
const useAddNewCourseToOrganisation = () => {
  const { organisationId } = useGetOrganisationID()
  const client = useApiClient()
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: (data: AddCourseToOrganisationModel) =>
      client.organisation.addNewCourseToOrganisation(organisationId, data),
    onSuccess: data => {
      queryClient.setQueryData(['course'], data) //set query (oldData,newData)
      queryClient.refetchQueries(['course']) // refetch queries that depend on the user data
    },
  })
  return mutation
}
// PUT:/api/Organisation/{organisationId}/users/{userId}/assign
export type AddExistingUserToOrganisation = {
  organisationId: string
  userId: string
}
const useAddExistingUserToOrganisation = () => {
  const client = useApiClient()
  const mutation = useMutation({
    mutationFn: (props: AddExistingUserToOrganisation) =>
      client.organisation.addExistingUserToOrganisation(
        props.organisationId,
        props.userId,
      ),
  })
  return mutation
}

// GET:/api/Organisation/{organisationId}/courses/instances
const useGetCourseInstancesByOrganisation = () => {
  const { organisationId, isLoading } = useGetOrganisationID()
  const client = useApiClient()
  const result = useQuery({
    queryKey: ['courses-instances', { organisationId }],
    queryFn: () =>
      client.organisation.getCourseInstancesByOrganisation(organisationId),
    enabled: !isLoading && !!organisationId, // Only enable query if organisationId is truthy and isLoading is false
    staleTime: Infinity,
  })
  return { ...result, courses: result.data?.data ?? [] }
}

// PUT: /api/Organisation/{organisationId}/images/bannerimage
interface useUpdateBannerImageProps {
  organisationId: string
  data: {
    bannerImage?: File
  }
}
const useUpdateBannerImage = () => {
  const client = useApiClient()
  const mutation = useMutation({
    mutationFn: (props: useUpdateBannerImageProps) =>
      client.organisation.updateBannerImage(props.organisationId, props.data),
  })
  return mutation
}

// DELETE: /api/Organisation/{organisationId}/images/bannerimage
interface useDeleteBannerImageProps {
  organisationId: string
}
const useDeleteBannerImage = () => {
  const client = useApiClient()
  const mutation = useMutation({
    mutationFn: (props: useDeleteBannerImageProps) =>
      client.organisation.deleteBannerImage(props.organisationId),
  })
  return mutation
}

// PUT: /api/Organisation/{organisationId}/images/companylogo
interface useUpdateCompanyLogoProps {
  organisationId: string
  data: {
    companyLogo?: File
  }
}
const useUpdateCompanyLogo = () => {
  const client = useApiClient()
  const mutation = useMutation({
    mutationFn: (props: useUpdateCompanyLogoProps) =>
      client.organisation.updateCompanyLogo(props.organisationId, props.data),
  })
  return mutation
}

// DELETE: /api/Organisation/{organisationId}/images/companylogo
interface useDeleteCompanyLogoProps {
  organisationId: string
}
const useDeleteCompanyLogo = () => {
  const client = useApiClient()
  const mutation = useMutation({
    mutationFn: (props: useDeleteCompanyLogoProps) =>
      client.organisation.deleteCompanyLogo(props.organisationId),
  })
  return mutation
}

// PUT: /api/Organisation/{organisationId}/images/emaillogo
interface useUpdateEmailLogoProps {
  organisationId: string
  data: {
    emailLogo?: File
  }
}
const useUpdateEmailLogo = () => {
  const client = useApiClient()
  const mutation = useMutation({
    mutationFn: (props: useUpdateEmailLogoProps) =>
      client.organisation.updateEmailLogo(props.organisationId, props.data),
  })
  return mutation
}

// DELETE: /api/Organisation/{organisationId}/images/emaillogo
interface useDeleteEmailLogoProps {
  organisationId: string
}
const useDeleteEmailLogo = () => {
  const client = useApiClient()
  const mutation = useMutation({
    mutationFn: (props: useDeleteEmailLogoProps) =>
      client.organisation.deleteEmailLogo(props.organisationId),
  })
  return mutation
}

// GET: /api/Organisation/{organisationId}/settings
const useGetSettings = () => {
  const { organisationId, isLoading } = useGetOrganisationID()
  const client = useApiClient()

  const result = useQuery({
    queryKey: ['settings'],
    queryFn: () => client.organisation.getSettings(organisationId as string),
    enabled: !isLoading && !!organisationId,
  })
  return {
    ...result,
    settingData: result.data?.data,
  }
}

// PUT: /api/Organisation/{organisationId}/settings
interface useUpdateOrganisationSettingsProps {
  organisationId: string
  data: UpdateOrganisationSettingsModel
}
const useUpdateOrganisationSettings = () => {
  const client = useApiClient()
  const mutation = useMutation({
    mutationFn: (props: useUpdateOrganisationSettingsProps) =>
      client.organisation.updateOrganisationSettings(
        props.organisationId,
        props.data,
      ),
  })
  return mutation
}
// GET:/api/Organisation/{organisationId}/messagetemplates
const useGetMessageTemplates = () => {
  const { organisationId, isLoading } = useGetOrganisationID()
  const client = useApiClient()
  const result = useQuery({
    queryKey: ['messages', { organisationId }],
    queryFn: () => client.organisation.getMessageTemplates(organisationId),
    enabled: !isLoading && !!organisationId,
    staleTime: Infinity,
  })
  return { ...result, messages: result.data?.data ?? [] }
}

// GET:/api/Organisation/{organisationId}/policydocuments
const useGetPolicyDocuments = () => {
  const { organisationId, isLoading } = useGetOrganisationID()
  const client = useApiClient()
  const result = useQuery({
    queryKey: ['organisation', { organisationId }],
    queryFn: () => client.organisation.getPolicyDocuments(organisationId),
    enabled: !isLoading && !!organisationId,
  })
  return { ...result, policyDocuments: result.data?.data ?? [] }
}

// POST:/api/Organisation/{organisationId}/policydocuments
interface useAddPolicyDocumentsToOrganisationProps {
  organisationId: string
  data: AddPolicyDocumentToOrganisationModel
}
const useAddPolicyDocumentsToOrganisation = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: (props: useAddPolicyDocumentsToOrganisationProps) =>
      client.organisation.addPolicyDocumentsToOrganisation(
        props.organisationId,
        props.data,
      ),
    onSuccess: data => {
      queryClient.setQueryData(['organisation'], data) //set query (oldData,newData)
      queryClient.refetchQueries(['organisation']) // refetch queries that depend on the user data
    },
  })
  return mutation
}

// GET:/api/Organisation/{organisationId}/testimonials
const useGetTestimonials = () => {
  const { organisationId, isLoading } = useGetOrganisationID()
  const client = useApiClient()
  const result = useQuery({
    queryKey: ['testimonial'],
    queryFn: () => client.organisation.getTestimonials(organisationId),
    enabled: !isLoading && !!organisationId,
    staleTime: Infinity,
  })
  return { ...result, testimonials: result.data?.data ?? [] }
}

// POST:/api/Organisation/{organisationId}/testimonials
interface useAddTestimonialToOrganisationProps {
  organisationId: string
  data: {
    Testimonial?: string
    Author?: string
    Image?: File
  }
}
const useAddTestimonialToOrganisation = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: (props: useAddTestimonialToOrganisationProps) =>
      client.organisation.addTestimonialToOrganisation(
        props.organisationId,
        props.data,
      ),
    onSuccess: async data => {
      queryClient.setQueryData(['testimonial'], data)
      queryClient.refetchQueries(['testimonial'])
    },
  })
  return mutation
}

// GET:/api/Organisation/{organisationId}/topics
const useGetTopics = () => {
  const { organisationId, isLoading } = useGetOrganisationID()
  const client = useApiClient()
  const result = useQuery({
    queryKey: ['topics', { organisationId }],
    queryFn: () => client.organisation.getTopics(organisationId),
    enabled: !isLoading && !!organisationId,
    staleTime: Infinity,
  })
  return { ...result, topics: result.data?.data ?? [] }
}

// POST:/api/Organisation/{organisationId}/topics
interface useAddTopicToOrganisationProps {
  organisationId: string
  data: {
    name?: string
    description?: string
  }
}
const useAddTopicToOrganisation = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: (props: useAddTopicToOrganisationProps) =>
      client.organisation.addTopicToOrganisation(
        props.organisationId,
        props.data,
      ),
    onSuccess: data => {
      queryClient.setQueryData(['topics'], data)
      queryClient.refetchQueries(['topics'])
    },
  })
  return mutation
}

// DELETE:/api/Course/{courseId}
interface useCopyCourseProps {
  firstParam: string
  secondParam: string
}
const useCopyCourse = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: async (props: useCopyCourseProps) => {
      await client.organisation.copyCourse(props.firstParam, props.secondParam)
    },
    onSuccess: async () => {
      await queryClient.refetchQueries(['courses'])
    },
  })
  return mutation
}

// GET:/api/Organisation/{organisationId}/roles
const useGetOrganisationRoles = () => {
  const { organisationId, isLoading } = useGetOrganisationID()
  const client = useApiClient()
  const result = useQuery({
    queryKey: ['orgRoles', { organisationId }],
    queryFn: () => client.organisation.getOrganisationRoles(organisationId),
    enabled: !isLoading && !!organisationId,
    staleTime: Infinity,
  })
  return { ...result, orgRoles: result.data?.data ?? [] }
}

// GET:/api/Organisation/{organisationId}/users/{userId}/roles
const useOrganisationUserRoles = (userId: string) => {
  const { organisationId, isLoading } = useGetOrganisationID()
  const client = useApiClient()
  const result = useQuery({
    queryKey: ['orgUserRoles', userId],
    queryFn: () =>
      client.organisation.organisationUserRoles(organisationId, userId),
    enabled: !isLoading && !!organisationId,
    staleTime: Infinity,
  })
  return { ...result, orgUserRoles: result.data?.data ?? [] }
}

// PUT:/api/Organisation/{organisationId}/users/{userId}/roles
interface useUpdateOrganisationUserRolesProps {
  userId: string
  data: UpdateUserOrganisationRolesModel
}
const useUpdateOrganisationUserRoles = () => {
  const { organisationId } = useGetOrganisationID()
  const client = useApiClient()
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: async (props: useUpdateOrganisationUserRolesProps) =>
      await client.organisation.updateOrganisationUserRoles(
        organisationId,
        props.userId,
        props.data,
      ),
    onSuccess: () => {
      queryClient.refetchQueries(['orgRoles'])
      queryClient.refetchQueries(['orgUserRoles'])
    },
  })
  return mutation
}

// GET:/api/Organisation/{organisationId}/stats
export interface getStatsDashboardQueryparams {
  statType?: string
}
const useGetStatsDashboard = (statType: string) => {
  const { organisationId, isLoading } = useGetOrganisationID()
  const client = useApiClient()
  const query: getStatsDashboardQueryparams = {
    statType: statType,
  }
  const result = useQuery({
    queryKey: ['organisation-stats', statType, { organisationId }],
    queryFn: () => client.organisation.getStatsDashboard(organisationId, query),
    enabled: !isLoading && !!organisationId,
    staleTime: Infinity,
  })
  return { ...result, stats: result.data?.data ?? [] }
}

// GET:/api/Organisation/{organisationId}/courses/instances/users/{userId}
const useGetUserCoursesByOrganisation = (
  organisationId: string,
  userId: string,
) => {
  const client = useApiClient()
  const result = useQuery({
    queryKey: ['organisation'],
    queryFn: () =>
      client.organisation.getUserCoursesByOrganisation(organisationId, userId),
  })
  return { ...result, userCoursesByOrganisation: result.data?.data ?? null }
}
// GET:/api/Organisation/{organisationId}/sitedomains
interface useGetSiteDomainsProps {
  organisationId: string
}
const useGetSiteDomains = () => {
  const client = useApiClient()
  const mutation = useMutation({
    mutationFn: (props: useGetSiteDomainsProps) =>
      client.organisation.getSiteDomains(props.organisationId),
  })
  return mutation
}

export {
  useBranding,
  useGetOrganisation,
  useUpdateOrganisation,
  useGetOrganisationCourses,
  useGetOrganisationUsers,
  useAddNewUserToOrganisation,
  useOrganisationsByUser,
  useAddNewCourseToOrganisation,
  useAddExistingUserToOrganisation,
  useGetOrganisationID,
  useGetUserFirstOrganisationId,
  useGetCourseInstancesByOrganisation,
  useUpdateBannerImage,
  useDeleteBannerImage,
  useUpdateCompanyLogo,
  useDeleteCompanyLogo,
  useUpdateEmailLogo,
  useDeleteEmailLogo,
  useGetSettings,
  useUpdateOrganisationSettings,
  useGetMessageTemplates,
  useGetTestimonials,
  useAddTestimonialToOrganisation,
  useGetPolicyDocuments,
  useAddPolicyDocumentsToOrganisation,
  useGetTopics,
  useAddTopicToOrganisation,
  useCopyCourse,
  useGetOrganisationUsersWithRoles,
  useGetOrganisationRoles,
  useOrganisationUserRoles,
  useUpdateOrganisationUserRoles,
  useGetStatsDashboard,
  useUpdateStripeData,
  useGetOrganisationById,
  useGetUserCoursesByOrganisation,
  useGetSiteDomains,
}
