import { useQuery } from '@tanstack/react-query'

import { useApiClient } from './use-clients'

const useGetUserCourseInstanceById = (participantId: string) => {
  const client = useApiClient()
  const result = useQuery({
    queryKey: ['userCourseInstance', { participantId }],
    queryFn: () =>
      client.userCourseInstance.getUserCourseInstanceById(
        participantId as string
      ),
    enabled: !!participantId,
     staleTime: Infinity,
  })
  return { ...result, userCourseInstance: result.data?.data }
}

export { useGetUserCourseInstanceById }
