import { createContext, useState, ReactNode } from 'react'
import { SectionModel } from 'utils/models/Slide'

interface CurrentSectionContextType {
  currentSection: SectionModel | undefined
  setCurrentSection: (section: SectionModel | undefined) => void
}

export const CurrentSectionContext = createContext<CurrentSectionContextType>({
  currentSection: undefined,
  setCurrentSection: () => {
    //initalized function
  },
})

const CurrentSectionProvider = ({ children }: { children: ReactNode }) => {
  const [currentSection, setCurrentSection] = useState<SectionModel>()

  return (
    <CurrentSectionContext.Provider
      value={{ currentSection, setCurrentSection }}
    >
      {children}
    </CurrentSectionContext.Provider>
  )
}

export { CurrentSectionProvider }
