import { useContext, useState } from 'react'
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Tabs,
  Tab,
  Button,
  TextField,
  OutlinedInput,
  InputAdornment,
  MenuItem,
} from '@mui/material'
import Select from '@mui/material/Select'
import { toast } from 'react-toastify'

import { CurrentSectionContext } from 'components/moduleSlides/CurrentSectionContext'
import { NewSectionContext } from 'components/moduleSlides/NewSectionContext'
import { KeyValueStringObject, emptyObjectKey } from 'utils/emptyObjectKey'
import { useUpdateSection } from 'utils/section'
import {
  HoursAndMinutesToSecondsConverter,
  SecondsToHoursAndMinutesConverter,
} from './TimeLimitConverter'
import { ASSESSOR, AUTO, RANDOM, STRUCTURED } from 'utils/constants/sections'
import QuestionsList from 'components/moduleSlides/QuestionsList'
import ModuleSectionButton from 'components/moduleSlides/ModuleSectionButton'

import AddCircleIcon from '@mui/icons-material/AddCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'

const UpdateAssessmentSection = () => {
  const { currentSection, setCurrentSection } = useContext(
    CurrentSectionContext,
  )
  const { setStatus, setNewSection, title, setTitle, setIsNewSlideEdited } =
    useContext(NewSectionContext)

  const [assessmentTab, setAssessmentTab] = useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setAssessmentTab(newValue)
  }

  const time = SecondsToHoursAndMinutesConverter(
    currentSection?.assessmentTimePerQuestionSeconds ?? (0 as number),
  )

  const [introduction, setIntroduction] = useState(
    currentSection?.contentArea1 as string,
  )
  const [questionOrder, setQuestionOrder] = useState(
    currentSection?.assessmentQuestionOrder,
  )
  const [markProcess, setMarkProcess] = useState(
    currentSection?.assessmentMarkProcess,
  )
  const [passScoreAmount, setPassScoreAmount] = useState(
    currentSection?.assessmentPassRate,
  )
  const [isTimed, setIsTimed] = useState(`${currentSection?.assessmentIsTimed}`)
  const [hours, setHours] = useState(time?.hours ?? 0)
  const [minutes, setMinutes] = useState(time?.minutes ?? 0)
  const [questionsToShow, setQuestionsToShow] = useState(
    currentSection?.assessmentNumQuestionsToShow,
  )
  const [numberOfAttempts, setNumberOfAttempts] = useState(
    currentSection?.assessmentNumberOfAttempts as number,
  )

  const IncrementCount = () => {
    setNumberOfAttempts(numberOfAttempts + 1)
  }
  const DecrementCount = () => {
    if (numberOfAttempts > 0) setNumberOfAttempts(numberOfAttempts - 1)
    else {
      setNumberOfAttempts(0)
    }
  }

  const contentArea1: KeyValueStringObject = {
    contentArea1: introduction,
  }

  const filteredData = emptyObjectKey(contentArea1)

  const data = {
    ...currentSection,
    ...filteredData,
    Name: title?.length ? title : currentSection?.name,
    AssessmentQuestionOrder: questionOrder,
    AssessmentMarkProcess: markProcess,
    AssessmentPassRate: passScoreAmount,
    AssessmentIsTimed: isTimed === 'true' ? true : false,
    AssessmentNumQuestionsToShow: questionsToShow,
    AssessmentTimePerQuestionSeconds: HoursAndMinutesToSecondsConverter(
      hours,
      minutes,
    ),
    AssessmentNumberOfAttempts: numberOfAttempts,
  }

  const { mutateAsync: updateSection } = useUpdateSection()
  const onClickUpdateSectionHandler = async () => {
    try {
      await updateSection({
        sectionId: currentSection?.id as string,
        data,
      })

      setNewSection({})
      setStatus(undefined)
      setCurrentSection(undefined)
      setTitle(undefined)
      setIsNewSlideEdited(false)
      toast.success('Slide updated')
    } catch {
      toast.error('Unable to update slide')
    }
  }

  return (
    <>
      <div className="p-25 width-full-40 max-height-full-40 mt-20 mb-20 border-20 bg-white-opacity-6 overflow-auto">
        <Tabs
          onChange={handleChange}
          value={assessmentTab}
          aria-label="Tabs where selection follows focus"
          selectionFollowsFocus
          className="mb-20"
        >
          <Tab
            label="Settings"
            className="bg-white mr-10 fw-700 min-w-150px min-h-60 text-capitalize border-top-20"
          />
          {currentSection?.id && (
            <Tab
              label="Questions"
              className="bg-white mr-10 fw-700 min-w-150px min-h-60 text-capitalize border-top-20"
            />
          )}
        </Tabs>

        {assessmentTab === 0 && (
          <>
            <p className="text-label">Introduction</p>
            <TextField
              multiline
              autoComplete="off"
              rows={3}
              value={introduction === null ? '' : introduction}
              placeholder="Introduction"
              fullWidth
              className="mb-20 bg-white border-8"
              onChange={e => {
                setIntroduction(e.target.value)
                setIsNewSlideEdited(true)
              }}
            />

            <p className="text-label">Question order</p>
            <Select
              value={questionOrder}
              onChange={e => {
                setQuestionOrder(e.target.value)
                setIsNewSlideEdited(true)
              }}
              className="mb-20 bg-white border-8 w-50"
            >
              <MenuItem value={RANDOM}>Random</MenuItem>
              <MenuItem value={STRUCTURED}>Structured</MenuItem>
            </Select>

            <p className="text-label">Mark Process</p>
            <Select
              value={markProcess}
              onChange={e => {
                setMarkProcess(e.target.value)
                setIsNewSlideEdited(true)
              }}
              className="mb-20 bg-white border-8 w-50"
            >
              <MenuItem value={AUTO}>Auto</MenuItem>
              <MenuItem value={ASSESSOR}>Assessor</MenuItem>
            </Select>

            <p className="text-label">Pass score amount</p>
            <div className="w-90px">
              <OutlinedInput
                type="number"
                value={passScoreAmount}
                className="bg-white border-8 mb-20"
                onChange={e => {
                  setPassScoreAmount(+e.target.value)
                  setIsNewSlideEdited(true)
                }}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </div>

            <p className="text-label">Is timed</p>
            <RadioGroup
              value={isTimed}
              onChange={e => {
                setIsTimed(e.target.value)
                setIsNewSlideEdited(true)
              }}
              row
              className="mb-10"
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>

            <p className="text-label">Time limit amount</p>
            <div className="mb-20 flex">
              <div>
                <p className="text-label">Hours</p>
                <TextField
                  type="number"
                  value={hours}
                  autoComplete="off"
                  className="addRemoveButton p-10 bg-white mr-10 border-8 w-70px border-none"
                  onChange={e => {
                    setHours(+e.target.value)
                    setIsNewSlideEdited(true)
                  }}
                />
              </div>
              <div>
                <p className="text-label">Minutes</p>
                <TextField
                  type="number"
                  value={minutes}
                  autoComplete="off"
                  className="addRemoveButton p-10 bg-white mr-10 border-8 w-70px border-none"
                  onChange={e => {
                    setMinutes(+e.target.value)
                    setIsNewSlideEdited(true)
                  }}
                />
              </div>
            </div>

            <p className="text-label">Random questions</p>
            <p className="text-label">
              Select the number of questions you want to be shown in the
              evaluation. The <br />
              questions will be displayed randomly.
            </p>
            <div className="flex flex-wrap mb-20 align-center">
              <TextField
                type="number"
                value={questionsToShow}
                autoComplete="off"
                className="addRemoveButton p-10 bg-white mr-10 border-8 w-70px border-none"
                onChange={e => {
                  setQuestionsToShow(+e.target.value)
                  setIsNewSlideEdited(true)
                }}
              />
              <p className="text-label">random questions from a total of 50</p>
            </div>

            <p className="text-label">Number of attempts</p>
            <div className="flex mb-30 border-8 overflow-hidden w-190px numberOfAttempts">
              <Button onClick={DecrementCount} className="p-0 bg-bcbfbe">
                <RemoveCircleIcon className="color-white" />
              </Button>
              <TextField
                value={numberOfAttempts}
                autoComplete="off"
                className="addRemoveButton border-none p-0"
                onChange={e => {
                  setNumberOfAttempts(+e.target.value)
                  setIsNewSlideEdited(true)
                }}
              />
              <Button onClick={IncrementCount} className="p-0 bg-bcbfbe">
                <AddCircleIcon className="color-white" />
              </Button>
            </div>

            <ModuleSectionButton
              onClickIconHandler={onClickUpdateSectionHandler}
              name="Update"
            />
          </>
        )}

        {assessmentTab === 1 && <QuestionsList />}
      </div>
    </>
  )
}

export default UpdateAssessmentSection
