/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AddAnswerOptionModel,
  AnswerOptionModel,
  NotFoundExceptionModel,
  QuestionModel,
  UnauthorizedExceptionModel,
  UpdateAnswerOptionOrderModel,
  UpdateQuestionModel,
  ValidationExceptionModel,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Question<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Question
   * @name GetQuestion
   * @request GET:/api/Question/{questionId}
   * @secure
   */
  getQuestion = (questionId: string, params: RequestParams = {}) =>
    this.request<QuestionModel, NotFoundExceptionModel>({
      path: `/api/Question/${questionId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Question
   * @name UpdateQuestion
   * @request PUT:/api/Question/{questionId}
   * @secure
   */
  updateQuestion = (
    questionId: string,
    data: UpdateQuestionModel,
    params: RequestParams = {},
  ) =>
    this.request<
      QuestionModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Question/${questionId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Question
   * @name DeleteQuestion
   * @request DELETE:/api/Question/{questionId}
   * @secure
   */
  deleteQuestion = (questionId: string, params: RequestParams = {}) =>
    this.request<void, UnauthorizedExceptionModel | NotFoundExceptionModel>({
      path: `/api/Question/${questionId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    })
  /**
   * No description
   *
   * @tags Question
   * @name GetAnswerOptions
   * @request GET:/api/Question/{questionId}/answersoptions
   * @secure
   */
  getAnswerOptions = (questionId: string, params: RequestParams = {}) =>
    this.request<AnswerOptionModel[], NotFoundExceptionModel>({
      path: `/api/Question/${questionId}/answersoptions`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Question
   * @name AddAnswerOption
   * @request POST:/api/Question/{questionId}/answersoptions
   * @secure
   */
  addAnswerOption = (
    questionId: string,
    data: AddAnswerOptionModel,
    params: RequestParams = {},
  ) =>
    this.request<
      AnswerOptionModel[],
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Question/${questionId}/answersoptions`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Question
   * @name UpdateAnswerOptionOrder
   * @request PUT:/api/Question/{questionId}/answersoptions/order
   * @secure
   */
  updateAnswerOptionOrder = (
    questionId: string,
    data: UpdateAnswerOptionOrderModel,
    params: RequestParams = {},
  ) =>
    this.request<
      AnswerOptionModel[],
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Question/${questionId}/answersoptions/order`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
