import { MenuItem, Select } from '@mui/material'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { UpdateModuleModel } from 'api/data-contracts'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useNavigate, useParams } from 'react-router-dom'

import { useAddNewModuleToCourse, AddModuleToCourseModel } from 'utils/course'
import { ConfirmPopup } from 'components/moduleSlides/ConfirmPopup'
import { useContext, useState } from 'react'
import { FormTextField } from 'components/FormTextField'
import { ActionButton } from 'components/ActionButton'
import { useBranding } from 'utils/useBranding'
import { PageTitle } from 'utils/constants/pageTitles'
import TitleHelmet from 'components/TitleHelmet'
import { AUTO, TRAINER } from 'utils/constants/modules'
import { Fileprops } from 'utils/models/File'
import ImageUploadDragAndDrop from 'components/ImageUploadDragAndDrop'
import { LockdownCourseContext } from 'components/moduleSlides/LockdownCourseContext'
import { CurrentSectionContext } from 'components/moduleSlides/CurrentSectionContext'
import CustomCheckedBoxwithLabel from 'components/CustomCheckedBoxwithLabel'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import { editorConfiguration } from 'utils/ckeditor/EditorConfiguration'

const validationSchema = Yup.object<UpdateModuleModel>().shape({
  Name: Yup.string().required('Name is required'),
  Description: Yup.string().required('Description is required'),
})

const NewDetails = () => {
  const { mutateAsync: addModule } = useAddNewModuleToCourse()
  const navigate = useNavigate()
  const { branding } = useBranding()
  const { courseId } = useParams()
  const [moduleCompletionImageFile, setModuleCompletionImageFile] =
    useState<Fileprops | null>(null)
  const { isCurrentCourseLocked } = useContext(LockdownCourseContext)
  const [showConfirm, setShowConfirm] = useState(false)
  const { setCurrentSection } = useContext(CurrentSectionContext)

  const onCancel = () => {
    navigate(`/settings/course-setup/modules/${courseId}`)
    setShowConfirm(false)
  }

  const onCancelHandler = () => {
    if (
      !watch('Name') &&
      !watch('Description') &&
      !watch('ModuleProgression')
    ) {
      navigate(`/settings/course-setup/modules/${courseId}`)
      setShowConfirm(false)
    } else {
      setShowConfirm(true)
    }
  }
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<AddModuleToCourseModel>({
    resolver: yupResolver(validationSchema),
  })

  const onSubmit: SubmitHandler<AddModuleToCourseModel> = data => {
    setCurrentSection(undefined)
    addModule({
      Name: data.Name,
      Description: data.Description,
      ModuleProgression: data.ModuleProgression,
      ModuleCompletionTitle: data.ModuleCompletionTitle,
      ModuleCompletionDescription: data.ModuleCompletionDescription,
      ModuleCompletionImage: moduleCompletionImageFile as File,
      PreInfo: data.PreInfo,
      SendCompleteEmail: data.SendCompleteEmail,
      ModuleCompleteEmailOptional: data.ModuleCompleteEmailOptional,
    }).then(module => {
      navigate(`/settings/module-setup/slides/${courseId}/${module.data.id}`)
    })
  }

  return (
    <>
      <TitleHelmet title={PageTitle.COURSE_SETUP_ADD_MODULE} />
      <h1>{PageTitle.COURSE_SETUP_ADD_MODULE}</h1>
      <form>
        <div className="flex mb-20 flex-wrap justify-between">
          <div className="w-full desktop-w-50">
            <FormTextField
              control={control}
              errors={errors?.Name}
              name="Name"
              label="Name"
              length={true}
            />
            <FormTextField
              control={control}
              errors={errors?.Description}
              name="Description"
              label="Description"
              length={true}
            />
            <Controller
              name={'ModuleProgression'}
              control={control}
              defaultValue={AUTO}
              render={({ field: { onChange, value } }) => (
                <div className="w-full mb-25 min-w-300px">
                  <p className="text-label">Module Progression</p>
                  <Select
                    value={value}
                    onChange={onChange}
                    error={errors.ModuleProgression?.message !== undefined}
                    className="bg-white"
                  >
                    <MenuItem value={AUTO}>Auto</MenuItem>
                    <MenuItem value={TRAINER}>Trainer</MenuItem>
                  </Select>
                </div>
              )}
            />
            <FormTextField
              control={control}
              errors={errors?.PreInfo}
              name="PreInfo"
              label="Pre module information"
              multilineValue={4}
              length={true}
            />
          </div>
        </div>
        <hr className="text-gray opacity-30"></hr>
        <div className="flex mb-20 flex-wrap justify-between">
          <div className="w-full desktop-w-50">
            <h2>Module Completion setup</h2>
            <FormTextField
              control={control}
              errors={errors?.ModuleCompletionTitle}
              name="ModuleCompletionTitle"
              label="Completion title"
              length={true}
            />
            <FormTextField
              control={control}
              errors={errors?.ModuleCompletionDescription}
              name="ModuleCompletionDescription"
              label="Completion description"
              length={true}
            />

            <div className="w-full mb-30">
              <Controller
                name="SendCompleteEmail"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <CustomCheckedBoxwithLabel
                    label="Send Completion Email"
                    action={field.value || false}
                    setAction={(value: boolean) => {
                      field.onChange(value)
                    }}
                  />
                )}
              />
              <p className="text-label">Completion Email</p>
              <Controller
                name="ModuleCompleteEmailOptional"
                control={control}
                render={({ field }) => (
                  <CKEditor
                    editor={Editor}
                    config={editorConfiguration}
                    onChange={(event, editor) => {
                      const data = editor.data.get()
                      field.onChange(data)
                    }}
                    data={field.value}
                  />
                )}
              />
            </div>
            <div className="w-full mb-30">
              <p className="text-label">Image</p>
              <ImageUploadDragAndDrop
                file={moduleCompletionImageFile}
                setFile={setModuleCompletionImageFile}
                fileTypes={['JPG', 'PNG', 'JPEG']}
              />
            </div>
          </div>
        </div>
        <ActionButton
          name="Next"
          background={branding.stylingPrimary ?? 'blue'}
          setAction={handleSubmit(onSubmit)}
          className={`${
            isCurrentCourseLocked && 'pointer-event-none opacity-50'
          }`}
        />
        <ActionButton
          name="Cancel"
          background={branding.stylingSecondary ?? 'orange'}
          setAction={onCancelHandler}
          className={`${
            isCurrentCourseLocked && 'pointer-event-none opacity-50'
          }`}
        />
      </form>
      {showConfirm && (
        <ConfirmPopup
          title="Confirm cancel"
          message="Are you sure you want to stop creating this module?"
          onConfirm={onCancel}
          open={showConfirm}
          onCancel={() => setShowConfirm(false)}
        />
      )}
    </>
  )
}
export default NewDetails
