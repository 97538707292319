import { useState, useEffect } from 'react'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'

import CustomInputTextWithLabel from '../../CustomInputTextWithLabel'
import { ActionButton } from '../../ActionButton'
import { useBranding } from 'utils/useBranding'
import * as ROUTE from '../../../utils/constants/clientRoutes'

// API
import { useUpdatePolicyDocument } from 'utils/policyDocuments'
import {
  useAddPolicyDocumentsToOrganisation,
  useGetOrganisationID,
} from 'utils/organisation'
import { editorConfiguration } from 'utils/ckeditor/EditorConfiguration'

const PolicyDetails = () => {
  const { organisationId } = useGetOrganisationID()
  const { branding } = useBranding()
  const { policyId } = useParams()
  const navigate = useNavigate()
  const { state: policyDocumentData } = useLocation()

  const { mutateAsync: updatePolicyDocument } = useUpdatePolicyDocument()
  const { mutateAsync: addPolicyDocumentsToOrganisation } =
    useAddPolicyDocumentsToOrganisation()

  const [name, setName] = useState<string>('')
  const [content, setContent] = useState<string>('')

  useEffect(() => {
    setName(policyDocumentData?.name)
    setContent(policyDocumentData?.content)
  }, [policyDocumentData])

  const onClickCreatePolicyHandler = async () => {
    if (organisationId !== '') {
      try {
        await addPolicyDocumentsToOrganisation({
          organisationId,
          data: {
            name,
            content,
          },
        })
        toast.success('Policy created')
        navigate(ROUTE.POLICY_DOCUMENTS)
      } catch {
        toast.error('Error')
      }
    }
  }

  const onClickUpdatePolicyHandler = async () => {
    try {
      await updatePolicyDocument({
        policyId: policyDocumentData?.id,
        data: {
          name,
          content,
        },
      })
      toast.success('Policy updated')
      navigate(ROUTE.POLICY_DOCUMENTS)
    } catch {
      toast.error('Error')
    }
  }

  return (
    <>
      <CustomInputTextWithLabel title="Name" value={name} setAction={setName} />

      <p className="text-label">Content</p>

      <div className="mb-30 p-20 bg-BCBFBE border-10">
        <CKEditor
          editor={Editor}
          config={editorConfiguration}
          data={content === null || content === undefined ? '' : content}
          onChange={(event, editor) => {
            const data = editor.data.get()
            setContent(data)
          }}
        />
      </div>

      {policyId ? (
        <ActionButton
          name="Update policy"
          background={branding.stylingPrimary ?? 'blue'}
          setAction={onClickUpdatePolicyHandler}
        />
      ) : (
        <ActionButton
          name="Create policy"
          background={branding.stylingPrimary ?? 'blue'}
          setAction={onClickCreatePolicyHandler}
        />
      )}
    </>
  )
}

export default PolicyDetails
