import { useEffect } from 'react'
import * as Yup from 'yup'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'

import { UpdateUserModel, UserModel } from 'api/data-contracts'
import { Header } from 'components/lib'
import { PageTitle } from 'utils/constants/pageTitles'
import { useBranding } from 'utils/useBranding'
import { ActionButton } from 'components/ActionButton'
import { FormTextField } from 'components/FormTextField'
import { useUpdateUser } from 'utils/user'

interface ContactInfromationProps {
  userDetails: UserModel
  setUserDetailTab: React.Dispatch<React.SetStateAction<number>>
  setContactInfo: React.Dispatch<React.SetStateAction<boolean>>
}

const validationSchema = Yup.object<UserModel>().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Email entered is invalid'),
  firstname: Yup.string().required('First name is required'),
  lastname: Yup.string().required('Last name is required'),
  department: Yup.string().required('Department is required'),
})

const ContactInfromation = ({
  userDetails,
  setUserDetailTab,
  setContactInfo,
}: ContactInfromationProps) => {
  const { branding } = useBranding()
  const { mutateAsync: updateUser } = useUpdateUser()

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<UpdateUserModel>({
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    if (userDetails) {
      setValue('email', userDetails.email!)
      setValue('firstname', userDetails.firstname!)
      setValue('lastname', userDetails.lastname!)
      setValue('department', userDetails.department!)
    }
  }, [userDetails, setValue])

  const onSubmit: SubmitHandler<UpdateUserModel> = async data => {
    const contactInformation = {
      email: data.email,
      firstname: data.firstname,
      lastname: data.lastname,
      department: data.department,
      userName: data.email,
    }

    try {
      await updateUser({ userid: userDetails.id!, data: contactInformation })
      toast.success('Contact information updated')
    } catch {
      toast.error('Unable to update contact information')
    }
  }

  return (
    <>
      <Header variant="signin">Manage contact information</Header>

      <button
        className="mb-30 p-0 fw-700 bg-transparent border-none fs-18"
        style={{ color: `${branding.stylingSecondary}` }}
        onClick={() => {
          setUserDetailTab(0)
          setContactInfo(false)
        }}
      >
        {'<'} {PageTitle.NAV_BACK}
      </button>

      <form>
        <FormTextField
          control={control}
          errors={errors?.email}
          name="email"
          label="Email"
          length={true}
        />
        <FormTextField
          control={control}
          errors={errors?.firstname}
          name="firstname"
          label="Firstname"
          length={true}
        />
        <FormTextField
          control={control}
          errors={errors?.lastname}
          name="lastname"
          label="Lastname"
          length={true}
        />
        <FormTextField
          control={control}
          errors={errors?.department}
          name="department"
          label="Department"
          length={true}
        />
        <ActionButton
          name="Save details"
          background={branding.stylingPrimary ?? 'blue'}
          setAction={handleSubmit(onSubmit)}
        />
      </form>
    </>
  )
}

export default ContactInfromation
