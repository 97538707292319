export const getEnv = () => {
  return {
    API_URL: process.env.REACT_APP_API_URL,
    NODE_ENV: process.env.NODE_ENV,
    ACCESS_TOKEN: process.env.REACT_APP_ACCESS_TOKEN,
    REFRESH_TOKEN: process.env.REACT_APP_REFRESH_TOKEN,
    LOG_ROCKET_PROJECT_ID: process.env.REACT_APP_LOG_ROCKET_PROJECT_ID_PORTAL,
  }
}

type ENV = ReturnType<typeof getEnv>

// App puts these on
declare global {
  // eslint-disable-next-line
  var ENV: ENV
  interface Window {
    ENV: ENV
  }
}
