import { lazy } from 'react'
import Loadable from 'ui-component/Loadable'

import MinimalLayout from 'layout/MinimalLayout'

// Constants
import * as ROUTE from '../utils/constants/clientRoutes'

const Login = Loadable(lazy(() => import('pages/Login')))

const AuthenticatedApp = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: ROUTE.LOGIN,
      element: <Login />,
    },
  ],
}

export default AuthenticatedApp
