import { useMemo, useState } from 'react'
import '../../styles/table.css'
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table'
import { useGetOrganisationUsers } from 'utils/organisation'
import { UserModel } from 'api/data-contracts'
import { FormatDate } from 'utils/FormatDate'
import AnchorLink from 'components/AnchorLink'

interface UsersTableProps {
  setDrawerAction: (value: boolean) => void
  setUserDetailData: React.Dispatch<React.SetStateAction<UserModel>>
}
const UsersTable = ({
  setDrawerAction,
  setUserDetailData,
}: UsersTableProps) => {
  const { users, isError } = useGetOrganisationUsers()

  const [rowSelection, setRowSelection] = useState({})

  const columns = useMemo<MRT_ColumnDef<UserModel>[]>(
    () => [
      {
        accessorKey: 'userName',
        header: 'User name',
        Cell: ({ cell }) => (
          <>
            <AnchorLink
              className="p-0"
              onClick={() => {
                setDrawerAction(true)
                setUserDetailData(cell?.row?.original)
              }}
            >
              {cell.getValue<number>().toLocaleString()}
            </AnchorLink>
          </>
        ),
      },
      {
        accessorKey: 'firstname',
        header: 'First name',
      },
      {
        accessorKey: 'lastname',
        header: 'Last name',
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },
      /* {
        accessorKey: 'department',
        header: 'Department',
      }, */
      {
        accessorKey: 'lastLoggedIn',
        header: 'Last logged in',
        Cell: ({ cell }) =>
          FormatDate(cell?.row?.original?.lastLoggedIn as string),
      },
    ],
    [setDrawerAction, setUserDetailData],
  )
  if (isError) {
    return <span>Error loading courses</span>
  }

  if (typeof users === 'undefined') {
    return <div>No courses found.</div>
  }
  return (
    <MaterialReactTable
      columns={columns}
      data={users}
      initialState={{
        showGlobalFilter: true,
      }}
      enableGlobalFilterModes
      muiSearchTextFieldProps={{
        placeholder: `Search`,
        sx: { maxWidth: '200px' },
        variant: 'outlined',
      }}
      muiTablePaperProps={{
        elevation: 0,
        sx: {
          '& tr > th > div': {
            display: 'flex',
            fontSize: '18px',
          },
          '& tr > th > div > div > span': {
            display: 'none',
          },
          borderRadius: '0',
          border: 'none',
        },
      }}
      enableRowSelection
      enableSelectAll={false}
      enableMultiRowSelection={false}
      getRowId={row => row.id || ''}
      onRowSelectionChange={setRowSelection}
      state={{ rowSelection }} //pass our managed row selection state to the table to use
      positionToolbarAlertBanner={'none'}
      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={true}
      enableSorting={true}
      enableBottomToolbar={true}
      enableTopToolbar={true}
      enableToolbarInternalActions={false}
      muiTableBodyRowProps={{ hover: false }}
    />
  )
}

export default UsersTable
