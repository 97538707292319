import { useState, useEffect } from 'react'
import { Drawer } from '@mui/material'
import { useParams } from 'react-router-dom'

// API - custom hooks
import { useGetOrganisationCourses } from 'utils/organisation'
import CourseInstanceDetails from './CourseInstanceDetails'

interface AddUserToOrgFormProps {
  drawerVisibility: boolean
  drawerClose: () => void
  id?: string
  settingCourseInstanceId?: string
}

const CourseInstancePopout = ({
  drawerVisibility,
  drawerClose,
  id,
  settingCourseInstanceId,
}: AddUserToOrgFormProps) => {
  const { courses } = useGetOrganisationCourses()
  const { courseId } = useParams()
  const [courseID, setCourseID] = useState('')

  useEffect(() => {
    courseId && setCourseID(courseId)
    drawerVisibility === false && setCourseID('')
  }, [drawerVisibility, courseId])

  return (
    <Drawer
      anchor={'right'}
      open={drawerVisibility}
      onClose={() => drawerClose()}
    >
      <div className="m-3rem mt-5rem min-w-100 min-w-500px">
        {id === 'EDIT' ? (
          <h2>Edit a course instance</h2>
        ) : (
          <h2>Create a new instance</h2>
        )}

        {courseID === '' ? (
          <>
            <p className="text-label mb-20">
              Please select which course you would like to
              <br />
              create an instance of
            </p>

            {courses &&
              courses?.map(value => (
                <div
                  key={value.id}
                  className="bg-white mt-20 mb-20 fw-600 p-20 cursor-pointer border-8"
                  onClick={() => setCourseID(value?.id as string)}
                >
                  {value.name}
                </div>
              ))}
          </>
        ) : (
          <CourseInstanceDetails
            courseID={courseID}
            setCourseID={setCourseID}
            drawerClose={drawerClose}
            id={id}
            settingCourseInstanceId={settingCourseInstanceId}
          />
        )}
      </div>
    </Drawer>
  )
}

export default CourseInstancePopout
