import jwt_decode from 'jwt-decode'

export const isValidToken = (token: string): boolean => {
  const decoded = jwt_decode(token) as { [key: string]: string }
  const orgRole = decoded.ORGROLE
  if (orgRole && orgRole.length > 0) {
    return true
  } else {
    return false
  }
  return false
}
