import { FormControlLabel, Button } from '@mui/material'
import MuiCheckbox from '@mui/material/Checkbox'

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'

import DeleteIcon from '@mui/icons-material/Delete'
import { AnswerDataModel } from 'utils/question'

interface MultiSelectProps {
  answerData: AnswerDataModel[]
  onChangeCheckboxHandler: (value: number) => void
  onClickDeleteAnswerHandler: (value: number, id: string) => void
}

const MultiSelect = ({
  answerData,
  onChangeCheckboxHandler,
  onClickDeleteAnswerHandler,
}: MultiSelectProps) => {
  return (
    <>
      {answerData && answerData.length
        ? answerData.map((key, index) => (
            <div className="questionCheckedboxWrapper" key={index}>
              <FormControlLabel
                value={key.answerText}
                control={
                  <MuiCheckbox
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<RadioButtonCheckedIcon />}
                    checked={key.isCorrect === true}
                  />
                }
                onChange={() => onChangeCheckboxHandler(index)}
                label={key.answerText}
                labelPlacement="start"
              />
              <Button
                sx={{ background: '#fff' }}
                onClick={() =>
                  onClickDeleteAnswerHandler(index, key.id as string)
                }
              >
                <DeleteIcon />
              </Button>
            </div>
          ))
        : ''}
    </>
  )
}

export default MultiSelect
