import React from 'react'
import { Link } from 'react-router-dom'
import { StandardButton } from './lib'

interface CustomLinkProps {
  to?: string
  children: React.ReactNode
  className?: string
  onClick?: () => void
  style?: object
}

const AnchorLink: React.FC<CustomLinkProps> = ({
  to,
  children,
  className,
  onClick,
  style,
}) => {
  const isInternalLink = (url: string) => {
    const isCurrentDomain = url.startsWith(window.location.host)
    const isRelativePath = !url.includes('://')

    return isCurrentDomain || isRelativePath
  }

  return to ? (
    isInternalLink(to) ? (
      <Link to={to} onClick={onClick} className={className}>
        {children}
      </Link>
    ) : (
      <a
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
        onClick={onClick}
      >
        {children}
      </a>
    )
  ) : (
    <StandardButton
      onClick={onClick}
      className={className}
      variant="outlined"
      style={{
        color: '#4831D4',
        textDecoration: 'none',
        border: 'none',
        background: 'none',
        ...style,
      }}
    >
      {children}
    </StandardButton>
  )
}

export default AnchorLink

//handle if onClick prop
//
