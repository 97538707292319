import TitleHelmet from 'components/TitleHelmet'
import PaymentDetails from 'components/companySettings/Payments'
import { PageTitle } from 'utils/constants/pageTitles'

const Payments = () => {
  return (
    <>
      <h1>{PageTitle.PAYMENTS}</h1>
      <TitleHelmet title={PageTitle.PAYMENTS} />
      <PaymentDetails />
    </>
  )
}

export default Payments
