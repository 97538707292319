export enum PageTitle {
  ELEARNING = 'eLearning',
  DASHBOARD = 'Dashboard',
  OVERVIEW_PANEL = 'Overview Panel',
  ACTIVE_COURSES = 'Active courses',

  SETTINGS = 'Settings',
  COMPANY_SETTING_COMPANY_DETAILS = 'Company details',
  PAYMENTS = 'Payments',
  COMPANY_SETTING_SECURITY = 'Security',
  COMPANY_SETTING_POLICY_DOCUMENTS = 'Policy documents',
  COURSES = 'Courses',
  ACTIVE_USERS = 'Active users',
  DELETE_USERS = 'Delete users',
  ROLES = 'Roles',
  THEMES = 'Themes',
  MESSAGE_TEMPLATES = 'Message templates',
  TOPICS = 'Topics',
  MEMBERS = 'Members',

  // course-setup
  COURSE_SETUP = 'Course setup',
  COURSE_SETUP_CONFIGURATION = 'Configuration',
  COURSE_SETUP_COURSE_INSTANCES = 'Course instances',
  COURSE_SETUP_COURSE_INSTANCE = 'Course instance',
  COURSE_SETUP_COURSE_INSTANCE_PARTICIPANT_DETAILS = 'Participant Details',
  COURSE_SETUP_COURSE_INSTANCE_PARTICIPANTS_MODULE = 'Participants Module',
  COURSE_SETUP_ACCESS = 'Access',
  COURSE_SETUP_MODULES = 'Modules',
  COURSE_SETUP_ADD_MODULE = 'Add a module',
  COURSE_SETUP_MODULE_DETAILS = 'Module details',
  COURSE_SETUP_RESOURCES = 'Resources',
  COURSE_SETUP_QUESTIONS = 'Questions',
  COURSE_SETUP_MESSAGE_FLOW = 'Message flow',

  // module-setup
  MODULE_SETUP = 'Module setup',
  COURSE_SETUP_MODULE_SLIDES = 'Module slides',

  NAV_BACK = 'Back',
  PLATFORM_SETTINGS = 'Platform settings',
  TESTIMONIALS = 'Testimonials',
}
