import { ReactElement, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TuneIcon from '@mui/icons-material/Tune'
import Filter1Icon from '@mui/icons-material/Filter1'
import SchoolIcon from '@mui/icons-material/School'
import BookIcon from '@mui/icons-material/Book'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import QuizIcon from '@mui/icons-material/Quiz'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'

import * as ROUTE from '../../../utils/constants/clientRoutes'
import AnchorLink from 'components/AnchorLink'
import { setQuestionList, setQuestionType } from 'utils/question'
import { PageTitle } from 'utils/constants/pageTitles'

const drawerWidth = 255

export default function ClippedDrawer() {
  // get courseId from react query
  //const queryClient = useQueryClient()
  //const courseId = queryClient.getQueryData(['courseId'])
  //OR
  // get courseId from URL
  const { courseId } = useParams()

  const navigate = useNavigate()

  type NavItem = {
    label: string
    path?: string
    icon: ReactElement
    children?: NavItem[]
  }

  const navigation: NavItem[] = [
    {
      label: 'Configuration',
      path: `/settings/course-setup/configuration/${courseId}`,
      icon: <TuneIcon />,
    },
    {
      label: 'Course instances',
      path: `/settings/course-setup/course-instances/${courseId}`,
      icon: <Filter1Icon />,
    },
    /* {
      label: 'Access',
      path: `/settings/course-setup/access/${courseId}`,
      icon: <SupervisorAccountIcon />,
    }, */
    {
      label: 'Modules',
      path: `/settings/course-setup/modules/${courseId}`,
      icon: <SchoolIcon />,
    },
    {
      label: 'Resources',
      path: `/settings/course-setup/resources/${courseId}`,
      icon: <BookIcon />,
    },
    {
      label: 'Questions',
      path: `/settings/course-setup/questions/${courseId}`,
      icon: <QuizIcon />,
    },
    {
      label: 'Message flow',
      path: `/settings/course-setup/message-flow/${courseId}`,
      icon: <QuestionAnswerIcon />,
    },
  ]

  const sxHide = {
    display: 'none',
  }
  const sxShow = {
    display: 'block',
    paddingLeft: '15px',
  }

  const [showChildren, setShowChildren] = useState<{ [key: string]: boolean }>(
    {},
  )

  const onClickHandler = (item: NavItem) => {
    navigate(item.path as string)
    setShowChildren({ ...showChildren, [item.path || '']: false })
    if (item.label === 'Questions') {
      setQuestionList('true')
      setQuestionType('')
    }
  }

  //add CSS for show hide children, relevant icons and dropdown icon
  const renderNavItem = (item: NavItem) => (
    <ListItem
      sx={{ flexDirection: 'Column', alignItems: 'stretch' }}
      key={item.path}
      disablePadding
    >
      <ListItemButton onClick={() => onClickHandler(item)}>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.label} />
        {!item.children ? '' : <ExpandMoreIcon />}
      </ListItemButton>
      {item.children && (
        <List
          sx={showChildren ? sxShow : sxHide}
          className={showChildren ? 'show-children' : 'hide-children'}
        >
          {item.children.map(child => renderNavItem(child))}
        </List>
      )}
    </ListItem>
  )

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: 'fit-content',
          boxSizing: 'border-box',
          flexDirection: 'column',
        },
        display: {
          xs: 'none',
          md: 'block',
        },
      }}
    >
      <Toolbar />
      <AnchorLink
        onClick={() => navigate(ROUTE.COURSES)}
        style={{
          display: 'flex',
          flexGrow: '1',
          justifyContent: 'start',
          position: 'relative',
          alignItems: 'center',
          marginTop: '20px',
          padding: '8px 16px',
          color: '#000000DE',
          overflowWrap: 'break-word',
          cursor: 'pointer',
          fontWeight: '400',
          fontSize: '1.1428571428571428rem',
          lineHeight: '1.5',
        }}
      >
        <ArrowBackIcon
          fontSize="medium"
          sx={{
            marginRight: '5px',
            display: 'inline-flex',
            flexShrink: '0',
          }}
        />
        <span
          style={{
            display: 'block',
            flex: '1 1 auto',
            minWidth: '0',
            marginTop: '4px',
            marginBottom: '4px',
          }}
        >
          {PageTitle.NAV_BACK}
        </span>
      </AnchorLink>
      <div className="flex overflow-auto h-full justify-between flex-column">
        <List>{navigation.map(renderNavItem)}</List>
      </div>
    </Drawer>
  )
}
