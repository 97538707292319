/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ContentItemModel,
  NotFoundExceptionModel,
  ValidationExceptionModel,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class ContentItem<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags ContentItem
   * @name DeleteContentItem
   * @request DELETE:/api/ContentItem/{contentItemId}
   * @secure
   */
  deleteContentItem = (contentItemId: string, params: RequestParams = {}) =>
    this.request<void, NotFoundExceptionModel>({
      path: `/api/ContentItem/${contentItemId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    })
  /**
   * No description
   *
   * @tags ContentItem
   * @name UpdateContentItem
   * @request PUT:/api/ContentItem/{contentItemId}
   * @secure
   */
  updateContentItem = (
    contentItemId: string,
    data: {
      ContentValue?: string
      /** @format binary */
      ContentValueImage?: File
    },
    params: RequestParams = {},
  ) =>
    this.request<
      ContentItemModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/ContentItem/${contentItemId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
}
