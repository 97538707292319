import { useState, useContext } from 'react'
import { FileUploader } from 'react-drag-drop-files'

import { ResourceFileContext } from './ResourceFileContext'
import { Fileprops } from 'utils/models/File'

const fileTypes = ['JPG', 'PNG', 'GIF', 'SVG', 'PDF']

export const FileUpload = () => {
  const { setResourceFile } = useContext(ResourceFileContext)
  const [file, setFile] = useState<Fileprops>()

  const handleChange = (file: Fileprops) => {
    setFile(file as File)
    setResourceFile(file as File)
  }

  return (
    <>
      <div className="width-full-40 m-20">
        <FileUploader
          classes="DragAndDropFile"
          multiple={false}
          handleChange={handleChange}
          name="file"
          types={fileTypes}
          label="Upload or Drop a file here"
        />

        <div className="flex flex-column align-center">
          {file && (
            <>
              <p style={{ textAlign: 'center', margin: '10px 0' }}>
                {`Image: ${file.name}`}
              </p>
            </>
          )}
        </div>
      </div>
    </>
  )
}
